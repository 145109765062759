/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setDashboardsOrganizationLinking = createAsyncThunk("dashboardsOrganizationLinking/setDashboardsOrganizationLinking", async (linking) => {
  return linking;
}
);

const initialState = {
  grafana_dashboard_id: "",
  grafana_dashboard_link_id: "",
  operation_profile_id: "",
  order_by: "",
  status: "active"
};
// const initialState = {
//   grafana_dashboard_id: "20221109072631897_mxKg6GNFdQm1IVVa0",
//   grafana_dashboard_link_id: "20221110195339905_Q4t2c1kczMERp1gfJ",
//   grafana_dashboard_name: "Machine CPS Comparison",
//   grafana_dashboard_name_backend: "MachineCWSComparison",
//   operation_name: "Gold Fields - South Deep",
//   operation_profile_id: 80,
//   order_by: 0,
//   status: "active"
// };

const dashboardsOrganizationLinkingSlice = createSlice({
  name: "dashboardsOrganizationLinking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDashboardsOrganizationLinking.fulfilled, (state, action) => action.payload);
  },
});

export const selectDashboardsOrganizationLinking = ({
  dashboardsOrganizationLinking,
}) => dashboardsOrganizationLinking;

export default dashboardsOrganizationLinkingSlice.reducer;
