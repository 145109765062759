import Typography from "@mui/material/Typography";

export default function NumberTypography({ children, color }) {
  return (
    <Typography
      sx={{
        color: color ?? "#198754",
        m: 0,
      }}
    >
      {children}
    </Typography>
  );
}
