/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setTheme = createAsyncThunk('theme/setTheme', async (theme) => {

  return theme;
});



const initialState =  "light"

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setTheme.fulfilled, (state, action) => action.payload);
  },
});

export const selectTheme = ({ theme }) => theme;

export default themeSlice.reducer;
