import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import CommonDateTimePicker from "../common/commonDateTimePicker";

function HomeGrafanaPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {t("IoT")}
          </Typography>
          <Typography
            sx={{
              mt: 1,
              color: theme.palette.custom.buttonTextColor,
            }}
            variant="h4"
            gutterBottom
          >
            {t("InternetOfThings")}
          </Typography>
        </Box>
        <Typography
          sx={{
            mb: 0,
            color: theme.palette.custom.buttonTextColor,
          }}
          variant="h4"
          gutterBottom
        >
          {t("Dashboard")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <CommonDateTimePicker />
    </Box>
  );
}

export default HomeGrafanaPage;
