import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../StyledTypography";
import { LatestDataColoring } from "../../utils/systemInfo";

export default function NotificationsTableRow({
  handleClick,
  row,
  labelId,
  viewAllPage,
}) {
  const theme = useTheme();
  return (
    <TableRow
      hover
      tabIndex={-1}
      key={row.notification}
      sx={{
        cursor: "pointer",
        backgroundColor: theme.palette.custom.backgroundColor,
        "&:hover": {
          backgroundColor: theme.palette.custom.buttonHoverColor,
        },
      }}
    >
      <TableCell
        sx={{
          height: "100%",
          color: theme.palette.custom.text,
          padding: "16px",
        }}
        component="th"
        id={labelId}
        scope="row"
      >
        <StyledTypography
          color={LatestDataColoring(row.timestamp, true)}
          variant="subtitle2"
        >
          {viewAllPage ? row.notifcation : row.notification}
        </StyledTypography>
      </TableCell>
      <TableCell
        sx={{
          height: "100%",
          color: theme.palette.custom.text,
          padding: "16px",
        }}
        component="th"
        id={labelId}
        scope="row"
      >
        <StyledTypography variant="subtitle2">{row.info}</StyledTypography>{" "}
      </TableCell>
      <TableCell
        sx={{
          height: "100%",
          color: theme.palette.custom.text,
          padding: "16px",
        }}
        component="th"
        id={labelId}
        scope="row"
      >
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            display: "flex",
            flexFlow: "row",
            justifyContent: "left",
          }}
        >
          <StyledTypography
            color={LatestDataColoring(row.timestamp, true)}
            variant="subtitle2"
          >
            {row.timestamp}
          </StyledTypography>{" "}
        </Box>
      </TableCell>
    </TableRow>
  );
}
