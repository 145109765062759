/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
} from "../utils/systemInfo";

export const setSafetyData = createAsyncThunk('safety/setSafetyData', async (inputData) => {
  let RecordOPSUM = [];
  if (inputData === null) {
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent,
    inputData.jsonPrevious,
    inputData.machineTypeFilter,
  )
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && RecordOPSUM && RecordOPSUM.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return RecordOPSUM.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return RecordOPSUM;
});
function PopulateDataHomeOPSUM(jsonThisWeek, jsonLastWeek, machineTypeFilter = "") {
  let OpsumArray = [];
  const DevicesArray = jsonThisWeek.opsum.device;
  for (let index in DevicesArray) {
    let opsumDataObj = {
      DataCached: false,
      MachineType: "",
      TotalSBICrawlMinutes: 0,
      TotalSBIStopMinutes: 0,
      TotalCWSCrawlMinutes: 0,
      TotalCWSBrakeMinutes: 0,
      TotalSBICrawlCounter: 0,
      TotalSBIStopCounter: 0,
      TotalCWSCrawlCounter: 0,
      TotalCWSBrakeCounter: 0,
      ActiveDevicesCount: 0,
      InactiveDevicesCount: 0,
      TotalSBICrawlMinutesLastWeek: 0,
      TotalSBIStopMinutesLastWeek: 0,
      TotalCWSCrawlMinutesLastWeek: 0,
      TotalCWSBrakeMinutesLastWeek: 0,
      TotalSBICrawlCounterLastWeek: 0,
      TotalSBIStopCounterLastWeek: 0,
      TotalCWSCrawlCounterLastWeek: 0,
      TotalCWSBrakeCounterLastWeek: 0,
      DeviceName: "",
      DeviceID: "",
      DeviceLastSeen: "",
      GrafanaDashboardUID: "",
      DeviceType: "",
      DeviceTypeIcon: "",
      DeviceTitleDataName: "Machine",
    };
    opsumDataObj.DeviceName = DevicesArray[index].profile.name;
    opsumDataObj.DeviceID = DevicesArray[index].profile.device_uid;
    const DeviceLatestData = DevicesArray[index].profile.latest_data_dst;
    opsumDataObj.DeviceLastSeen = DevicesArray[index].profile.dst_last_seen;
    if (
      new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
      opsumDataObj.DeviceLastSeen.length === 0
    ) {
      opsumDataObj.DeviceLastSeen = DeviceLatestData;
      if (
        new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
        opsumDataObj.DeviceLastSeen.length === 0
      )
        opsumDataObj.DeviceLastSeen = " not seen";
    }

    // DeviceType = String(DevicesArray[index].profile.vehicle_type);
    // if (DeviceType == "") DeviceType = "Unknown";

    let MachineType = String(DevicesArray[index].profile.machine_type);
    opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

    let DeviceType = String(DevicesArray[index].profile.vehicle_type);
    if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
    const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
    opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;
    if (DeviceType !== machineTypeFilter && machineTypeFilter !== "") continue;

    const SafetyJson = DevicesArray[index].safety;
    for (let i in SafetyJson) {
      switch (SafetyJson[i].event_type) {
        case "SBI":
          for (let j in SafetyJson[i].value) {
            switch (SafetyJson[i].value[j].event) {
              case "CRAWL":
                opsumDataObj.TotalSBICrawlMinutes =
                  SafetyJson[i].value[j].timer;
                opsumDataObj.TotalSBICrawlCounter =
                  SafetyJson[i].value[j].counter;
                break;
              case "STOP":
                opsumDataObj.TotalSBIStopMinutes = SafetyJson[i].value[j].timer;
                opsumDataObj.TotalSBIStopCounter =
                  SafetyJson[i].value[j].counter;
                break;
              default:
                break;
            }
          }
          break;
        case "CWS":
          for (let j in SafetyJson[i].value) {
            switch (SafetyJson[i].value[j].event) {
              case "CRAWL":
                opsumDataObj.TotalCWSCrawlMinutes =
                  SafetyJson[i].value[j].timer;
                opsumDataObj.TotalCWSCrawlCounter =
                  SafetyJson[i].value[j].counter;
                break;
              case "BRAKE":
                opsumDataObj.TotalCWSBrakeMinutes =
                  SafetyJson[i].value[j].timer;
                opsumDataObj.TotalCWSBrakeCounter =
                  SafetyJson[i].value[j].counter;
                break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
    }
    if (
      jsonLastWeek &&
      jsonLastWeek.opsum &&
      jsonLastWeek.opsum.device &&
      jsonLastWeek.opsum.device.length > 0
    ) {
      const DevicesArrayLastWeek = jsonLastWeek.opsum.device.filter(
        (x) => x.profile.device_uid === opsumDataObj.DeviceID
      );
      for (let index in DevicesArrayLastWeek) {
        const DeviceNameLastWeek = DevicesArrayLastWeek[index].profile.name;
        if (opsumDataObj.DeviceName === DeviceNameLastWeek) {
          // let Table_Row = table.insertRow(-1);
          const SafetyJson = DevicesArrayLastWeek[index].safety;

          for (let i in SafetyJson) {
            switch (SafetyJson[i].event_type) {
              case "SBI":
                for (let j in SafetyJson[i].value) {
                  switch (SafetyJson[i].value[j].event) {
                    case "CRAWL":
                      opsumDataObj.TotalSBICrawlMinutesLastWeek =
                        SafetyJson[i].value[j].timer;
                      opsumDataObj.TotalSBICrawlCounterLastWeek =
                        SafetyJson[i].value[j].counter;
                      break;
                    case "STOP":
                      opsumDataObj.TotalSBIStopMinutesLastWeek =
                        SafetyJson[i].value[j].timer;
                      opsumDataObj.TotalSBIStopCounterLastWeek =
                        SafetyJson[i].value[j].counter;
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "CWS":
                for (let j in SafetyJson[i].value) {
                  switch (SafetyJson[i].value[j].event) {
                    case "CRAWL":
                      opsumDataObj.TotalCWSCrawlMinutesLastWeek =
                        SafetyJson[i].value[j].timer;
                      opsumDataObj.TotalCWSCrawlCounterLastWeek =
                        SafetyJson[i].value[j].counter;
                      break;
                    case "BRAKE":
                      opsumDataObj.TotalCWSBrakeMinutesLastWeek =
                        SafetyJson[i].value[j].timer;
                      opsumDataObj.TotalCWSBrakeCounterLastWeek =
                        SafetyJson[i].value[j].counter;
                      break;
                    default:
                      break;
                  }
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }
    OpsumArray.push(opsumDataObj);
  }
  // RecordOPSUM.InactiveDevicesCount = DevicesArray.length - RecordOPSUM.ActiveDevicesCount;

  //////////////////////////////////////////////////////////////

  // const DevicesArrayLastWeek = jsonLastWeek.safety.device;

  // for (let index in DevicesArrayLastWeek) {

  //   let DeviceType = String(DevicesArrayLastWeek[index].profile.vehicle_type);
  //   if (DeviceType === "") DeviceType = "Unknown";
  //   if ((DeviceType !== machineTypeFilter) && (machineTypeFilter !== "")) continue;

  //   const SafetyJson = DevicesArrayLastWeek[index].safety;
  //   for (let i in SafetyJson) {
  //     switch (SafetyJson[i].event_type) {
  //       case "SBI":
  //         for (let j in SafetyJson[i].value) {
  //           switch (SafetyJson[i].value[j].event) {
  //             case "CRAWL":
  //               RecordOPSUM.TotalSBICrawlMinutesLastWeek += SafetyJson[i].value[j].timer;
  //               RecordOPSUM.TotalSBICrawlCounterLastWeek += SafetyJson[i].value[j].counter;
  //               break;
  //             case "STOP":
  //               RecordOPSUM.TotalSBIStopMinutesLastWeek += SafetyJson[i].value[j].timer;
  //               RecordOPSUM.TotalSBIStopCounterLastWeek += SafetyJson[i].value[j].counter;
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //         break;
  //       case "CWS":
  //         for (let j in SafetyJson[i].value) {
  //           switch (SafetyJson[i].value[j].event) {
  //             case "CRAWL":
  //               RecordOPSUM.TotalCWSCrawlMinutesLastWeek += SafetyJson[i].value[j].timer;
  //               RecordOPSUM.TotalCWSCrawlCounterLastWeek += SafetyJson[i].value[j].counter;
  //               break;
  //             case "BRAKE":
  //               RecordOPSUM.TotalCWSBrakeMinutesLastWeek += SafetyJson[i].value[j].timer;
  //               RecordOPSUM.TotalCWSBrakeCounterLastWeek += SafetyJson[i].value[j].counter;
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  // }
  // RecordOPSUM.InactiveDevicesCountLastWeek = DevicesArrayLastWeek.length - RecordOPSUM.ActiveDevicesCountLastWeek;
  // RecordOPSUM.DataCached = true;
  // RecordOPSUM.MachineType = machineTypeFilter;
  //  const localStorageRange = getLocalStorageRange();
  // localStorage.setItem(`simac_home_${localStorageRange.Text}_dst`, new Date());
  //localStorage.setItem(`simac_home_${localStorageRange.Text}_apikey`, api_key_current);
  //localStorage.setItem(`simac_home_safety`, JSON.stringify(RecordOPSUM));

  return OpsumArray;
}

const initialState = null;

const safetySlice = createSlice({
  name: "safety",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSafetyData.fulfilled, (state, action) => action.payload);
  },
});

export const selectSafety = ({ safety }) => safety;

export default safetySlice.reducer;
