import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { Suspense } from "react";
// import defaultTheme from './theme'
import router from "./utils/router";
import Theme from "./utils/themeService";
import store from "./store";

function App() {
  if (Number(localStorage.getItem("rowsPerPage")) < 5) {
    localStorage.setItem("rowsPerPage", 10);
  }
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <Suspense fallback={<h2>🌀 Loading...</h2>}> */}
        <Theme>
          <CssBaseline />

          <div style={{ minHeight: "100vh" }}>
            <RouterProvider router={router} />
          </div>
        </Theme>
        {/* </Suspense> */}
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
