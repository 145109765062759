/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setPermissions = createAsyncThunk('permissions/setPermissions', async (permissions) => {

  return permissions;
});



const initialState = null


const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setPermissions.fulfilled, (state, action) => action.payload);
  },
});

export const selectPermissions = ({ permissions }) => permissions;

export default permissionsSlice.reducer;
