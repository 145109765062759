import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import {MuiFileInput} from "mui-file-input";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AttachFile } from "@mui/icons-material";
import CustomTextField from "./CustomTextField";


const HoverBox = styled(Box)(({theme}) => ({
    "root": {
        "&:hover": {
            cursor: "pointer"
        }
    },
    "& .MuiBox-root": {
        
        "&:hover": {
            cursor: "pointer"
        }
    }
}));

export function TemplateInputs({inputs, onChange, selectedValue, setSelectedValue, setSelectedID, setSelectedFile, selectedFile}) {
    const theme = useTheme();
    const handleChangeOption = (e) => {
        setSelectedValue(e.target.value);
        setSelectedID(e.target.name);
    };
    const handleChangeFile = (newValue) => {
        setSelectedFile(newValue);
    };
    // template_display.innerHTML += `
    //     <div class="row ms-2 me-2">
    //         <div class="row">
    //             <div class="col">
    //                 <h2>${TicketRequirementName}</h2>
    //             </div>
    //         </div>
    //         <div id="template_display_${TicketRequirementUID}">

    //         </div>
    //     </div>
    //     <hr>
    // `;
    const TicketRequirementInput = inputs;
    const TicketRequirementInputUID = TicketRequirementInput.template_requirement_input_uid;
    const TicketRequirementInputConfigUID = TicketRequirementInput.input_requirement_config_uid;
    const TicketRequirementInputName = TicketRequirementInput.name;
    const TicketRequirementInputType = TicketRequirementInput.config ? TicketRequirementInput.config.requirement : "";

    // 2 = Date and Time (Please submit your current date and time)
    // 3 = Geo Location (Please submit your location)
    // 4 = Confirmation (Yes or No) (Please confirm)
    // 5 = Drop Down Selection (Please select)
    // 6 = Check Box Options (Please select)
    // 7 = Description (Please enter a description)
    // 8 = Date Select (Please select date)
    // 9 = Number Only Field (Please enter number)
    // 10 = Photograph (Please capture photo)
    // 11 = QR Code (Please capture QR Code)
    // 12 = Rating (Please select rating)
    // 13 = Signature (Please acquire signature)
    // 14 = Comments (Please provide comments)
    // 15 = Time Select (Please enter the time)
    // 16 = Short Text (Please capture)
    // 17 = Latitude (Set Latitude)
    // 18 = Longitude (Set Longitude)
    // 19 = byte[] (Byte Array)
    // 20 = Decimal Value (Please capture value)
    let inputHTML;
    switch (TicketRequirementInputConfigUID) {
        case 2: // 2 = Date and Time (Please submit your current date and time)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <CustomTextField sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}} onChange={onChange} required placeholder={TicketRequirementInputName} type="datetime-local" className="form-control" id={"Input" + TicketRequirementInputUID} />
                </>
            );

            break;

        case 4: // 4 = Confirmation (Yes or No) (Please confirm)
            inputHTML = (
                <FormControl sx={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center"}}>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="No" name={"Input" + TicketRequirementInputUID} id={"Input" + TicketRequirementInputUID} onChange={onChange}>
                        <FormControlLabel
                            value="No"
                            control={
                                <Radio
                                    sx={{
                                        "color": theme.palette.custom.buttonHoverColor,
                                        "&.Mui-checked": {
                                            color: theme.palette.custom.buttonColor
                                        }
                                    }}
                                />
                            }
                            label="No"
                        />
                        <FormControlLabel
                            value="Yes"
                            control={
                                <Radio
                                    sx={{
                                        "color": theme.palette.custom.buttonHoverColor,
                                        "&.Mui-checked": {
                                            color: theme.palette.custom.buttonColor
                                        }
                                    }}
                                />
                            }
                            label="Yes"
                        />
                    </RadioGroup>
                </FormControl>
            );
            break;

        case 5: // 5 = Drop Down Selection (Please select)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <TextField
                        sx={{
                            "background": "gray",
                            "outlineColor": theme.palette.custom.borderColor,
                            "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.custom.borderColor,
                                color: theme.palette.custom.text
                            },
                            "& #sltLanguage": {
                                borderColor: theme.palette.custom.borderColor,
                                color: theme.palette.custom.text
                            },
                            "& .MuiInputLabel-outlined": {
                                color: theme.palette.custom.text
                            },
                            "& .Mui-focused": {
                                color: theme.palette.custom.text
                            },
                            "&:focused": {
                                color: theme.palette.custom.text
                            },
                            "marginBottom": "10px",
                            "marginTop": "10px",
                            "width": {xs: "25%", sm: "25%"},
                            "borderColor": theme.palette.custom.borderColor,
                            "borderRadius": "11px",
                            "backgroundColor": theme.palette.custom.inputBackgroundColor
                        }}
                        // id={"Input" + TicketRequirementInputUID}
                        select
                        label={"Status"}
                        variant="outlined"
                        value={selectedValue}
                        onChange={(e) => {
                            handleChangeOption(e);
                        }}
                        name={"Input" + TicketRequirementInputUID}>
                        {TicketRequirementInput.select.map((option, index) => (
                            <MenuItem key={index} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </>
            );
            break;

        case 6: // 6 = Check Box Options (Please select)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    {TicketRequirementInput.select.map((option, index) => (
                        <Box key={index}>
                            <Typography>{option.name} </Typography>
                            <Checkbox
                                inputProps={{"data-id": option.template_requirement_input_select_uid.toString()}}
                                onChange={onChange}
                                name={"Input" + TicketRequirementInputUID}
                                sx={{
                                    "color": theme.palette.custom.buttonHoverColor,
                                    "&.Mui-checked": {
                                        color: theme.palette.custom.buttonColor
                                    }
                                }}
                            />
                        </Box>
                    ))}
                </>
            );

            break;

        case 8: // 8 = Date Select (Please select date)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <CustomTextField sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}} onChange={onChange} required placeholder={TicketRequirementInputType} type="date" className="form-control" id={"Input" + TicketRequirementInputUID} />
                </>
            );
            break;

        case 9: // 9 = Number Only Field (Please enter number)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <CustomTextField sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}} onChange={onChange} required type="number" className="form-control" id={"Input" + TicketRequirementInputUID} />
                </>
            );
            break;

        case 10: // 10 = Photograph (Please capture photo)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <HoverBox>
                    <MuiFileInput
                        sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}}
                        onClick={() => {
                            setSelectedID("Input" + TicketRequirementInputUID);
                        }}
                        onChange={handleChangeFile}
                        value={selectedFile}
                        required
                        type="file"
                        className="form-control"
                        id={"Input" + TicketRequirementInputUID}
                        InputProps={{
                            endAdornment: <AttachFile />
                          }}
                    />
                    </HoverBox>
                </>
            );
            break;

        // case 12: // 12 = Rating (Please select rating)
        //     inputHTML =
        //         <div className="row">
        //             <div className="col-sm-5">
        //                 <input  required type="range" min="0" max="5" value="0" className="rangeInp" oninput={rangevalue.value=value} id={"Input" + TicketRequirementInputUID} />
        //             </div>
        //             <div className="col-sm-2">
        //                 <input type="number" id="rangevalue" value="0" oninput="range.value=value" className="rangeOut" />
        //             </div>
        //         </div>
        //     ;
        //     break;

        case 15: // 15 = Time Select (Please enter the time)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <CustomTextField sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}} onChange={onChange} required placeholder={TicketRequirementInputType} type="time" className="form-control" id={"Input" + TicketRequirementInputUID} />
                </>
            );
            break;

        default: // 16 = Short Text (Please capture)
            inputHTML = (
                <>
                    <Typography sx={{width: "50%"}}>{TicketRequirementInputName}</Typography>
                    <CustomTextField sx={{backgroundColor: theme.palette.custom.inputBackgroundColor}} onChange={onChange} required label={TicketRequirementInputType} type="text" className="form-control" id={"Input" + TicketRequirementInputUID} />
                </>
            );
            break;
    }
    return <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", marginBottom: "10px"}}>{inputHTML}</Box>;

    // TicketRequirementInputHTML = TicketRequirementInputHTML.substring(1, TicketRequirementInputHTML.length-1);

    // document.getElementById(`template_display_${TicketRequirementUID}`).innerHTML += `
    //     <div class="row">
    //         <div class="col">
    //             ${TicketRequirementInputHTML}
    //         </div>
    //     </div>
    // `;

    // if (TicketRequirementInputConfigUID == 2) { // 2 = Date and Time (Please submit your current date and time)
    //     const now = new Date();
    //     now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    //     document.getElementById(`InputDateTime${RandomUID}`).value = now.toISOString().slice(0, -1);
    // }
    // template_display.innerHTML += `
    //     <div class="row ms-2 me-2">
    //         <div class="col">
    //             <button class="btn btn-primary float-end" onClick="SaveTicket('${template_header_uid}','${DepartmentID}');">
    //                 <i class="bi bi-save"></i> <span class="Multilingual">Save</span>
    //             </button>
    //         </div>
    //     </div>
    // `;

    // document.getElementById(`lblTicketName_${DepartmentID}`).innerText = SelectedName;

    // let template_display = document.getElementById(`template_display_${DepartmentID}`);
    // template_display.innerHTML = "<hr>";
}
