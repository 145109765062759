
import Box from '@mui/material/Box';

export default function StyledColumnBox({ children }) {
    return (
        <Box sx={{
            textAlign: "center", display: 'flex', flexFlow: 'column'
        }}>
            {children}
        </Box>
    );
}