/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setAssignUsersData = createAsyncThunk("assignUsers/setAssignUsersData", async (inputData) => {
    let assignUsers = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    assignUsers = inputData.json
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && assignUsers && assignUsers.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return assignUsers.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return assignUsers;
});

export const setAssignUsersDataDirectly = createAsyncThunk("assignUsers/setAssignUsersDataDirectly", async (inputData) => {
    
    return inputData;
});

const initialState = null;

const assignUsersSlice = createSlice({
    name: "assignUsers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAssignUsersData.fulfilled, (state, action) => action.payload);
        builder.addCase(setAssignUsersDataDirectly.fulfilled, (state, action) => action.payload);
    }
});

export const selectAssignUsers = ({assignUsers}) => assignUsers;

export default assignUsersSlice.reducer;
