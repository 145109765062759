import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { setDeviceData } from "../store/deviceSlice";
import {
  GetDateTimeStringToday,
  getUrlVars,
  GetMachineTypeIcon,
  LatestDataColoring,
} from "../utils/systemInfo";
import DevicesService from "../api/devices";
import Loading from "../common/loading";
import StyledTypography from "../common/StyledTypography";
import CustomTextField from "../common/CustomTextField";
import handleNavigate from "../common/customFunctions/handleNavigate";


const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);

function AddEditMachine() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const device = useSelector(({ device }) => device);
  const [deviceName, setDeviceName] = useState("");
  const [deviceOem, setDeviceOem] = useState(undefined);
  const [deviceSerial, setDeviceSerial] = useState(undefined);
  const [deviceMachineType, setDeviceMachineType] = useState(undefined);
  const [deviceTypeBackend, setDeviceTypeBackend] = useState(undefined);
  const [deviceId, setDeviceId] = useState(undefined);
  const [deviceProfileId, setDeviceProfileId] = useState(undefined);
  const [deviceTypeIcon, setDeviceTypeIcon] = useState(undefined);
  const [deviceLastSeen, setDeviceLastSeen] = useState(undefined);
  const [savingLoading, setSavingLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(true);
  const DeviceId = String(getUrlVars()["device"]);
  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  useEffect(() => {
    setDeviceName(undefined);
    setDeviceOem(undefined);
    setDeviceSerial(undefined);
    setDeviceMachineType(undefined);
    setDeviceTypeBackend(undefined);
    setDeviceId(undefined);
    setDeviceProfileId(undefined);
    setDeviceTypeIcon(undefined);
    setDeviceLastSeen(undefined);
    if (organziation && ((device && device.DeviceID !== DeviceId) || !device)) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass;
      switch (DeviceTitleDataName.toLowerCase()) {
        case "machine":
        default:
          DevicesClass = { deviceType: "nerohub", source: "" };
          DevicesService.getDevices({
            deviceID: DeviceId ? Number(DeviceId) : "",
            deviceType: DevicesClass.deviceType,
            source: DevicesClass.source,
            apiKey,
            historyFromDST: "",
            historyToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                // dispatch(setDeviceData({ json: deviceObject, machineTypeFilter: "", machineType: DeviceTitleDataName }))
                setDeviceName(deviceObject.name);
                setDeviceOem(deviceObject.oem);
                setDeviceSerial(
                  Number(deviceObject.serial).toString(16).toLocaleUpperCase()
                );
                setDeviceMachineType(deviceObject.vehicle_type);
                setDeviceTypeBackend(deviceObject.machine_type);
                setDeviceId(deviceObject.device_uid);
                setDeviceProfileId(deviceObject.device_profile_id);
                let DeviceType = String(
                  deviceObject.vehicle_type !== ""
                    ? deviceObject.vehicle_type
                    : deviceObject.device_type
                );
                if (DeviceType === "") DeviceType = "Unknown";
                setDeviceTypeIcon(GetMachineTypeIcon(DeviceType));
                const DeviceLatestData = deviceObject.latest_data_dst;
                let DeviceLastSeen = deviceObject.dst_last_seen;
                if (
                  new Date(DeviceLastSeen).getFullYear() < 1900 ||
                  DeviceLastSeen.length === 0
                ) {
                  DeviceLastSeen = DeviceLatestData;
                  if (
                    new Date(DeviceLastSeen).getFullYear() < 1900 ||
                    DeviceLastSeen.length === 0
                  )
                    DeviceLastSeen = " not seen";
                }
                setDeviceLastSeen(DeviceLastSeen);
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });

          break;

        case "device":
          DevicesClass = { deviceType: "", source: "iot" };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            Live: true,
            History: true,
            apiKey,
            historyFromDST: "",
            historyToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                dispatch(
                  setDeviceData({
                    json: deviceObject,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName,
                  })
                );
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });
          break;
      }
    } else {
      setDeviceName(device.DeviceName);
      setDeviceOem(device.DeviceOEM);
      setDeviceSerial(
        Number(device.DeviceSerial).toString(16).toLocaleUpperCase()
      );
      setDeviceMachineType(device.DeviceType);
      setDeviceTypeBackend(device.MachineType);
      setDeviceId(device.DeviceID);
      setDeviceProfileId(device.DeviceProfileID);
      setDeviceTypeIcon(device.DeviceTypeIcon);
      setDeviceLastSeen(device.DeviceLastSeen);
    }
    setLoadingValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceId]);

  const handleSubmit = (event) => {
    setSavingLoading(true);
    event.preventDefault();
    if (deviceId) {
      DevicesService.SetDeviceProfile({
        device_profile_id: deviceProfileId,
        device_uid: deviceId,
        serial: Number(deviceSerial).toString(10).toLocaleUpperCase(),
        name: deviceName,
        oem: deviceOem,
        device_type: "",
        nerohub: "",
        device_type_id: "",
        device_oem_operation_profile_uid: "",
        machine_type: deviceTypeBackend,
      })
        .then((json) => {
          navigate(
            `/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`
          );
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setSavingLoading(false);
  };
  const handleReturn = (event) => {
    handleNavigate(event, navigate, `/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`);
    // navigate();
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <IconButton
          size="small"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: theme.palette.custom.text,
            mr: 4,
            width: "40px",
            height: "40px",
          }}
          onClick={handleReturn}
        >
          <ArrowBackOutlinedIcon fontSize="inherit" />
        </IconButton>
        <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%" }}>
          {deviceTypeIcon && (
            <Box
              sx={{
                textAlign: "center",
                width: "30%",
                display: "flex",
                flexFlow: "row",
              }}
            >
              <div
                style={{ width: "70px" }}
                dangerouslySetInnerHTML={{
                  __html: deviceTypeIcon.replace(
                    "theme.palette.custom.text",
                    theme.palette.custom.text
                  ),
                }}
              />{" "}
              <StyledTypography
                sx={{ ml: "8px", color: theme.palette.custom.text }}
                variant="h4"
              >
                {deviceName}
              </StyledTypography>{" "}
            </Box>
          )}

          <Box
            sx={{
              textAlign: "center",
              width: "70%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <StyledTypography
              sx={{ color: theme.palette.custom.text }}
              variant="h4"
            >
              {`${t("LastSeen")}:`}
            </StyledTypography>{" "}
            <Typography
              sx={{
                ml: "8px",
                color: LatestDataColoring(deviceLastSeen, true),
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
              }}
              variant="h4"
            >
              {deviceLastSeen !== "Not set" && deviceLastSeen !== " not seen"
                ? moment(deviceLastSeen).add(timezoneMins, 'minutes').format("YYYY-MM-DD HH:mm")
                : deviceLastSeen}
            </Typography>{" "}
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {!loadingValue ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            m: 2,
            pb: 1,
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <CustomTextField
            id="deviceName"
            label={t("deviceName")}
            variant="filled"
            value={deviceName}
            defaultValue={deviceName}
            onChange={(event) => {
              setDeviceName(event.target.value);
            }}
            required
            focused
            inputProps={{ maxLength: 32 }}
            sx={{
              width: { xs: "100%", sm: "48%" },
              mr: { xs: "0%", sm: "1%" },
              mb: 1,
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTextField
            id="deviceOem"
            label={t("deviceOem")}
            name="deviceOem"
            variant="filled"
            value={deviceOem}
            onChange={(event) => {
              setDeviceOem(event.target.value);
            }}
            required
            focused
            inputProps={{ maxLength: 32 }}
            sx={{
              width: { xs: "100%", sm: "48%" },
              mb: 1,
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTextField
            id="serial"
            label={t("SerialNumber")}
            name="serial"
            autoComplete="string"
            variant="filled"
            value={deviceSerial}
            onChange={(event) => {
              setDeviceSerial(event.target.value);
            }}
            required
            focused
            inputProps={{ maxLength: 32 }}
            sx={{
              width: { xs: "100%", sm: "48%" },
              mr: { xs: "0%", sm: "1%" },
              mb: 1,
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTextField
            label={t("deviceType")}
            name="deviceType"
            variant="filled"
            id="deviceType"
            value={deviceMachineType}
            onChange={(event) => {
              setDeviceMachineType(event.target.value);
            }}
            required
            focused
            inputProps={{ maxLength: 32 }}
            sx={{
              width: { xs: "100%", sm: "48%" },
              mb: 1,
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTextField
            id="deviceTypeBackend"
            label={t("deviceTypeBackend")}
            name="deviceTypeBackend"
            autoComplete="string"
            variant="filled"
            value={deviceTypeBackend}
            defaultValue={deviceTypeBackend}
            onChange={(event) => {
              setDeviceTypeBackend(event.target.value);
            }}
            required
            focused
            inputProps={{ maxLength: 32 }}
            sx={{
              width: { xs: "100%", sm: "48%" },
              mr: { xs: "0%", sm: "1%" },
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
        </Box>
      ) : (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      )}
      <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
    </Box>
  );
}

export default AddEditMachine;
