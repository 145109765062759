/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const setShiftsTabOpened = createAsyncThunk(
  "shifts/setShiftsTabOpened",
  async (inputData) => {
    return inputData;
  }
);
export const setShiftsData = createAsyncThunk(
  "shifts/setShiftsData",
  async (inputData, { dispatch, getState }) => {
    let shifts = [];
    if (inputData === null) {
      return null;
    }
    shifts = PopulateShifts(inputData.json);
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      shifts &&
      shifts.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return shifts.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return shifts;
  }
);
function PopulateShifts(json) {
  let shift = [];
  const ShiftsArray = json.shift_time_config;
  for (let index in ShiftsArray) {
    let shiftDataObj = {
      name: "",
      start_time: "",
      end_time: "",
      operation_uid: "",
      shift_time_config_uid: "",
    };
    shiftDataObj.name = ShiftsArray[index].name;
    shiftDataObj.start_time = ShiftsArray[index].start_time
      ? dayjs(ShiftsArray[index].start_time, "h:mm:ss")?.format("HH:mm")
      : ShiftsArray[index].start_time;
    shiftDataObj.end_time = ShiftsArray[index].end_time
      ? dayjs(ShiftsArray[index].end_time, "h:mm:ss")?.format("HH:mm")
      : ShiftsArray[index].end_time;
    shiftDataObj.operation_uid = ShiftsArray[index].operation_uid;
    shiftDataObj.shift_time_config_uid =
      ShiftsArray[index].shift_time_config_uid;
    shift.push(shiftDataObj);
  }
  return shift;
}

const initialState = { shifts: null, tabOpened: false };

const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setShiftsData.fulfilled, (state, action) => ({
      ...state,
      shifts: action.payload,
    }));
    builder.addCase(setShiftsTabOpened.fulfilled, (state, action) => ({
      ...state,
      tabOpened: action.payload,
    }));
  },
});

export const selectShift = ({ shifts }) => shifts;

export default shiftsSlice.reducer;
