import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import DevicesService from "../api/devices";
import CommonDateTimePicker from "../common/commonDateTimePicker";
import { GetDateTimeStringToday, getUrlVars } from "../utils/systemInfo";
import DevicesMenu from "../common/devicesMenu";
import SignalsMenu from "../common/signalsMenu";
import { setDeviceData } from "../store/deviceSlice";

const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);
function DeviceSignals() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = useSelector(({ device }) => device);
  const [loadingValue, setLoadingValue] = useState(true);
  const [devicesList, setDevicesList] = useState(undefined);
  const [signalsList, setSignalsList] = useState([0]);
  const DeviceId = String(getUrlVars()["device_uid"]);
  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);

  useEffect(() => {
    setDevicesList(undefined);
    dispatch(setDeviceData(null));
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      // const apiKey = organziation?.api_key_list?.[0]?.key;

      let DevicesClass;
      switch (DeviceTitleDataName.toLowerCase()) {
        case "machine":
        default:
          DevicesClass = { deviceType: "nerohub", source: "" };
          DevicesService.getDevices({
            deviceID: DeviceId ? Number(DeviceId) : "",
            deviceType: DevicesClass.deviceType,
            source: DevicesClass.source,
            apiKey,
            historyFromDST: "",
            historyToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                dispatch(
                  setDeviceData({
                    json: deviceObject,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName,
                  })
                );
                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });
          break;

        case "device":
          DevicesClass = { deviceType: "", source: "iot" };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            Live: true,
            History: true,
            apiKey,
            historyFromDST: "",
            historyToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                dispatch(
                  setDeviceData({
                    json: deviceObject,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName,
                  })
                );
                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewSignals = (newSignals) => {
    setSignalsList(newSignals);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {!loadingValue && device ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: { xs: "block", sm: "center" },
              height: "64px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: {
                  xs: "100%",
                  sm: DeviceTitleDataName !== "Device" ? "20%" : "30%",
                },
                marginRight: { xs: 0, sm: "5%" },
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "50px" }}
                dangerouslySetInnerHTML={{
                  __html: device.DeviceTypeIcon.replace(
                    "theme.palette.custom.text",
                    theme.palette.custom.text
                  ),
                }}
              />
              {devicesList && devicesList.length > 0 ? (
                <DevicesMenu
                  pageType="signals"
                  devices={devicesList}
                  isSmall={true}
                />
              ) : (
                <NumberTypography variant="h6">
                  {device.DeviceName}
                </NumberTypography>
              )}
            </Box>
            <SignalsMenu
              machineType={device.MachineType}
              handleChangeSingals={handleNewSignals}
            />
          </Box>
        ) : null}
      </Box>

      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {device ? (
        <CommonDateTimePicker
          isSignals={true}
          device={device}
          signalsList={signalsList}
        />
      ) : null}
    </Box>
  );
}

export default DeviceSignals;
