/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setDashboard = createAsyncThunk('dashboard/setDashboard', async (dashboard) => {

  return dashboard;
});



const initialState = {
  grafana_dashboard_id: "",
  name: "",
  name_tab: "",
  status: "",
  name_backend: "",
  name_type: "",
  description: "",
};


const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setDashboard.fulfilled, (state, action) => action.payload);
  },
});

export const selectDashboard = ({ dashboard }) => dashboard;

export default dashboardSlice.reducer;
