/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setAssignDeviceData = createAsyncThunk('assignDeviceData/setAssignDeviceData', async (inputData) => {
  let assignDevice = [];
  if (inputData === null) {
    return null
  }
  //   assignDevice = PopulateAssignDevicesData(inputData.json)
  assignDevice = inputData.json;
  assignDevice = assignDevice.map(opsumDataObj => ({
    ...opsumDataObj,
    DeviceSerialHex: Number(opsumDataObj?.DeviceSerial)?.toString(16)?.toLocaleUpperCase()
  }));
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && assignDevice && assignDevice.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
    return assignDevice.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );
  }
  return assignDevice;
});

const initialState = null


const assignDevice = createSlice({
  name: 'assignDevice',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setAssignDeviceData.fulfilled, (state, action) => action.payload);
  },
});

export const selectAssignDevice = ({ assignDevice }) => assignDevice;

export default assignDevice.reducer;
