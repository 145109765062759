import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import NotificationsService from "../api/notifications";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import DeviceNotificationsTableRow from "./deviceNotificationsTableRow";
import { getUrlVars } from "../utils/systemInfo";
import StyledTypography from "../common/StyledTypography";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "notifcation",
    numeric: false,
    disablePadding: true,
    label: "Notifcation",
    sort: true,
  },
  {
    id: "info",
    numeric: false,
    disablePadding: true,
    label: "Info",
    sort: true,
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: true,
    label: "Timestamp",
    sort: true,
  },
];
export default function DeviceNotificationsTab() {
  const theme = useTheme();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [notificationsArray, setNotificationsArray] = useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  useEffect(() => {
    if (organziation) {
      setNotificationsArray(null);
      const apiKey = localStorage.getItem("apiKey");
      NotificationsService.GetOperationNotifications({
        operation_profile_uid: organziation.uid,
        apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.operation_notifications &&
            json.operation_notifications.length > 0
          ) {
            const notificationsList = json.operation_notifications.filter(
              (item) =>
                Number(item.device_id) === Number(getUrlVars()["device"])
            );
            setNotificationsArray(notificationsList);
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
  }, [organziation]);

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      notificationsArray &&
      notificationsArray.length > 0
    ) {
      setNotificationsArray(
        notificationsArray.filter((item) =>
          item.notifcation.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (notificationsArray && notificationsArray.length > 0) {
        return stableSort(
          notificationsArray,
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, notificationsArray]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  return (
    <Box
      sx={{
        p: "12px",
        mt: "8px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t("Notifications")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <TextField
        value={searchText}
        onChange={handleSearch}
        margin="normal"
        fullWidth
        id="search"
        label={t("Search")}
        name="search"
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          // borderColor: theme.palette.custom.borderColor,
          //borderRadius: '11px',
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {notificationsArray &&
              notificationsArray.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <DeviceNotificationsTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      index={index}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={notificationsArray ? notificationsArray.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
