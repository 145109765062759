import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import SignalsService from "../api/signals";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import DeviceSignalsTableRow from "./deviceSignalsTableRow";
import SearchInput from "../common/searchInput";
import StyledTypography from "../common/StyledTypography";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "eunit",
    numeric: false,
    disablePadding: true,
    label: "EUnit",
    sort: true,
  },
  {
    id: "color",
    numeric: false,
    disablePadding: true,
    label: "Color",
    sort: true,
  },
  {
    id: "tag",
    numeric: false,
    disablePadding: true,
    label: "Tag",
    sort: true,
  },
  {
    id: "sort_idx",
    numeric: true,
    disablePadding: true,
    label: "Sort Index",
    sort: true,
  },
  {
    id: "signal_idx",
    numeric: true,
    disablePadding: true,
    label: "Signal Index",
    sort: true,
  },
  {
    id: "min",
    numeric: true,
    disablePadding: true,
    label: "Min",
    sort: true,
  },
  {
    id: "max",
    numeric: true,
    disablePadding: true,
    label: "Max",
    sort: true,
  },
  {
    id: "low_thresh",
    numeric: true,
    disablePadding: true,
    label: "Low Threshold",
    sort: true,
  },
  {
    id: "high_thresh",
    numeric: true,
    disablePadding: true,
    label: "High Threshold",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
export default function DeviceSignalsTab({ machineType }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");
  const [searchArray, setSearchArray] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState([]);
  const [signalsArray, setSignalsArray] = useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  useEffect(() => {
    if (organziation && machineType) {
      setSignalsArray(null);
      const apiKey = localStorage.getItem("apiKey");
      SignalsService.GetDeviceSignalDescriptors({
        signal_descriptor_id: "",
        machine_type: machineType,
        apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.device_signal_descriptor &&
            json.device_signal_descriptor.length > 0
          ) {
            setSignalsArray(json.device_signal_descriptor);
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
  }, [organziation, machineType]);

  function deepSearch(obj, searchText) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (deepSearch(obj[key], searchText)) {
          return true;
        }
      } else if (String(obj[key]).toLowerCase().includes(searchText)) {
        return true;
      }
    }
    return false;
  }

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      signalsArray &&
      signalsArray.length > 0
    ) {
      setSearching(true);
      setSearchArray(
        signalsArray.filter((item) => deepSearch(item, newSearchText))
      );
    } else {
      setSearching(false);
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (searching && searchArray && searchArray.length > 0) {
        return stableSort(searchArray, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        if (signalsArray && signalsArray.length > 0) {
          return stableSort(signalsArray, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          );
        } else {
          return [];
        }
      }
    },
    [order, orderBy, page, rowsPerPage, signalsArray, searchArray, searching]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  return (
    <Box
      sx={{
        p: "12px",
        mt: "8px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t("Signals")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <SearchInput searchText={searchText} handleSearch={handleSearch} />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {searching && searchArray && searchArray.length > 0 ? (
                visibleRows.length ? (
                  visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <DeviceSignalsTableRow
                        key={row.name}
                        row={row}
                        handleClick={handleClick}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                      />
                    );
                  })
                ) : (
                  <StyledTypography>{t("No entries found")}</StyledTypography>
                )
              ) : signalsArray &&
                signalsArray.length > 0 &&
                visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <DeviceSignalsTableRow
                      key={row.name}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={
          searching && searchArray && searchArray.length > 0
            ? searchArray.length
            : signalsArray && signalsArray.length > 0
            ? signalsArray.length
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
