import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, FormControlLabel, FormGroup, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import ProjectService from "../api/project.js";
import ProfileService from "../api/profile.js";
import MachineStatusTableRow from "./machineStatusTableRow.js";
import { stableSort, getComparator } from "../utils/systemInfo";
import SearchInput from "../common/searchInput.js";
import { setMachineStatusData } from "../store/machineStatusSlice.js";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ImportButton from "../common/importButton.js";
import ExportButton from "../common/exportButton.js";
import { Fragment } from "react";
import Switch from "@mui/material/Switch";
import { Dropdown, DropdownMenuItem } from "../common/Layout/DropDown.js";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { useTooltip, TooltipWithBounds } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Text } from "@visx/text";
import { useRef } from "react";
import useResizeObserver from "../common/cutomHooks/useResizeObserver.js";
import Breadcrumbs from "../common/breadcrumbs";
import { useLocation } from "react-router-dom";
import handleNavigate from "../common/customFunctions/handleNavigate.js";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({ theme }) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));

const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    { id: 1, name: "Machine Status", href: "machineStatus" },
    { id: 2, name: "Machine Status - Overview", href: "machineStatus" }
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
    "height": "40px",
    "backgroundColor": theme.palette.custom.buttonColor,
    "color": theme.palette.custom.buttonTextColor,
    "&:hover": {
        backgroundColor: theme.palette.custom.buttonHoverColor,
        color: theme.palette.custom.buttonHoverTextColor,
        boxShadow: "none"
    }
}));

export default function MachineStatus() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organziation = useSelector(({ organziation }) => organziation);
    const machines = useSelector(({ machineStatus }) => machineStatus);
    const theme = useTheme();
    const { t } = useTranslation();
    const [statusInputData, setStatusInputData] = useState(undefined);
    const [orderStatus, setOrderStatus] = useState("asc");
    const [orderByStatus, setorderByStatus] = useState("name");
    const [pageStatus, setPageStatus] = useState(0);
    const [rowsPerPageStatus, setRowsPerPageStatus] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [searchTextStatus, setSearchTextStatus] = useState("");
    const [loadingValueStatus, setLoadingValueStatus] = useState(true);
    const [hidden, setHidden] = useState(false);
    const [vertical, setVertical] = useState(false);
    const [orgUid, setOrgUid] = useState(undefined);
    const [projectID, setProjectID] = useState("");
    const [apiKey, SetApiKey] = useState(undefined);
    const [pagination, setPagination] = useState(true);
    const [paginationHeight, setPaginationHeight] = useState("");
    const [containerWidth, setContainerWidth] = useState(0);
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState(undefined);
    const prevMachinesRef = useRef(machines);
    const [userList, setUserList] = useState([]);
    const location = useLocation();
    useResizeObserver(chartRef, (entry) => {
        setContainerWidth(entry.contentRect.width - 90);
    });
    const [statusHead, setStatusHead] = useState([
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Machine",
            sort: true
        }
    ]);
    const visibleRowsStatus = useMemo(
        () => {
            if (machines && machines.length > 0) {
                if (orderByStatus !== "Machine") {
                    return sortByTaskValue(machines, orderByStatus).slice(pageStatus * rowsPerPageStatus, pageStatus * rowsPerPageStatus + rowsPerPageStatus);
                } else {
                    return stableSort(sortByTaskValue(machines, orderByStatus), getComparator(orderStatus, orderByStatus)).slice(pageStatus * rowsPerPageStatus, pageStatus * rowsPerPageStatus + rowsPerPageStatus);
                }
            } else {
                return [];
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderStatus, orderByStatus, pageStatus, rowsPerPageStatus, machines]
        //[page, rowsPerPage, safety],
    );
    function sortByTaskValue(machines, taskName) {
        // console.log(taskName)
        let sortedMachines = machines.map((e) => {
            return e;
        });
        sortedMachines.sort((a, b) => {
            const taskA = a.tasks.find((task) => task.name.includes(taskName));
            const taskB = b.tasks.find((task) => task.name.includes(taskName));
            const valueA = taskA ? Number(taskA.value) : 0;
            const valueB = taskB ? Number(taskB.value) : 0;

            if (orderStatus === "asc") {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
        return sortedMachines;
    }

    useEffect(() => {
        const orgID = organziation?.uid;
        const apiKey = organziation?.api_key_list?.[0]?.key;

        setOrgUid(orgID);
        setStatusInputData(null);
        SetApiKey(apiKey);

        if (!orgID || !apiKey) return;

        ProjectService.GetProjectProfile(orgID, "", "", "Machine Rollout", "active", apiKey).then((jsonProfile) => {
            if (jsonProfile) {
                const projectRolloutID = jsonProfile.project_profile.find((e) => e.name === "Machine Rollout")?.project_profile_id;
                if (projectRolloutID) {
                    setProjectID(projectRolloutID.toString());
                }
            }
        });

        ProfileService.GetUserProfile({ status: "active", apiKey }).then((jsonProfile) => {
            if (jsonProfile) {
                setUserList(jsonProfile.person_profile);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organziation, chartRef]);

    useEffect(() => {
        if (!projectID || !apiKey) return;

        ProjectService.GetProjectSummary(projectID, "Machine Rollout", "", apiKey).then((jsonSummary) => {
            if (jsonSummary.count > 0) {
                const machinesList = PopulateMachines(jsonSummary);
                PopulateStatusHeaders(jsonSummary);
                setStatusInputData({ json: machinesList });
                dispatch(setMachineStatusData({ json: machinesList }));
            }
            setLoadingValueStatus(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectID, apiKey]);

    useEffect(() => {
        if (JSON.stringify(machines) !== JSON.stringify(prevMachinesRef.current) && machines && machines.length > 0) {
            const updatedChartData = getChartTotals(machines);
            setChartData(updatedChartData);
            prevMachinesRef.current = machines;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machines]);

    const getChartTotals = (machinesData) => {
        if (machinesData && machinesData.length > 0) {
            let cols = machinesData[0].tasks.map((e) => {
                return { name: e.name, values: [], totalValue: 0, finalTotal: 0 };
            });

            for (let i in machinesData) {
                for (let k in machinesData[i].tasks) {
                    for (let j in cols) {
                        if (machinesData[i].tasks[k].name === cols[j].name) {
                            cols[j].values.push(Number(machinesData[i].tasks[k].value));
                        }
                    }
                }
            }
            const data = calculateStackedBarData(cols);
            // console.log("processedData", cols);
            function calculateStackedBarData(data) {
                const processedData = [];

                data.forEach((column) => {
                    const valueCounts = Array(7).fill(0);

                    column.values.forEach((value) => {
                        if (value >= 0 && value <= 6) {
                            valueCounts[value]++;
                        }
                    });

                    const combinedCount = valueCounts[5] + valueCounts[6];
                    valueCounts[5] = combinedCount;
                    valueCounts.splice(6, 1);

                    const reorderedCounts = [valueCounts[5], ...valueCounts.slice(1, 5), valueCounts[0]];

                    const total = reorderedCounts.reduce((sum, count) => sum + count, 0);

                    const percentages = reorderedCounts.map((count) => (total > 0 ? (count / total) * 100 : 0));

                    processedData.push({
                        name: column.name,
                        counts: reorderedCounts,
                        percentages: percentages
                    });
                });

                return processedData;
            }

            return data;
        }
    };

    const margin = { top: 40, right: 30, bottom: 50, left: 40 };
    const height = 400;

    const TotalsChart = (chartData) => {
        const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } = useTooltip();
        const data = chartData.data;
        // console.log(data);
        const colors = ["#18FF44", "#FFD23E", "#FF792E", "#FF2E20", "#FF119E", "#1D1D1D"];
        const selectOptions = ["Complete", "Started", "Holding", "Problem", "Cancelled", "Not Started"];

        if (data !== undefined && data.length > 0) {
            const xScale = scaleBand({
                domain: data.map((d) => d.name),
                range: [margin.left, containerWidth - margin.right],
                padding: 0.3
            });

            const yScale = scaleLinear({
                domain: [0, 100],
                range: [height - margin.bottom, margin.top]
            });

            const handleMouseOver = (event, barData) => {
                const { x, y } = localPoint(event) || { x: 0, y: 0 };
                showTooltip({
                    tooltipData: barData,
                    tooltipLeft: x,
                    tooltipTop: y
                });
                // console.log(tooltipData);
            };

            const Legend = ({ colors, labels }) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", flexDirection: "column", marginLeft: "10px" }}>
                        {labels.map((label, index) => (
                            <div
                                key={`legend-${index}`}
                                style={{
                                    display: "flex",
                                    alignItems: "left",
                                    marginBottom: "5px"
                                }}>
                                <div
                                    style={{
                                        width: "15px",
                                        height: "15px",
                                        backgroundColor: colors[index],
                                        marginRight: "5px"
                                    }}
                                />
                                <span style={{ fontSize: "12px", color: "white", display: "flex", flexWrap: "nowrap" }}>{label}</span>
                            </div>
                        ))}
                    </div>
                );
            };
            // console.log(containerWidth);
            if (!containerWidth || isNaN(containerWidth)) return null;

            return (
                <div style={{ position: "relative", display: "inline-flex" }}>
                    <svg width={containerWidth} height={height}>
                        <Group>
                            <GridRows scale={yScale} width={containerWidth - margin.left - margin.right} stroke="rgba(255, 255, 255, 0.2)" />
                            {data.map((d, i) => {
                                let cumulativeHeight = 0;
                                return (
                                    <Group key={`stack-${i}`}>
                                        {d.percentages.map((percentage, j) => {
                                            const barHeight = yScale(0) - yScale(percentage);
                                            const yPosition = yScale(cumulativeHeight);
                                            cumulativeHeight += percentage;
                                            // console.log(percentage);

                                            return (
                                                <Group key={`bar-${i}-${j}`}>
                                                    <Bar x={xScale(d.name)} y={yPosition - barHeight} width={xScale.bandwidth()} height={barHeight} fill={colors[j]} onMouseOver={(e) => handleMouseOver(e, { name: d.name, value: percentage, selectOption: selectOptions[j] })} onMouseOut={hideTooltip} />
                                                    {percentage >= 5 && (
                                                        <Text x={xScale(d.name) + xScale.bandwidth() / 2} y={yPosition - barHeight / 2} fill={j === d.percentages.length - 1 ? "white" : "black"} fontSize={12} fontWeight={700} textAnchor="middle">
                                                            {`${percentage.toFixed(1)}%`}
                                                        </Text>
                                                    )}
                                                </Group>
                                            );
                                        })}
                                    </Group>
                                );
                            })}
                            <AxisBottom
                                top={height - margin.bottom}
                                scale={xScale}
                                stroke="white"
                                tickStroke="white"
                                tickLabelProps={() => ({
                                    fill: "white",
                                    fontSize: 12,
                                    textAnchor: "middle"
                                })}
                            />
                            <AxisLeft
                                left={margin.left}
                                scale={yScale}
                                stroke="white"
                                tickStroke="white"
                                tickLabelProps={() => ({
                                    fill: "white",
                                    fontSize: 12,
                                    textAnchor: "end",
                                    dy: "0.33em"
                                })}
                            />
                        </Group>
                    </svg>
                    <Legend colors={colors} labels={selectOptions} />
                    {tooltipData && (
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop}
                            left={tooltipLeft}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                color: "white",
                                padding: "8px",
                                borderRadius: "8px",
                                fontSize: "14px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                                position: "absolute"
                            }}>
                            <div>
                                <strong>{tooltipData.name}</strong>
                            </div>
                            <div>{`${tooltipData.selectOption}: ${tooltipData.value.toFixed(1)}%`}</div>
                        </TooltipWithBounds>
                    )}
                </div>
            );
        }

        return null;
    };

    function handleSearchStatus(ev) {
        setLoadingValueStatus(true);
        const newSearchText = ev.target.value;
        setSearchTextStatus(newSearchText);
        let statusInputObj = statusInputData;
        statusInputObj.newSearchText = newSearchText;
        dispatch(setMachineStatusData(statusInputObj));
        setLoadingValueStatus(false);
    }

    const handleChangeRowsPerPageStatus = (event) => {
        setRowsPerPageStatus(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPageStatus", event.target.value);
        setPageStatus(0);
    };
    const handleChangePageStatus = (event, newPage) => {
        setPageStatus(newPage);
    };
    const handleRequestSortStatus = (event, property) => {
        // console.log(property);
        const isAsc = orderByStatus === property && orderStatus === "asc";
        setOrderStatus(isAsc ? "desc" : "asc");
        setorderByStatus(property);
    };

    const handleChangeStatusData = (row, value, param_id, rowTasks) => {
        let newMachinesList = [];
        let newTaskList = rowTasks.map((task) => ({ ...task }));

        statusInputData.json.forEach((e) => {
            if (e.task_group_id === row.task_group_id) {
                newTaskList.forEach((task) => {
                    if (task.task_param_id === param_id) {
                        // console.log(task)
                        task.value = value;
                    }
                });
                let statusObj = {
                    name: row.name,
                    tasks: newTaskList,
                    task_group_id: row.task_group_id,
                    task_id: row.task_id,
                    status: row.status,
                    latest_comment: row.latest_comment
                };
                newMachinesList.push(statusObj);
            } else {
                newMachinesList.push(e);
            }
        });
        // console.log(newMachinesList)
        dispatch(
            setMachineStatusData({
                json: newMachinesList,
                newSearchText: searchTextStatus
            })
        );
        setStatusInputData({ json: newMachinesList });
        getChartTotals();
    };

    function PopulateStatusHeaders(json) {
        if (json.count > 0) {
            const MachinesArray = json.project_summary;
            statusHead.splice(0, statusHead.length);
            statusHead.push({
                id: "name",
                numeric: false,
                disablePadding: true,
                label: "Machine",
                sort: true
            });
            MachinesArray[0].tasks.forEach((e) => {
                statusHead.push({
                    id: e.name,
                    numeric: true,
                    disablePadding: false,
                    label: e.name,
                    sort: true
                });
            });
            statusHead.push(
                {
                    id: "comments",
                    numeric: false,
                    disablePadding: false,
                    label: "Comments",
                    sort: true
                },
                {
                    id: "action",
                    numeric: false,
                    disablePadding: false,
                    label: "Action",
                    sort: true
                }
            );
            setStatusHead(statusHead);
            // console.log(statusHead)
        }
    }

    function PopulateMachines(json) {
        if (json.count > 0) {
            let machine = [];
            const MachinesArray = json.project_summary;
            for (let index in MachinesArray) {
                let statusObj = {
                    name: "",
                    tasks: "",
                    task_group_id: "",
                    task_id: "",
                    status: "",
                    latest_comment: ""
                };
                if (MachinesArray[index].tasks !== null) {
                    statusObj.name = MachinesArray[index].name;
                    statusObj.tasks = MachinesArray[index].tasks;
                    statusObj.task_group_id = MachinesArray[index].task_group_id;
                    statusObj.task_id = MachinesArray[index].task_id;
                    statusObj.status = MachinesArray[index].status;
                    statusObj.latest_comment = MachinesArray[index].latest_comment;
                    machine.push(statusObj);
                }
            }
            return machine;
        } else {
            setLoadingValueStatus(false);
        }
    }

    function PopulateStatusFooter() {
        let cols = machines[0].tasks.map((e) => {
            return { name: e.name, values: [], totalValue: 0, finalTotal: 0 };
        });

        for (let i in machines) {
            for (let k in machines[i].tasks) {
                for (let j in cols) {
                    if (machines[i].tasks[k].name === cols[j].name) {
                        cols[j].values.push(Number(machines[i].tasks[k].value));
                    }
                }
            }
        }
        calculateTotalValues(cols);
        function calculateTotalValues(arr) {
            // console.log(arr)
            arr.forEach((item) => {
                const totalRelevantValues = item.values.filter((value) => value === 5 || value === 6).length;
                item.totalValue = (totalRelevantValues / item.values.length) * 100;
            });

            const overallCompletion = arr.reduce((acc, item) => acc + item.totalValue, 0) / arr.length;
            arr.forEach((item) => {
                item.finalTotal = overallCompletion;
            });
        }
        return cols.map((item, index) => {
            if (index === 0) {
                return (
                    <Fragment key={Math.floor(Math.random() * 1000000000)}>
                        <TableCell key={index}>
                            <StyledTypography sx={{ fontWeight: "bold" }}>Totals</StyledTypography>
                        </TableCell>
                        <TableCell key={index + 909}>
                            <StyledTypography sx={{ fontWeight: "bold" }}>{item.totalValue.toFixed(2)}%</StyledTypography>
                        </TableCell>
                    </Fragment>
                );
            }
            if (index !== cols.length - 1) {
                return (
                    <TableCell key={index}>
                        <StyledTypography sx={{ fontWeight: "bold" }}>{item.totalValue.toFixed(2)}%</StyledTypography>
                    </TableCell>
                );
            } else {
                return (
                    <Fragment key={Math.floor(Math.random() * 1000000000)}>
                        <TableCell key={index}>
                            <StyledTypography sx={{ fontWeight: "bold" }}>{item.totalValue.toFixed(2)}%</StyledTypography>
                        </TableCell>
                        <TableCell key={index + 901}>
                            <StyledTypography sx={{ fontWeight: "bold" }}>({item.finalTotal.toFixed(2)}%)</StyledTypography>
                        </TableCell>
                    </Fragment>
                );
            }
        });
    }
    async function importCsvDocs() {
        let loader;
        const { value: csv } = await Swal.fire({
            title: "Upload CSV",
            showCancelButton: true,
            confirmButtonText: "Post",
            color: theme.palette.custom.text,
            background: theme.palette.custom.inputBackgroundColor,
            input: "file",
            inputLabel: "Select File",
            inputValidator: async (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });

        const data = await new Promise((resolve, reject) => {
            readCSVFile(csv, function (data) {
                resolve(data);
                loader = Swal.fire({
                    title: "Uploading CSV...",
                    text: "Please wait...",
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            });
        });

        for (let i = 0; i < data.length; i++) {
            let machineName = data[i].Machine;
            let comment = data[i].Comment || "";
            let useProjectID = projectID.toString();
            useProjectID = projectID.toString();

            const json = await ProjectService.SetProjectGroup("", useProjectID, "", machineName, "", "active", apiKey).then((json) => {
                // console.log(json);
                return json;
            });

            if (json.count > 0) {
                const jsonTask = await ProjectService.SetProjectTask("", useProjectID, json.task_group[0].task_group_id, "", "Progress", "", "active", apiKey).then((jsonTask) => {
                    console.log(jsonTask);
                    return jsonTask;
                });
                if (comment !== "") {
                    await ProjectService.SetGroupComment(json.task_group[0].task_group_id, comment, "active", apiKey).then((jsonComment) => {
                        return jsonComment;
                    });
                }

                for (let key in data[i]) {
                    if (key !== "Machine" && key !== "Comment") {
                        const timestamp = getTimestamp(Object.keys(data[i]).indexOf(key));
                        const jsonParam = await ProjectService.SetTaskParam("", jsonTask.project_task[0].task_id, key, "", "16", timestamp, "active", apiKey).then((jsonParam) => {
                            // console.log(jsonParam);
                            return jsonParam;
                        });

                        if (jsonParam.count > 0) {
                            const jsonVal = await ProjectService.SetParamVal("", jsonParam.task_param[0].task_param_id, data[i][key], "active", apiKey).then((jsonVal) => {
                                // console.log(jsonVal);
                                return jsonVal;
                            });

                            if (jsonVal.count > 0 && i === data.length - 1) {
                                loader.close();
                                Swal.fire({
                                    title: "Success!",
                                    showConfirmButton: true,
                                    confirmButtonText: "confirm",
                                    icon: "success",
                                    color: theme.palette.custom.text,
                                    background: theme.palette.custom.inputBackgroundColor
                                }).then((result) => {
                                    // if (result.isConfirmed) navigate("machineStatus");
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    function readCSVFile(file, callback) {
        if (file !== null && file !== undefined) {
            const reader = new FileReader();

            reader.onload = function (event) {
                const csvData = event.target.result;
                const data = parseCSV(csvData);
                callback(data);
            };

            reader.onerror = function () {
                console.error("error");
            };

            reader.readAsText(file);
        }
    }

    function parseCSV(csvData) {
        let splitBy;
        if (csvData.includes(";")) {
            splitBy = ";";
        } else {
            splitBy = ",";
        }
        const lines = csvData
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.length > 0);
        const headers = lines[0]
            .split(splitBy)
            .map((header) => header.replace(/"/g, ""))
            .filter((header) => header.trim() !== "");
        const data = [];

        for (let i = 1; i < lines.length; i++) {
            const currentline = lines[i].split(splitBy).map((value) => value.replace(/"/g, "").trim());
            const obj = {};
            let hasValues = false;
            for (let j = 0; j < headers.length; j++) {
                if (currentline[j] !== undefined) {
                    obj[headers[j]] = currentline[j];
                    hasValues = true;
                }
            }
            if (hasValues) {
                data.push(obj);
            }
        }

        return data;
    }

    function getTimestamp(seconds) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return timestamp;
    }

    const handleSetPagination = () => {
        setPagination(!pagination);
        if (pagination) {
            setRowsPerPageStatus(machines.length);
            setPaginationHeight("550px");
        } else {
            setRowsPerPageStatus(5);
            setPaginationHeight("");
        }
    };

    const exportToCSV = (rowData, fileName) => {
        let csv = "Machine,";
        rowData[0].tasks.forEach((task) => {
            csv += `${task.name},`;
        });
        csv += "Comment\n";
        rowData.forEach((row) => {
            const taskStatuses = row.tasks
                .map((task) => {
                    const statusText = getStatusText(task.value);
                    return `${statusText}`;
                })
                .join(",");

            const comment = row.latest_comment || "";
            csv += `${row.name},${taskStatuses},${comment}\n`;
        });
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileName}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const getStatusText = (value) => {
        switch (value) {
            case 0:
                return "Not Started";
            case 1:
                return "Started";
            case 2:
                return "Holding";
            case 3:
                return "Problem";
            case 4:
                return "Cancelled";
            case 5:
                return "Completed";
            case 6:
                return "Not Applicable";
            default:
                return "Unknown";
        }
    };
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}>
            {useMemo(
                () => (
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                ),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [location]
            )}
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.text, width: "80%" }} variant="h2" gutterBottom>
                    {t("Machine")} {t("Status")} - {t("Overview")}:
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "right", width: "20%", alignItems: "center" }}>
                    <StyledButton
                        onClick={(event) => {
                            handleNavigate(event, navigate, `/addMachineStatus?projectId=${projectID}`);
                            // navigate();
                        }}>
                        {t("Add New Machine")}
                    </StyledButton>
                </Box>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    TabIndicatorProps={{
                        style: { backgroundColor: theme.palette.custom.borderColor }
                    }}
                    value={0}
                    aria-label="basic tabs example">

                    <Dropdown
                        sx={{ color: theme.palette.custom.text }}
                        trigger={
                            <Button variant="text" sx={{ color: theme.palette.custom.text, marginLeft: "auto" }}>
                                <StyledTypography>{t("Settings")}</StyledTypography>
                            </Button>
                        }
                        menu={[
                            <DropdownMenuItem onClick={() => { }} sx={{ color: theme.palette.custom.text }}>
                                <FormGroup sx={{ display: "flex", flexDirection: "column" }}>
                                    <FormControlLabel label="Toggle Icon Descriptors" control={<Switch checked={hidden} onChange={() => setHidden(!hidden)}></Switch>}></FormControlLabel>
                                    <FormControlLabel label="Toggle Vertical Columns" control={<Switch checked={vertical} onChange={() => setVertical(!vertical)}></Switch>}></FormControlLabel>
                                    <FormControlLabel label="Toggle Paging" control={<Switch checked={pagination} onChange={handleSetPagination}></Switch>}></FormControlLabel>
                                </FormGroup>
                            </DropdownMenuItem>
                        ]}
                    />
                </Tabs>
            </Box>
            {visibleRowsStatus.length > 0 && (
                <Box sx={{ width: "100%", height: "400px" }} ref={chartRef}>
                    {machines && machines.length > 0 && containerWidth > 0 && <TotalsChart data={chartData} />}
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                <SearchInput searchText={searchTextStatus} handleSearch={handleSearchStatus} />
            </Box>
            {loadingValueStatus ? (
                <Box sx={{ height: "30vh", pt: "10%" }}>
                    <Loading />
                </Box>
            ) : (
                <TableContainer sx={{ maxHeight: paginationHeight }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        stickyHeader={!pagination}
                        aria-label={!pagination ? "sticky table" : ""}
                    //  size={'medium'}
                    >
                        <EnhancedTableHead headCells={statusHead} order={orderStatus} orderBy={orderByStatus} onRequestSort={handleRequestSortStatus} hideCheck vertical={vertical} />

                        <TableBody key={Math.floor(Math.random() * 1000000000)}>
                            {machines && machines.length > 0 && visibleRowsStatus.length ? (
                                visibleRowsStatus.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <MachineStatusTableRow hidden={hidden} handleChangeStatusData={handleChangeStatusData} labelId={labelId} ikey={Math.floor(Math.random() * 1000000000)} key={Math.floor(Math.random() * 1000000000)} row={row} orgUid={orgUid} projectID={projectID} userList={userList}></MachineStatusTableRow>;
                                })
                            ) : (
                                <StyledTypography>{t("No entries found")}</StyledTypography>
                            )}
                            {machines && machines.length > 0 && (
                                <TableRow
                                    key={Math.floor(Math.random() * 1000000000)}
                                    sx={{
                                        backgroundColor: theme.palette.custom.backgroundColor2
                                    }}>
                                    {PopulateStatusFooter()}
                                    <TableCell key={Math.floor(Math.random() * 1000000000)}></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {pagination && (
                <StyledTablePagination
                    SelectProps={{
                        inputProps: {
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        "backgroundColor": theme.palette.custom.backgroundColor,
                                        "color": theme.palette.custom.text,
                                        "& .MuiMenuItem-root.Mui-selected": {
                                            backgroundColor: theme.palette.custom.borderColor
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "blue"
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover": {
                                            backgroundColor: "blue"
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={machines ? machines.length : 0}
                    rowsPerPage={rowsPerPageStatus}
                    page={pageStatus}
                    onPageChange={handleChangePageStatus}
                    onRowsPerPageChange={handleChangeRowsPerPageStatus}
                />
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ImportButton onClick={importCsvDocs} style={{ marginRight: "5px" }}>
                    Upload CSV
                </ImportButton>
                <ExportButton onClick={() => exportToCSV(machines, "Machine_Status_Report")}>Export to CSV</ExportButton>
            </Box>
        </Box>
    );
}
