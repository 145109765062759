import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import RequirementInput from "./requirementInput";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "125px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
const StyledBoxButton = styled((props) => <IconButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.buttonColor,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
    // display: "flex",
    // justifyContent: "center",
    width: "46px",
    height: "32px",
    padding: "5px 12px",
    marginRight: "5px",
  })
);
export default function RequirementComponent({
  requirement,
  groupIndex,
  handleTriggerDeleteGroup,
  handleChangeInput,
  handleAddInput,
  handleDeleteGroupInput,
  handleSaveInput,
  handleTriggerEditGroup,
  handleAddInputOption,
  handleChangeInputOption,
  handleSaveInputOption,
  handleDeleteInputOption,
  handleMoveGroupDown,
  handleMoveGroupUp,
  requirementsCount,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleEditGroup = () => {
    handleTriggerEditGroup(groupIndex);
  };
  const handleDeleteGroup = () => {
    handleTriggerDeleteGroup(groupIndex);
  };
  const handleGroupDown = () => {
    handleMoveGroupDown(groupIndex);
  };
  const handleGroupUp = () => {
    handleMoveGroupUp(groupIndex);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {" "}
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography
            sx={{ mb: 0, color: theme.palette.custom.borderColor }}
            variant="subtitle"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t("RequirementName")}
            {":"}&nbsp;
          </Typography>
          <Typography
            sx={{ mb: 0, color: theme.palette.custom.borderColor }}
            variant="subtitle"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {requirement.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", height: "64px" }}>
          <StyledBoxButton onClick={handleEditGroup}>
            <EditIcon
              sx={{
                color: theme.palette.custom.buttonTextColor,
                fontSize: 20,
              }}
            />
          </StyledBoxButton>
          <StyledBoxButton onClick={handleDeleteGroup}>
            <DeleteIcon
              sx={{
                color: theme.palette.custom.buttonTextColor,
                fontSize: 20,
              }}
            />
          </StyledBoxButton>
          <StyledBoxButton
            onClick={handleGroupDown}
            disabled={requirement.order_by === requirementsCount}
          >
            <ArrowDownwardIcon
              sx={{
                color: theme.palette.custom.buttonTextColor,
                fontSize: 20,
              }}
            />
          </StyledBoxButton>
          <StyledBoxButton
            onClick={handleGroupUp}
            disabled={requirement.order_by === 1}
          >
            <ArrowUpwardIcon
              sx={{
                color: theme.palette.custom.buttonTextColor,
                fontSize: 20,
              }}
            />
          </StyledBoxButton>
          <StyledButton
            variant="contained"
            onClick={() => handleAddInput(groupIndex)}
            startIcon={<AddIcon />}
          >
            {t("AddInput")}
          </StyledButton>
        </Box>
      </Box>
      {requirement &&
        requirement.inputs &&
        requirement.inputs.map((input, index) => (
          <RequirementInput
            input={input}
            index={index}
            groupIndex={groupIndex}
            handleChangeInput={handleChangeInput}
            handleDeleteInput={handleDeleteGroupInput}
            handleSaveInput={handleSaveInput}
            handleAddInputOption={handleAddInputOption}
            handleChangeInputOption={handleChangeInputOption}
            handleSaveInputOption={handleSaveInputOption}
            handleDeleteInputOption={handleDeleteInputOption}
          />
        ))}
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
    </Box>
  );
}
