import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import ProfileService from "../api/profile";
import { setAllUsersData } from "../store/allUserSlice";
import DevToUserTableRow from "./devToUserTableRow.js";
import DevToUserDeviceTableRow from "./devToUserDeviceTableRow.js";
import {
  stableSort,
  getComparator,
  GetMachineTypeIcon,
} from "../utils/systemInfo";
import OrganizationService from "../api/organization";
import { setDevToUserData } from "../store/devToUserSlice.js";
import SearchInput from "../common/searchInput.js";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV.js";
import StyledTypography from "../common/StyledTypography.js";
import Breadcrumbs from "../common/breadcrumbs";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    height: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);

const deviceHead = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceSerial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
    sort: true,
  },
  {
    id: "DeviceType",
    numeric: false,
    disablePadding: false,
    label: "Machine Type",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "userType",
    numeric: false,
    disablePadding: false,
    label: "User Type",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];

const userHead = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    sort: true,
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email Address",
    sort: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile Number",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Developer Users" },
  { id: 2, name: "Assign Device To User", href: "devToUsers" },
];

export default function DevToUsers() {
  const machines = useSelector(({ devToUser }) => devToUser);
  const dispatch = useDispatch();
  const users = useSelector(({ allUsers }) => allUsers);
  const [value, setValue] = useState(0);
  const [userInputData, setUserInputData] = useState(undefined);
  const [orderUser, setOrderUser] = useState("asc");
  const [orderByUser, setOrderByUser] = useState("firstname");
  const [pageUser, setPageUser] = useState(0);
  const [rowsPerPageUser, setRowsPerPageUser] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchTextUser, setSearchTextUser] = useState("");
  const [loadingValueUser, setLoadingValueUser] = useState(true);
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");
  const [loadingValue, setLoadingValue] = useState(true);
  const [orgUid, setOrgUid] = useState(undefined);
  const [disabledTab, setDisabledTab] = useState(true);
  const visibleRows = useMemo(() => {
    if (machines && machines.length > 0) {
      return stableSort(machines, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return [];
    }
  }, [order, orderBy, page, rowsPerPage, machines]);
  const visibleRowsUser = useMemo(() => {
    if (users && users.length > 0) {
      return stableSort(users, getComparator(orderUser, orderByUser)).slice(
        pageUser * rowsPerPageUser,
        pageUser * rowsPerPageUser + rowsPerPageUser
      );
    } else {
      return [];
    }
  }, [orderUser, orderByUser, pageUser, rowsPerPageUser, users]);
  function getCSVUser(u) {
    let email = "";
    let mobile = "";
    if (u?.contact?.length) {
      for (let i in u.contact) {
        if (
          u.contact[i].contact_type === "email_address" &&
          u.contact[i].primary_contact === true
        ) {
          email = u.contact[i].value;
        } else if (
          u.contact[i].contact_type === "tel_mobile" &&
          u.contact[i].primary_contact === true
        ) {
          mobile = u.contact[i].value;
        }
      }
    }
    return { ...u, email, mobile };
  }

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = "";
    opsumInputObj.machineTypeValue = "machine";
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setDevToUserData(opsumInputObj));
    setLoadingValue(false);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleSearchUser(ev) {
    setLoadingValueUser(true);
    const newSearchText = ev.target.value;
    setSearchTextUser(newSearchText);
    let userInputObj = userInputData;
    userInputObj.newSearchText = newSearchText;
    dispatch(setAllUsersData(userInputObj));
    setLoadingValueUser(false);
  }
  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPageUser(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageUser", event.target.value);
    setPageUser(0);
  };
  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };
  const handleRequestSortUser = (event, property) => {
    const isAsc = orderByUser === property && orderUser === "asc";
    setOrderUser(isAsc ? "desc" : "asc");
    setOrderByUser(property);
  };
  useEffect(() => {
    const defaultOrganization = JSON.parse(
      localStorage.getItem("defaultOrganization")
    );
    const apiKey = defaultOrganization?.api_key_list?.[0]?.key;
    setOrgUid(defaultOrganization.uid);
    setUserInputData(null);
    setOpsumInputData(null);
    ProfileService.GetUserProfile({
      profile_uid: "",
      profile_id: "",
      app_profile_uid: "",
      firstname: "",
      lastname: "",
      fullname: "",
      tel_primary: "",
      email_primary: "",
      status: "active",
      opertaion_profile_uid: orgUid,
      apiKey: apiKey,
    })
      .then((json) => {
        if (json && json.person_profile && json.person_profile.length > 0) {
          setUserInputData({ json });
          dispatch(setAllUsersData({ json }));
        }
        setLoadingValueUser(false);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") console.log(error);
      });
      OrganizationService.GetApiKeys(
        "",
        defaultOrganization.uid,
        "active",
        "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA"
      ).then((json) => {
        localStorage.setItem(
          "assignToOrg",
          JSON.stringify({
            fullName: json.app[0].operation_full_name,
            uid: json.app[0].operation_uid,
            apiKey: json.app[0].key,
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (value === 1 && localStorage.getItem("assignToUser")) {
      setLoadingValue(true);
      OrganizationService.GetPersonDeviceLink({
        operation_uid: orgUid,
        device_id: "",
        person_id: localStorage.getItem("assignToUser"),
        apiKey: apiKey,
      })
        .then((json) => {
          if (json) {
            const deviceData = PopulateAssignDevicesData(json);
            setOpsumInputData({ json: deviceData });
            dispatch(setDevToUserData({ json: deviceData }));
          }
          setLoadingValue(false);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") console.log(error);
        });
    }
    if (value === 0) {
      setDisabledTab(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChangeData = (row, isLink, permission) => {
    let newDeviceList = [];
    opsumInputData.json.forEach((e) => {
      if (e.DeviceID === row.DeviceID) {
        let deviceNewObj = {
          MachineType: row.MachineType,
          DeviceName: row.DeviceName,
          DeviceID: row.DeviceID,
          DeviceLastSeen: row.DeviceLastSeen,
          DeviceSerial: row.DeviceSerial,
          DeviceType: row.DeviceType,
          DeviceTypeIcon: row.DeviceTypeIcon,
          DevicePID: row.DevicePID,
          status: isLink ? "Assigned" : "Unassigned",
          permission: permission,
        };
        newDeviceList.push(deviceNewObj);
      } else {
        newDeviceList.push(e);
      }
    });
    dispatch(
      setDevToUserData({ json: newDeviceList, newSearchText: searchText })
    );
    setOpsumInputData({ json: newDeviceList });
  };

  function PopulateAssignDevicesData(json) {
    const deviceArr = [];
    const jsonThisWeek = json.person_device_link;
    for (let i in jsonThisWeek) {
      if (jsonThisWeek[i].link !== null && jsonThisWeek[i].link !== undefined) {
        let assignDeviceDataObj = {
          MachineType: "",
          DeviceName: "",
          DeviceID: "",
          DeviceLastSeen: "",
          DeviceSerial: "",
          DeviceType: "",
          DeviceTypeIcon: "",
          DevicePID: "",
          status: "Unassigned",
          permission: null,
        };
        assignDeviceDataObj.DeviceName = jsonThisWeek[i].name;
        assignDeviceDataObj.DeviceID = jsonThisWeek[i].device_uid;
        assignDeviceDataObj.DeviceSerial = jsonThisWeek[i].serial;
        assignDeviceDataObj.DevicePID = jsonThisWeek[i].device_profile_id;
        assignDeviceDataObj.DeviceLastSeen = jsonThisWeek[i].dst_last_seen;
        assignDeviceDataObj.permission =
          jsonThisWeek[i].link && jsonThisWeek[i].link.permission
            ? jsonThisWeek[i].link.permission
            : 0;
        if (
          jsonThisWeek[i].link.status === "active" ||
          jsonThisWeek[i].link.status === "linked" ||
          jsonThisWeek[i].link.status === "Link successful"
        )
          assignDeviceDataObj.status = "Assigned";
        assignDeviceDataObj.MachineType = jsonThisWeek[i].machine_type;
        assignDeviceDataObj.DeviceType = jsonThisWeek[i].device_type;
        if (assignDeviceDataObj.DeviceType === "")
          assignDeviceDataObj.DeviceType = "Unknown";
        const DeviceTypeIcon = GetMachineTypeIcon(
          assignDeviceDataObj.DeviceType
        );
        assignDeviceDataObj.DeviceTypeIcon = DeviceTypeIcon;

        deviceArr.push(assignDeviceDataObj);
      } else {
        let assignDeviceDataObj = {
          MachineType: "",
          DeviceName: "",
          DeviceID: "",
          DeviceLastSeen: "",
          DeviceSerial: "",
          DeviceType: "",
          DeviceTypeIcon: "",
          DevicePID: "",
          status: "Unassigned",
        };
        assignDeviceDataObj.DeviceName = jsonThisWeek[i].name;
        assignDeviceDataObj.DeviceID = jsonThisWeek[i].device_uid;
        assignDeviceDataObj.DeviceSerial = jsonThisWeek[i].serial;
        assignDeviceDataObj.DevicePID = jsonThisWeek[i].device_profile_id;
        assignDeviceDataObj.DeviceLastSeen = jsonThisWeek[i].dst_last_seen;
        assignDeviceDataObj.MachineType = jsonThisWeek[i].machine_type;
        assignDeviceDataObj.DeviceType = jsonThisWeek[i].device_type;
        if (assignDeviceDataObj.DeviceType === "")
          assignDeviceDataObj.DeviceType = "Unknown";
        const DeviceTypeIcon = GetMachineTypeIcon(
          assignDeviceDataObj.DeviceType
        );
        assignDeviceDataObj.DeviceTypeIcon = DeviceTypeIcon;

        deviceArr.push(assignDeviceDataObj);
      }
    }
    return deviceArr;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Box sx={{ width: "100%" }}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.text,
            }}
            variant="h2"
            gutterBottom
          >
            {t("Assign")} {t("Device")} {t("To")} {t("User")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={value}
          TabIndicatorProps={{
            style: { backgroundColor: theme.palette.custom.borderColor },
          }}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label={t("Users")} {...a11yProps(0)} />
          {value === 1 && (
            <StyledTab
              label={t("Devices")}
              {...a11yProps(1)}
              disabled={disabledTab}
            />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "1rem",
            alignItems: { md: "center" },
          }}
        >
          <SearchInput
            searchText={searchTextUser}
            handleSearch={handleSearchUser}
          />
          <Typography sx={{ flexGrow: "1", textAlign: "center" }}>
            {t("First, select a user you want to assign a device to")}
          </Typography>
        </Box>
        {loadingValueUser ? (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                headCells={userHead}
                order={orderUser}
                orderBy={orderByUser}
                onRequestSort={handleRequestSortUser}
                hideCheck
              />

              <TableBody>
                {users && users.length > 0 && visibleRowsUser.length ? (
                  visibleRowsUser.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <DevToUserTableRow
                        setValue={setValue}
                        setDisabledTab={setDisabledTab}
                        iKey={index}
                        labelId={labelId}
                        key={index}
                        row={row}
                        orgUid={orgUid}
                      ></DevToUserTableRow>
                    );
                  })
                ) : (
                  <StyledTypography>{t("No entries found")}</StyledTypography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={users ? users.length : 0}
          rowsPerPage={rowsPerPageUser}
          page={pageUser}
          onPageChange={handleChangePageUser}
          onRowsPerPageChange={handleChangeRowsPerPageUser}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
            float: "right",
            width: "min-content",
            whiteSpace: "nowrap",
            padding: "1rem",
          }}
          onClick={() =>
            exportTableToCSV(
              userHead,
              visibleRowsUser.map(getCSVUser),
              "Assign Device To User List"
            )
          }
        >
          {t("Download List (*.csv)")}
        </Button>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SearchInput searchText={searchText} handleSearch={handleSearch} />
        {loadingValue ? (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                headCells={deviceHead}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                hideCheck
              />

              <TableBody>
                {machines && machines.length > 0 && visibleRows.length ? (
                  visibleRows.map((row, index) => {
                    return (
                      <DevToUserDeviceTableRow
                        handleChangeData={handleChangeData}
                        iKey={index}
                        key={index}
                        row={row}
                        orgUid={orgUid}
                      ></DevToUserDeviceTableRow>
                    );
                  })
                ) : (
                  <StyledTypography>{t("No entries found")}</StyledTypography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={machines ? machines.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
            float: "right",
            width: "min-content",
            whiteSpace: "nowrap",
            padding: "1rem",
          }}
          onClick={() =>
            exportTableToCSV(
              deviceHead,
              visibleRows,
              "Assign Device To User List"
            )
          }
        >
          {t("Download List (*.csv)")}
        </Button>
      </CustomTabPanel>
    </Box>
  );
}
