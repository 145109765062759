import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TicketsService from "../api/tickets";
import { useState, useEffect } from "react";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "200px",
    backgroundColor: theme.palette.custom.borderColor,
    color: theme.palette.custom.text,
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonHoverColor,
      color: theme.palette.custom.buttonHoverTextColor,
      boxShadow: "none",
    },
  },
}));
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "100%",
  height: "36px",

  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.inputBackgroundColor,
  border: 1,
  borderColor: theme.palette.custom.borderColor,
  borderRadius: "11px",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
export default function InputOptionsList({ handleChooseOption, optionId }) {
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const options = useSelector(({ inputOptions }) => inputOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [optionsList, setOptionsList] = useState(undefined);
  const [optionName, setOptionName] = useState(undefined);

  useEffect(() => {
    const optionsCached = JSON.parse(sessionStorage.getItem("OptionsList"));
    if (optionsCached) {
      const selectedOptionName =
        optionsCached.find((x) => x.input_requirement_config_uid === optionId)
          ?.requirement ?? "Input type";
      setOptionName(selectedOptionName);
      setOptionsList(optionsCached);
    } else if (organziation && !options) {
      setOptionsList(null);
      const apiKey = organziation?.api_key_list?.[0]?.key;
      TicketsService.GetTicketTemplateInputs({
        input_requirement_config_uid: "",
        input_type: "",
        order_by: "",
        request: "",
        requirement: "",
        status: "",
        apiKey,
      })
        .then((json) => {
          if (json?.app_input_requirement_config?.length) {
            const selectedOptionName =
              json.app_input_requirement_config.find(
                (x) => x.input_requirement_config_uid === optionId
              )?.requirement ?? "Input type";
            setOptionName(selectedOptionName);
            setOptionsList(json.app_input_requirement_config);
            sessionStorage.setItem(
              "OptionsList",
              JSON.stringify(json.app_input_requirement_config)
            );
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
        });
    } else if (options) {
      const selectedOptionName =
        options.find((x) => x.input_requirement_config_uid === optionId)
          ?.requirement ?? "Input type";
      setOptionName(selectedOptionName);
      setOptionsList(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOption = (option) => {
    setOptionName(option.requirement);
    handleChooseOption(
      option.input_requirement_config_uid,
      "input_requirement_config_uid"
    );
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={t("inputType")}>
        <StyledButton
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          sx={{}}
        >
          {optionName}
        </StyledButton>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {optionsList &&
          optionsList.map((option) => (
            <MenuItem
              key={option.input_requirement_config_uid}
              onClick={() => handleClickOption(option)}
              selected={option.input_requirement_config_uid === optionId}
            >
              <Typography textAlign="center">
                {t(option.requirement)}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}
