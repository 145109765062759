import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import PestControlOutlinedIcon from "@mui/icons-material/PestControlOutlined";
import CodeOffOutlinedIcon from "@mui/icons-material/CodeOffOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import VibrationIcon from "@mui/icons-material/Vibration";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Info } from "@mui/icons-material";
import Toolbar from "./toolbar";
import NavListItem from "./NavListItem";
import { UserPermissionConstants } from "../../utils/permissions";
import utils from "../../utils/utils";
import { Outlet } from "react-router-dom";
import "./layout.css";
// import OrganizationService from "../../api/organization";
// import AppService from "../../api/app";
import DevicesService from "../../api/devices";
import OrganizationService from "../../api/organization";
import {
  GetDateTimeStringToday,
  ContainsFileExtension,
} from "../../utils/systemInfo";

// import { config } from "../../config";
import { useMediaQuery } from "@mui/material";
import MessageSender from "../message/messageSender";
// Importing all created components
// import Sidebar from "../Sidebar/Sidebar";
// import Navbar from "../Navbar/Navbar";
// Pass the child props

const navigation = [
  { id: 0, label: "Home", icon: <HomeOutlinedIcon />, url: "/" },
  {
    id: 1,
    label: "Machines",
    icon: <LocalShippingOutlinedIcon />,
    url: "machines",
    permission: UserPermissionConstants.VIEW_MACHINES,
  },
  {
    id: 11,
    label: "My Machines",
    icon: <LocalShippingOutlinedIcon />,
    url: "myMachines",
    permission: UserPermissionConstants.VIEW_MY_MACHINES,
  },
  {
    id: 2,
    label: "Devices",
    icon: <VibrationIcon />,
    url: "devices",
    permission: UserPermissionConstants.VIEW_DEVICES,
  },
  {
    id: 12,
    label: "My Devices",
    icon: <VibrationIcon />,
    url: "myDevices",
    permission: UserPermissionConstants.VIEW_MY_DEVICES,
  },
  {
    id: 3,
    label: "Machine Status",
    icon: <Info />,
    url: "machineStatus",
    permission: UserPermissionConstants.VIEW_MACHINES,
    dropdown: [
    {
        id: 0,
        label: "Status",
        icon: <SecurityUpdateGoodIcon />,
        url: "machineStatus",
        permission:
          UserPermissionConstants.VIEW_MACHINES,
      },
      {
        id: 1,
        label: "Trend",
        icon: <PersonAddIcon />,
        url: "machineTrend",
        permission:
          UserPermissionConstants.VIEW_MACHINES,
      },
      {
        id: 2,
        label: "Documentation",
        icon: <EngineeringIcon />,
        url: "machineDocumentation",
        permission:
          UserPermissionConstants.VIEW_MACHINES,
      },
    ],
  },
  {
    id: 4,
    label: "Dashboards",
    icon: <ChromeReaderModeOutlinedIcon />,
    url: "dashboards",
    permission: UserPermissionConstants.VIEW_DASHBOARDS,
  },
  {
    id: 5,
    label: "Users",
    icon: <PeopleAltOutlinedIcon />,
    url: "users",
    permission: UserPermissionConstants.VIEW_USERS,
  },
  {
    id: 6,
    label: "Organizations",
    icon: <LocationCityOutlinedIcon />,
    url: "organizations",
    permission: UserPermissionConstants.VIEW_ORGANIZATIONS,
  },
  // {
  //   id: 7,
  //   label: "SiteLayout",
  //   icon: <MapOutlinedIcon />,
  //   url: "/",
  //   permission: UserPermissionConstants.VIEW_MAP,
  // },
  {
    id: 8,
    label: "Helpdesk",
    icon: <PestControlOutlinedIcon />,
    url: "indexHelpdesk",
    dropdown: [
      {
        id: 0,
        label: "Dashboard",
        icon: <ChromeReaderModeOutlinedIcon />,
        url: "indexHelpdesk",
      },
      {
        id: 1,
        label: "MyTickets",
        icon: <RateReviewOutlinedIcon />,
        url: "myTickets",
      },
      {
        id: 2,
        label: "NewTicket",
        icon: <EditOutlinedIcon />,
        url: "newTicket",
      },
      {
        id: 3,
        label: "Tickets",
        icon: <ViewKanbanOutlinedIcon />,
        url: "tickets",
      },
      {
        id: 4,
        label: "Templates",
        icon: <CropSquareOutlinedIcon />,
        url: "templates",
      },
    ],
  },
  {
    id: 9,
    label: "Admin Portal",
    icon: <AdminPanelSettingsIcon />,
    url: "/",
    dropdown: [
      {
        id: 0,
        label: "Assign Devices",
        icon: <SecurityUpdateGoodIcon />,
        url: "assignDevices",
        permission:
          UserPermissionConstants.ASSIGN_DEVICE_PROFILES_TO_ORGANIZATION,
      },
      {
        id: 1,
        label: "Assign Users",
        icon: <PersonAddIcon />,
        url: "assignUsers",
        permission:
          UserPermissionConstants.ASSIGN_USER_PROFILES_TO_ORGANIZATION,
      },
      {
        id: 2,
        label: "Assign User Devices",
        icon: <EngineeringIcon />,
        url: "devToUsers",
        permission:
          UserPermissionConstants.ASSIGN_USER_PROFILES_TO_ORGANIZATION,
      },
    ],
    permission: UserPermissionConstants.VIEW_DEVELOPER_PORTAL,
  },
  { id: 8, label: "DeveloperPortal", icon: <CodeOffOutlinedIcon />, url: "/" },
];
let UserPermissionsArray = Object.values(UserPermissionConstants);
function RemovePermissionsForHardcodedOrganizations(
  OrgArray,
  PermissionToRemove
) {
  const organizationName = JSON.parse(
    localStorage.getItem("defaultOrganization") || "{}"
  ).full_name;
  if (!OrgArray.includes(organizationName?.toLowerCase())) {
    const index = UserPermissionsArray.indexOf(PermissionToRemove);
    if (index > -1) UserPermissionsArray.splice(index, 1);
  }
}
const openedMixin = (theme) => ({
  width: `calc(${theme.palette.custom.navWidth} + 55px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: ({ prop, open }) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(${theme.palette.custom.navWidth} + 55px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout(props) {
  const theme = useTheme();
  const smOnly = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(
    localStorage.getItem("sidebarOpen")
      ? localStorage.getItem("sidebarOpen") === "true"
      : !smOnly
  );
  const permissions = useSelector(({ permissions }) => permissions);
  const user = useSelector(({ user }) => user);
  const [machinesFound, setMachinesFound] = useState(true);
  const [devicesFound, setDevicesFound] = useState(true);
  const [myMachinesFound, setMyMachinesFound] = useState(true);
  const [myDevicesFound, setMyDevicesFound] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [rerenderKey, setRerenderKey] = useState(0);
  // function getFeatures() {
  //   const organizationUid = JSON.parse(localStorage.getItem("defaultOrganization") || '{}').uid;
  //   OrganizationService.GetOperationFeatures(organizationUid,organziation?.api_key_list?.[0]?.key).then(response => {
  //   });
  // }
  function getDevices() {
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization);
    DevicesService.getDevices({
      deviceID: "",
      deviceType: "",
      source: "",
      apiKey: orgObj?.api_key_list?.[0]?.key,
      // operationUid: organziation.uid,
      historyFromDST: "",
      historyToDST: GetDateTimeStringToday(),
    })
      .then((response) => {
        if (response?.device_profile?.length) {
          setMachinesFound(
            Boolean(
              response?.device_profile.find(
                (m) =>
                  m.device_type?.toLowerCase()?.includes("nerohub") ||
                  m.device_type?.toLowerCase()?.includes("nanohub")
              )
            )
          );
          setDevicesFound(
            Boolean(
              response?.device_profile.find(
                (m) => m.source.toLowerCase() === "iot"
              )
            )
          );
        } else {
          setMachinesFound(false);
          setDevicesFound(false);
        }
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
  }
  function getLinkedDevices() {
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization);
    const person_id = user?.profileId;
    OrganizationService.GetPersonDeviceLink({
      operation_uid: orgObj?.uid,
      device_id: "",
      person_id,
      apiKey: orgObj?.api_key_list?.[0]?.key,
    })
      .then((response) => {
        if (response?.person_device_link?.length) {
          setMyMachinesFound(
            Boolean(
              response?.person_device_link.find(
                (m) =>
                  m.link &&
                  (m.device_type?.toLowerCase()?.includes("nerohub") ||
                    m.device_type?.toLowerCase()?.includes("nanohub"))
              )
            )
          );

          setMyDevicesFound(
            Boolean(
              response?.person_device_link.find(
                (m) => m.link && m.source.toLowerCase() === "iot"
              )
            )
          );
        } else {
          setMyMachinesFound(false);
          setMyDevicesFound(false);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") console.log(error);
      });
  }
  useEffect(() => {
    getDevices();
    getLinkedDevices();
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization);
    let backgroundimageurl = null;
    for (let i in orgObj?.operation_image_list) {
      if (
        orgObj?.operation_image_list[i].image_type ===
          "web_dashboard_background" &&
        orgObj?.operation_image_list[i].status === "active"
      ) {
        if (ContainsFileExtension(orgObj?.operation_image_list[i].public_url)) {
          backgroundimageurl = orgObj?.operation_image_list[i].public_url;
          break;
        }
      }
    }
    setBackgroundImage(backgroundimageurl);
    if (UserPermissionsArray.includes(UserPermissionConstants.VIEW_MAP)) {
      const OrgsWithPermissions = [
        "gses",
        "vinci",
        "nerospec sk",
        "nerospec",
        "nerospec oscon",
        "shaun roselt",
        "glencore kroondal",
        "glencore magareng",
        "glencore helena",
        "glencore thorncliff",
        "unki",
        "mine machines",
        "dwarsrivier mine",
        "strata",
        "sasol",
        "k+s minerals and agriculture gmbh",
        "glencore waterval",
        "amandelbult",
        "marula mine",
        "ghh",
        "samancor doornbosch",
        "gold fields - south deep",
        "black rock",
        "soteria risk solutions",
        "ctrack",
        "nerospec demo",
        "sibanye bathopele",
      ];
      RemovePermissionsForHardcodedOrganizations(
        OrgsWithPermissions,
        UserPermissionConstants.VIEW_MAP
      );
    }
    if (UserPermissionsArray.includes(UserPermissionConstants.VIEW_PINGER)) {
      const OrgsWithPermissions = [
        "nerospec",
        "nerospec it",
        "nerospec networks",
        "katakomb",
        "afrihost",
        "shaun roselt",
      ];
      RemovePermissionsForHardcodedOrganizations(
        OrgsWithPermissions,
        UserPermissionConstants.VIEW_PINGER
      );
    }
    if (
      UserPermissionsArray.includes(UserPermissionConstants.VIEW_GITEA_SERVERS)
    ) {
      const OrgsWithPermissions = [
        "nerospec",
        "nerospec group",
        "nerospec sk",
        "nerospec iot",
        "nerospec oscon",
        "nerospec networks",
        "katakomb",
        "nerospec it",
        "shaun roselt",
      ];
      RemovePermissionsForHardcodedOrganizations(
        OrgsWithPermissions,
        UserPermissionConstants.VIEW_GITEA_SERVERS
      );
    }
    if (UserPermissionsArray.includes(UserPermissionConstants.VIEW_HELPDESK)) {
      const OrgsWithPermissions = [
        "gses",
        "nerospec group",
        "nerospec sk",
        "nerospec iot",
        "katakomb",
        "nerospec networks",
        "nerospec",
        "shaun roselt",
        "nerospec it",
        "jojo",
        "glencore kroondal",
        "gold fields - south deep",
      ];
      RemovePermissionsForHardcodedOrganizations(
        OrgsWithPermissions,
        UserPermissionConstants.VIEW_HELPDESK
      );
    }
    // getFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDrawer = () => {
    setOpen(!open);
    localStorage.setItem("sidebarOpen", !open);
    window.dispatchEvent(new Event("customEventLocalStorageChange"));
  };
  function handleRerender() {
    setRerenderKey((k) => k + 1);
  }
  const drawer = (
    <>
      <DrawerHeader>
        <IconButton onClick={handleDrawer}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {permissions &&
          navigation.map((item, index) =>
            // utils.hasPermission(item.permission, permissions) &&
            utils.hasPermission(item.permission, UserPermissionsArray) &&
            (item.label !== "Devices" || devicesFound) &&
            (item.label !== "Machines" || machinesFound) &&
            (item.label !== "My Devices" || myDevicesFound) &&
            (item.label !== "My Machines" || myMachinesFound) ? (
              <NavListItem
                key={index}
                menuOpen={open}
                navItem={item}
                onRerender={handleRerender}
              />
            ) : null
          )}
      </List>
    </>
  );
  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;
  useEffect(() => {
    document.getElementsByTagName("body")[0].style = { overflow: "auto" };
    document.getElementById("AppBar").style = { padding: 0 };
  });
  return (
    <Box sx={{ display: "flex", minHeight: "100%" }}>
      {/* Attaching all file components */}
      <Toolbar handleDrawerstate={handleDrawer} />
      <Box
        component="nav"
        sx={{
          width: {
            xs: open ? `calc(${theme.palette.custom.navWidth} + 55px)` : 0,
            sm: open
              ? `calc(${theme.palette.custom.navWidth} + 55px)`
              : `calc(${theme.spacing(7)} + 1px)`,
          },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <MuiDrawer
          container={container}
          variant="temporary"
          open={open}
          anchor={"left"}
          onClose={handleDrawer}
          onOpen={handleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: `calc(${theme.palette.custom.navWidth} + 55px)`,
              color: theme.palette.custom.text,
            },
          }}
        >
          {drawer}
        </MuiDrawer>
        <Drawer
          PaperProps={{
            sx: {
              color: theme.palette.custom.text,
              display: { xs: "none", sm: "block" },
            },
          }}
          variant="permanent"
          open={open}
          onOpen={handleDrawer}
          onClose={handleDrawer}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        key={rerenderKey}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: theme.palette.custom.backgroundColor,
          minHeight: "100vh",
          position: "relative",
          top: "64px",
          width: "100%",
          overflow: "auto",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            margin: "0px",
            backgroundColor: theme.palette.custom.backgroundColor,
            borderRadius: "16px",
            border: `1px solid transparent`,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <MessageSender />
    </Box>
  );
}
