import { config } from "../config";
import Utils from "../utils/utils";

class TimezoneService extends Utils.EventEmitter {

    GetTimezones(apiKey) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get: {
                    "locale_id": "",
                    "name": "",
                    "abbreviation": "",
                    "timezone": "",
                    "utc_offset_display": "",
                    "status": ""
                }
            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/config/locale/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
}

const instance = new TimezoneService();

export default instance;
