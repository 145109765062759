import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TicketsService from "../api/tickets";
import OrganizationService from "../api/organization";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import CustomTextField from "../common/CustomTextField";



const statuses = [
    {
        value: "active",
        label: "active"
    },
    {
        value: "removed",
        label: "removed"
    }
];

function handleSave(name, txtArea, orderBy, depID, status, apiKey) {
    TicketsService.SetTicketTemplateHeaders("", name, txtArea, orderBy, depID, status, apiKey);
}

export default function NewTicketTemplate() {
    const organziation = useSelector(({ organziation }) => organziation);
    const [name, setName] = useState("");
    const [orderBy, setorderBy] = useState("");
    const [depID, setDepID] = useState("");
    const [departments, setDepartments] = useState([]);
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [apiKey, setApiKey] = useState("");
    useEffect(() => {
        setApiKey(organziation?.api_key_list?.[0]?.key);
        OrganizationService.GetOperationDepartment("", "", "", "active", apiKey).then(json => {
            const deps = [];
            for (let i in json.department) {
                deps.push({
                    value: json.department[i].department_id,
                    label: json.department[i].name
                });
            }
            setDepartments(deps)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = useTheme();
    const { t } = useTranslation();
    const handleChangeDepartment = (value) => {
        setDepID(value);
    };
    const handleChangeStatus = (value) => {
        setStatus(value);
    };
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                height: "100%"
            }}>
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.borderColor }} variant="h2" gutterBottom>
                    {t("New Ticket Template")}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%", flexDirection: "row", color: "#FFFFFF" }}>
                <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "50%" }}>
                        <CustomTextField
                            id="txtName"
                            label={t("Template Name")}
                            variant="filled"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px"
                            }}
                        />
                        <CustomTextField
                            id="txtOrder"
                            label={t("Display Order")}
                            variant="filled"
                            value={orderBy}
                            onChange={(e) => setorderBy(e.target.value)}
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px",
                                marginTop: "10px"
                            }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "50%" }}>
                        <CustomTextField
                            id="txtDesc"
                            label={t("Description")}
                            variant="filled"
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px"
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <TextField
                    sx={{
                        "background": "gray",
                        "outlineColor": theme.palette.custom.borderColor,
                        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.custom.borderColor,
                            color: theme.palette.custom.borderColor
                        },
                        "& #sltLanguage": {
                            borderColor: theme.palette.custom.borderColor,
                            color: theme.palette.custom.borderColor
                        },
                        "& .MuiInputLabel-outlined": {
                            color: theme.palette.custom.borderColor
                        },
                        "& .Mui-focused": {
                            color: theme.palette.custom.borderColor
                        },
                        "&:focused": {
                            color: theme.palette.custom.borderColor
                        },
                        "marginBottom": "10px",
                        "marginTop": "10px",
                        "width": { xs: "25%", sm: "25%" },
                        "borderColor": theme.palette.custom.borderColor,
                        "borderRadius": "11px",
                        "backgroundColor": theme.palette.custom.light
                    }}
                    id="sltStatus"
                    select
                    label={t("Status")}
                    variant="outlined"
                    value={status}
                    onChange={(e) => {
                        handleChangeStatus(e.target.value);
                    }}>
                    {statuses.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    sx={{
                        "background": "gray",
                        "outlineColor": theme.palette.custom.borderColor,
                        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.custom.borderColor,
                            color: theme.palette.custom.borderColor
                        },
                        "& #sltLanguage": {
                            borderColor: theme.palette.custom.borderColor,
                            color: theme.palette.custom.borderColor
                        },
                        "& .MuiInputLabel-outlined": {
                            color: theme.palette.custom.borderColor
                        },
                        "& .Mui-focused": {
                            color: theme.palette.custom.borderColor
                        },
                        "&:focused": {
                            color: theme.palette.custom.borderColor
                        },
                        "marginBottom": "10px",
                        "width": { xs: "25%", sm: "25%" },
                        "borderColor": theme.palette.custom.borderColor,
                        "borderRadius": "11px",
                        "backgroundColor": theme.palette.custom.light
                    }}
                    id="sltDepartment"
                    select
                    label={t("Department")}
                    variant="outlined"
                    value={depID}
                    onChange={(e) => {
                        handleChangeDepartment(e.target.value);
                    }}>
                    <MenuItem key={0} value={""}>
                        {"No Department"}
                    </MenuItem>
                    {departments.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                <Button variant="contained" sx={{
                    "backgroundColor": theme.palette.custom.borderColor,
                    "color": "white",
                    '&:hover': {
                        backgroundColor: theme.palette.custom.buttonHoverColor,
                        color: theme.palette.custom.buttonHoverTextColor,
                        boxShadow: 'none',
                    }
                }} onClick={() => { handleSave(name, description, orderBy, depID, status, apiKey) }}>
                    {t("Save")}
                </Button>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
        </Box>
    );
}
