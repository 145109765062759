
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export default function NumberTypography({ children }) {
    const theme = useTheme();
    return (
        <Typography sx={{
            color: theme.palette.custom.borderColor,
            m: 0
        }}>
            {children}
        </Typography>
    );
}