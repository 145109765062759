import { config } from "../config";
import Utils from "../utils/utils";

class SignalsService extends Utils.EventEmitter {
  GetDeviceSignalDescriptors(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          signal_descriptor_id: inputData.signal_descriptor_id ?? "",
          machine_type: inputData.machine_type ?? "",
          status: inputData.status ?? "",
          sort_idx: inputData.sort_idx ?? "",
          signal_idx: inputData.signal_idx ?? "",
          name: inputData.name ?? "",
          eunit: inputData.eunit ?? "",
          color: inputData.color ?? "",
          min_from: inputData.min_from ?? "",
          min_to: inputData.min_to ?? "",
          max_from: inputData.max_from ?? "",
          max_to: inputData.max_to ?? "",
          low_thresh_from: inputData.low_thresh_from ?? "",
          low_thresh_to: inputData.low_thresh_to ?? "",
          high_thresh_from: inputData.high_thresh_from ?? "",
          high_thresh_to: inputData.high_thresh_to ?? "",
          updated_timestamp_from: inputData.updated_timestamp_from ?? "",
          order_by: inputData.order_by ?? "",
          order_kind: inputData.order_kind ?? "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/signal/descriptor/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetDeviceSignalDescriptors(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            signal_descriptor_id: inputData.signal_descriptor_id,
            machine_type: inputData.machine_type,
            status: inputData.status,
            sort_idx: inputData.sort_idx,
            signal_idx: inputData.signal_idx,
            name: inputData.name,
            eunit: inputData.eunit,
            color: inputData.color,
            min: inputData.min,
            max: inputData.max,
            low_thresh: inputData.low_thresh,
            high_thresh: inputData.high_thresh,
            updated_timestamp_from: inputData.updated_timestamp_from,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/signal/descriptor/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new SignalsService();

export default instance;
