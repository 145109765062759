import { memo } from "react";
import {
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function SelectFields(props) {
  const { fields, onChange, required, value, title, disabled } = props;
  const theme = useTheme();

  const handleOnChange = (event) => {
    if (event === null) {
      event = [];
    }
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return (
    <FormControl
      required={required}
      disabled={disabled}
      fullWidth
      variant="filled"
      sx={{
        ...props.sx,
        border: "1px solid",
        borderColor: theme.palette.custom.borderColor,
        borderRadius: "11px",
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <InputLabel
        id="demo-simple-select-label"
        style={{ color: "white", mixBlendMode: "difference" }}
      >
        {title}
      </InputLabel>
      <Select
        disableUnderline={true}
        error={required && !value}
        value={value}
        onChange={handleOnChange}
        label={title}
        sx={{
          borderRadius: "11px",
          backgroundColor: theme.palette.custom.backgroundColor,
          "&:before": { border: "none" },
          "&.Mui-disabled:before": { borderStyle: "none" },
        }}
      >
        {/* <MenuItem aria-label="None" value="" /> */}
        {fields?.length
          ? fields.map((item) => (
              <MenuItem key={item.id || item.value} value={item.value}>
                <Typography textAlign="left">
                  {capitalize(item?.label || item.value)}
                </Typography>
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
}

export default memo(SelectFields);

/*
<TextField
                sx={{
                    "background": theme.palette.custom.inputBackgroundColor,
                    "outlineColor": theme.palette.custom.borderColor,
                    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.custom.borderColor,
                        color: theme.palette.custom.borderColor
                    },
                    "& #sltLanguage": {
                        borderColor: theme.palette.custom.borderColor,
                        color: theme.palette.custom.borderColor
                    },
                    "& .MuiInputLabel-outlined": {
                        color: theme.palette.custom.borderColor
                    },
                    "& .Mui-focused": {
                        color: theme.palette.custom.borderColor
                    },
                    "&:focused": {
                        color: theme.palette.custom.borderColor
                    },
                    "&:before": {
                        borderBottom: "none"
                    },
                    "&:hover": {
                        borderBottom: "none"
                    },
                    "marginBottom": "10px",
                    "width": { xs: "100%", sm: "100%%" },
                    "borderColor": theme.palette.custom.borderColor,
                    "borderRadius": "11px",
                    "backgroundColor": theme.palette.custom.light
                }}
                id="sltLanguage"
                select
                variant="filled"
                error={required && !value}
                value={value}
                onChange={handleOnChange}
                label={title}
            >
                <MenuItem aria-label="None" value="" />
                {fields?.length ? fields.map(item => (
                    <MenuItem key={item.value || item} value={item.value || item}>
                        <Typography textAlign="left">{item?.label || item}</Typography>
                    </MenuItem>
                )) : null}
            </TextField>
*/
