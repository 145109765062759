import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (ref, callback) => {
  useEffect(() => {
    const element = ref.current;
    const observer = new ResizeObserver((entries) => {
      if (entries.length === 0) return;
      const entry = entries[0];
      callback(entry);
    });

    if (element) {
      observer.observe(element);
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && element) {
        callback({ contentRect: element.getBoundingClientRect() });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ref, callback]);
};

export default useResizeObserver;
