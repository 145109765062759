/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setDashboards = createAsyncThunk('dashboards/setDashboards', async (dashboards) => {

  return dashboards;
});



const initialState = null;

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setDashboards.fulfilled, (state, action) => action.payload);
  },
});

export const selectDashboards = ({ dashboards }) => dashboards;

export default dashboardsSlice.reducer;
