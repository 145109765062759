import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import "./scheduledTime.css";
import ScheduledTimeService from "../../api/scheduledTime";
import {
  getUrlVars,
  UpdateURLQueryStringWithoutReload,
} from "../../utils/systemInfo";
import AlertDialog from "../../common/AlertDialog";
import { showMessage } from "../../store/messageSlice";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

function TimeScheduler(popShifts) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveLoading, setSaveLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const organziation = useSelector(({ organziation }) => organziation);
  let apiKey = localStorage.getItem("apiKey");
  let orgID = organziation?.uid;
  let orgName = organziation?.full_name;
  useEffect(() => {
    if (popShifts) populateShifts(orgID, orgName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  const handleClose = () => {
    setOpenDialog(false);
  };
  function handleOpenDialog() {
    setOpenDialog(true);
  }
  function startDragSelect(event) {
    let element = event.target;
    sessionStorage.setItem("mousedown", true);
    if (element.classList.contains("selected")) {
      element.classList.remove("selected");
    } else {
      element.classList.add("selected");
    }
  }
  function handleWeekSelect(event) {
    let element = event.target;
    if (element.classList.contains("weekSelect")) {
      element.classList.remove("weekSelect");
    } else {
      element.classList.add("weekSelect");
    }
    for (let i = 1; i < 8; i++) {
      let letter;
      switch (i) {
        case 1:
          letter = "m";
          break;
        case 2:
          letter = "t";
          break;
        case 3:
          letter = "w";
          break;
        case 4:
          letter = "tt";
          break;
        case 5:
          letter = "f";
          break;
        case 6:
          letter = "s";
          break;
        case 7:
          letter = "ss";
          break;
        default:
          break;
      }
      if (element.classList.contains("weekSelect")) {
        document
          .getElementById(letter + element.id.toString())
          .classList.add("selected");
      } else {
        document
          .getElementById(letter + element.id.toString())
          .classList.remove("selected");
      }
    }
  }
  function handleClear() {
    document.querySelectorAll(".selected").forEach((element) => {
      element.classList.remove("selected");
    });
  }
  function handleMouseDown(element) {
    if (element.classList.contains("selected")) {
      element.classList.remove("selected");
    } else {
      element.classList.add("selected");
    }
  }
  function handleDragSelect(event, isMouseDown) {
    let selectCount;
    let element = event.target;
    let daySelect;
    if (
      element.id.charAt(0).toLowerCase() === element.id.charAt(1).toLowerCase()
    ) {
      daySelect =
        element.id.charAt(0).toLowerCase() + element.id.charAt(0).toLowerCase();
    } else {
      daySelect = element.id.charAt(0).toLowerCase();
    }
    let rowCount = 0;
    let dayCount = 0;
    for (let i = 1; i < 49; i++) {
      if (
        document
          .getElementById(daySelect + i.toString())
          .classList.contains("selected")
      )
        dayCount++;
      if (dayCount === 48) {
        document.getElementById(daySelect).classList.add("daySelect");
      } else if (
        document.getElementById(daySelect).classList.contains("daySelect")
      ) {
        document.getElementById(daySelect).classList.remove("daySelect");
      }
      rowCount = 0;
      let index = i;
      for (let j = 1; j < 8; j++) {
        let letter;
        switch (j) {
          case 1:
            letter = "m";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 2:
            letter = "t";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 3:
            letter = "w";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 4:
            letter = "tt";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 5:
            letter = "f";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 6:
            letter = "s";
            if (
              document
                .getElementById(letter + index.toString())
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          case 7:
            letter = "ss";
            if (
              document
                .getElementById(letter + index)
                .classList.contains("selected")
            ) {
              rowCount++;
            }
            break;
          default:
            break;
        }
        if (rowCount === 7) {
          document.getElementById(index).classList.add("weekSelect");
        } else {
          document.getElementById(index).classList.remove("weekSelect");
        }
      }
    }
    selectCount = document.querySelectorAll(".selected").length;
    document.getElementById("timeSelected").innerText =
      "Total Time Selected: " + (selectCount / 2).toString() + " Hours";
    if (isMouseDown === "true") {
      document.getElementById("scheduler_container").classList.add("selecting");
      handleMouseDown(element, true);
    } else {
      selectCount = document.querySelectorAll(".selected").length;
      document
        .getElementById("scheduler_container")
        .classList.remove("selecting");
      document.getElementById("timeSelected").innerText =
        "Total Time Selected: " + (selectCount / 2).toString() + " Hours";
      sessionStorage.setItem("mouseDown", false);
    }
  }
  function handleDaySelect(event) {
    let selectCount;
    let element = event.target;
    let daySelect = element.innerText.charAt(0).toLowerCase();
    if (element.innerText === "Thursday") {
      daySelect = "tt";
    } else if (element.innerText === "Sunday") {
      daySelect = "ss";
    }
    if (element.classList.contains("daySelect")) {
      element.classList.remove("daySelect");
    } else {
      element.classList.add("daySelect");
    }
    for (let i = 1; i < 49; i++) {
      if (element.classList.contains("daySelect")) {
        document
          .getElementById(daySelect + i.toString())
          .classList.add("selected");
      } else {
        document
          .getElementById(daySelect + i.toString())
          .classList.remove("selected");
      }
    }
    selectCount = document.querySelectorAll(".selected").length;
    document.getElementById("timeSelected").innerText =
      "Total Time Selected: " + (selectCount / 2).toString() + " Hours";
  }

  function handleSave() {
    // console.log("handlesavescheduletime");
    setSaveLoading(true);
    let options = { hour12: false };
    ScheduledTimeService.GetScheduledTimeConfig(
      getUrlVars()["ProfileID"],
      "active",
      apiKey
    )
      .then((json) => {
        // console.log(
        //   "scheduled_time_config",
        //   json.success,
        //   json.scheduled_time_config
        // );
        if (
          json.success &&
          json.scheduled_time_config &&
          json.scheduled_time_config.length > 0
        ) {
          let config_id =
            json.scheduled_time_config[0]?.scheduled_time_config_id;
          let updatedTimeStart;
          let updatedTimeEnd;
          let time_start = new Date(new Date().setHours(0, 0, 0, 0));
          let time_end = new Date(time_start.getTime() + 30 * 60000);
          document.querySelectorAll(".timerow").forEach((e, index) => {
            let i = index + 1;
            let monday = 0;
            let tuesday = 0;
            let wednesday = 0;
            let thursday = 0;
            let friday = 0;
            let saturday = 0;
            let sunday = 0;
            if (index === 48) {
              time_end = new Date(new Date().setHours(0, 0, 0, 0));
            } else if (index !== 0) {
              time_start = new Date(time_start.getTime() + 30 * 60000);
              time_end = new Date(time_end.getTime() + 30 * 60000);
            }
            if (
              document
                .getElementById("m" + i.toString())
                .classList.contains("selected")
            ) {
              monday = 30;
            }
            if (
              document
                .getElementById("t" + i.toString())
                .classList.contains("selected")
            ) {
              tuesday = 30;
            }
            if (
              document
                .getElementById("w" + i.toString())
                .classList.contains("selected")
            ) {
              wednesday = 30;
            }
            if (
              document
                .getElementById("tt" + i.toString())
                .classList.contains("selected")
            ) {
              thursday = 30;
            }
            if (
              document
                .getElementById("f" + i.toString())
                .classList.contains("selected")
            ) {
              friday = 30;
            }
            if (
              document
                .getElementById("s" + i.toString())
                .classList.contains("selected")
            ) {
              saturday = 30;
            }
            if (
              document
                .getElementById("ss" + i.toString())
                .classList.contains("selected")
            ) {
              sunday = 30;
            }
            updatedTimeStart = time_start
              .toLocaleTimeString("en-US", options)
              .slice(0, 5);
            updatedTimeEnd = time_end
              .toLocaleTimeString("en-US", options)
              .slice(0, 5);
            if (updatedTimeStart.slice(0, 2) === "24") {
              updatedTimeStart = updatedTimeStart.replace("24", "00");
            }
            if (updatedTimeEnd.slice(0, 2) === "24") {
              updatedTimeEnd = updatedTimeEnd.replace("24", "00");
            }
            const apiKeyValue = localStorage.getItem("apiKey");
            ScheduledTimeService.SetScheduledTimeRoster(
              config_id,
              "",
              updatedTimeStart,
              updatedTimeEnd,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
              sunday,
              "active",
              apiKeyValue
            )
              .then((json) => {
                setSaveLoading(false);
                setOpenDialog(false);
              })
              .catch((_errors) => {
                //  console.log("scheduledtimeerror", _errors);
                setSaveLoading(false);
                showErrorMessage();
                setOpenDialog(false);
              });
          });
        } else {
          //  console.log("elseerror");
          setSaveLoading(false);
          showErrorMessage();
          setOpenDialog(false);
        }
      })
      .catch((_errors) => {
        //   console.log("scheduledtimeerror", _errors);
        setSaveLoading(false);
        showErrorMessage();
        setOpenDialog(false);
      });
  }
  function showErrorMessage() {
    dispatch(
      showMessage({
        message: t("error occurred"), //text or html
        autoHideDuration: 10000, //ms
        anchorOrigin: {
          vertical: "top", //top bottom
          horizontal: "center", //left center right
        },
        variant: "error", //success error info warning null
      })
    );
  }
  function handleDelete() {
    let orgID = getUrlVars()["ProfileID"];
    ScheduledTimeService.GetScheduledTimeConfig(orgID, "active", apiKey).then(
      (jsonConfig) => {
        let config_id =
          jsonConfig.scheduled_time_config[0]?.scheduled_time_config_id;
        ScheduledTimeService.GetScheduledTimeRoster(
          config_id,
          "active",
          apiKey
        ).then((json) => {
          if (json.success && json.count > 0) {
            let count = 0;
            for (let i in json.scheduled_time_roster) {
              // eslint-disable-next-line no-loop-func
              ScheduledTimeService.SetScheduledTimeRoster(
                "",
                json.scheduled_time_roster[i].scheduled_time_roster_id,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "removed",
                apiKey
                // eslint-disable-next-line no-loop-func
              ).then((jsonRoster) => {
                if (jsonRoster.success) {
                  count++;
                  if (count === 48) {
                    handleClear();
                  }
                }
              });
            }
          }
        });
      }
    );
  }

  function populateShifts(orgID, orgName) {
    // console.log("populateShifts");
    let json;
    let config_id;
    ScheduledTimeService.GetScheduledTimeProfile(
      "",
      orgID,
      "",
      "active",
      apiKey
    ).then((jsonProfile) => {
      UpdateURLQueryStringWithoutReload(
        "ProfileID",
        jsonProfile.scheduled_time_profile?.[0].scheduled_time_profile_id
      );
      ScheduledTimeService.GetScheduledTimeConfig(
        jsonProfile.scheduled_time_profile?.[0].scheduled_time_profile_id,
        "active",
        apiKey
      ).then((jsonConfig) => {
        config_id =
          jsonConfig.scheduled_time_config[0]?.scheduled_time_config_id;
        ScheduledTimeService.GetScheduledTimeRoster(
          config_id,
          "active",
          apiKey
        ).then((jsonRoster) => {
          json = jsonRoster;
          // document.querySelector("title").innerText = `${""} | Device Tracking and Telemetry`;
          if (
            typeof document.getElementById("scheduler_container") !==
            "undefined"
          ) {
            if (json.success && json.count > 0) {
              for (let i in json.scheduled_time_roster) {
                let jsonObject = json.scheduled_time_roster[i];
                let index = parseInt(i) + 1;
                let letter;
                let rowCount = 0;
                if (
                  jsonObject.monday !== 0 ||
                  jsonObject.tuesday !== 0 ||
                  jsonObject.wednesday !== 0 ||
                  jsonObject.thursday !== 0 ||
                  jsonObject.friday !== 0 ||
                  jsonObject.saturday !== 0 ||
                  jsonObject.sunday !== 0
                ) {
                  for (let j = 1; j < 8; j++) {
                    switch (j) {
                      case 1:
                        letter = "m";
                        if (jsonObject.monday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 2:
                        letter = "t";
                        if (jsonObject.tuesday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 3:
                        letter = "w";
                        if (jsonObject.wednesday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 4:
                        letter = "tt";
                        if (jsonObject.thursday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 5:
                        letter = "f";
                        if (jsonObject.friday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 6:
                        letter = "s";
                        if (jsonObject.saturday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      case 7:
                        letter = "ss";
                        if (jsonObject.sunday !== 0) {
                          if (
                            document.getElementById(letter + index) !==
                              undefined &&
                            document.getElementById(letter + index) !== null
                          ) {
                            document
                              .getElementById(letter + index)
                              .classList.add("selected");
                            let count = 0;
                            for (let k = 1; k < 49; k++) {
                              if (
                                document
                                  .getElementById(letter + k.toString())
                                  .classList.contains("selected")
                              )
                                count++;
                            }
                            if (count === 48)
                              document
                                .getElementById(letter)
                                .classList.add("daySelect");
                            rowCount++;
                          }
                        }
                        break;
                      default:
                        break;
                    }
                    if (rowCount === 7) {
                      document
                        .getElementById(index)
                        .classList.add("weekSelect");
                    }
                  }
                }
              }
            }
          }
        });
      });
    });
  }
  return (
    <>
      <div id="timeScheduler" style={{ width: "100%" }}>
        <h2
          style={{
            color: theme.palette.custom.text,
          }}
          id="timeSelected"
        >
          Total Time Selected:
        </h2>
        <div
          id="timeButtons"
          className="buttons"
          style={{
            flexDirection: "row",
            paddingLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <StyledButton
            id="clear"
            className="btn-primary btn"
            sx={{
              float: "left",
              marginRight: "5px",
            }}
            onClick={handleClear}
          >
            {t("Clear")}
          </StyledButton>
          <StyledButton
            id="save"
            className="btn-primary btn"
            sx={{ float: "left", marginRight: "5px" }}
            onClick={handleOpenDialog}
          >
            {t("Save")}
          </StyledButton>
          <StyledButton
            id="delete"
            className="btn-primary btn"
            sx={{ float: "left" }}
            onClick={handleDelete}
          >
            {t("Delete")}
          </StyledButton>
        </div>
        <br />
        <div
          id="scheduler_container"
          className="scheduler-container"
          style={{
            display: "flex",
            marginBottom: "10px",
            paddingLeft: "10px",
            float: "left",
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div
            className="col"
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div className="row-1 "></div>
            <div id="1" className="row-1 timerow" onClick={handleWeekSelect}>
              00:00
            </div>
            <div id="2" className="row-1 timerow" onClick={handleWeekSelect}>
              00:30
            </div>
            <div id="3" className="row-1 timerow" onClick={handleWeekSelect}>
              01:00
            </div>
            <div id="4" className="row-1 timerow" onClick={handleWeekSelect}>
              01:30
            </div>
            <div id="5" className="row-1 timerow" onClick={handleWeekSelect}>
              02:00
            </div>
            <div id="6" className="row-1 timerow" onClick={handleWeekSelect}>
              02:30
            </div>
            <div id="7" className="row-1 timerow" onClick={handleWeekSelect}>
              03:00
            </div>
            <div id="8" className="row-1 timerow" onClick={handleWeekSelect}>
              03:30
            </div>
            <div id="9" className="row-1 timerow" onClick={handleWeekSelect}>
              04:00
            </div>
            <div id="10" className="row-1 timerow" onClick={handleWeekSelect}>
              04:30
            </div>
            <div id="11" className="row-1 timerow" onClick={handleWeekSelect}>
              05:00
            </div>
            <div id="12" className="row-1 timerow" onClick={handleWeekSelect}>
              05:30
            </div>
            <div id="13" className="row-1 timerow" onClick={handleWeekSelect}>
              06:00
            </div>
            <div id="14" className="row-1 timerow" onClick={handleWeekSelect}>
              06:30
            </div>
            <div id="15" className="row-1 timerow" onClick={handleWeekSelect}>
              07:00
            </div>
            <div id="16" className="row-1 timerow" onClick={handleWeekSelect}>
              07:30
            </div>
            <div id="17" className="row-1 timerow" onClick={handleWeekSelect}>
              08:00
            </div>
            <div id="18" className="row-1 timerow" onClick={handleWeekSelect}>
              08:30
            </div>
            <div id="19" className="row-1 timerow" onClick={handleWeekSelect}>
              09:00
            </div>
            <div id="20" className="row-1 timerow" onClick={handleWeekSelect}>
              09:30
            </div>
            <div id="21" className="row-1 timerow" onClick={handleWeekSelect}>
              10:00
            </div>
            <div id="22" className="row-1 timerow" onClick={handleWeekSelect}>
              10:30
            </div>
            <div id="23" className="row-1 timerow" onClick={handleWeekSelect}>
              11:00
            </div>
            <div id="24" className="row-1 timerow" onClick={handleWeekSelect}>
              11:30
            </div>
            <div id="25" className="row-1 timerow" onClick={handleWeekSelect}>
              12:00
            </div>
            <div id="26" className="row-1 timerow" onClick={handleWeekSelect}>
              12:30
            </div>
            <div id="27" className="row-1 timerow" onClick={handleWeekSelect}>
              13:00
            </div>
            <div id="28" className="row-1 timerow" onClick={handleWeekSelect}>
              13:30
            </div>
            <div id="29" className="row-1 timerow" onClick={handleWeekSelect}>
              14:00
            </div>
            <div id="30" className="row-1 timerow" onClick={handleWeekSelect}>
              14:30
            </div>
            <div id="31" className="row-1 timerow" onClick={handleWeekSelect}>
              15:00
            </div>
            <div id="32" className="row-1 timerow" onClick={handleWeekSelect}>
              15:30
            </div>
            <div id="33" className="row-1 timerow" onClick={handleWeekSelect}>
              16:00
            </div>
            <div id="34" className="row-1 timerow" onClick={handleWeekSelect}>
              16:30
            </div>
            <div id="35" className="row-1 timerow" onClick={handleWeekSelect}>
              17:00
            </div>
            <div id="36" className="row-1 timerow" onClick={handleWeekSelect}>
              17:30
            </div>
            <div id="37" className="row-1 timerow" onClick={handleWeekSelect}>
              18:00
            </div>
            <div id="38" className="row-1 timerow" onClick={handleWeekSelect}>
              18:30
            </div>
            <div id="39" className="row-1 timerow" onClick={handleWeekSelect}>
              19:00
            </div>
            <div id="40" className="row-1 timerow" onClick={handleWeekSelect}>
              19:30
            </div>
            <div id="41" className="row-1 timerow" onClick={handleWeekSelect}>
              20:00
            </div>
            <div id="42" className="row-1 timerow" onClick={handleWeekSelect}>
              20:30
            </div>
            <div id="43" className="row-1 timerow" onClick={handleWeekSelect}>
              21:00
            </div>
            <div id="44" className="row-1 timerow" onClick={handleWeekSelect}>
              21:30
            </div>
            <div id="45" className="row-1 timerow" onClick={handleWeekSelect}>
              22:00
            </div>
            <div id="46" className="row-1 timerow" onClick={handleWeekSelect}>
              22:30
            </div>
            <div id="47" className="row-1 timerow" onClick={handleWeekSelect}>
              23:00
            </div>
            <div
              id="48"
              className="row-last timerow"
              onClick={handleWeekSelect}
            >
              23:30
            </div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="m" className="row-1 day" onClick={handleDaySelect}>
              Monday
            </div>
            <div
              id="m1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="m48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="t" className="row-1 day" onClick={handleDaySelect}>
              Tuesday
            </div>
            <div
              id="t1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="t48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="w" className="row-1 day" onClick={handleDaySelect}>
              Wednesday
            </div>
            <div
              id="w1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="w48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="tt" className="row-1 day" onClick={handleDaySelect}>
              Thursday
            </div>
            <div
              id="tt1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="tt48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="f" className="row-1 day" onClick={handleDaySelect}>
              Friday
            </div>
            <div
              id="f1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="f48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="s" className="row-1 day" onClick={handleDaySelect}>
              Saturday
            </div>
            <div
              id="s1"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s2"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s3"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s4"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s5"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s6"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s7"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s8"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s9"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s10"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s11"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s12"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s13"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s14"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s15"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s16"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s17"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s18"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s19"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s20"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s21"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s22"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s23"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s24"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s25"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s26"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s27"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s28"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s29"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s30"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s31"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s32"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s33"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s34"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s35"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s36"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s37"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s38"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s39"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s40"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s41"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s42"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s43"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s44"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s45"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s46"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s47"
              className="row-1 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="s48"
              className="row-last timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              float: "left",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="ss" className="row-1 row-2 day" onClick={handleDaySelect}>
              Sunday
            </div>
            <div
              id="ss1"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss2"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss3"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss4"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss5"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss6"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss7"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss8"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss9"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss10"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss11"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss12"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss13"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss14"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss15"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss16"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss17"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss18"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss19"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss20"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss21"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss22"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss23"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss24"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss25"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss26"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss27"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss28"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss29"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss30"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss31"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss32"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss33"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss34"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss35"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss36"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss37"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss38"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss39"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss40"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss41"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss42"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss43"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss44"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss45"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss46"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss47"
              className="row-1 row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
            <div
              id="ss48"
              className="row-last row-2 timeslot"
              onMouseDown={startDragSelect}
              onMouseOver={(event) => {
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
              onMouseUp={(event) => {
                sessionStorage.setItem("mousedown", false);
                handleDragSelect(event, sessionStorage.getItem("mousedown"));
              }}
            ></div>
          </div>
        </div>
      </div>
      <h3>Exceptions</h3>
      <hr />
      <StyledButton id="exceptionBtn" className="btn btn-primary">
        Add Exception
      </StyledButton>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={handleSave}
        saveLoading={saveLoading}
        dialogTitle={t("Are you sure you want to save schedule?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Save")}
      />
    </>
  );
}
export default TimeScheduler;
