import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import ProjectService from "../api/project";
import CustomTextField from "../common/CustomTextField";
import { getUrlVars } from "../utils/systemInfo";
import Breadcrumbs from "../common/breadcrumbs";
import Swal from "sweetalert2";

const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    { id: 1, name: "MachineStatus", href: "machineStatus" },
    { id: 2, name: "AddMachineStatus", href: "AddMachineStatus" }
];
const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(({ theme }) => ({
    "backgroundColor": theme.palette.custom.borderColor,
    "width": "10%",
    "&.MuiLoadingButton-loading": {
        backgroundColor: theme.palette.custom.light
    }
}));
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    color: theme.palette.custom.text,
    '&:hover': {
        backgroundColor: theme.palette.custom.buttonHoverColor,
        color: theme.palette.custom.buttonHoverTextColor,
        boxShadow: 'none',
    }
}));

function AddMachineStatusPage() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [name, setName] = useState("");
    const [savingLoading, setSavingLoading] = useState(false);
    const organziation = useSelector(({ organziation }) => organziation);
    const navigate = useNavigate();

    useEffect(() => {
        const projectId = String(getUrlVars()["projectId"]);
        const groupId = String(getUrlVars()["groupId"]);
        const apiKey = organziation?.api_key_list?.[0]?.key;
        if ((projectId, groupId)) {
            ProjectService.GetProjectGroup(groupId, "", "", "", "active", apiKey)
                .then((json) => {
                    setName(json.task_group ? json.task_group[0].name : undefined);
                    //   navigate(
                    //     `/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`
                    //   );
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function CreateTasks(task_id, orgID, apiKey) {
        let Tasks = [];
        const TabName = "Status";
        switch (orgID) {
            case "18": // Black Rock
                switch (TabName) {
                    case "Status":
                    default:
                        Tasks = [
                            ["Milestone 1 MCI Installed (10%)", "16"],
                            ["Milestone 2 SBI (50%)", "16"],
                            ["Milestone 3 SIMAC (80%)", "16"],
                            ["Milestone 4 L9 Activation (100%)", "16"],
                            ["Operational Status", "16"]
                        ];
                        break;

                    case "Documentation":
                        break;
                }
                break;
            case "65": // Amandelbult and Default
            default:
                switch (TabName) {
                    case "Status":
                    default:
                        Tasks = [
                            ["Firmware Updated (30%)", "16"],
                            ["Wifi Script (60%)", "16"],
                            ["SIMAC Check (100%)", "16"]
                        ];
                        break;

                    case "Documentation":
                        break;
                }
                break;
            case "30": // Shaun Roselt
            case "102": // Mototolo
                switch (TabName) {
                    case "Status":
                    default:
                        Tasks = [
                            ["Milestone 1 MCI Installed (10%)", "16"],
                            ["Milestone 2 SBI (50%)", "16"],
                            ["Milestone 3 SIMAC (80%)", "16"],
                            ["Milestone 4 L9 Activation (100%)", "16"]
                        ];
                        break;

                    case "Documentation":
                        break;
                }
                break;
            case "84": // RioTinto Kennecott Underground Copper
                switch (TabName) {
                    case "Status":
                    default:
                        Tasks = [
                            ["Firmware Updated (30%)", "16"],
                            ["Wifi Script (60%)", "16"],
                            ["SIMAC Check (100%)", "16"]
                        ];
                        break;

                    case "Documentation":
                        Tasks = [
                            ["Risk", "16"],
                            ["Electrical Schematics", "16"],
                            ["Hydraulic Schematics", "16"],
                            ["Pneumatic Schematics", "16"],
                            ["Equipment Manual", "16"],
                            ["Workshop Manual", "16"],
                            ["Service Manual", "16"],
                            ["Control Philosophy", "16"],
                            ["Machine Drawings", "16"],
                            ["Firmware and Software", "16"],
                            ["Installation Methodology", "16"],
                            ["Audit Photo", "16"],
                            ["Audit Video", "16"],
                            ["Audit Documents", "16"],
                            ["CAN Data", "16"],
                            ["Specifications", "16"]
                        ];
                        break;
                }
                break;
        }
        Tasks.forEach((task, index) => {
            const timestamp = getTimestamp(index);
            ProjectService.SetTaskParam("", task_id, task[0], "", task[1], timestamp, "active", apiKey).then((jsonParam) => {
                if (jsonParam.count > 0) {
                    ProjectService.SetParamVal("", jsonParam.task_param[0].task_param_id, "0", "active", apiKey).then((jsonVal) => {
                        if (jsonVal.count > 0 && index === Tasks.length - 1) {
                            Swal.fire({
                                title: "Success!",
                                showConfirmButton: true,
                                confirmButtonText: "confirm",
                                icon: "success",
                                color: theme.palette.custom.text,
                                background: theme.palette.custom.inputBackgroundColor
                            }).then((result) => {
                                navigate("/machineStatus");
                            });
                        }
                    });
                }
            });
        });
    }
    function handleSubmit(event) {
        setSavingLoading(true);
        event.preventDefault();
        const projectId = String(getUrlVars()["projectId"]);
        const groupId = String(getUrlVars()["groupId"]);
        if (projectId) {
            const apiKey = organziation?.api_key_list?.[0]?.key;
            const orgID = organziation.uid;
            ProjectService.SetProjectGroup(groupId === undefined ? groupId : "", projectId, "", name, "", "active", apiKey)
                .then((json) => {
                    ProjectService.SetProjectTask("", projectId, json.task_group[0].task_group_id, "", "Progress", "", "active", apiKey).then((jsonTask) => {
                        if (jsonTask.count > 0) {
                            CreateTasks(jsonTask.project_task[0].task_id, orgID, apiKey);
                        }
                    });
                    //   navigate(
                    //     `/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`
                    //   );
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
                });
        }

        setSavingLoading(false);
    }

    function getTimestamp(seconds) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return timestamp;
    }

    function handleRemove() {
        //remove machine from rollout list (not required yet)
        const groupId = String(getUrlVars()["groupId"]);
        const apiKey = organziation?.api_key_list?.[0]?.key;
        Swal.fire({
            title: "Are you sure?",
            showCancelButton: true,
            showConfirmButton: true,
            color: theme.palette.custom.text,
            background: theme.palette.custom.inputBackgroundColor,
            icon: "warning"
        }).then((result) => {
            if (result.isConfirmed) {
                ProjectService.SetProjectGroup(groupId, "", "", "", "", "removed", apiKey).then((json) => {
                    if (json.count > 0) {
                        navigate("/machineStatus");
                    }
                });
            }
        });
    }
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{ height: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography
                    sx={{
                        fontSize: "45px",
                        mb: 0,
                        color: theme.palette.custom.borderColor
                    }}
                    gutterBottom>
                    {t("AddMachineStatus")}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box
                sx={{
                    display: { xs: "block", sm: "flex" },
                    width: "100%",
                    flexDirection: "row",
                    color: "#FFFFFF"
                }}>
                <CustomTextField
                    error={!name}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    id="txtName"
                    label={t("MachineName")}
                    variant="filled"
                    margin="normal"
                />
            </Box>
            <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
                <StyledButton sx={{ mt: 3, mb: 1, mr: 1 }} onClick={handleRemove}>
                    {t("Delete")}
                </StyledButton>
                <StyledLoadingButton loading={savingLoading} loadingIndicator={<CircularProgress color="primary" size={16} />} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} onClick={handleSubmit}>
                    {t("Save")}
                </StyledLoadingButton>
            </Box>
        </Box>
    );
}

export default AddMachineStatusPage;
