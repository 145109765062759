import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import { useNavigate } from "react-router-dom";
import ProfileService from "../api/profile";
import { setUsersData } from "../store/usersSlice";
import UserTableRow from "./usersTableRow";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { stableSort, getComparator } from "../utils/systemInfo";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import SearchInput from "../common/searchInput";
import Breadcrumbs from "../common/breadcrumbs";
import StyledTypography from "../common/StyledTypography";
import { UserPermissionConstants } from "../utils/permissions";
import handleNavigate from "../common/customFunctions/handleNavigate";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Users", href: "users" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const userHead = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    sort: true,
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email Address",
    sort: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile Number",
    sort: true,
  },
  {
    id: "user_type_uid",
    numeric: false,
    disablePadding: false,
    label: "User Type",
    sort: true,
  },
  {
    id: "signin_logger[0].dst",
    numeric: false,
    disablePadding: false,
    label: "Last Login",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];

export default function Users() {
  const organziation = useSelector(({ organziation }) => organziation);
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userTypes, setUserTypes] = useState([]);
  const permissions = useSelector(({ permissions }) => permissions);
  const users = useSelector(({ users }) => users);
  const [userInputData, setUserInputData] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstname");
  const [pageUser, setPageUser] = useState(0);
  const [rowsPerPageUser, setRowsPerPageUser] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchTextUser, setSearchTextUser] = useState("");
  const [loadingValueUser, setLoadingValueUser] = useState(true);
  const [selected, setSelected] = useState([]);
  const visibleRowsUser = useMemo(
    () => {
      if (users?.length) {
        return stableSort(users, getComparator(order, orderBy)).slice(
          pageUser * rowsPerPageUser,
          pageUser * rowsPerPageUser + rowsPerPageUser
        );
      } else {
        return [];
      }
    },
    [order, orderBy, pageUser, rowsPerPageUser, users]
    //[page, rowsPerPage, safety],
  );
  function getCSVUser(u) {
    let email = "";
    let mobile = "";
    if (u?.contact?.length) {
      for (let i in u.contact) {
        if (
          u.contact[i].contact_type === "email_address" &&
          u.contact[i].primary_contact === true
        ) {
          email = u.contact[i].value;
        } else if (
          u.contact[i].contact_type === "tel_mobile" &&
          u.contact[i].primary_contact === true
        ) {
          mobile = u.contact[i].value;
        }
      }
    }
    const lastLogin = u?.signinLogger?.[0]?.dst;
    return { ...u, email, mobile, lastLogin };
  }
  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const handleClick = (event, name, uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // navigate(`/profile?profile=${uid}`);
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  function handleSearchUser(ev) {
    setLoadingValueUser(true);
    setPageUser(0);
    const newSearchText = ev.target.value;
    setSearchTextUser(newSearchText);
    let userInputObj = userInputData;
    if (userInputObj) {
      userInputObj.newSearchText = newSearchText;
    }
    dispatch(setUsersData(userInputObj));
    setLoadingValueUser(false);
  }
  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPageUser(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageUser", event.target.value);
    setPageUser(0);
  };
  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };
  const handleRequestSortUser = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    let currentorganization;
    const apiKey = localStorage.getItem("apiKey");
    if (!organziation && apiKey) {
      handleGetUersTableDate();
    } else if (organziation && organziation !== currentorganization) {
      currentorganization = organziation;
      handleGetUersTableDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleGetUersTableDate() {
    setLoadingValueUser(true);
    setUserInputData(null);
    if (!permissions || permissions.includes("1") === false) {
      document.querySelectorAll(".viewUser").forEach((e) => {
        e.disabled = true;
        e.style = "cursor: default";
      });
    }
    const apiKey = localStorage.getItem("apiKey");
    let ProfileInfo = {
      user_type_uid: "",
      name: "",
      status: "active",
      apiKey: apiKey,
    };
    ProfileService.GetUserType(ProfileInfo).then((json) => {
      if (json.app_user_type !== undefined && json.app_user_type.length > 0) {
        let userTypesJson = json.app_user_type;
        let userTypesArr = [];
        for (let i in userTypesJson) {
          userTypesArr.push({
            value: userTypesJson[i].user_type_uid,
            label: userTypesJson[i].name,
          });
          setUserTypes(userTypesArr);
        }
      }
    });
    ProfileService.GetUserProfile({
      profile_uid: "",
      profile_id: "",
      app_profile_uid: "",
      firstname: "",
      lastname: "",
      tel_primary: "",
      email_primary: "",
      status: "active",
      apiKey: apiKey,
    })
      .then((json) => {
        if (json && json.person_profile && json.person_profile.length > 0) {
          setUserInputData({ json, newSearchText: searchTextUser });
          dispatch(setUsersData({ json, newSearchText: searchTextUser }));
        }
        setLoadingValueUser(false);
      })
      .catch(() => {
        setLoadingValueUser(false);
      });
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {
              //<HomePagesMenu pageName={'Maintenance'} />
            }
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
              sx={{
                color: theme.palette.custom.text,
                fontSize: "2.5rem",
              }}
            >
              {t("Users")}
            </Typography>
          </Box>
          <Box
            sx={{
              float: "right",
              display: "flex",
              gap: "1rem",
              width: { xs: "100%", sm: "450px" },
            }}
          >
            {permissions &&
              permissions.includes(
                UserPermissionConstants.ASSIGN_DEVICE_PROFILES_TO_ORGANIZATION
              ) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    height: "36px",
                    backgroundColor: theme.palette.custom.buttonColor,
                    color: theme.palette.custom.buttonTextColor,
                    "&:hover": {
                      backgroundColor: theme.palette.custom.buttonHoverColor,
                      color: theme.palette.custom.buttonHoverTextColor,
                      boxShadow: "none",
                    },
                    float: "right",
                  }}
                  onClick={(event) => {
                    handleNavigate(event, navigate, "/assignUsers");
                    // navigate();
                  }}
                >
                  {t("Assign Users")}
                </Button>
              )}
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "36px",
                backgroundColor: theme.palette.custom.buttonColor,
                color: theme.palette.custom.buttonTextColor,
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                  boxShadow: "none",
                },
                float: "right",
              }}
              onClick={(event) => {
                handleNavigate(event, navigate, "/userCreate");
                // navigate();
              }}
            >
              {t("Create New User")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <SearchInput
        searchText={searchTextUser}
        handleSearch={handleSearchUser}
      />
      {loadingValueUser ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              headCells={userHead}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSortUser}
            />
            <TableBody>
              {users?.length && visibleRowsUser.length ? (
                visibleRowsUser.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isItemSelected = isSelected(row.profile_id);
                  return (
                    <UserTableRow
                      userTypes={userTypes}
                      handleClick={handleClick}
                      handleArrowIcon={handleArrowIcon}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      key={row.profile_id}
                      row={row}
                      refreshUsers={handleGetUersTableDate}
                    ></UserTableRow>
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={users ? users.length : 0}
        rowsPerPage={rowsPerPageUser}
        page={pageUser}
        onPageChange={handleChangePageUser}
        onRowsPerPageChange={handleChangeRowsPerPageUser}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(
            userHead,
            visibleRowsUser.map(getCSVUser),
            "Users List"
          )
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}
