function downloadCSV(csv, filename) {
    let csvFile;
    let downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();

    // Remove the link from DOM
    document.body.removeChild(downloadLink); // Might not be needed

    //Free link Object
    downloadLink = null;
}

function exportTableToCSV(allHeadCells, dataRows, filename) {
    const headCells = allHeadCells.filter(h => h.label && h.label !== 'View' && h.label !== 'Action');
    const firstRow = headCells.map(h => h.label).join(",");
    let csv = [firstRow];
    dataRows.forEach(dataRow => {
        let row = [];
        headCells.forEach(headCell => {
            row.push(dataRow?.[headCell.id])
        });
        csv.push(row.join(","));
    });
    downloadCSV(csv.join("\n"), filename);
}
export default exportTableToCSV;