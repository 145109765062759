const prod = {
  url: {
    api_key_current: "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA",
    api_app_name: "SIMAC",
    api_ip: "https://eu.simac.app",
    api_app_version: "2.3.0",
    grafana_url: "https://eu.simac.app/grafana-view/",
  },
};
const dev = {
  url: {
    api_key_current: "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA",
    api_app_name: "SIMAC",
    api_ip: "https://nerospec.app",
    api_app_version: "2.3.0",
    grafana_url: "https://simac.app/grafana/",
  },
};
function handleConfig() {
  // if (process.env.REACT_APP_staging === 'staging') {
  //     return stage;
  // }
  // eslint-disable-next-line
  // else
  if (process.env.NODE_ENV === "development") {
    return dev;
  }
  return prod;
}
// eslint-disable-next-line
export const config = handleConfig();
//export const config = prod; // only for debugging with production urls
// export const config = prod; // only for debugging with production urls
