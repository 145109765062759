import { useState, useEffect, useMemo, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { setNotificationsData } from "../../store/notificationsSlice";
import OrganizationService from "../../api/organization";
import { Box } from "@mui/material";
// import { capitalize } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import NotificationsTableRow from "./notificationsTableRow";
import { Typography } from "@mui/material";

// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../loading";
import EnhancedTableHead from "../EnhancedTableHead";
import SearchInput from "../searchInput";
import StyledTypography from "../StyledTypography";
import handleNavigate from "../customFunctions/handleNavigate";

const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.custom.backgroundColor2,
    color: theme.palette.custom.text,
    padding: "0px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
  },
  "& .MuiList-root": {
    padding: "0px",
  },
}));

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "notification",
    numeric: false,
    disablePadding: true,
    label: "Notification",
    sort: true,
  },
  {
    id: "info",
    numeric: false,
    disablePadding: true,
    label: "Info",
    sort: true,
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: true,
    label: "Timestamp",
    sort: true,
  },
];
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "50px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
function NotificationsMenu(props, ref) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) !== undefined
      ? Number(localStorage.getItem("rowsPerPage"))
      : 5
  );
  const notifications = useSelector(({ notifications }) => notifications);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [notificationsInputData, setNotificationsInputData] =
    useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  const [notificationLength, setNotificationLength] = useState(0);

  useEffect(() => {
    if (organziation) {
      setNotificationsInputData(null);
      dispatch(setNotificationsData(null));
      OrganizationService.GetOperationNotifications({
        orgUid: organziation.uid,
        apiKey: organziation?.api_key_list?.[0]?.key,
      }).then((json) => {
        dispatch(setNotificationsData({ json: json, newSearchText: "" }));
        setNotificationsInputData({ json: json, newSearchText: "" });
        setNotificationLength(json.operation_notifications.length);
      });
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [organziation]);

  const handleCloseNotificationsMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenNotificationsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {};
  function handleSearch(ev) {
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let notificationsInputObj = notificationsInputData;
    notificationsInputObj.newSearchText = newSearchText;
    dispatch(setNotificationsData(notificationsInputObj));
    setLoadingValue(false);
  }
  function handleViewAll(event) {
    handleNavigate(event, navigate, '/notifications');
    // navigate(``);
    setAnchorEl(null);
  }
  const visibleRows = useMemo(
    () => {
      if (notifications) {
        return stableSort(notifications, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, notifications]
    //[page, rowsPerPage, safety],
  );
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="text"
          onClick={handleOpenNotificationsMenu}
          startIcon={
            <ChatBubbleOutlineIcon
              sx={{
                color: theme.palette.custom.text,
              }}
            />
          }
        >
          <StyleSpan>{notificationLength}</StyleSpan>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseNotificationsMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "#2b2f38",
              padding: "11px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "21px",
              mb: 0,
              color: theme.palette.custom.dark,
              fontWeight: "bolder",
            }}
            variant="h2"
            gutterBottom
          >
            {t("NOTIFICATIONS")}:
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "0px 11px",
            backgroundColor: theme.palette.custom.backgroundColor,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "block", sm: "center" },
            }}
          >
            <SearchInput searchText={searchText} handleSearch={handleSearch} />
            <StyledButton
              variant="contained"
              onClick={handleViewAll}
              sx={{ width: "100px" }}
            >
              {t("ViewAll")}
            </StyledButton>
          </Box>
          <Divider
            sx={{ my: "10px", backgroundColor: theme.palette.custom.light }}
          />
          {loadingValue ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                //  size={'medium'}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  hideCheck={true}
                />

                <TableBody>
                  {notifications &&
                  notificationLength > 0 &&
                  visibleRows.length ? (
                    visibleRows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <NotificationsTableRow
                          key={index}
                          row={row}
                          handleClick={handleClick}
                          labelId={labelId}
                        />
                      );
                    })
                  ) : (
                    <StyledTypography>{t("No entries found")}</StyledTypography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={notifications ? notificationLength : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </StyledMenu>
    </>
  );
}

export default forwardRef(NotificationsMenu);
