import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

import StyledTypography from "./StyledTypography";
import NumberTypography from "./NumberTypography";

export default function OpsumHistoryTotals({
  tittle,
  value,
  valueLabel,
  compareComponent,
  sizeSmall,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleArrowIcon = ({ newValue, OldValue, reverse, fontSize }) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <StyledTypography variant={sizeSmall ? "subtitle1" : "h6"}>
        {t(tittle)}{" "}
        {compareComponent ? handleArrowIcon(compareComponent) : null}
      </StyledTypography>
      <StyledTypography variant={sizeSmall ? "h5" : "h3"}>
        {value}
      </StyledTypography>
      <NumberTypography variant={sizeSmall ? "body2" : "subtitle2"}>
        {t(valueLabel)}
      </NumberTypography>
    </Box>
  );
}
