import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TicketsService from "../api/tickets";
import ProgressBar from "../common/progressBar";
import {
  BorderColor,
  CheckBoxOutlineBlankOutlined,
  FilterList,
  Mode,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import handleNavigate from "../common/customFunctions/handleNavigate";

function HelpdeskDashboard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statusInfo, setStatusInfo] = useState({
    stsClosed: 0,
    stsOpen: 0,
    stsNew: 0,
    stsClosedPerc: 0,
    stsOpenPerc: 0,
    stsNewPerc: 0,
    loggedTotal: 0,
  });

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      TicketsService.GetTicketDashboardInfo(apiKey).then((json) => {
        let stsClosed = 0;
        let stsOpen = 0;
        let stsNew = 0;
        let stsClosedPerc = 0;
        let stsOpenPerc = 0;
        let stsNewPerc = 0;
        let loggedTotal = 0;
        for (let i in json.ticket_header) {
          let jsonObject = json.ticket_header[i];
          if (jsonObject.status === "closed") {
            stsClosed++;
          } else if (jsonObject.status === "open") {
            stsOpen++;
          } else if (jsonObject.status === "new") {
            stsNew++;
          }
        }
        loggedTotal = stsClosed + stsOpen + stsNew;
        stsOpenPerc = (stsOpen / loggedTotal) * 100;
        stsClosedPerc = (stsClosed / loggedTotal) * 100;
        stsNewPerc = (stsNew / loggedTotal) * 100;
        setStatusInfo({
          stsClosed: stsClosed === 0 ? "" : stsClosed,
          stsOpen: stsOpen === 0 ? "" : stsOpen,
          stsNew: stsNew === 0 ? "" : stsNew,
          stsClosedPerc: stsClosedPerc,
          stsOpenPerc: stsOpenPerc,
          stsNewPerc: stsNewPerc,
          loggedTotal: loggedTotal,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTicketsClick(event) {
    handleNavigate(event, navigate, "/tickets");
    // navigate();
  }

  function handleNewTicketClick(event) {
    handleNavigate(event, navigate, "/newTicket");
    // navigate();
  }
  function handleTemplatesClick(event) {
    handleNavigate(event, navigate, "/templates");
    // navigate();
  }
  function handleMyTicketClick(event) {
    handleNavigate(event, navigate, "/myTickets");
    // navigate();
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "start" },
          minHeight: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Box
            sx={{
              width: "100%",
              display: "inline-flex",
              alignItems: "baseline",
              flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                fontSize: "45px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
              gutterBottom
            >
              {t("Helpdesk")}
            </Typography>
            <Typography
              sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
              gutterBottom
            >
              {t("support platform")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Typography
        sx={{
          fontSize: "30px",
          mb: 0,
          color: theme.palette.custom.text,
          textDecoration: "underline",
          fontWeight: "700",
        }}
        gutterBottom
      >
        {t("Ticket Dashboard")}:
      </Typography>
      <ProgressBar statusInfo={statusInfo}></ProgressBar>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Typography
        sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
        gutterBottom
      >
        {t("Summaries")}:
      </Typography>
      <Box
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="statusBox"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            width: "100%",
            margin: "5px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
            gutterBottom
            className="statusText"
          >
            {t("New")}
          </Typography>
          <Typography
            sx={{
              fontSize: "40px",
              mb: 0,
              color: theme.palette.custom.text,
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
            gutterBottom
            className="statusText"
          >
            <CircleIcon sx={{ color: "#0000FF", fontSize: "46px" }} />
            {statusInfo.stsNew === "" ? 0 : statusInfo.stsNew}
          </Typography>
        </Box>
        <Box
          className="statusBox"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            width: "100%",
            margin: "5px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
            gutterBottom
            className="statusText"
          >
            {t("Open")}
          </Typography>
          <Typography
            sx={{
              fontSize: "40px",
              mb: 0,
              color: theme.palette.custom.text,
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
            gutterBottom
            className="statusText"
          >
            <CircleIcon sx={{ color: "#00FF00", fontSize: "46px" }} />
            {statusInfo.stsOpen === "" ? 0 : statusInfo.stsOpen}
          </Typography>
        </Box>
        <Box
          className="statusBox"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            width: "100%",
            margin: "5px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
            gutterBottom
            className="statusText"
          >
            {t("Closed")}
          </Typography>
          <Typography
            sx={{
              fontSize: "40px",
              mb: 0,
              color: theme.palette.custom.text,
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
            gutterBottom
            className="statusText"
          >
            <CircleIcon sx={{ color: "#FF0000", fontSize: "46px" }} />
            {statusInfo.stsClosed === "" ? 0 : statusInfo.stsClosed}
          </Typography>
        </Box>
        <Box
          className="statusBox"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            width: "100%",
            margin: "5px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontSize: "30px", mb: 0, color: theme.palette.custom.text }}
            gutterBottom
            className="statusText"
          >
            {t("Total")}
          </Typography>
          <Typography
            sx={{
              fontSize: "40px",
              mb: 0,
              color: theme.palette.custom.text,
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
            gutterBottom
            className="statusText"
          >
            <RadioButtonUnchecked sx={{ color: "#FFFFFF", fontSize: "46px" }} />
            {statusInfo.loggedTotal}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Typography
        sx={{
          fontSize: "30px",
          mb: 0,
          color: theme.palette.custom.borderColor,
          fontWeight: "bold",
        }}
        gutterBottom
      >
        {t("WHERE WOULD YOU LIKE TO GO")}?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          className="thumbnail"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "225px",
            height: "200px",
            margin: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
            onClick={handleTemplatesClick}
          >
            <CheckBoxOutlineBlankOutlined
              sx={{
                fontSize: "60px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
              className="thumbtext"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
            className="thumbtext"
          >
            {t("TEMPLATES")}
          </Typography>
        </Box>
        <Box
          className="thumbnail"
          onClick={handleTicketsClick}
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "225px",
            height: "200px",
            margin: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
          >
            <FilterList
              sx={{
                fontSize: "60px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
              className="thumbtext"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
            className="thumbtext"
          >
            {t("TICKETS")}
          </Typography>
        </Box>
        <Box
          className="thumbnail"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "225px",
            height: "200px",
            margin: "5px",
          }}
          onClick={handleMyTicketClick}
        >
          <Typography
            sx={{
              fontSize: "30px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
          >
            <BorderColor
              sx={{
                fontSize: "60px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
              className="thumbtext"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
            className="thumbtext"
          >
            {t("MY TICKETS")}
          </Typography>
        </Box>
        <Box
          className="thumbnail"
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "225px",
            height: "200px",
            margin: "5px",
          }}
          onClick={handleNewTicketClick}
        >
          <Typography
            sx={{
              fontSize: "30px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
          >
            <Mode
              className="thumbtext"
              sx={{
                fontSize: "60px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            gutterBottom
            className="thumbtext"
          >
            {t("NEW TICKET")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HelpdeskDashboard;
