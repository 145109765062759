import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { styled } from "@mui/material/styles";
import Loading from "../common/loading";
import Breadcrumbs from "../common/breadcrumbs";
import TicketsService from "../api/tickets";
import TicketsGeneralTab from "./ticketsGeneralTab";
import handleNavigate from "../common/customFunctions/handleNavigate";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Ticket Dashboard", href: "indexHelpdesk" },
  { id: 2, name: "Tickets", href: "tickets" },
];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    height: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);

function Tickets() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingValue, setLoadingValue] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [departmentsList, setDepartmentsList] = useState([
    { id: "", name: "General" },
  ]);
  const [selectedTabObject, setSelectedTabObject] = useState({
    id: "",
    name: "General",
  });

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      // console.log("ticketseffect", organziation);
      // if (organziation) {
      setDepartmentsList(null);
      //  const apiKey = organziation?.api_key_list?.[0]?.key;
      TicketsService.GetOperationDepartment({
        department_id: "",
        operation_profile_uid: "",
        name: "",
        status: "",
        apiKey,
      })
        .then((json) => {
          if (json && json.department && json.department.length > 0) {
            let DepList = [{ id: "", name: "General" }];
            for (let index = 0; index < json.department.length; index++) {
              const RealDepartmentID = json.department[index].department_id;
              DepList.push({
                id: RealDepartmentID,
                name: json.department[index].name,
              });
            }
            setDepartmentsList(DepList);
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    } else {
      setDepartmentsList(null);
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditButton = (event) => {
    handleNavigate(event, navigate, `/newTicket`);
    // navigate();
  };
  const handleBreadCrumbsArray = (breadcrumbs, device) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    // breadcrumbs.push({ id: 1, name: device.DeviceName, href: `/device?device=${device.DeviceID}` })
    return breadcrumbs;
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedTabObject(departmentsList[newValue]);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {" "}
            {/* <HomePagesMenu pageName={'Maintenance'} /> */}
            {!loadingValue ? (
              <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
            ) : (
              <Box sx={{ height: "30vh", pt: "10%" }}>
                <Loading />
              </Box>
            )}
            <Typography
              sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.text }}
              variant="h2"
              gutterBottom
            >
              {/* {capitalize(t('Devices'))} */}
              {t("Tickets")}
            </Typography>
          </Box>
          <StyledButton
            variant="contained"
            onClick={handleEditButton}
            startIcon={<EditIcon />}
          >
            {t("NewTicket")}
          </StyledButton>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.custom.borderColor },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        {departmentsList && departmentsList.length > 0
          ? departmentsList.map((item, index) => (
            <StyledTab key={item.name} label={item.name} />
          ))
          : null}
      </Tabs>
      <TicketsGeneralTab selectedTab={selectedTabObject} />
      {/* {tabValue === 0 &&
                <EventsTab />
            } */}
    </Box>
  );
}

export default Tickets;
