import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function EditButton({ onClick }) {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: theme.palette.custom.buttonColor,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        // display: "flex",
        // justifyContent: "center",
        width: "46px",
        height: "36px",
        padding: "5px 10px",
      }}
    >
      <EditNoteIcon
        onClick={onClick}
        sx={{
          color: theme.palette.custom.buttonTextColor,
          fontSize: 24,
        }}
      />
    </Box>
  );
}

export default EditButton;
