import SelectFields from './selectFields';
import TimezoneService from "../api/timezone";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function TimezonePicker(props) {
    const [list, setList] = useState();
    const organziation = useSelector(({ organziation }) => organziation);

    function getTimezones() {
        TimezoneService.GetTimezones(organziation?.api_key_list?.[0]?.key).then((response) => {
            if (response.success && response.count > 0) {
                setList(
                    response.config_locale?.filter(timezone => timezone.timezone).map(timezone => ({
                        id: timezone.timezone,
                        value: parseInt(timezone.utc_offset_display),
                        label: timezone.utc_offset_display + " " + timezone.timezone
                    }))
                )
            }
        });
    }
    useEffect(() => {
        getTimezones()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <SelectFields {...props} fields={list || []} />;
}

export default TimezonePicker;
