/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setAllUsersData = createAsyncThunk(
  "allUsers/setAllUsersData",
  async (inputData) => {
    let allUsers = [];
    if (inputData === null) {
      return null;
    }
    allUsers = PopulateUsers(inputData.json);
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      allUsers &&
      allUsers.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return allUsers.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return allUsers;
  }
);
function PopulateUsers(json) {
  let user = [];
  const UsersArray = json.person_profile;
  for (let index in UsersArray) {
    let userDataObj = {
      firstname: "",
      lastname: "",
      fullname: "",
      contact: "",
      uid: "",
      signinLogger: "",
      profile_id: "",
      user_type_uid: "",
      public_url: "",
      email: "",
      mobile: "",
    };

    userDataObj.fullname =
      UsersArray[index].firstname + " " + UsersArray[index].lastname;
    userDataObj.firstname = UsersArray[index].firstname;
    userDataObj.lastname = UsersArray[index].lastname;
    userDataObj.contact = UsersArray[index].contact;
    userDataObj.uid = UsersArray[index].profile_uid;
    userDataObj.signinLogger = UsersArray[index].signin_logger;
    userDataObj.profile_id = UsersArray[index].profile_id;
    userDataObj.user_type_uid = UsersArray[index].user_type_uid;
    if (
      UsersArray[index].image !== undefined &&
      UsersArray[index].image.length > 0
    ) {
      userDataObj.public_url = UsersArray[index].image[0].public_url;
    } else {
      userDataObj.public_url = "../FakeUser.png";
    }
    if (UsersArray[index].contact.length > 0) {
      for (let i in UsersArray[index].contact) {
        if (
          UsersArray[index].contact[i].contact_type === "email_address" &&
          UsersArray[index].contact[i].primary_contact === true
        ) {
          userDataObj.email = UsersArray[index].contact[i].value;
        }
        if (
          UsersArray[index].contact[i].contact_type === "tel_mobile" &&
          UsersArray[index].contact[i].primary_contact === true
        ) {
          userDataObj.mobile = UsersArray[index].contact[i].value;
        }
      }
    }
    user.push(userDataObj);
  }
  return user;
}

const initialState = null;

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setAllUsersData.fulfilled, (state, action) => action.payload);
  },
});

export const selectAllUser = ({ allUsers }) => allUsers;

export default allUsersSlice.reducer;
