import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { capitalize } from '@mui/material';
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Tabs from "@mui/material/Tabs";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import CompareIcon from "@mui/icons-material/Compare";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import CommonDateTimePicker from "../common/commonDateTimePicker";
import GrayCircle from "../common/grayCircle";
import RedCircle from "../common/redCircle";
import {
  GetDateTimeStringToday,
  getUrlVars,
  GetHistoryTimerSumJSON,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  GetMachineTypeWireframe,
  GetMachineTypeIcon,
  LatestDataColoring,
} from "../utils/systemInfo";

import DevicesService from "../api/devices";
// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import { setDeviceData } from "../store/deviceSlice";
import { setDeviceData as setDeviceCompareData } from "../store/deviceCompareSlice";
import StyledTypography from "../common/StyledTypography";
import TableGreenTypography from "../common/TableGreenTypography";
import OpsumTotalsComparisonProgress from "../common/opsumTotalsComparisonProgress";
import OpsumHistoryTotals from "../common/opsumHistoryTotals";
import OpsumService from "../api/opsum";
import { setMachineSafetyData } from "../store/machineSafetySlice";
import StyledColumnBox from "../common/StyledColumnBox";
import DevicesMenu from "../common/devicesMenu";
import Breadcrumbs from "../common/breadcrumbs";
import ViewHistoryButton from "../common/viewHistoryButton";
import ViewSignalsButton from "../common/viewSignalsButton";
import EventsTab from "./eventsTab";
import DeviceSignalsTab from "./deviceSignalsTab";
import DeviceNotificationsTab from "./deviceNotificationsTab";
import DeviceRowDataTab from "./deviceRowDataTab";
import { Dropdown, DropdownMenuItem } from "../common/Layout/DropDown";
import AssignButton from "../common/assignButton";
import AlertDialog from "../common/AlertDialog";
import OrganizationService from "../api/organization";
import { showMessage } from "../store/messageSlice";
import { UserPermissionConstants } from "../utils/permissions";
import handleNavigate from "../common/customFunctions/handleNavigate";

const opsumTotalsComparisonList = [
  {
    id: 0,
    tittle: "Powered",
    valueName: "powered",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EngineRun",
    valueName: "engine_run",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Tramming",
    valueName: "tramming",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EnginerWorking",
    valueName: "high_rpm",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Forward",
    valueName: "forward",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Reverse",
    valueName: "reverse",
    valueLabel: "Hours",
    compareReverse: false,
  },
];
//forwardRef warning
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    width: "160px",
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "11px",
    height: "36px",
    minHeight: "36px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
      backgroundColor: theme.palette.custom.backgroundColor,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  })
);
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  minWidth: "100px",
  width: "fit-content",
  height: "36px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

function Machine(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const permissions = useSelector(({ permissions }) => permissions);
  const device1 = useSelector(({ device }) => device);
  const device2 = useSelector(({ deviceCompare }) => deviceCompare);
  const [device, setDevice] = useState();
  const machineSafety = useSelector(({ machineSafety }) => machineSafety);
  const [productionJson, setProductionJson] = useState(undefined);
  const [scheduledTime, setScheduledTime] = useState(undefined);
  const [devicesList, setDevicesList] = useState(undefined);
  const [deviceHistoryTotals, setDeviceHistoryTotals] = useState(undefined);
  const [compareDeviceHistoryTotals, setCompareDeviceHistoryTotals] =
    useState(undefined);
  const [tabValue, setTabValue] = useState(0);
  const timezoneMins = useSelector(({ timezone }) => timezone);
  const [loadingValue, setLoadingValue] = useState(true);
  const DeviceId = String(getUrlVars()[props.device2 ? "device2" : "device"]);
  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
  const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    {
      id: 1,
      name:
        DeviceTitleDataName.toLowerCase() === "device" ? "Devices" : "Machines",
      href:
        DeviceTitleDataName.toLowerCase() === "device" ? "devices" : "machines",
    },
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  useEffect(() => {
    setDevice(props.device2 ? device2 : device1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device1, device2]);
  useEffect(() => {
    if (props.device2) {
      dispatch(setDeviceCompareData(null));
    } else {
      dispatch(setDeviceData(null));
    }
    setDevicesList(undefined);
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization);
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? orgObj?.api_key_list?.[0]?.key;
    if (apiKey) {
      //const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass;
      switch (DeviceTitleDataName.toLowerCase()) {
        case "machine":
        default:
          DevicesClass = { deviceType: ["nerohub", "nanohub"], source: "" };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                if (props.device2) {
                  dispatch(
                    setDeviceCompareData({
                      json: deviceObject,
                      machineTypeFilter: "",
                      machineType: DeviceTitleDataName,
                    })
                  );
                } else {
                  dispatch(
                    setDeviceData({
                      json: deviceObject,
                      machineTypeFilter: "",
                      machineType: DeviceTitleDataName,
                    })
                  );
                }
                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          OpsumService.GetDeviceOpsumTimers({
            deviceID: DeviceId,
            deviceType: "nerohub",
            apiKey,
            historyFromDST: GetDateTimeString7DaysAgo(),
            historyToDST: GetDateTimeStringToday(),
          })
            .then((jsonCurrent) => {
              const DeviceHistoryTotals = GetHistoryTimerSumJSON(
                jsonCurrent.opsum.device[0].data,
                GetDateTimeString7DaysAgo(),
                GetDateTimeStringToday()
              );
              setDeviceHistoryTotals(DeviceHistoryTotals);
              setProductionJson(jsonCurrent.opsum.device[0].production);
              setScheduledTime(jsonCurrent.scheduled_time.scheduled);
              OpsumService.GetDeviceOpsumTimers({
                deviceID: DeviceId,
                deviceType: "nerohub",
                apiKey,
                historyFromDST: GetDateTimeString14DaysAgo(),
                historyToDST: GetDateTimeString7DaysAgo(),
              })
                .then((jsonPrevious) => {
                  const CompareDeviceHistoryTotals = GetHistoryTimerSumJSON(
                    jsonPrevious.opsum.device[0].data,
                    GetDateTimeString14DaysAgo(),
                    GetDateTimeString7DaysAgo()
                  );
                  setCompareDeviceHistoryTotals(CompareDeviceHistoryTotals);
                })
                .catch((errorr) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("getUserTypePermissionserrorr", errorr);
                });
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          OpsumService.getDeviceOpsumQuery({
            deviceID: DeviceId,
            deviceType: "nerohub",
            apiKey,
            historyFromDST: GetDateTimeString7DaysAgo(),
            historyToDST: GetDateTimeStringToday(),
          })
            .then((jsonCurrent) => {
              OpsumService.getDeviceOpsumQuery({
                deviceID: DeviceId,
                deviceType: "nerohub",
                apiKey,
                historyFromDST: GetDateTimeString14DaysAgo(),
                historyToDST: GetDateTimeString7DaysAgo(),
              })
                .then((jsonPrevious) => {
                  dispatch(
                    setMachineSafetyData({
                      jsonCurrent,
                      jsonPrevious,
                      machineTypeFilter: "",
                      datePreviousFrom: GetDateTimeString14DaysAgo(),
                      dateFrom: GetDateTimeString7DaysAgo(),
                      dateto: GetDateTimeString7DaysAgo(),
                      newSearchText: undefined,
                    })
                  );
                  setLoadingValue(false);
                })
                .catch((errorr) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("getUserTypePermissionserrorr", errorr);
                });
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          break;

        case "device":
          DevicesClass = { deviceType: "", source: "iot" };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            Live: true,
            History: true,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                DevicesService.GetDeviceHistoryLatest({
                  devices: [deviceObject.device_profile_id],
                  channels: [],
                  apiKey,
                }).then((jsonLatest) => {
                  console.log(`GetDeviceHistoryLatest`, jsonLatest);
                  const channels = jsonLatest?.latest?.device[0]?.channel;
                  deviceObject.channels = channels;
                  if (props.device2) {
                    dispatch(
                      setDeviceCompareData({
                        json: deviceObject,
                        machineTypeFilter: "",
                        machineType: DeviceTitleDataName,
                      })
                    );
                  } else {
                    dispatch(
                      setDeviceData({
                        json: deviceObject,
                        machineTypeFilter: "",
                        machineType: DeviceTitleDataName,
                      })
                    );
                  }
                });

                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceId]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const handleBreadCrumbsArray = (breadcrumbs, device) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    breadcrumbs.push({
      id: 1,
      name: device.DeviceName,
      href: `/device?device=${device.DeviceID}`,
      noTranslation: true,
    });
    return breadcrumbs;
  };

  const handleEditButton = (event) => {
    handleNavigate(
      event,
      navigate,
      `/deviceProfile?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`
    );
    // navigate();
  };
  const handleCompare = (event, secondMachine) => {
    handleNavigate(
      event,
      navigate,
      `/deviceCompare?device=${DeviceId}&device2=${secondMachine.device_uid}&DeviceTitleDataName=${DeviceTitleDataName}`
    );
    // navigate();
  };
  const handleClickViewHistory = (event) => {
    const href = `/deviceHistory?Dashboard=${device.GrafanaDashboardUID}&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&DeviceTitleDataName=${device.DeviceTitleDataName}&timerange`;
    // const newUrl = window.location
    handleNavigate(event, navigate, href);
    // navigate();
  };
  const handleClickViewSignals = (event) => {
    const href = `/deviceSignals?Dashboard=MachineMultipleSignal&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&SensorID=0`;
    // const newUrl = window.location
    handleNavigate(event, navigate, href);
    // navigate();
  };
  function iframe3DObjectLoaded() {
    const obj3d0 = document.getElementById("obj3d0");
    const innerDoc = obj3d0.contentDocument || obj3d0.contentWindow.document;
    const element = innerDoc.getElementById("x-model-renderLayer");
    element.style.backgroundColor = theme.palette.custom.backgroundColor;
  }
  const handleUnassign = () => {
    setSaveLoading(true);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    OrganizationService.SetAssignDeviceToOrg({
      operation_uid: organziation.uid,
      device_id: device.DevicePID,
      status: "unlink",
      apiKey,
    })
      .then((json) => {
        if (json) {
          dispatch(
            showMessage({
              message: t("Device/Machine unassigned Successfully"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "success", //success error info warning null
            })
          );
        }
        setSaveLoading(false);
        setOpenDialog(false);
      })
      .catch((_errors) => {
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("error occurred"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      });
  };
  function handleClose() {
    setOpenDialog(false);
  }
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          minHeight: "64px",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <Box sx={{ width: "100%" }}>
          {" "}
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          {!loadingValue && device ? (
            <Breadcrumbs
              breadcrumbs={handleBreadCrumbsArray(breadcrumbs, device)}
            />
          ) : (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          )}
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {DeviceTitleDataName !== "Device" ? t("Machine") : t("Device")}
          </Typography>
        </Box>
        {!props.compare && (
          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: "1rem",
            }}
          >
            {permissions &&
              permissions.includes(
                UserPermissionConstants.ASSIGN_DEVICE_PROFILES_TO_ORGANIZATION
              ) && (
                <StyledButton
                  className="viewUser"
                  hidden
                  sx={{ padding: 0, minWidth: 0 }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  <AssignButton type={"remove"} title={"Unassign"} />
                </StyledButton>
              )}
            <StyledButton
              variant="contained"
              onClick={handleEditButton}
              startIcon={<EditNoteIcon />}
            >
              {t("Edit")}
            </StyledButton>
            {devicesList?.length && (
              <Dropdown
                sx={{ color: theme.palette.custom.text }}
                trigger={
                  <StyledButton variant="contained" startIcon={<CompareIcon />}>
                    {t("Compare")}
                  </StyledButton>
                }
                menu={devicesList
                  .filter((m) => m.device_uid !== device?.DeviceID)
                  .map((m) => (
                    <DropdownMenuItem
                      onClick={(e) => {
                        handleCompare(e, m);
                      }}
                    >
                      <div
                        style={{ width: "40px" }}
                        dangerouslySetInnerHTML={{
                          __html: GetMachineTypeIcon(m.vehicle_type).replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ),
                        }}
                      />
                      &nbsp;
                      {m.name}
                    </DropdownMenuItem>
                  ))}
              />
            )}
          </Box>
        )}
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      {!loadingValue && device ? (
        <Box
          sx={{
            p: "12px",
            width: "100%",
            display: { xs: "block", sm: "flex" },
            minHeight: "150px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              width: {
                xs: "100%",
                sm: DeviceTitleDataName !== "Device" ? "30%" : "45%",
              },
              marginRight: { xs: 0, sm: "5%" },
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "150px" }}
              dangerouslySetInnerHTML={{
                __html: device.DeviceTypeIcon.replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />
            <Box>
              <StyledTypography variant="h6">
                {device.DeviceType}
              </StyledTypography>
              {devicesList && devicesList.length > 0 ? (
                <DevicesMenu
                  devices={devicesList}
                  pageType={props.compare && "compareDevices"}
                  compareDevice2={props.device2}
                />
              ) : (
                <NumberTypography variant="h3">
                  {device.DeviceName}
                </NumberTypography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              height: "140px",
              width: {
                xs: "100%",
                sm: DeviceTitleDataName !== "Device" ? "30%" : "45%",
              },
              marginRight: { xs: 0, sm: "5%" },
              marginBottom: { xs: 2, sm: 0 },
              p: "12px",
              textAlign: "center",
            }}
          >
            <StyledTypography variant="h6">
              {t("MachineLastSeen")}
            </StyledTypography>
            <Typography
              sx={{
                color: LatestDataColoring(device.DeviceLastSeen, true),
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
              }}
              variant="h5"
            >
              {moment
                .utc(device.DeviceLastSeen)
                .add(timezoneMins, "minutes")
                .format("YYYY-MM-DD HH:mm")}
            </Typography>
          </Box>
          {DeviceTitleDataName !== "Device" && (
            <Box
              sx={{
                minHeight: "140px",
                width: { xs: "100%", sm: "30%" },
                marginBottom: { xs: 2, sm: 0 },
                p: "12px",
                textAlign: "center",
              }}
            >
              {device.NamedLocationOriginal !== "Unknown" && (
                <StyledTypography variant="h6">
                  {t("LastLocation")}
                </StyledTypography>
              )}
              {device.NamedLocationOriginal !== "Unknown" && (
                <StyledTypography variant="h3">
                  {device.NamedLocationOriginal}
                </StyledTypography>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      )}
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {!loadingValue && device ? (
        <Box
          sx={{
            p: "12px",
            width: "100%",
            display: { xs: "block", sm: "flex" },
            minHeight: "70px",
          }}
        >
          <Box
            sx={{
              height: "70px",
              width: { xs: "100%", sm: "30%" },
              marginBottom: { xs: 2, sm: 0 },
              textAlign: "center",
            }}
          >
            <StyledTypography variant="subtitle2">{t("oem")}</StyledTypography>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
              }}
            >
              <img width="50px" src={device.DeviceOEMIcon} alt="logo" />
              <StyledTypography sx={{ ml: "8px" }} variant="h6">
                {device.DeviceOEM}
              </StyledTypography>{" "}
            </Box>
          </Box>
          <Box
            sx={{
              height: "70px",
              width: { xs: "100%", sm: "30%" },
              marginBottom: { xs: 2, sm: 0 },
              textAlign: "center",
            }}
          >
            <StyledTypography variant="subtitle2">
              {t("SerialNumber")}
            </StyledTypography>
            <StyledTypography sx={{ ml: "8px" }} variant="h6">
              {`${device.DeviceSerialHex} (${device.DeviceSerial})`}
            </StyledTypography>
          </Box>
          {DeviceTitleDataName !== "Device" && !props.compare && (
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                height: "70px",
                width: { xs: "100%", sm: "30%" },
                marginBottom: { xs: 2, sm: 0 },
                textAlign: "center",
              }}
            >
              <StyledTypography variant="subtitle2">
                {t("Signals")} {":"}
              </StyledTypography>
              <ViewSignalsButton onClick={handleClickViewSignals} />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              height: "70px",
              width: { xs: "100%", sm: "30%" },
              marginBottom: { xs: 2, sm: 0 },
              textAlign: "center",
            }}
          >
            <StyledTypography variant="subtitle2">
              {t("History")} {":"}
            </StyledTypography>
            <ViewHistoryButton onClick={handleClickViewHistory} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "70px", pt: "30" }}>
          <Loading />
        </Box>
      )}
      {DeviceTitleDataName !== "Device" && (
        <Divider
          sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
        />
      )}
      {DeviceTitleDataName !== "Device" ? (
        !loadingValue && device ? (
          <Box sx={{ margin: 1 }}>
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="subtitle2"
                gutterBottom
              >
                {`${t("Opsum Data For")}: ${device.DeviceName}`} &nbsp;|&nbsp;
              </Typography>
              <NumberTypography variant="subtitle2">
                {t("Last 7 days")}
                {` (${moment(GetDateTimeString7DaysAgo()).format(
                  "YYYY/MM/DD"
                )} - ${moment(GetDateTimeStringToday()).format("YYYY/MM/DD")})`}
              </NumberTypography>
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="subtitle2"
                gutterBottom
              >
                &nbsp;|&nbsp;{t("Timespan")}:{"168 hours"}
              </Typography>
            </Box>
            <OpsumHistoryTotals
              opsumTotalsComparisonList={opsumTotalsComparisonList}
              deviceHistoryTotals={deviceHistoryTotals}
              compareDeviceHistoryTotals={compareDeviceHistoryTotals}
              handleArrowIcon={handleArrowIcon}
              arrowSize={props.compare && 15}
              sizeSmall={props.compare}
            />
            <Box sx={{ mt: "28px", textAlign: "center", width: "100%" }}>
              {productionJson && scheduledTime ? (
                // <div style={{ width: '100%',height: '75px' }} dangerouslySetInnerHTML={{
                //     __html: (GetDeviceOpsumTotalsComparisonProgressHTML(productionJson, scheduledTime))
                // }} />
                <OpsumTotalsComparisonProgress
                  ProductionJson={productionJson}
                  scheduled_time={scheduledTime}
                />
              ) : null}
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        )
      ) : null}
      {DeviceTitleDataName !== "Device" ? (
        !loadingValue && machineSafety ? (
          <Box
            sx={{
              mt: "48px",
              display: { xs: "block", sm: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <StyledColumnBox>
                <StyledTypography variant="subtitle2">
                  {t("SBI CRAWL EVENTS")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalSBICrawlCounter
                      ? Number(machineSafety.TotalSBICrawlCounter).toFixed(2)
                      : (0).toFixed(2)}
                    &nbsp; {t("incidents")}
                  </TableGreenTypography>
                </StyledTypography>

                <StyledTypography variant="subtitle2" gutterBottom>
                  {t("Time")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalSBICrawlMinutes
                      ? Number(machineSafety.TotalSBICrawlMinutes / 60).toFixed(
                          2
                        )
                      : (0).toFixed(2)}
                    &nbsp;{t("hours")}
                  </TableGreenTypography>
                </StyledTypography>
              </StyledColumnBox>
              {handleArrowIcon(
                machineSafety.TotalSBICrawlCounter,
                machineSafety.TotalSBICrawlCounterLastWeek,
                true,
                30
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <StyledColumnBox>
                <StyledTypography variant="subtitle2">
                  {t("SBI STOP EVENTS")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalSBIStopCounter
                      ? Number(machineSafety.TotalSBIStopCounter).toFixed(2)
                      : (0).toFixed(2)}
                    &nbsp; {t("incidents")}
                  </TableGreenTypography>
                </StyledTypography>

                <StyledTypography variant="subtitle2" gutterBottom>
                  {t("Time")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalSBIStopMinutes
                      ? Number(machineSafety.TotalSBIStopMinutes / 60).toFixed(
                          2
                        )
                      : (0).toFixed(2)}
                    &nbsp; {t("hours")}
                  </TableGreenTypography>
                </StyledTypography>
              </StyledColumnBox>
              {handleArrowIcon(
                machineSafety.TotalSBIStopCounter,
                machineSafety.TotalSBIStopCounterLastWeek,
                true,
                30
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <StyledColumnBox>
                <StyledTypography variant="subtitle2">
                  {t("CWS CRAWL EVENTS")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalCWSCrawlCounter
                      ? Number(machineSafety.TotalCWSCrawlCounter).toFixed(2)
                      : (0).toFixed(2)}
                    &nbsp; {t("incidents")}
                  </TableGreenTypography>
                </StyledTypography>

                <StyledTypography variant="subtitle2" gutterBottom>
                  {t("Time")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalCWSCrawlMinutes
                      ? Number(machineSafety.TotalCWSCrawlMinutes / 60).toFixed(
                          2
                        )
                      : (0).toFixed(2)}
                    &nbsp; {t("hours")}
                  </TableGreenTypography>
                </StyledTypography>
              </StyledColumnBox>
              {handleArrowIcon(
                machineSafety.TotalCWSCrawlCounter,
                machineSafety.TotalCWSCrawlCounterLastWeek,
                true,
                30
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <StyledColumnBox>
                <StyledTypography variant="subtitle2">
                  {t("CWS BRAKE EVENTS")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalCWSBrakeCounter
                      ? Number(machineSafety.TotalCWSBrakeCounter).toFixed(2)
                      : (0).toFixed(2)}
                    &nbsp; {t("incidents")}
                  </TableGreenTypography>
                </StyledTypography>

                <StyledTypography variant="subtitle2" gutterBottom>
                  {t("Time")} :&nbsp;{" "}
                  <TableGreenTypography variant="subtitle2">
                    {machineSafety.TotalCWSBrakeMinutes
                      ? Number(machineSafety.TotalCWSBrakeMinutes / 60).toFixed(
                          2
                        )
                      : (0).toFixed(2)}
                    &nbsp;{t("hours")}
                  </TableGreenTypography>
                </StyledTypography>
              </StyledColumnBox>
              {handleArrowIcon(
                machineSafety.TotalCWSBrakeCounter,
                machineSafety.TotalCWSBrakeCounterLastWeek,
                true,
                30
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        )
      ) : null}
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      {DeviceTitleDataName !== "Device" ? (
        !loadingValue && device ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4} key={0}>
                <div
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: GetMachineTypeWireframe(
                      `MachineType_Side_${device.DeviceType.replaceAll(
                        " ",
                        ""
                      )}.svg`
                    ).replace(
                      "theme.palette.custom.borderColor",
                      theme.palette.custom.borderColor
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4} key={1}>
                <div
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: GetMachineTypeWireframe(
                      `MachineType_Top_${device.DeviceType.replaceAll(
                        " ",
                        ""
                      )}.svg`
                    ).replace(
                      "theme.palette.custom.borderColor",
                      theme.palette.custom.borderColor
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4} key={2}>
                <div
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: GetMachineTypeWireframe(
                      `MachineType_Perspective_${device.DeviceType.replaceAll(
                        " ",
                        ""
                      )}.svg`
                    ).replace(
                      "theme.palette.custom.borderColor",
                      theme.palette.custom.borderColor
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        )
      ) : null}
      {DeviceTitleDataName === "Device" ? (
        !loadingValue && device ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6} key={0}>
                <img width="250px" src={device.DeviceIcon} alt="logo" />
              </Grid>
              <Grid item xs={2} sm={4} md={4} key={1}>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "center",
                    pt: "8px",
                  }}
                >
                  <Box sx={{ mr: "10px", display: "flex", flexFlow: "column" }}>
                    {device.DevDataIcon !== "" && (
                      <div
                        style={{ width: "40px", marginBottom: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: device.DevDataIcon.replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    )}
                    {device.DeviceType === "tortoise" && (
                      <div
                        style={{ width: "40px", marginBottom: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: device.DevDataSecondIcon.replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    )}
                    {device.DeviceType === "nngen2_people_counter1" && (
                      <img
                        style={{ width: "40px", marginBottom: "18px" }}
                        src={device.DevDataSecondIcon}
                        alt="logo"
                      />
                    )}
                    {device.DeviceType === "tortoise" && (
                      <div
                        style={{ width: "40px", marginBottom: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: device.DevDataThirdIcon.replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    )}
                    {device.DeviceType === "nngen2_people_counter1" && (
                      <img
                        style={{ width: "40px", marginBottom: "18px" }}
                        src={device.DevDataThirdIcon}
                        alt="logo"
                      />
                    )}
                    {device.DeviceType === "tortoise" && (
                      <div
                        style={{ width: "40px", marginBottom: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: device.DevDataFourthIcon.replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    )}
                    {device.DeviceType === "tortoise" && (
                      <img
                        style={{ width: "40px", marginRight: "4px" }}
                        src={device.DevDataFifthIcon}
                        alt="logo"
                      />
                    )}
                    <div
                      style={{ width: "40px", marginBottom: "10px" }}
                      dangerouslySetInnerHTML={{
                        __html: device.BatteryImage.replace(
                          "theme.palette.custom.text",
                          theme.palette.custom.text
                        ).replace(
                          "theme.palette.custom.borderColor",
                          theme.palette.custom.borderColor
                        ),
                      }}
                    />

                    <img
                      style={{ width: "40px", marginRight: "4px" }}
                      src={device.DevConnectivityImg}
                      alt="logo"
                    />
                  </Box>
                  <Box>
                    <Box sx={{ mb: "10px" }}>
                      <StyledTypography variant="subtitle2">
                        {t(device.DevDataName)}
                      </StyledTypography>
                      <StyledTypography variant="subtitle2">
                        {device.DevDataValue === "YES" && <RedCircle />}
                        {device.DevDataValue === "NO" && <GrayCircle />}{" "}
                        {t(device.DevDataValue)}
                      </StyledTypography>
                      {device.DevDataValue2 !== "" && (
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataValue2)}
                        </StyledTypography>
                      )}
                    </Box>
                    {(device.DeviceType === "tortoise" ||
                      device.DeviceType === "nngen2_people_counter1") && (
                      <Box sx={{ mb: "10px" }}>
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataSecondName)}
                        </StyledTypography>
                        <StyledTypography variant="subtitle2">
                          {device.DevDataSecondValue === "YES" && (
                            <GrayCircle />
                          )}
                          {device.DevDataSecondValue === "NO" && <RedCircle />}
                          {t(device.DevDataSecondValue)}
                        </StyledTypography>
                      </Box>
                    )}
                    {(device.DeviceType === "tortoise" ||
                      device.DeviceType === "nngen2_people_counter1") && (
                      <Box sx={{ mb: "10px" }}>
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataThirdName)}
                        </StyledTypography>
                        <StyledTypography variant="subtitle2">
                          {device.DevDataThirdValue === "YES" && <GrayCircle />}
                          {device.DevDataThirdValue === "NO" && <RedCircle />}
                          {t(device.DevDataThirdValue)}
                        </StyledTypography>
                      </Box>
                    )}
                    {device.DeviceType === "tortoise" && (
                      <Box sx={{ mb: "10px" }}>
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataFourthName)}
                        </StyledTypography>
                        <StyledTypography variant="subtitle2">
                          {device.DevDataFourthValue === "YES" ? (
                            <GrayCircle />
                          ) : (
                            <RedCircle />
                          )}
                          {t(device.DevDataFourthValue)}
                        </StyledTypography>
                      </Box>
                    )}
                    {device.DeviceType === "tortoise" && (
                      <Box sx={{ mb: "10px" }}>
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataFifthName)}
                        </StyledTypography>
                        <StyledTypography variant="subtitle2">
                          {t(device.DevDataFifthValue)}
                        </StyledTypography>
                      </Box>
                    )}
                    <Box sx={{ mb: "10px" }}>
                      <StyledTypography variant="subtitle2">
                        {device.BatteryTitle}
                      </StyledTypography>
                      <StyledTypography variant="subtitle2">
                        {device.BatteryLvl}
                      </StyledTypography>
                    </Box>
                    <Box>
                      <StyledTypography variant="subtitle2">
                        {t("Connectivity")}
                      </StyledTypography>
                      <StyledTypography variant="subtitle2">
                        {t(device.DevConnectivity)}
                      </StyledTypography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        )
      ) : null}
      {!props.compare && (
        <>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          {DeviceTitleDataName === "Device" ? (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.custom.borderColor,
                },
              }}
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
            >
              <StyledTab label={t("History")} />
              <StyledTab label={t("Raw Data")} />

              <StyledTab label={t("Notifications")} />
            </Tabs>
          ) : (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: { backgroundColor: theme.palette.custom.borderColor },
              }}
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
            >
              <StyledTab label={t("Events")} />
              <StyledTab label={t("Signals")} />
              {["lhd", "drill", "adt", "dump truck"].includes(
                device?.DeviceType?.toLowerCase()
              ) && <StyledTab label={t("3D View")} />}
              <StyledTab label={t("Notifications")} value={3} />
            </Tabs>
          )}
          {tabValue === 0 && DeviceTitleDataName !== "Device" && <EventsTab />}
          {tabValue === 0 && DeviceTitleDataName === "Device" && device && (
            <>
              <div style={{ marginBottom: "8px" }} />
              <CommonDateTimePicker device={device} />
            </>
          )}
          {tabValue === 1 && DeviceTitleDataName !== "Device" && (
            <DeviceSignalsTab
              machineType={device ? device.MachineType : null}
            />
          )}
          {tabValue === 1 && DeviceTitleDataName === "Device" && (
            <DeviceRowDataTab />
          )}
          {tabValue === 2 && (
            <Box>
              <Typography textAlign="start" variant="h5">
                {t("3D View")}:
              </Typography>
              <iframe
                src={`${
                  process.env.PUBLIC_URL
                }/3d-objects/machine-3d-view.html?MachineType=${device?.DeviceType?.toLowerCase().replaceAll(
                  " ",
                  ""
                )}`}
                id="obj3d0"
                style={{
                  width: "100%",
                  height: "50vh",
                  border: 0,
                  backgroundColor: theme.palette.custom.backgroundColor,
                }}
                onLoad={iframe3DObjectLoaded}
                title={device?.DeviceType?.toLowerCase().replaceAll(" ", "")}
              ></iframe>
            </Box>
          )}
          {tabValue === 3 && <DeviceNotificationsTab />}
        </>
      )}
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={handleUnassign}
        saveLoading={saveLoading}
        dialogTitle={t(
          "Are you sure you want to unassign this machine/device from the organization?"
        )}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Unassign")}
      />
    </Box>
  );
}

export default Machine;
