import React, { useState } from "react";
import { styled } from '@mui/material/styles';


const StyledDiv = styled((props) => <div  {...props} />)(
    ({ theme }) => ({
        cursor: "pointer",
        position: "relative",
        width: "190px",
        height: "109.6965511460289px",
        backgroundColor: "#2b2f38",
        margin: "calc(54.84827557301445px + 15px) auto 0 auto",
        borderLeft: `5px solid ${theme.palette.custom.borderColor}`,
        borderRight: `5px solid ${theme.palette.custom.borderColor}`,
        display: "grid",
        transition: "0.4s",

        "&::before, &::after": {
            content: '""',
            position: "absolute",
            zIndex: 1,
            width: "134.35028842544403px",
            height: "134.35028842544403px",
            transform: "scaleY(0.5774) rotate(-45deg)",
            backgroundColor: "inherit",
            left: "22.824855787277983px",
            transition: "0.4s",
        },

        "&::before": {
            top: "-67.17514421272202px",
            borderTop: `solid 7.0710678118654755px ${theme.palette.custom.borderColor}`,
            borderRight: `solid 7.0710678118654755px ${theme.palette.custom.borderColor}`,
        },

        "&::after": {
            bottom: "-67.17514421272202px",
            borderBottom: `solid 7.0710678118654755px ${theme.palette.custom.borderColor}`,
            borderLeft: `solid 7.0710678118654755px ${theme.palette.custom.borderColor}`,
        },


        "&:hover": {
            margin: "calc(54.84827557301445px + 15px) auto 0 auto",
            boxShadow: `0 0 25px ${theme.palette.custom.borderColor}`,
            borderLeft: `solid 13px ${theme.palette.custom.borderColor}`,
            borderRight: `solid 13px ${theme.palette.custom.borderColor}`,
        },

        "&:hover:before, &:hover:after": {
            width: "134.35028842544403px",
            height: "134.35028842544403px",
            transform: "scaleY(0.5774) rotate(-45deg)",
            left: "14.824855787277983px",
            boxShadow: `0 0 25px ${theme.palette.custom.borderColor}`,
        },

        "&:hover:before": {
            top: "-67.17514421272202px",
            borderTop: `solid 18.38477631085024px ${theme.palette.custom.borderColor}`,
            borderRight: `solid 18.38477631085024px ${theme.palette.custom.borderColor}`,
        },
        "&:hover:after": {
            bottom: "-67.17514421272202px",
            borderBottom: `solid 18.38477631085024px ${theme.palette.custom.borderColor}`,
            borderLeft: `solid 18.38477631085024px ${theme.palette.custom.borderColor}`,
        },


    }),
);
const StyledSpan = styled((props) => <span  {...props} />)(
    ({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        fontSize: "85px",
        color: theme.palette.custom.borderColor,
        top: "2.8867513459481287px",
        left: 0,
        width: "180px",
        height: "103.9230px",
        zIndex: 2,
        background: "inherit",
        transition: " 0.4s",
        "&:hover": {
            fontSize: "105px",
            top: "7.505553499465135px",
            width: "164px",
            height: "94.6854px",
        }
    }),
);
export default function Hexagons({ children, onClick }) {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    const handleClick = (item) => {
        onClick()
    };

    return (
        <StyledDiv
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <StyledSpan
                style={{
                    fontSize: isHovering ? "105px" : "85px",
                    width: isHovering ? "164px" : "180px",
                    height: isHovering ? "94.6854px" : "103.9230px",
                    top: isHovering ? "7.505553499465135px" : "2.8867513459481287px",
                }}
            >
                {children}
            </StyledSpan>
        </StyledDiv>
    );
}