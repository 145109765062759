/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setNotificationsData = createAsyncThunk('notifications/setNotificationsData', async (inputData) => {
  let notifications = [];
  if (inputData === null) {
    return null
  }
  notifications = PopulateNotifications(inputData.json)
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && notifications && notifications.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return notifications.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return notifications;
});
function PopulateNotifications(json) {
  let notification = [];
  const NotificationsArray = json.operation_notifications;
  for (let index in NotificationsArray) {
    let notificationsObject = {
        "notification": "",
        "device_id": "",
        "device_type":"",
        "info": "",
        "timestamp": ""
    }
    notificationsObject.notification = NotificationsArray[index].notifcation;
    notificationsObject.device_id = NotificationsArray[index].device_id;
    notificationsObject.device_type = NotificationsArray[index].device_type;
    notificationsObject.info = NotificationsArray[index].info;
    notificationsObject.timestamp = NotificationsArray[index].timestamp.toString();
    notification.push(notificationsObject)
  }
  return notification
}

const initialState = null


const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setNotificationsData.fulfilled, (state, action) => action.payload);
  },
});

export const selectNotifications = ({ notifications }) => notifications;

export default notificationsSlice.reducer;
