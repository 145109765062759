import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box, Button, FormControlLabel, FormGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {useState, useEffect, useMemo} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import ProjectService from "../api/project.js";
import MachineDocumentationTableRow from "./machineDocumentationTableRow.js";
import {stableSort, getComparator} from "../utils/systemInfo";
import SearchInput from "../common/searchInput.js";
import {setMachineDocumentationData} from "../store/machineDocumentationSlice.js";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography.js";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import ImportButton from "../common/importButton.js";
import ExportButton from "../common/exportButton.js";
import Switch from "@mui/material/Switch";
import {Dropdown, DropdownMenuItem} from "../common/Layout/DropDown.js";
import Breadcrumbs from "../common/breadcrumbs";
import {useLocation} from "react-router-dom";
import handleNavigate from "../common/customFunctions/handleNavigate.js";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({theme}) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "Machine Status", href: "machineStatus"}
];

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const StyledButton = styled((props) => <Button {...props} />)(({theme}) => ({
    "height": "40px",
    "backgroundColor": theme.palette.custom.buttonColor,
    "color": theme.palette.custom.buttonTextColor,
    "&:hover": {
        backgroundColor: theme.palette.custom.buttonHoverColor,
        color: theme.palette.custom.buttonHoverTextColor,
        boxShadow: "none"
    }
}));

export default function MachineDocumentation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organziation = useSelector(({organziation}) => organziation);
    const documentation = useSelector(({machineDocumentation}) => machineDocumentation);
    const theme = useTheme();
    const {t} = useTranslation();
    const [docsInputData, setDocsInputData] = useState(undefined);
    const [orderDocs, setOrderDocs] = useState("asc");
    const [orderByDocs, setOrderByDocs] = useState("U/G ID Number");
    const [pageDocs, setPageDocs] = useState(0);
    const [rowsPerPageDocs, setRowsPerPageDocs] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [searchtextDocs, setSearchTextDocs] = useState("");
    const [loadingValueDocs, setLoadingValueDocs] = useState(true);
    const [hidden, setHidden] = useState(false);
    const [vertical, setVertical] = useState(false);
    const [orgUid, setOrgUid] = useState(undefined);
    const [projectID2, setProjectID2] = useState("");
    const [apiKey, SetApiKey] = useState(undefined);
    const [pagination, setPagination] = useState(true);
    const [paginationHeight, setPaginationHeight] = useState("");
    const location = useLocation();
    const [docsHead, setDocsHead] = useState([
        {
            id: "U/G ID Number",
            numeric: false,
            disablePadding: true,
            label: "U/G ID Number",
            sort: true
        },
        {
            id: "Model",
            numeric: false,
            disablePadding: true,
            label: "Equipment Model",
            sort: true
        },
        {
            id: "Manufacturer",
            numeric: false,
            disablePadding: true,
            label: "Equipment Manufacturer",
            sort: true
        },
        {
            id: "Equipment Type",
            numeric: false,
            disablePadding: true,
            label: "Equipment Type",
            sort: true
        }
    ]);
    
    const visibleRowsDocs = useMemo(
        () => {
            //   console.log(documentation);
            if (documentation && documentation.length > 0) {
                if (orderByDocs !== "Machine") {
                    return sortByTaskValueDocs(documentation, orderByDocs).slice(pageDocs * rowsPerPageDocs, pageDocs * rowsPerPageDocs + rowsPerPageDocs);
                } else {
                    // console.log("true")
                    return stableSort(documentation, getComparator(orderDocs, orderByDocs)).slice(pageDocs * rowsPerPageDocs, pageDocs * rowsPerPageDocs + rowsPerPageDocs);
                }
            } else {
                return [];
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderByDocs, orderDocs, pageDocs, rowsPerPageDocs, documentation]
        //[page, rowsPerPage, safety],
    );
    
    function sortByTaskValueDocs(documentation, taskName) {
        let sortedDocs = documentation.map((e) => e);

        sortedDocs.sort((a, b) => {
            const taskA = a.tasks.find((task) => task.name.includes(taskName));
            const taskB = b.tasks.find((task) => task.name.includes(taskName));

            let valueA = taskA ? taskA.value : "";
            let valueB = taskB ? taskB.value : "";

            if (["Model", "Manufacturer", "Equipment Type", "U/G ID Number"].includes(taskName)) {
                valueA = valueA ? String(valueA) : "";
                valueB = valueB ? String(valueB) : "";

                if (orderDocs === "asc") {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            } else {
                valueA = Number(valueA) || 0;
                valueB = Number(valueB) || 0;

                if (orderDocs === "asc") {
                    return valueA - valueB;
                } else {
                    return valueB - valueA;
                }
            }
        });

        return sortedDocs;
    }

    useEffect(() => {
        const orgID = organziation?.uid;
        const apiKey = organziation?.api_key_list?.[0]?.key;

        setOrgUid(orgID);
        setDocsInputData(null);
        SetApiKey(apiKey);

        if (!orgID || !apiKey) return;


        ProjectService.GetProjectProfile(orgID, "", "", "Machine Documentation", "active", apiKey).then((jsonProfile) => {
            if (jsonProfile) {
                const projectDocID = jsonProfile.project_profile.find((e) => e.name === "Machine Documentation")?.project_profile_id;
                if (projectDocID) {
                    setProjectID2(projectDocID.toString());
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organziation]);

    

    useEffect(() => {
        if (!projectID2 || !apiKey) return;

        ProjectService.GetProjectSummary(projectID2, "Machine Documentation", "", apiKey).then((jsonSummary) => {
            if (jsonSummary.count > 0) {
                const docsList = PopulateDocs(jsonSummary);
                PopulateDocsHeaders(jsonSummary);
                setDocsInputData({json: docsList});
                dispatch(setMachineDocumentationData({json: docsList}));
            }
            setLoadingValueDocs(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectID2, apiKey])

    function handleSearchDocs(ev) {
        setLoadingValueDocs(true);
        const newSearchText = ev.target.value;
        setSearchTextDocs(newSearchText);
        let docsInputObj = docsInputData;
        docsInputObj.newSearchText = newSearchText;
        dispatch(setMachineDocumentationData(docsInputObj));
        setLoadingValueDocs(false);
    }

    const handleChangeRowsPerPageDocs = (event) => {
        setRowsPerPageDocs(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPageDocs", event.target.value);
        setPageDocs(0);
    };
    const handleChangePageDocs = (event, newPage) => {
        setPageDocs(newPage);
    };
    const handleRequestSortDocs = (event, property) => {
        const isAsc = orderByDocs === property && orderDocs === "asc";
        setOrderDocs(isAsc ? "desc" : "asc");
        setOrderByDocs(property);
    };

    const handleChangeDocsData = (row, value, param_id, rowTasks) => {
        let newMachinesList = [];
        let newTaskList = rowTasks.map((task) => ({...task}));

        docsInputData.json.forEach((e) => {
            if (e.task_group_id === row.task_group_id) {
                newTaskList.forEach((task) => {
                    if (task.task_param_id === param_id) {
                        task.value = value;
                    }
                });
                let docsObj = {
                    name: row.name,
                    tasks: newTaskList,
                    task_group_id: row.task_group_id,
                    task_id: row.task_id,
                    status: row.status,
                    latest_comment: row.latest_comment
                };
                newMachinesList.push(docsObj);
            } else {
                newMachinesList.push(e);
            }
        });

        dispatch(
            setMachineDocumentationData({
                json: newMachinesList,
                newSearchText: searchtextDocs
            })
        );
        setDocsInputData({json: newMachinesList});
    };
    function PopulateDocsHeaders(json) {
        if (json.count > 0) {
            const MachinesArray = json.project_summary;
            // console.log(MachinesArray[0].tasks);
            docsHead.splice(0, docsHead.length);
            MachinesArray[0].tasks.forEach((e) => {
                docsHead.push({
                    id: e.name,
                    numeric: false,
                    disablePadding: false,
                    label: e.name,
                    sort: true
                });
            });
            docsHead.push(
                {
                    id: "comments",
                    numeric: false,
                    disablePadding: false,
                    label: "Comments",
                    sort: true
                },
                {
                    id: "action",
                    numeric: false,
                    disablePadding: false,
                    label: "Action",
                    sort: false
                }
            );
            setDocsHead(docsHead);
        }
    }

    function PopulateDocs(json) {
        if (json.count > 0) {
            let machine = [];
            const MachinesArray = json.project_summary;
            for (let index in MachinesArray) {
                let statusObj = {
                    name: "",
                    tasks: "",
                    task_group_id: "",
                    task_id: "",
                    status: "",
                    latest_comment: ""
                };
                if (MachinesArray[index].tasks !== null) {
                    statusObj.name = MachinesArray[index].name;
                    statusObj.tasks = MachinesArray[index].tasks;
                    statusObj.task_group_id = MachinesArray[index].task_group_id;
                    statusObj.task_id = MachinesArray[index].task_id;
                    statusObj.status = MachinesArray[index].status;
                    statusObj.latest_comment = MachinesArray[index].latest_comment;
                    machine.push(statusObj);
                }
            }
            return machine;
        }
    }
    async function importCsvDocs() {
        let loader;
        const {value: csv} = await Swal.fire({
            title: "Upload CSV",
            showCancelButton: true,
            confirmButtonText: "Post",
            color: theme.palette.custom.text,
            background: theme.palette.custom.inputBackgroundColor,
            input: "file",
            inputLabel: "Select File",
            inputValidator: async (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });

        const data = await new Promise((resolve, reject) => {
            readCSVFile(csv, function (data) {
                resolve(data);
                loader = Swal.fire({
                    title: "Uploading CSV...",
                    text: "Please wait...",
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            });
        });

        for (let i = 0; i < data.length; i++) {
            let machineName = `Docs_${Math.floor(Math.random() * 1000000000)}`;
            let comment = data[i].Comment || "";
            let useProjectID = projectID2.toString();

            const json = await ProjectService.SetProjectGroup("", useProjectID, "", machineName, "", "active", apiKey).then((json) => {
                // console.log(json);
                return json;
            });

            if (json.count > 0) {
                const jsonTask = await ProjectService.SetProjectTask("", useProjectID, json.task_group[0].task_group_id, "", "Progress", "", "active", apiKey).then((jsonTask) => {
                    console.log(jsonTask);
                    return jsonTask;
                });
                if (comment !== "") {
                    await ProjectService.SetGroupComment(json.task_group[0].task_group_id, comment, "active", apiKey).then((jsonComment) => {
                        return jsonComment;
                    });
                }

                for (let key in data[i]) {
                    if (key !== "Machine" && key !== "Comment") {
                        const timestamp = getTimestamp(Object.keys(data[i]).indexOf(key));
                        const jsonParam = await ProjectService.SetTaskParam("", jsonTask.project_task[0].task_id, key, "", "16", timestamp, "active", apiKey).then((jsonParam) => {
                            // console.log(jsonParam);
                            return jsonParam;
                        });

                        if (jsonParam.count > 0) {
                            const jsonVal = await ProjectService.SetParamVal("", jsonParam.task_param[0].task_param_id, data[i][key], "active", apiKey).then((jsonVal) => {
                                // console.log(jsonVal);
                                return jsonVal;
                            });

                            if (jsonVal.count > 0 && i === data.length - 1) {
                                loader.close();
                                Swal.fire({
                                    title: "Success!",
                                    showConfirmButton: true,
                                    confirmButtonText: "confirm",
                                    icon: "success",
                                    color: theme.palette.custom.text,
                                    background: theme.palette.custom.inputBackgroundColor
                                }).then((result) => {
                                    // if (result.isConfirmed) navigate("machineStatus");
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    function readCSVFile(file, callback) {
        if (file !== null && file !== undefined) {
            const reader = new FileReader();

            reader.onload = function (event) {
                const csvData = event.target.result;
                const data = parseCSV(csvData);
                callback(data);
            };

            reader.onerror = function () {
                console.error("error");
            };

            reader.readAsText(file);
        }
    }

    function parseCSV(csvData) {
        let splitBy;
        if (csvData.includes(";")) {
            splitBy = ";";
        } else {
            splitBy = ",";
        }
        const lines = csvData
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.length > 0);
        const headers = lines[0]
            .split(splitBy)
            .map((header) => header.replace(/"/g, ""))
            .filter((header) => header.trim() !== "");
        const data = [];

        for (let i = 1; i < lines.length; i++) {
            const currentline = lines[i].split(splitBy).map((value) => value.replace(/"/g, "").trim());
            const obj = {};
            let hasValues = false;
            for (let j = 0; j < headers.length; j++) {
                if (currentline[j] !== undefined) {
                    obj[headers[j]] = currentline[j];
                    hasValues = true;
                }
            }
            if (hasValues) {
                data.push(obj);
            }
        }

        return data;
    }

    function getTimestamp(seconds) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return timestamp;
    }

    const handleSetPagination = () => {
        setPagination(!pagination);
        if (pagination) {
            setRowsPerPageDocs(documentation.length);
            setPaginationHeight("550px");
        } else {
            setRowsPerPageDocs(5);
            setPaginationHeight("");
        }
    };

    const exportToCSVDocs = (rowData, fileName) => {
        let csv = "";
        rowData[0].tasks.forEach((task) => {
            if (task.name !== undefined) {
                console.log(task.name);
                csv += `${task.name},`;
            }
        });
        csv += "Comment\n";
        rowData.forEach((row) => {
            const taskStatuses = row.tasks
                .map((task) => {
                    console.log(task.value);
                    const statusText = getDocsStatusText(task.value, task.name);
                    return `${statusText}`;
                })
                .join(",");

            const comment = row.latest_comment || "";
            csv += `${taskStatuses},${comment}\n`;
        });

        const blob = new Blob([csv], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileName}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const getDocsStatusText = (value, name) => {
        switch (name) {
            case "Risk":
                switch (value) {
                    case 0:
                        return "Not Set";
                    case 1:
                        return "No Risk";
                    case 2:
                        return "Low Risk";
                    case 3:
                        return "Medium Risk";
                    case 4:
                        return "High Risk";
                    default:
                        return "Unknown";
                }
            case "Electrical Schematics":
            case "Hydraulic Schematics":
            case "Pneumatic Schematics":
            case "Equipment Manual":
            case "Workshop Manual":
            case "Service Manual":
            case "Control Philosophy":
            case "Machine Drawings":
            case "Firmware and Software":
            case "Installation Methodology":
            case "Audit Photo":
            case "Audit Video":
            case "Audit Documents":
            case "CAN Data":
            case "Specifications":
                switch (value) {
                    case 0:
                        return "Not Set";
                    case 1:
                        return "Yes";
                    case 2:
                        return "No";
                    default:
                        return "Unknown";
                }
            case "U/G ID Number":
            case "Model":
            case "Manufacturer":
            case "Equipment Type":
                return value;

            default:
                switch (value) {
                    case 0:
                        return "Not Started";
                    case 1:
                        return "Started";
                    case 2:
                        return "Holding";
                    case 3:
                        return "Problem";
                    case 4:
                        return "Cancelled";
                    case 5:
                        return "Completed";
                    case 6:
                        return "Not Applicable";
                    default:
                        return "Unknown";
                }
        }
    };
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}>
            {useMemo(
                () => (
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                ),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [location]
            )}
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text, width: "80%"}} variant="h2" gutterBottom>
                    {t("Machine")} {t("Status")} - {t("Documentation")}:
                </Typography>
                <Box sx={{display: "flex", justifyContent: "right", width: "20%", alignItems: "center"}}>
                        <StyledButton
                            onClick={(event) => {
                                handleNavigate(event, navigate, `/addMachineDocs?projectId=${projectID2}`);
                                // navigate();
                            }}>
                            {t("Add New Documentation")}
                        </StyledButton>
                </Box>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{display: "flex", justifyContent: "space-between"}}
                    TabIndicatorProps={{
                        style: {backgroundColor: theme.palette.custom.borderColor}
                    }}
                    value={0}
                    aria-label="basic tabs example">
                        <Dropdown
                            sx={{color: theme.palette.custom.text}}
                            trigger={
                                <Button variant="text" sx={{color: theme.palette.custom.text, marginLeft: "auto"}}>
                                    <StyledTypography>{t("Settings")}</StyledTypography>
                                </Button>
                            }
                            menu={[
                                <DropdownMenuItem onClick={() => {}} sx={{color: theme.palette.custom.text}}>
                                    <FormGroup sx={{display: "flex", flexDirection: "column"}}>
                                        <FormControlLabel label="Toggle Icon Descriptors" control={<Switch checked={hidden} onChange={() => setHidden(!hidden)}></Switch>}></FormControlLabel>
                                        <FormControlLabel label="Toggle Vertical Columns" control={<Switch checked={vertical} onChange={() => setVertical(!vertical)}></Switch>}></FormControlLabel>
                                        <FormControlLabel label="Toggle Paging" control={<Switch checked={pagination} onChange={handleSetPagination}></Switch>}></FormControlLabel>
                                    </FormGroup>
                                </DropdownMenuItem>
                            ]}
                        />
                </Tabs>
            </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <SearchInput searchText={searchtextDocs} handleSearch={handleSearchDocs} />
                </Box>

                {loadingValueDocs ? (
                    <Box sx={{height: "30vh", pt: "10%"}}>
                        <Loading />
                    </Box>
                ) : (
                    <TableContainer sx={{maxHeight: paginationHeight}}>
                        <Table key={Math.floor(Math.random() * 1000000000)} sx={{minWidth: 750}} aria-labelledby="tableTitle" stickyHeader={!pagination} aria-label={!pagination ? "sticky table" : ""}>
                            <EnhancedTableHead headCells={docsHead} order={orderDocs} orderBy={orderByDocs} onRequestSort={handleRequestSortDocs} hideCheck vertical={vertical} />

                            <TableBody>
                                {documentation && documentation.length > 0 && visibleRowsDocs.length ? (
                                    visibleRowsDocs.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return <MachineDocumentationTableRow handleChangeDocsData={handleChangeDocsData} labelId={labelId} ikey={index + 10} key={index} row={row} orgUid={orgUid} projectID={projectID2} hidden={hidden}></MachineDocumentationTableRow>;
                                    })
                                ) : (
                                    <StyledTypography>{t("No entries found")}</StyledTypography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {pagination && (
                    <StyledTablePagination
                        SelectProps={{
                            inputProps: {
                                MenuProps: {
                                    PaperProps: {
                                        sx: {
                                            "backgroundColor": theme.palette.custom.backgroundColor,
                                            "color": theme.palette.custom.text,
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: theme.palette.custom.borderColor
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "blue"
                                            },
                                            "& .MuiMenuItem-root.Mui-selected:hover": {
                                                backgroundColor: "blue"
                                            }
                                        }
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={documentation ? documentation.length : 0}
                        rowsPerPage={rowsPerPageDocs}
                        page={pageDocs}
                        onPageChange={handleChangePageDocs}
                        onRowsPerPageChange={handleChangeRowsPerPageDocs}
                    />
                )}
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <ImportButton sx={{}} onClick={importCsvDocs}>
                        Upload CSV
                    </ImportButton>
                    <ExportButton onClick={() => exportToCSVDocs(documentation, "Machine_Documentation_Report")}>Export to CSV</ExportButton>
                </Box>
        </Box>
    );
}
