import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ProfilePictureSelect from "../common/ProfileSelect/button";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import OrganizationService from "../api/organization";
import AppService from "../api/app";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import { useNavigate } from "react-router-dom";
import ScheduledTimeGraphic from "../common/scheduledTime/scheduledTimeGraphic";
import Breadcrumbs from "../common/breadcrumbs";
import ShiftsTableRow from "./shiftsTableRow";
import ExceptionsTableRow from "./exceptionsTableRow";
import ScheduledTimeService from "../api/scheduledTime";
import { setShiftsData, setShiftsTabOpened } from "../store/shiftsSlice";
import { setExceptionsData } from "../store/exceptionsSlice";
// import GetTimeScheduler from "../common/scheduledTime/scheduledTime"
import TimeScheduler from "../common/scheduledTime/scheduledTime";
import {
  stableSort,
  getComparator,
  GetOperationPicturesFromJSON,
} from "../utils/systemInfo";
import ThemesService from "../api/themes";
import SearchInput from "../common/searchInput";
import CustomTextField from "../common/CustomTextField";
import SelectFields from "../common/selectFields";
import TimezonePicker from "../common/timezonePicker";
import ColorPicker from "../common/colorPicker";
import StyledTypography from "../common/StyledTypography";
import { showMessage } from "../store/messageSlice";
import AlertDialog from "../common/AlertDialog";
import handleNavigate from "../common/customFunctions/handleNavigate";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

// const userHead = [
//     {
//         id: "FirstName",
//         numeric: false,
//         disablePadding: true,
//         label: "First Name",
//         sort: true
//     },
//     {
//         id: "LastName",
//         numeric: false,
//         disablePadding: false,
//         label: "Last Name",
//         sort: true
//     },
//     {
//         id: "Email",
//         numeric: false,
//         disablePadding: false,
//         label: "Email Address",
//         sort: true
//     },
//     {
//         id: "Mobile",
//         numeric: false,
//         disablePadding: false,
//         label: "Mobile Number",
//         sort: true
//     },
//     {
//         id: "view",
//         numeric: false,
//         disablePadding: false,
//         label: "View",
//         sort: false
//     }
// ];

// const deviceHead = [
//     {
//         id: "DeviceName",
//         numeric: false,
//         disablePadding: true,
//         label: "Name",
//         sort: true
//     },
//     {
//         id: "DeviceSerial",
//         numeric: false,
//         disablePadding: false,
//         label: "Serial",
//         sort: true
//     },
//     {
//         id: "DeviceType",
//         numeric: false,
//         disablePadding: false,
//         label: "MachineType",
//         sort: true
//     },
//     {
//         id: "DeviceOEM",
//         numeric: false,
//         disablePadding: false,
//         label: "OEM",
//         sort: true
//     },
//     {
//         id: "NamedLocationOriginal",
//         numeric: false,
//         disablePadding: false,
//         label: "LastLocation",
//         sort: true
//     },
//     {
//         id: "DeviceLastSeen",
//         numeric: false,
//         disablePadding: false,
//         label: "LastSeen",
//         sort: true
//     },
//     {
//         id: "view",
//         numeric: false,
//         disablePadding: false,
//         label: "View",
//         sort: false
//     }
// ];

const shiftHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "start_time",
    numeric: false,
    disablePadding: false,
    label: "Start Time",
    sort: true,
  },
  {
    id: "end_time",
    numeric: false,
    disablePadding: false,
    label: "End Time",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
const exceptionHead = [
  {
    id: "ExceptionName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "StartTime",
    numeric: false,
    disablePadding: false,
    label: "From",
    sort: true,
  },
  {
    id: "EndTime",
    numeric: false,
    disablePadding: false,
    label: "To",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    sort: false,
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// const Languages = [
//     {
//         value: "af",
//         label: "afrikaans"
//     },
//     {
//         value: "en",
//         label: "english"
//     },
//     {
//         value: "de",
//         label: "german"
//     },
//     {
//         value: "kl",
//         label: "klingon"
//     },
//     {
//         value: "ru",
//         label: "russian"
//     }
// ];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    height: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonHoverColor,
    },
  })
);
const initOrganization = {
  uid: "",
  full_name: "",
  short_name: "",
  registration_number: "",
  vat_number: "",
  vat_percentage: 0,
  tax_number: "",
  tel_primary: "",
  tel_secondary: "",
  email_address: "",
  email_additional: "",
  website_address: "",
  admin_domain: "",
  commercial_domain: "",
  email_domain: "",
  super_company: 0,
  feature_lock: 0,
  status: "active",
  operation_image_list: [],
  api_key_list: [],
};
async function handleSaveNew(
  name,
  sName,
  vat,
  tax,
  reg,
  mobile,
  email,
  web,
  apiKey
) {
  let organziation = initOrganization;
  const orgInfo = {
    uid: organziation.uid,
    full_name: name,
    short_name: sName,
    registration_number: reg,
    vat_number: vat,
    vat_percentage: organziation.vat_percentage,
    tax_number: tax,
    tel_primary: mobile,
    tel_secondary: organziation.tel_secondary,
    email_address: email,
    email_additional: organziation.email_additional,
    website_address: web,
    admin_domain: organziation.email_domain,
    super_company: organziation.super_company,
    feature_lock: organziation.feature_lock,
    status: organziation.status,
    apiKey,
  };
  let newOrganization = null;
  await OrganizationService.SetOperationProfile(orgInfo).then(
    async (jsonSetOperationProfile) => {
      if (
        jsonSetOperationProfile.success &&
        jsonSetOperationProfile.count > 0
      ) {
        const Organization_UID = jsonSetOperationProfile.operation[0].uid;
        await AppService.SetApiKey(
          "",
          Organization_UID,
          "",
          undefined,
          "active",
          apiKey
        ).then(async (jsonSetApiKey) => {
          if (jsonSetApiKey.success && jsonSetApiKey.count > 0) {
            await AppService.GetAppProfile("", "", "", "active", apiKey).then(
              async (jsonGetAppProfile) => {
                if (
                  jsonGetAppProfile.success === true &&
                  jsonGetAppProfile.app.length > 0
                ) {
                  for (let i in jsonGetAppProfile.app) {
                    if (
                      jsonGetAppProfile.app[i].full_name.toLowerCase() ===
                      "simac"
                    ) {
                      await AppService.SetOrganizationAppProfile(
                        jsonGetAppProfile.app[i].uid,
                        Organization_UID,
                        "active",
                        apiKey
                        // eslint-disable-next-line no-loop-func
                      ).then(() => {
                        // SetOperationPictures("frmOrganizationProfile", Organization_ApiKey).then(jsonSetOperationPictures=> {
                        // });
                        if (
                          document.getElementById("iconFile")?.files?.length
                        ) {
                          OrganizationService.SetOperationImages(
                            "small_logo",
                            "iconFrm"
                          );
                        }
                        newOrganization = jsonSetOperationProfile.operation[0];

                        // Swal.fire({
                        //     icon: 'success',
                        //     title: 'Saved!',
                        //     background: "var(--background-color)"
                        // }).then((result) => {
                        //     if(result.isConfirmed){
                        //         // renderPage("organizations");
                        //     };
                        // });
                      });
                    }
                  }
                }
              }
            );
          }
        });
      }
    }
  );

  if (document.getElementById("iconFile")?.files?.length) {
    OrganizationService.SetOperationImages("small_logo", "iconFrm");
  }
  if (document.getElementById("logoFile")?.files?.length) {
    OrganizationService.SetUserProfilePicture("full_logo", "logoFrm");
  }
  if (document.getElementById("iconFile")?.files?.length) {
    OrganizationService.SetUserProfilePicture(
      "web_dashboard_background",
      "bgFrm"
    );
  }
  return newOrganization;
}
async function handleSave(name, sName, vat, tax, reg, mobile, email, web) {
  let organziation = JSON.parse(localStorage.getItem("defaultOrganization"));
  const orgInfo = {
    uid: organziation.uid,
    full_name: name,
    short_name: sName,
    registration_number: reg,
    vat_number: vat,
    vat_percentage: organziation.vat_percentage,
    tax_number: tax,
    tel_primary: mobile,
    tel_secondary: organziation.tel_secondary,
    email_address: email,
    email_additional: organziation.email_additional,
    website_address: web,
    admin_domain: organziation.email_domain,
    super_company: organziation.super_company,
    feature_lock: organziation.feature_lock,
    status: organziation.status,
    apiKey: organziation?.api_key_list?.[0]?.key,
  };
  let updatedOrganization = null;
  await OrganizationService.SetOperationProfile(orgInfo).then(
    (jsonSetOperationProfile) => {
      if (
        jsonSetOperationProfile.success &&
        jsonSetOperationProfile.count > 0
      ) {
        updatedOrganization = jsonSetOperationProfile.operation[0];
      }
    }
  );

  if (document.getElementById("iconFile")?.files?.length) {
    OrganizationService.SetOperationImages("small_logo", "iconFrm");
  }
  if (document.getElementById("logoFile")?.files?.length) {
    OrganizationService.SetUserProfilePicture("full_logo", "logoFrm");
  }
  if (document.getElementById("iconFile")?.files?.length) {
    OrganizationService.SetUserProfilePicture(
      "web_dashboard_background",
      "bgFrm"
    );
  }
  return updatedOrganization;
}
const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Organization", href: "organization" },
];
function BasicTabs() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  // const machines = useSelector(({ machines }) => machines);
  // const users = useSelector(({ users }) => users);
  const exceptions = useSelector(({ exceptions }) => exceptions);
  const shifts = useSelector(({ shifts }) => shifts.shifts);
  const tabOpened = useSelector(({ shifts }) => shifts.tabOpened);
  // const [opsumInputData, setOpsumInputData] = useState(undefined);
  // const [userInputData, setUserInputData] = useState(undefined);
  const [shiftInputData, setShiftInputData] = useState(undefined);
  const [exceptionInputData, setExceptionInputData] = useState(undefined);
  // const [order, setOrder] = useState("asc");
  // const [orderUser, setOrderUser] = useState("asc");
  const [orderExceptions, setOrderExceptions] = useState("asc");
  const [orderShift, setOrderShift] = useState("asc");
  // const [orderBy, setOrderBy] = useState("DeviceName");
  // const [orderByUser, setOrderByUser] = useState("firstname");
  const [orderByShift, setOrderByShift] = useState("name");
  const [orderByExceptions, setOrderByExceptions] = useState("name");
  // const [page, setPage] = useState(0);
  // const [pageUser, setPageUser] = useState(0);
  const [pageShift, setPageShift] = useState(0);
  const [pageException, setPageException] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem("rowsPerPage") !== undefined ? Number(localStorage.getItem("rowsPerPage")) : 5);
  // const [rowsPerPageUser, setRowsPerPageUser] = useState(Number(localStorage.getItem("rowsPerPageUser")) || 5);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage") !== undefined
      ? Number(localStorage.getItem("rowsPerPage"))
      : 5
  );
  const [rowsPerPageException, setRowsPerPageException] = useState(
    localStorage.getItem("rowsPerPageException") !== undefined
      ? Number(localStorage.getItem("rowsPerPageException"))
      : 5
  );
  // const [searchText, setSearchText] = useState("");
  // const [searchTextUser, setSearchTextUser] = useState("");
  const [searchTextShift, setSearchTextShift] = useState("");
  const [searchTextException, setSearchTextException] = useState("");
  // const [loadingValue, setLoadingValue] = useState(true);
  // const [loadingValueUser, setLoadingValueUser] = useState(true);
  const [loadingValueShift, setLoadingValueShift] = useState(true);
  const [loadingValueException, setLoadingValueException] = useState(true);
  const [value, setValue] = React.useState(0);
  const [bgColor, setBgColor] = React.useState(0);
  const [bHColor, setBHColor] = React.useState(0);
  const [iBgColor, setIBgColor] = React.useState(0);
  const [pColor, setPColor] = React.useState(0);
  const [bgColor2, setBgColor2] = React.useState(0);
  const [bHTextColor, setBHTextColor] = React.useState(0);
  const [iTextColor, setITextColor] = React.useState(0);
  const [tDarkColor, setTDarkColor] = React.useState(0);
  const [bShadowColor, setBShadowColor] = React.useState(0);
  const [bTextColor, setBTextColor] = React.useState(0);
  const [nBColor, setNBColor] = React.useState(0);
  const [tLightColor, setTLightColor] = React.useState(0);
  const [bColor, setBColor] = React.useState(0);
  const [hColor, setHColor] = React.useState(0);
  const [nWidth, setNWidth] = React.useState(0);
  const [bgColorID, setBgColorID] = React.useState(0);
  const [bHColorID, setBHColorID] = React.useState(0);
  const [iBgColorID, setIBgColorID] = React.useState(0);
  const [pColorID, setPColorID] = React.useState(0);
  const [bgColor2ID, setBgColor2ID] = React.useState(0);
  const [bHTextColorID, setBHTextColorID] = React.useState(0);
  const [iTextColorID, setITextColorID] = React.useState(0);
  const [tDarkColorID, setTDarkColorID] = React.useState(0);
  const [bShadowColorID, setBShadowColorID] = React.useState(0);
  const [bTextColorID, setBTextColorID] = React.useState(0);
  const [nBColorID, setNBColorID] = React.useState(0);
  const [tLightColorID, setTLightColorID] = React.useState(0);
  const [bColorID, setBColorID] = React.useState(0);
  const [hColorID, setHColorID] = React.useState(0);
  const [nWidthID, setNWidthID] = React.useState(0);
  const [themeProfileID, setThemeProfileID] = React.useState(0);

  const [openSaveThemeDialog, setOpenSaveThemeDialog] = useState(false);
  const [saveThemeLoading, setSaveThemeLoading] = useState(false);

  const navigate = useNavigate();

  const handleColorChange1 = (newColor) => {
    setBgColor(newColor);
  };
  const handleColorChange2 = (newColor) => {
    setBHColor(newColor);
  };
  const handleColorChange3 = (newColor) => {
    setIBgColor(newColor);
  };
  const handleColorChange4 = (newColor) => {
    setPColor(newColor);
  };
  const handleColorChange5 = (newColor) => {
    setBgColor2(newColor);
  };
  const handleColorChange6 = (newColor) => {
    setBHTextColor(newColor);
  };
  const handleColorChange7 = (newColor) => {
    setITextColor(newColor);
  };
  const handleColorChange8 = (newColor) => {
    setTDarkColorID(newColor);
  };
  const handleColorChange9 = (newColor) => {
    setBShadowColor(newColor);
  };
  const handleColorChange10 = (newColor) => {
    setBTextColorID(newColor);
  };
  const handleColorChange11 = (newColor) => {
    setNBColor(newColor);
  };
  const handleColorChange12 = (newColor) => {
    setTLightColor(newColor);
  };
  const handleColorChange13 = (newColor) => {
    setBColor(newColor);
  };
  const handleColorChange14 = (newColor) => {
    setHColor(newColor);
  };

  const handleSaveTheme = () => {
    setSaveThemeLoading(true);
    ThemesService.SetThemeProfileAttributes(
      bgColorID,
      themeProfileID,
      "",
      "",
      bgColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bHColorID,
      themeProfileID,
      "",
      "",
      bHColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      iBgColorID,
      themeProfileID,
      "",
      "",
      iBgColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      pColorID,
      themeProfileID,
      "",
      "",
      pColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bgColor2ID,
      themeProfileID,
      "",
      "",
      bgColor2,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bHTextColorID,
      themeProfileID,
      "",
      "",
      bHTextColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      iTextColorID,
      themeProfileID,
      "",
      "",
      iTextColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      tDarkColorID,
      themeProfileID,
      "",
      "",
      tDarkColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bShadowColorID,
      themeProfileID,
      "",
      "",
      bShadowColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bTextColorID,
      themeProfileID,
      "",
      "",
      bTextColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      nBColorID,
      themeProfileID,
      "",
      "",
      nBColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      tLightColorID,
      themeProfileID,
      "",
      "",
      tLightColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      bColorID,
      themeProfileID,
      "",
      "",
      bColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      hColorID,
      themeProfileID,
      "",
      "",
      hColor,
      "",
      "",
      "active"
    );
    ThemesService.SetThemeProfileAttributes(
      nWidthID,
      themeProfileID,
      "",
      "",
      nWidth,
      "",
      "",
      "active"
    );
    setSaveThemeLoading(false);
    setOpenSaveThemeDialog(false);
    dispatch(
      showMessage({
        message: t("Saved Successfully"), //text or html
        autoHideDuration: 10000, //ms
        anchorOrigin: {
          vertical: "top", //top bottom
          horizontal: "center", //left center right
        },
        variant: "success", //success error info warning null
      })
    );
  };

  // const handleRequestSort = (event, property) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  // };
  // const handleRequestSortUser = (event, property) => {
  //     const isAsc = orderByUser === property && orderUser === "asc";
  //     setOrderUser(isAsc ? "desc" : "asc");
  //     setOrderByUser(property);
  // };
  const handleRequestSortShift = (event, property) => {
    const isAsc = orderByShift === property && orderShift === "asc";
    setOrderShift(isAsc ? "desc" : "asc");
    setOrderByShift(property);
  };
  const handleRequestSortException = (event, property) => {
    const isAsc = orderByExceptions === property && orderExceptions === "asc";
    setOrderExceptions(isAsc ? "desc" : "asc");
    setOrderByExceptions(property);
  };
  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };
  // const handleChangePageUser = (event, newPage) => {
  //     setPageUser(newPage);
  // };
  const handleChangePageShift = (event, newPage) => {
    setPageShift(newPage);
  };
  const handleChangePageException = (event, newPage) => {
    setPageException(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     localStorage.setItem("rowsPerPage", event.target.value);
  //     setPage(0);
  // };
  // const handleChangeRowsPerPageUser = (event) => {
  //     setRowsPerPageUser(parseInt(event.target.value, 10));
  //     localStorage.setItem("rowsPerPageUser", event.target.value);
  //     setPageUser(0);
  // };
  const handleChangeRowsPerPageShift = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPageShift(0);
  };
  const handleChangeRowsPerPageException = (event) => {
    setRowsPerPageException(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageException", event.target.value);
    setPageException(0);
  };
  // const handleClick = (event, uid) => {
  //     navigate(`/device?device=${uid}`)
  // };
  // function handleSearch(ev) {
  //     setLoadingValue(true);
  //     const newSearchText = ev.target.value;
  //     setSearchText(newSearchText);
  //     let opsumInputObj = opsumInputData;
  //     opsumInputObj.machineTypeFilter = "";
  //     opsumInputObj.machineTypeValue = "machine";
  //     opsumInputObj.newSearchText = newSearchText;
  //     dispatch(setMachinesData(opsumInputObj));
  //     setLoadingValue(false);
  // }
  // function handleSearchUser(ev) {
  //     setLoadingValueUser(true);
  //     const newSearchText = ev.target.value;
  //     setSearchTextUser(newSearchText);
  //     let userInputObj = userInputData;
  //     userInputObj.newSearchText = newSearchText;
  //     dispatch(setUsersData(userInputObj));
  //     setLoadingValueUser(false);
  // }
  function handleSearchShift(ev) {
    setLoadingValueShift(true);
    const newSearchText = ev.target.value;
    setSearchTextShift(newSearchText);
    let shiftInputObj = shiftInputData;
    shiftInputObj.newSearchText = newSearchText;
    dispatch(setShiftsData(shiftInputObj));
    setLoadingValueShift(false);
  }
  function handleSearchException(ev) {
    setLoadingValueException(true);
    const newSearchText = ev.target.value;
    setSearchTextException(newSearchText);
    let exceptionInputObj = exceptionInputData;
    exceptionInputObj.newSearchText = newSearchText;
    dispatch(setExceptionsData(exceptionInputObj));
    setLoadingValueException(false);
  }
  const visibleRowsShift = useMemo(() => {
    if (shifts?.length) {
      const returnvalue = stableSort(
        shifts,
        getComparator(orderShift, orderByShift)
      ).slice(pageShift * rowsPerPage, pageShift * rowsPerPage + rowsPerPage);
      return returnvalue;
    } else {
      return [];
    }
  }, [orderShift, orderByShift, pageShift, rowsPerPage, shifts]);
  const visibleRowsException = useMemo(
    () => {
      if (exceptions && exceptions.length > 0) {
        return stableSort(
          exceptions,
          getComparator(orderExceptions, orderByExceptions)
        ).slice(
          pageException * rowsPerPageException,
          pageException * rowsPerPageException + rowsPerPageException
        );
      } else {
        return [];
      }
    },
    [
      orderExceptions,
      orderByExceptions,
      pageException,
      rowsPerPageException,
      exceptions,
    ]
    //[page, rowsPerPage, safety],
  );
  // const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
  useEffect(() => {
    let currentorganization;
    if (organziation && organziation !== currentorganization) {
      currentorganization = organziation;
      // setOpsumInputData(null);
      // setUserInputData(null);
      setShiftInputData(null);
      setExceptionInputData(null);
      const apiKey = organziation?.api_key_list?.[0]?.key;

      ThemesService.getThemeProfileLink({
        uid: organziation.uid,
        apiKey,
      })
        .then((linkJson) => {
          for (
            let index = 0;
            index < linkJson.theme_profile_link.length;
            index++
          ) {
            ThemesService.getThemeProfile({
              theme_profile_id:
                linkJson.theme_profile_link[index].theme_profile_id,
              apiKey,
            })
              .then((profileJson) => {
                console.log(
                  "getThemeProfile",
                  profileJson.theme_profile[0].name,
                  theme.palette.mode
                );
                const darkOrLight = profileJson.theme_profile[0].name
                  .toLowerCase()
                  .includes("dark")
                  ? "dark"
                  : "light";
                if (darkOrLight === theme.palette.mode) {
                  setThemeProfileID(profileJson.theme_profile[0].theme_id);
                  ThemesService.GetThemeProfileAttributes({
                    theme_attribute_id: "",
                    theme_profile_id: profileJson.theme_profile[0].theme_id,
                    name: "",
                    status: "",
                    apiKey,
                  })
                    .then((attributeJson) => {
                      if (
                        attributeJson &&
                        attributeJson.theme_profile_attributes &&
                        attributeJson.theme_profile_attributes.length > 0
                      ) {
                        setPColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "primary-color"
                          ).value
                        );
                        setPColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "primary-color"
                          ).theme_attribute_id
                        );
                        setTLightColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-light-color"
                          ).value
                        );
                        setTLightColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-light-color"
                          ).theme_attribute_id
                        );
                        setTDarkColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-dark-color"
                          ).value
                        );
                        setTDarkColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-dark-color"
                          ).theme_attribute_id
                        );
                        setHColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "header-color"
                          ).value
                        );
                        setHColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "header-color"
                          ).theme_attribute_id
                        );
                        setIBgColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-background-color"
                          ).value
                        );
                        setIBgColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-background-color"
                          ).theme_attribute_id
                        );
                        setBgColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color"
                          ).value
                        );
                        setBgColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color"
                          ).theme_attribute_id
                        );
                        setBgColor2(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color2"
                          ).value
                        );
                        setBgColor2ID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color2"
                          ).theme_attribute_id
                        );
                        setNBColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-color"
                          ).value
                        );
                        setNBColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-color"
                          ).theme_attribute_id
                        );
                        setBColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-color"
                          ).value
                        );
                        setBColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-color"
                          ).theme_attribute_id
                        );
                        setBTextColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-text-color"
                          ).value
                        );
                        setBTextColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-text-color"
                          ).theme_attribute_id
                        );
                        setBHColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-color"
                          ).value
                        );
                        setBHColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-color"
                          ).theme_attribute_id
                        );
                        setBHTextColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-text-color"
                          ).value
                        );
                        setBHTextColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-text-color"
                          ).theme_attribute_id
                        );
                        setBShadowColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "box-shadow-color"
                          ).value
                        );
                        setBShadowColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "box-shadow-color"
                          ).theme_attribute_id
                        );
                        setITextColor(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-text-color"
                          ).value
                        );
                        setITextColorID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-text-color"
                          ).theme_attribute_id
                        );
                        setNWidth(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-width"
                          ).value
                        );
                        setNWidthID(
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-width"
                          ).theme_attribute_id
                        );
                      }
                    })
                    .catch((errorr) => {
                      if (process.env.NODE_ENV === "development")
                        console.log("themes error", errorr);
                    });
                }
                //  else {
                //   setThemeProfileID(profileJson.theme_profile[0].theme_id);
                //   ThemesService.GetThemeProfileAttributes({
                //     theme_attribute_id: "",
                //     theme_profile_id: profileJson.theme_profile[0].theme_id,
                //     name: "",
                //     status: "",
                //     apiKey,
                //   })
                //     .then((attributeJson) => {
                //       if (
                //         attributeJson &&
                //         attributeJson.theme_profile_attributes &&
                //         attributeJson.theme_profile_attributes.length > 0
                //       ) {
                //         setPColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "primary-color"
                //           ).value
                //         );
                //         setPColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "primary-color"
                //           ).theme_attribute_id
                //         );
                //         setTLightColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "text-light-color"
                //           ).value
                //         );
                //         setTLightColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "text-light-color"
                //           ).theme_attribute_id
                //         );
                //         setTDarkColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "text-dark-color"
                //           ).value
                //         );
                //         setTDarkColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "text-dark-color"
                //           ).theme_attribute_id
                //         );
                //         setHColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "header-color"
                //           ).value
                //         );
                //         setHColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "header-color"
                //           ).theme_attribute_id
                //         );
                //         setIBgColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "input-background-color"
                //           ).value
                //         );
                //         setIBgColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "input-background-color"
                //           ).theme_attribute_id
                //         );
                //         setBgColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "background-color"
                //           ).value
                //         );
                //         setBgColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "background-color"
                //           ).theme_attribute_id
                //         );
                //         setBgColor2(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "background-color2"
                //           ).value
                //         );
                //         setBgColor2ID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "background-color2"
                //           ).theme_attribute_id
                //         );
                //         setNBColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "nav-color"
                //           ).value
                //         );
                //         setNBColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "nav-color"
                //           ).theme_attribute_id
                //         );
                //         setBColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-color"
                //           ).value
                //         );
                //         setBColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-color"
                //           ).theme_attribute_id
                //         );
                //         setBTextColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-text-color"
                //           ).value
                //         );
                //         setBTextColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-text-color"
                //           ).theme_attribute_id
                //         );
                //         setBHColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-hover-color"
                //           ).value
                //         );
                //         setBHColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-hover-color"
                //           ).theme_attribute_id
                //         );
                //         setBHTextColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-hover-text-color"
                //           ).value
                //         );
                //         setBHTextColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "button-hover-text-color"
                //           ).theme_attribute_id
                //         );
                //         setBShadowColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "box-shadow-color"
                //           ).value
                //         );
                //         setBShadowColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "box-shadow-color"
                //           ).theme_attribute_id
                //         );
                //         setITextColor(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "input-text-color"
                //           ).value
                //         );
                //         setITextColorID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "input-text-color"
                //           ).theme_attribute_id
                //         );
                //         setNWidth(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "nav-width"
                //           ).value
                //         );
                //         setNWidthID(
                //           attributeJson.theme_profile_attributes.find(
                //             (x) => x.name === "nav-width"
                //           ).theme_attribute_id
                //         );
                //       }
                //     })
                //     .catch((errorr) => {
                //       if (process.env.NODE_ENV === "development")
                //         console.log("themes error", errorr);
                //     });
                // }
              })
              .catch((errorr) => {
                if (process.env.NODE_ENV === "development")
                  console.log("themes error", errorr);
              });
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("themes error", errorr);
        });

      // ProfileService.GetUserProfile({
      //     profile_uid: "",
      //     profile_id: "",
      //     app_profile_uid: "",
      //     firstname: "",
      //     lastname: "",
      //     tel_primary: "",
      //     email_primary: "",
      //     status: "active",
      //     apiKey: apiKey
      // })
      //     .then((json) => {
      //         if (json && json.person_profile && json.person_profile.length > 0) {
      //             setUserInputData({ json });
      //             dispatch(setUsersData({ json }));
      //         }
      //         setLoadingValueUser(false);
      //     })
      //     .catch((error) => { });
      ScheduledTimeService.GetScheduledTimeProfile({
        scheduled_time_profile_id: "",
        operation_profile_uid: organziation.uid,
        name: "",
        apiKey: apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.scheduled_time_profile &&
            json.scheduled_time_profile.length > 0
          ) {
            ScheduledTimeService.GetScheduledTimeConfig({
              scheduled_time_profile_id:
                json.scheduled_time_profile[0].scheduled_time_profile_id,
              status: "active",
              apiKey: apiKey,
            })
              .then((json) => {
                if (
                  json &&
                  json.scheduled_time_config &&
                  json.scheduled_time_config.length > 0
                ) {
                  ScheduledTimeService.GetExceptions({
                    scheduled_time_exception_id: "",
                    scheduled_time_config_id:
                      json.scheduled_time_config[0].scheduled_time_config_id,
                    status: "active",
                    apiKey: apiKey,
                  })
                    .then((json) => {
                      if (
                        json &&
                        json.scheduled_time_exception &&
                        json.scheduled_time_exception.length > 0
                      ) {
                        setExceptionInputData({ json });
                        dispatch(setExceptionsData({ json }));
                      }
                      setLoadingValueException(false);
                    })
                    .catch((error) => { });
                }
                setLoadingValueException(false);
              })
              .catch((error) => { });
          }
          setLoadingValueException(false);
        })
        .catch((error) => { });
      ScheduledTimeService.GetOrganizationShiftTimes({
        shift_time_config_uid: "",
        operation_profile_uid: organziation.uid,
        name: "",
        day_of_week: "",
        status: "active",
        apiKey: apiKey,
      })
        .then((json) => {
          // let jsonTemp = {};
          // if (json.success === true && json.shift_time_config.length === 0) {
          //   jsonTemp = {
          //     success: true,
          //     count: 2,
          //     shift_time_config: [
          //       {
          //         operation_uid: organziation.uid,
          //         name: "Shift 1",
          //         start_time: "00:00:00",
          //         end_time: "12:00:00",
          //         status: "active",
          //       },
          //       {
          //         operation_uid: organziation.uid,
          //         name: "Shift 2",
          //         start_time: "12:00:00",
          //         end_time: "24:00:00",
          //         status: "active",
          //       },
          //     ],
          //   };
          // } else {
          //   jsonTemp = json;
          // }
          // json = jsonTemp;
          if (
            json &&
            json.shift_time_config &&
            json.shift_time_config.length > 0
          ) {
            setShiftInputData({ json });
            dispatch(setShiftsData({ json }));
          }
          setLoadingValueShift(false);
        })
        .catch((error) => { });
      // let DevicesClass;
      // switch (DeviceTitleDataName.toLowerCase()) {
      //     case "machine":
      //         DevicesClass = { deviceType: "nerohub", source: "" };
      //         break;

      //     case "device":
      //         DevicesClass = { deviceType: "", source: "iot" };
      //         break;

      //     default:
      //         DevicesClass = { deviceType: "nerohub", source: "" };
      //         break;
      // }
      // DevicesService.getDevices({
      //     deviceID: "",
      //     deviceType: DevicesClass.deviceType,
      //     source: DevicesClass.source,
      //     apiKey,
      //     historyFromDST: "",
      //     historyToDST: GetDateTimeStringToday()
      // })
      //     .then((json) => {

      //         if (json && json.device_profile && json.device_profile.length > 0) {
      //             setOpsumInputData({
      //                 json,
      //                 machineTypeFilter: "",
      //                 machineType: DeviceTitleDataName ? DeviceTitleDataName : "machine"
      //             });
      //             dispatch(setMachinesData({ json, machineTypeFilter: "", machineType: DeviceTitleDataName ? DeviceTitleDataName : "machine" }));
      //         }
      //         setLoadingValue(false);
      //     })
      //     .catch((errorr) => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);
  useEffect(() => {
    if (tabOpened) {
      setValue(1);
      dispatch(setShiftsTabOpened(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleShiftNavigate = (event) => {
    handleNavigate(event, navigate, `/addShift?orgUid=${organziation.uid}&action=add`);
    // navigate();
  };
  function handleSaveThemeClose() {
    setOpenSaveThemeDialog(false);
  }
  // const handleClick = (event, uid) => {
  //     navigate(`/device?device=${uid}`);
  // };

  // const handleClickUser = (event, uid) => {
  //     navigate(`/profile?profile=${uid}`);
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{ display: "flex", justifyContent: "space-between" }}
          TabIndicatorProps={{
            style: { backgroundColor: theme.palette.custom.borderColor },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label={t("Theme Editing")} {...a11yProps(0)} />
          <StyledTab label={t("Shifts")} {...a11yProps(1)} />
          <StyledTab label={t("Schedule")} {...a11yProps(2)} />
          <StyledTab label={t("TS, KPI's & OEE")} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
          style={{
            minHeight: "90vh",
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "block", sm: "start" },
              height: "64px",
            }}
          >
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "2.5rem",
                    mb: 0,
                    color: theme.palette.custom.text,
                  }}
                  gutterBottom
                >
                  {t("Theme Editing")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          <Box
            id="profileBox"
            sx={{
              display: { xs: "block", sm: "flex" },
              width: "100%",
              flexDirection: "row",
              color: "#FFFFFF",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", sm: "flex" },
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "start",
                gap: "3rem",
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ColorPicker
                  initialColor={bgColor}
                  label="Background Color 1"
                  onColorChange={handleColorChange1}
                />
                <ColorPicker
                  initialColor={bHColor}
                  label="Button Hover Color"
                  onColorChange={handleColorChange2}
                />
                <ColorPicker
                  initialColor={iBgColor}
                  label="Input Background Color"
                  onColorChange={handleColorChange3}
                />
                <ColorPicker
                  initialColor={pColor}
                  label="Primary Color"
                  onColorChange={handleColorChange4}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ColorPicker
                  initialColor={bgColor2}
                  label="Background Color 2"
                  onColorChange={handleColorChange5}
                />
                <ColorPicker
                  initialColor={bHTextColor}
                  label="Button Hover Text Color"
                  onColorChange={handleColorChange6}
                />
                <ColorPicker
                  initialColor={iTextColor}
                  label="Input Text Color"
                  onColorChange={handleColorChange7}
                />
                <ColorPicker
                  initialColor={tDarkColor}
                  label="Text Dark Color"
                  onColorChange={handleColorChange8}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ColorPicker
                  initialColor={bShadowColor}
                  label="Box Shadow Color"
                  onColorChange={handleColorChange9}
                />
                <ColorPicker
                  initialColor={bTextColor}
                  label="Button Text Color"
                  onColorChange={handleColorChange10}
                />
                <ColorPicker
                  initialColor={nBColor}
                  label="Navigation Background Color"
                  onColorChange={handleColorChange11}
                />
                <ColorPicker
                  initialColor={tLightColor}
                  label="Text Light Color"
                  onColorChange={handleColorChange12}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ColorPicker
                  initialColor={bColor}
                  label="Button Color"
                  onColorChange={handleColorChange13}
                />
                <ColorPicker
                  initialColor={hColor}
                  label="Header Color"
                  onColorChange={handleColorChange14}
                />
                <CustomTextField
                  id="nWidth"
                  label={t("Navigation Width")}
                  variant="filled"
                  margin="normal"
                  value={nWidth}
                  sx={{
                    width: { xs: "100%", sm: "100%%" },
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: "11px",
                    backgroundColor: theme.palette.custom.light,
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            <StyledButton
              sx={{
                backgroundColor: theme.palette.custom.borderColor,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.custom.borderColor,
                },
              }}
              onClick={() => {
                setOpenSaveThemeDialog(true);
              }}
            >
              {t("Save Theme")}
            </StyledButton>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
          style={{
            minHeight: "90vh",
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "block", sm: "center" },
              height: "64px",
            }}
          >
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "2.5rem",
                    mb: 0,
                    color: theme.palette.custom.text,
                  }}
                  gutterBottom
                >
                  {t("Shift Schedule")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchInput
              searchText={searchTextShift}
              handleSearch={handleSearchShift}
              autoFocus
              sx={{
                width: { xs: "100%", sm: "20%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <StyledButton onClick={handleShiftNavigate} sx={{ height: "36px" }}>
              {t("Add Shift")}
            </StyledButton>
          </Box>
          {loadingValueShift ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              //  size={'medium'}
              >
                <EnhancedTableHead
                  headCells={shiftHead}
                  order={orderShift}
                  orderBy={orderByShift}
                  onRequestSort={handleRequestSortShift}
                  hideCheck
                />

                <TableBody>
                  {shifts && shifts.length && visibleRowsShift.length ? (
                    visibleRowsShift.map((row, index) => {
                      return (
                        <ShiftsTableRow key={index} row={row}></ShiftsTableRow>
                      );
                    })
                  ) : (
                    <StyledTypography>{t("No entries found")}</StyledTypography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={shifts ? shifts.length : 0}
            rowsPerPage={rowsPerPage}
            page={pageShift}
            onPageChange={handleChangePageShift}
            onRowsPerPageChange={handleChangeRowsPerPageShift}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
          style={{
            minHeight: "90vh",
            height: "fit-content",
          }}
        >
          <Box sx={{ width: "100%", display: "block" }}>
            <TimeScheduler
              popShifts={true}
              style={{ width: "100%" }}
            ></TimeScheduler>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "block", sm: "center" },
              height: "64px",
            }}
          >
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "2.5rem",
                    mb: 0,
                    color: theme.palette.custom.text,
                  }}
                  gutterBottom
                >
                  {t("Exceptions")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />

          <SearchInput
            searchText={searchTextException}
            handleSearch={handleSearchException}
            autoFocus
            sx={{
              width: { xs: "100%", sm: "20%" },
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          {loadingValueException ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  headCells={exceptionHead}
                  order={orderExceptions}
                  orderBy={orderByExceptions}
                  onRequestSort={handleRequestSortException}
                  hideCheck
                />

                <TableBody>
                  {exceptions &&
                    exceptions.length > 0 &&
                    visibleRowsException.length ? (
                    visibleRowsException.map((row, index) => {
                      return (
                        <ExceptionsTableRow
                          key={index}
                          row={row}
                        ></ExceptionsTableRow>
                      );
                    })
                  ) : (
                    <StyledTypography>{t("No entries found")}</StyledTypography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={exceptions ? exceptions.length : 0}
            rowsPerPage={rowsPerPageException}
            page={pageException}
            onPageChange={handleChangePageException}
            onRowsPerPageChange={handleChangeRowsPerPageException}
          />
          <Box style={{ display: "block", width: "100%" }}>
            <StyledButton
              sx={{
                marginRight: "5px",
              }}
            >
              {t("Download Exceptions List")}(*.csv)
            </StyledButton>
            <StyledButton
              sx={{
                float: "left",
                marginRight: "5px",
              }}
            >
              {t("Download Exceptions List")}(*.png)
            </StyledButton>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Box
          sx={{
            p: "12px",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "flex" } }}>
            <Typography
              sx={{
                fontSize: "2.5rem",
                mb: 0,
                color: theme.palette.custom.text,
              }}
              gutterBottom
            >
              {t("TS, KPI's & OEE Graph")}
            </Typography>
          </Box>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />

          <ScheduledTimeGraphic />
        </Box>
      </CustomTabPanel>
      <AlertDialog
        open={openSaveThemeDialog}
        onClose={handleSaveThemeClose}
        onDoFunction={handleSaveTheme}
        saveLoading={saveThemeLoading}
        dialogTitle={t("Are you sure you want to save Theme?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Save")}
      />
    </Box>
  );
}

function Organization(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentOrganziation = useSelector(({ organziation }) => organziation);
  const [organziation, setOrganziation] = useState(
    props.new ? initOrganization : currentOrganziation
  );
  const [name, setName] = useState("");
  const [sName, setSName] = useState("");
  const [reg, setReg] = useState("");
  const [vat, setVat] = useState("");
  const [tax, setTax] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [timezone, setTimezone] = useState("");
  const [measurementSystem, setMeasurementSystem] = useState("");
  const [smallLogo, setSmallLogo] = useState(undefined);
  const [fullLogo, setFullLogo] = useState(undefined);
  const [orgBack, setOrgBack] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  // const [lang, setLang] = useState(undefined);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    setOrganziation(props.new ? initOrganization : currentOrganziation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  function getOrganizationAttributes(orgInfo) {
    OrganizationService.GetOperationProfileAttributes(orgInfo).then(
      (response) => {
        if (response.success && response.count > 0) {
          setTimezone(
            response?.profile_attribute?.find(
              (a) => a.channel === "timezone_default"
            )?.value
          );
          setMeasurementSystem(
            response?.profile_attribute?.find(
              (a) => a.channel === "measure_default"
            )?.value
          );
          // updatedOrganization = response.operation[0];
        }
      }
    );
  }
  function handleClose() {
    setOpenDialog(false);
  }
  function saveOrganizationAttributes(organizationUid) {
    const attributes = [
      {
        channel: "timezone_default",
        status: "active",
        value: timezone,
      },
      {
        channel: "measure_default",
        status: "active",
        value: measurementSystem,
      },
    ];
    OrganizationService.SetOperationProfileAttributes({
      attributes,
      uid: organizationUid,
      apiKey: currentOrganziation?.api_key_list?.[0]?.key,
    });
  }
  useEffect(() => {
    if (organziation?.uid) {
      getOrganizationAttributes({
        uid: organziation.uid,
        apiKey: currentOrganziation?.api_key_list?.[0]?.key,
      });

      setName(organziation.full_name);
      setSName(organziation.short_name);
      setReg(organziation.registration_number);
      setVat(organziation.vat_number);
      setTax(organziation.tax_number);
      setMobile(organziation.tel_primary);
      setEmail(organziation.email_address);
      setWeb(organziation.website_address);
      // for (let i in Languages) {
      //     if (localStorage.getItem("user_language") === Languages[i].value) {
      //         setLang(Languages[i].value);
      //     }
      // }
      let Organization_Image_Array = GetOperationPicturesFromJSON(
        organziation.operation_image_list
      );
      setSmallLogo(Organization_Image_Array.small_logo);
      setFullLogo(Organization_Image_Array.full_logo);
      setOrgBack(Organization_Image_Array.web_dashboard_background);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  function canBeSubmitted() {
    return (
      name &&
      web &&
      sName &&
      mobile &&
      email &&
      email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
    );
  }
  async function onSaveClick() {
    setSaveLoading(true);
    // const userObj = JSON.parse(localStorage.getItem('user'))
    // const organizationsArray = userObj.organizations
    if (props.new) {
      const newOrganization = await handleSaveNew(
        name,
        sName,
        vat,
        tax,
        reg,
        mobile,
        email,
        web,
        currentOrganziation?.api_key_list?.[0]?.key
      );
      // userObj.organizations = [...organizationsArray, newOrganization]
      if (newOrganization) {
        saveOrganizationAttributes(newOrganization.uid);
        navigate("/organizations");
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("Saved Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
      } else {
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("Error"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      }
    } else {
      const updatedOrganization = await handleSave(
        name,
        sName,
        vat,
        tax,
        reg,
        mobile,
        email,
        web
      );
      // userObj.organizations = organizationsArray.map(org => org.uuid === updatedOrganization.uuid ? updatedOrganization : org)
      if (updatedOrganization) {
        saveOrganizationAttributes(updatedOrganization.uid);
        navigate("/organizations");
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("Saved Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
      } else {
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("Error"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      }
    }
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
    >
      <Box>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <Typography
          sx={{
            fontSize: "2.5rem",
            mb: 0,
            color: theme.palette.custom.text,
          }}
          gutterBottom
        >
          {t("Organization")}:
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row" }}
          >
            <span style={{ margin: "15px" }}>
              <ProfilePictureSelect
                frmID={"iconFrm"}
                layID={"iconLay"}
                divID={"iconDiv"}
                fileID={"iconFile"}
                header={"Change Icon Image"}
                label={t("Icon Image")}
                url={
                  smallLogo || `${process.env.PUBLIC_URL}/image_placeholder.svg`
                }
              ></ProfilePictureSelect>
            </span>
            <span style={{ margin: "15px" }}>
              <ProfilePictureSelect
                frmID={"logoFrm"}
                layID={"logoLay"}
                divID={"logoDiv"}
                fileID={"logoFile"}
                header={"Change Logo Image"}
                label={t("Logo Image")}
                url={
                  fullLogo || `${process.env.PUBLIC_URL}/image_placeholder.svg`
                }
              ></ProfilePictureSelect>
            </span>
            <span style={{ margin: "15px" }}>
              <ProfilePictureSelect
                frmID={"bgFrm"}
                layID={"bgLay"}
                divID={"bgDiv"}
                fileID={"bgFile"}
                header={"Change Background Image"}
                label={t("Background Image")}
                url={
                  orgBack || `${process.env.PUBLIC_URL}/image_placeholder.svg`
                }
              ></ProfilePictureSelect>
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "stretch",
              width: "100%",
              gap: "3rem",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", sm: "flex" },
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <CustomTextField
                error={!name}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                id="txtName"
                label={t("Organization Name")}
                variant="filled"
              />
              <CustomTextField
                error={
                  !(
                    email &&
                    email.match(
                      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                    )
                  )
                }
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                id="txtEmail"
                label={t("Organization Email")}
                variant="filled"
              />
              <CustomTextField
                error={!web}
                value={web}
                onChange={(e) => {
                  setWeb(e.target.value);
                }}
                id="txtWeb"
                label={t("Website")}
                variant="filled"
              />
              <TimezonePicker
                title={t("Time Zone")}
                value={timezone}
                onChange={(value) => {
                  setTimezone(value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "flex" },
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <CustomTextField
                error={!sName}
                value={sName}
                onChange={(e) => {
                  setSName(e.target.value);
                }}
                id="txtShort"
                label={t("Organization Short Name")}
                variant="filled"
              />
              <CustomTextField
                error={!mobile}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                id="txtMobile"
                label={t("Mobile Number")}
                variant="filled"
              />
              <SelectFields
                title={t("Measurement System")}
                value={measurementSystem}
                fields={[
                  { value: "metric", label: "Metric (m,Kg)" },
                  { value: "imperial ", label: "Imperial  (ft,lbs)" },
                ]}
                onChange={(value) => {
                  setMeasurementSystem(value);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
            },
            marginTop: "1rem",
          }}
          disabled={!canBeSubmitted()}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          {t("Save")}
        </StyledButton>
      </Box>
      {!props.new && (
        <>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          <BasicTabs />
        </>
      )}
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={onSaveClick}
        saveLoading={saveLoading}
        dialogTitle={t("Are you sure you want to save?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Save")}
      />
    </Box>
  );
}

export default Organization;
