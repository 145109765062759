import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from '@mui/lab';


function AlertDialog(props) {
    const theme = useTheme();
    return (
        <Dialog
            open={Boolean(props.open)}
            onClose={props.onClose}
        >
            <DialogTitle sx={{ color: theme.palette.custom.text }}>{props.dialogTitle}</DialogTitle>
            <DialogActions>
                <Button
                    sx={{
                        color: theme.palette.custom.buttonTextColor,
                        backgroundColor: theme.palette.custom.borderColor,
                        '&:hover': {
                            backgroundColor: theme.palette.custom.buttonHoverColor,
                            color: theme.palette.custom.buttonHoverTextColor,
                            boxShadow: 'none',
                        }
                    }}
                    onClick={props.onClose}
                >
                    {props.cancelLabel}
                </Button>
                <LoadingButton
                    sx={{
                        mt: 1,
                        mb: 1,
                        color: theme.palette.custom.buttonTextColor,
                        backgroundColor: theme.palette.custom.buttonColor,
                        '&:hover': {
                            backgroundColor: theme.palette.custom.buttonHoverColor,
                            color: theme.palette.custom.buttonHoverTextColor,
                            boxShadow: 'none',
                        }
                    }}
                    loading={props.saveLoading}
                    loadingIndicator={<CircularProgress
                        sx={{
                            color: theme.palette.custom.buttonTextColor,
                            '&:hover': { color: theme.palette.custom.buttonHoverTextColor, }
                        }}
                        size={16} />}
                    onClick={props.onDoFunction} autoFocus
                >
                    {props.buttonLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;