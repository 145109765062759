import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import {useSelector} from "react-redux";
import {useState, useEffect, useMemo} from "react";
import ProjectService from "../api/project.js";
import {UpdateURLQueryStringWithoutReload} from "../utils/systemInfo";
import CommonDateTimePicker from "../common/commonDateTimePicker.js";
import Breadcrumbs from "../common/breadcrumbs";
import {useLocation} from "react-router-dom";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "Machine Status", href: "machineStatus"},
    {id: 1, name: "Machine Status - Trend", href: "machineTrend"}
];

export default function MachineTrend() {
    const organziation = useSelector(({organziation}) => organziation);
    const theme = useTheme();
    const {t} = useTranslation();
    const [projectID, setProjectID] = useState("");
    const [apiKey, SetApiKey] = useState(undefined);
    const location = useLocation();

    useEffect(() => {
        const orgID = organziation?.uid;
        const apiKey = organziation?.api_key_list?.[0]?.key;
        SetApiKey(apiKey);

        if (!orgID || !apiKey) return;

        ProjectService.GetProjectProfile(orgID, "", "", "Machine Rollout", "active", apiKey).then((jsonProfile) => {
            if (jsonProfile) {
                const projectRolloutID = jsonProfile.project_profile.find((e) => e.name === "Machine Rollout")?.project_profile_id;
                if (projectRolloutID) {
                    setProjectID(projectRolloutID.toString());
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organziation]);

    useEffect(() => {
        if (!projectID || !apiKey) return;

        UpdateURLQueryStringWithoutReload("Dashboard", "MachineStatus");
        UpdateURLQueryStringWithoutReload("ms-id", projectID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectID, apiKey]);

    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}>
            {useMemo(
                () => (
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                ),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [location]
            )}
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text, width: "80%"}} variant="h2" gutterBottom>
                    {t("Machine")} {t("Status")} - {t("Trend")}:
                </Typography>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
            {organziation && organziation.uid && <CommonDateTimePicker />}
            </Box>
        </Box>
    );
}
