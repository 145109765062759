

import { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import StyledTypography from '../common/StyledTypography';

export default function DeviceEventsTableRow({ row, handleClick, isItemSelected }) {

    return (
        <Fragment>
            <TableRow
                hover
                onClick={(event) => handleClick(event, row.device_dst)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.device_dst}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
            >
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.event_type_descriptor.event_type}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.event_type_descriptor.name}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.event_type_descriptor.description}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.device_dst}</StyledTypography>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}