export const UserPermissionConstants = {
    BETA_ACCESS: 104,
    ALPHA_ACCESS: 105,

    VIEW_DEVICES: 53,
    VIEW_MY_DEVICES: 121,
    VIEW_MACHINES: 57,
    VIEW_MY_MACHINES: 122,
    VIEW_USERS: 7,
    VIEW_ORGANIZATIONS: 6,
    VIEW_DASHBOARDS: 58,
    VIEW_MAP: 59,
    VIEW_USER_TYPES: 16,
    VIEW_API_KEYS: 9,
    VIEW_API_ENDPOINTS: 10,
    VIEW_PINGER: 63,
    VIEW_PINGER_SITES: 64,
    VIEW_PINGER_SIMS: 65,
    VIEW_DEVICE_ERROR_CODES: 66,
    VIEW_TICKETS: 14,
    VIEW_TICKET_TEMPLATES: 17,
    VIEW_HELPDESK: 71,
    VIEW_LANGUAGE_TRANSLATIONS: 72,
    VIEW_THEMES: 75,
    VIEW_SERVER_HEALTH: 12,
    VIEW_USER_SIGNIN_LOGGER: 76,
    VIEW_DEVELOPER_PORTAL: 77,
    VIEW_GITEA_SERVERS: 78,
    VIEW_EMBARCADERO_APPWAVE: 79,
    VIEW_XNEELO_CPANEL: 80,
    VIEW_WORDPRESS_WEBSITES: 81,
    VIEW_SITEMAP: 82,
    VIEW_SIMAC_HISTORY: 83,
    VIEW_USER_PERMISSIONS: 4,
    VIEW_HUB_SERVER_INFO: 85,
    VIEW_GRAFANA: 95,
    VIEW_DEVICE_MAINTENANCE_API: 96,
    VIEW_DEVICE_MAINTENANCE: 41,
    VIEW_DEVICES_API: 102,
    VIEW_USERS_API: 103,
    VIEW_AUDIT_TRAILS: 104,
    VIEW_UPLOAD_FILES: 107,
    VIEW_NAMED_LOCATION_CONFIG: 109,

    CREATE_USERS: 44,
    CREATE_ORGANIZATIONS: 49,
    CREATE_TICKETS: 13,
    CREATE_TICKET_TEMPLATES: 69,
    CREATE_LANGUAGE_TRANSLATIONS: 74,
    CREATE_HUB_SERVER_INFO: 87,
    CREATE_USER_PERMISSIONS: 94,
    CREATE_DEVICE_MAINTENANCE_API: 98,
    CREATE_DEVICE_MAINTENANCE: 99,
    CREATE_NAMED_LOCATION_CONFIG: 111,

    EDIT_USERS: 43,
    EDIT_ORGANIZATIONS_ALL: 88,
    EDIT_ORGANIZATIONS_OWN: 89,
    EDIT_USER_TYPES: 60,
    EDIT_DEVICES: 52,
    EDIT_MACHINES: 90,
    EDIT_API_KEYS: 61,
    EDIT_API_ENDPOINTS: 62,
    EDIT_DEVICE_ERROR_CODES: 67,
    EDIT_TICKETS: 70,
    EDIT_TICKET_TEMPLATES: 68,
    EDIT_LANGUAGE_TRANSLATIONS: 73,
    EDIT_THEMES: 91,
    EDIT_HUB_SERVER_INFO: 86,
    EDIT_USER_PERMISSIONS: 93,
    EDIT_DEVICE_MAINTENANCE_API: 97,
    EDIT_DEVICE_MAINTENANCE: 100,
    EDIT_NAMED_LOCATION_CONFIG: 110,

    DELETE_USERS: 45,
    DELETE_DEVICE_MAINTENANCE: 101,

    ASSIGN_DEVICE_PROFILES_TO_ORGANIZATION: 42,
    ASSIGN_USER_PROFILES_TO_ORGANIZATION: 26,

    
    UPLOAD_FILES: 108
}
 