import * as React from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        "id": `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const CustomTabs = styled(Tabs)(({theme}) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.custom.light,
    },
  }));
  
  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.custom.light,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: theme.palette.custom.light,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));

function BasicTabs() {
    const {t} = useTranslation();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box sx={{borderBottom: 1, borderColor: "divider", display: "flex", flexDirection: "row"}}>
                <CustomTabs sx={{display: {xs: "block", sm: "flex"}}} value={value} onChange={handleChange} aria-label="basic tabs example" orientation="vertical">
                    <CustomTab sx={{color: `${theme.palette.custom.text}`, alignItems: "baseline"}} label={t("Productivity")} {...a11yProps(0)} />
                    <CustomTab sx={{color: `${theme.palette.custom.text}`, alignItems: "baseline"}} label={t("Safety")} {...a11yProps(1)} />
                    <CustomTab sx={{color: `${theme.palette.custom.text}`, alignItems: "baseline"}} label={t("Maintenance")} {...a11yProps(2)} />
                    <CustomTab sx={{color: `${theme.palette.custom.text}`, alignItems: "baseline"}} label={t("Monitoring")} {...a11yProps(3)} />
                    <CustomTab sx={{color: `${theme.palette.custom.text}`, alignItems: "baseline"}} label={t("Communication")} {...a11yProps(4)} />
                </CustomTabs>
                <CustomTabPanel value={value} index={0} style={{width: "100%", marginLeft: "auto"}}>
                    <Box sx={{display: {xs: "block", sm: "flex"}}}>
                        <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                        {t("PRODUCTIVITY")}:
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                        <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            <ul>
                                <li>{t("Tonnage Reporting")}</li>
                                <li>{t("Production/Trip Count Monitoring")}</li>
                                <li>{t("Movement Reporting")}</li>
                                <li>{t("Production Reporting")}</li>
                                <li>{t("Digital Operator Checklists")}</li>
                                <li>{t("Dispatch Messaging")}</li>
                                <li>{t("Runtime/Utilization")}</li>
                            </ul>

                            <p>
                            {t("The successful utilisation of resources is how efficient production is measured.")}
                            {t("The neroHUB can be fine-tuned and customised to ensure that all processes are being streamlined and running optimally.")}
                            {t("Digital technologies are leveraged, thereby achieving an environment free from danger to personnel whilst maintaining optimal daily production quotas.")}
                            {t("Digital Operator checklists are accessible to personnel, improving operational efficiency.")}
                            {t("During automatic scooping, the calculation and interpretation of the load in the machine's bucket is an essential analytic that can be used to calculate Tonnage Reporting.")}
                            {t("Production Management can be reviewed by pulling up Movement Reports and Trip Count Monitoring statistics, which indicates the runtime and utilisation of Trackless Mining Machines (TMMs) and vehicles on the site itself.")}
                            {t("Dispatch Messaging will enable personnel to promptly receive messages and notifications, mitigating communication delays that could slow down the workflow.")}
                            </p>
                        </Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} style={{width: "100%", marginLeft: "auto"}}>
                    <Box sx={{display: {xs: "block", sm: "flex"}}}>
                        <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                            {t("SAFETY")}:
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                        <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            <p>{t("Level 9 Safety Compliance")}:</p>

                            <p>
                            {t("The Proximity Detection Interface (PDI), also known as a Vehicle Interface Controller (VIC), is developed to slow down and/or stop the machinery if it receives a Slow-Down (CRAWL) or Brake (STOP) signal from the connected Proximity Detection System (PDS) that detects personnel or objects possessing a tag within a predefined range.")}
                            {t("These collision avoidance systems (CAS) will dictate to the operator of diesel and electric Trackless Mining Machines (TMMs) that they should slow down and even stop in specific environments when the system detects a potential threat.")}
                            {t("This is described by the EMESRT Levels 7 & 8 to significantly decrease the number of incidents involving other machines and personnel, preventing injury (which are often fatalities), the need for unnecessary repairs to machinery, and the costs related to avoidable accidents.")}
                            {t("The neroHUB is level 9 safety compliant, meaning it automatically acts upon those signals / commands / instructions that are received from the Proximity Detection System, when it detects personnel or a machine, as it has intervention controls that will slow down and/or stop the vehicle and can prevent a vehicle from rollback on an incline.")} 
                            </p>
                        </Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} style={{width: "100%", marginLeft: "auto"}}>
                    <Box sx={{display: {xs: "block", sm: "flex"}}}>
                        <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                        {t("MAINTENANCE")}:
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                        <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            <p>{t("Includes some of the following features")}:</p>

                            <ul>
                                <li>{t("Operational Summaries")}</li>
                                <li>{t("Vehicle Speed and RPM")}</li>
                                <li>{t("Engine Temperatures")}</li>
                                <li>{t("Pressures")}</li>
                                <li>{t("Top Offending Machines")}</li>
                                <li>{t("Top Performing Machines")}</li>
                            </ul>
                        </Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3} style={{width: "100%", marginLeft: "auto"}}>
                    <Box sx={{display: {xs: "block", sm: "flex"}}}>
                        <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                        {t("MONITORING")}:
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                        <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            <ul>
                                <li>{t("Black Box Recorder")}</li>
                                <li>{t("Brake Wear Monitoring")}</li>
                                <li>{t("Tyre Pressure Monitoring")}</li>
                                <li>{t("Engine Monitoring & Protection")}</li>
                                <li>{t("Transmission Monitoring & Protection")}</li>
                                <li>{t("Operational Exposure Monitoring")}</li>
                                <li>{t("Temperature Monitoring")}</li>
                                <li>{t("Fluid Level Monitoring")}</li>
                                <li>{t("Pressure Monitoring")}</li>
                                <li>{t("Flow Rate Monitoring")}</li>
                            </ul>
                            <p>{t("Hazardous and harsh mining environments cannot stand in the way of the neroHUB fulfilling its many vital functions.")}
                            {t("This type of black box recorder is encased in an IP67 enclosure that enables it to securely capture and monitor data and events.")}
                            {t("All digital/analogue variables are measured in real-time and actively managed, by when and at what rate variables should be logged, based on how the measured variables are changing.")}
                            {t("This contributes to implementing an effective Collision Warning and Prevention System with consistent performance and reliability throughout your fleet.")}
                            {t("The neroHUB mitigates failures by monitoring essential parameters such as Brake Wear, Tyre Pressure, Engine-, Transmission-, Fluid Level, Pressure, Flow Rate, and Temperature.")}
                            {t("The Engine and Transmission are protected by this monitoring, minimising the risk of damage to these vital parts, and preventing mishaps from occurring due to these parts wearing, malfunctioning, or being put under excessive stress.")}
                            {t("The tracking of these parts will also ensure the longevity of these expensive parts, negating unnecessary expenditure.")}
                                     
                            </p>
                        </Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4} style={{width: "100%", marginLeft: "auto"}}>
                    <Box sx={{display: {xs: "block", sm: "flex"}}}>
                        <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                        {t("COMMUNICATION")}:
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                        <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            <ul>
                                <li>{t("Vehicle Area Network (VAN)")}</li>
                                <li>{t("Wireless Communication Router")}</li>
                            </ul>
                            <p>
                            {t("Another function of the neroHUB is to serve as a wireless communication router, facilitating several different modes and methods of connection, such as being a configurable Wi-Fi Access Point (AP) or Client Promises Equipment (CPE) device, BLE (Bluetooth Low Energy), UHF or VHF, GSM, LoRa (Private Network), Serial RS232, Serial RS485, and CAN Bus (open CAN/SAE J1939/ISO 21815).")}
                            {t("The neroHUB acts as a master radio for communication with distributed wireless sensors located throughout the Trackless Mobile Machine (TMM), allowing communication with nearby TMM vehicles, enabling the exchange of machine movement information with one another.")}
                            {t("The connectivity is provisioned between the machine and the underground mining communication infrastructure and machine-to-machine (or Vehicle to Vehicle) connectivity.")}
                            {t("The neroHUB is unique in that it has specialised high-level protocols that allow data to transact opportunistically and continuously to suit all possible underground data networking models.")}
                            {t("Note: CAN Bus supersedes Vehicle Area Network (VAN) – so having a CAN Bus is better than just having the VAN.")}                                
                            </p>
                        </Typography>
                    </Box>
                </CustomTabPanel>
            </Box>
        </Box>
    );
}

function About() {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        // <Layout>
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
                backgroundColor: theme.palette.custom.backgroundColor
            }}>
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("About")}:
                </Typography>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                <Typography sx={{fontSize: "32px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("What is SIMAC?")}
                </Typography>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex"}, marginBottom: "12px"}}>
                <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("SIMAC (Short Interval Monitoring and Control) Software System is a state-of-the-art software package that allows operators and managers alike to access in-depth overviews and insights via dashboards that are expertly tailored to a specific mine.")}
                {t("Managers and overseers can control what viewing privileges other staff members have regarding the dashboard, and they can customise their own dashboard to maximise the impact of the data and statistics they are surveying.")}
                {t("The customisation of this pivotal information will empower mines as no other tech has before; with the SIMAC AI, the software will deliver proactive insights, allowing mines to mitigate machine maintenance and preventing scenarios that can stagger or halt production.")}
                </Typography>
            </Box>
            <Box
                sx={{
                    p: "12px",
                    borderRadius: "16px",
                    border: `1px solid ${theme.palette.custom.borderColor}`,
                    marginBottom: "12px"
                }}>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "32px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("Dashboards")}   
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("By harnessing the innovative SIMAC software, managers can compare machines with one another, highlighting which machines are the most productive and which machine / operator combinations are the most optimal for productivity.")}
                    {t("The dashboard overview highlights key metrics that allow managers to easily identify negative indicators and quickly react and remedy.")}
                    {t("The SIMAC Dashboard provides valuable information concerning")}:
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex"}, flexDirection: "row", marginBottom: "12px"}}>
                    <Box
                        sx={{
                            p: "12px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.custom.borderColor}`,
                            marginRight: "12px"
                        }}
                        style={{
                            width: "20%"
                        }}>
                        <Box sx={{display: {xs: "block", sm: "flex"}}}>
                            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {t("Productivity")}    
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: "12px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.custom.borderColor}`,
                            marginRight: "12px"
                        }}
                        style={{
                            width: "20%"
                        }}>
                        <Box sx={{display: {xs: "block", sm: "flex"}}}>
                            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {t("Safety")}    
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: "12px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.custom.borderColor}`,
                            marginRight: "12px"
                        }}
                        style={{
                            width: "20%"
                        }}>
                        <Box sx={{display: {xs: "block", sm: "flex"}}}>
                            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {t("Maintenance")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: "12px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.custom.borderColor}`,
                            marginRight: "12px"
                        }}
                        style={{
                            width: "20%"
                        }}>
                        <Box sx={{display: {xs: "block", sm: "flex"}}}>
                            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {t("Monitoring")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: "12px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.custom.borderColor}`
                        }}
                        style={{
                            width: "20%"
                        }}>
                        <Box sx={{display: {xs: "block", sm: "flex"}}}>
                            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {t("Communication")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <BasicTabs></BasicTabs>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("SIMAC is a state-of-the-art software that allows operators and managers alike to access in-depth overviews and insights via dashboards that cater to their specific mine.")}
                {t("Managers and overseers can control what viewing privileges other staff members have regarding the dashboard, and they can customise their dashboard to maximise the impact of the data and statistics they are surveying.")}
                {t("The customisation of this pivotal information will empower mines as no other tech has before; with the SIMAC Augmented Intelligence (IA), the software will deliver proactive insights, allowing mines to mitigate machine maintenance and prevent scenarios that can stagger or halt production.")}
                {t("The SIMAC dashboards can provide invaluable information concerning Safety, Productivity, Maintenance, and Monitoring.")}
                {t("By harnessing the innovative SIMAC software, managers can see which machines are the most productive by monitoring the average tonnage being hauled, which operators use which machines, what changes have been made to the machine, and who made these specific changes.")}
                {t("Short Interval Control is integrated into the SIMAC IA, allowing managers and operators to view machine statistics in real-time.")}
                {t("Should the IA predict that the machine could potentially have issues in the future, an alert will notify the user viewing the particular machine.")}
                {t("Production will remain uninterrupted, allowing it to run smoothly while the machine is still in working condition while new parts are on their way, minimising maintenance times.")}
                {t("The following will be available via the SIMAC dashboards")}:
                </Typography>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <ul style={{color: theme.palette.custom.text, fontSize: "14.4px"}}>
                    <li>{t("Big Data - for in-depth investigation and post-analytic trending")} </li>
                    <li>{t("Little Data - for on the fly, daily information")} </li>
                    <li>{t("Remote visualisation of machine metrics in real-time")} </li>
                    <li>{t("Graphs and push notifications tracking mining conditions")} </li>
                    <li>{t("Custom Dashboards provide a general overview of machine vs machine metrics and overall stats")}</li>
                    <li>{t("View statistics for different groups of machines, comparing how they size up to one another")} </li>
                    <li>{t("24/7 monitoring of production, enabling managers to leverage these learnings to boost productivity and safety")} </li>
                    <li>{t("General overview of Safety stats -see which machines perform best in this category, quick insights into total SBI Events, CWS Events, CRAWL Events and STOP Events")} </li>
                    <li>{t("Productivity Insights by utilising operator comparisons and keeping track of the average Running Time, Tramming Time, Down Time and Idle Time, and also allows for the examination of the tonnage per haul")} </li>
                    <li>{t("Maintenance metrics at a glance - prevent maintenance from impacting productivity by keeping tabs on the average Breakdowns, Hard Break Instances, Brake Wear (additional sensors needed for this functionality) and RPM")} </li>
                    <li>{t("Under each interactive tab in the dashboard, view the top offending machines that are negatively impacting the curve.")} </li>
                </ul>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("The SIMAC software aims to augment and enhance the connected machine, as well as the human operator/manager.")}
                {t("By collecting, processing, and analyzing all of the metrics collected by the many sensors of the connected machines, detailed reports and data analysis can be done for the user.")}
                {t("The application of intelligence amplification in the SIMAC software allows for monitoring certain parts of equipment on the connected machine, collecting real-time data, and analyzing previous records, enabling the software to predict possible breakdowns or failures.")}
                {t("This means that operators and managers will be notified before the equipment fails, making the working environment safer and keeping production uninterrupted.")}
                {t("Therefore, the data analysis of the SIMAC software allows the software to make preventative interventions, negating downtime, and production delays, ensuring that the bottom line remains unscathed.")}
                {t("In tandem with the SIMAC software, the neroHUB Proximity Detection Interface (PDI) enables the connected machines to STOP and CRAWL in relevant situations, contributing a positive net worth to the mine's productivity.")}
                {t("The neroHUB PDI also introduces Speed Brake Interlock (SBI) and Speed Limiting Interlock (SLI), which helps the system reduce the number of unknown variables that the third-party Proximity Detection System (PDS) needs to accommodate for, reducing the number of interruptions to the system.")}
                </Typography>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("SHORT INTERVAL CONTROL")}
                </Typography>
            </Box>
            <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                {t("SIMAC provides critical operational insights that can be constantly optimised to increase productivity, unlocking the measurable benefits of Short Interval Control.")}
                {t("With Short Interval Control integrated into the SIMAC AI, managers and operators are able to view machine statistics in real-time.")}
                {t("Should the AI predict that the machine could potentially have issues in the future, an alert will notify the user, leading to an early intervention that ensures production remains uninterrupted while new parts are on their way, minimising maintenance times.")}

                </Typography>
            </Box>
            <Box
                sx={{
                    p: "12px",
                    borderRadius: "16px",
                    border: `1px solid ${theme.palette.custom.borderColor}`
                }}>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text, width: "100%", backgroundColor: theme.palette.custom.backgroundColor, borderRadius: "5px"}} variant="h2" gutterBottom>
                    {t("Introduction to SBI: (what is it and why is it important)")}
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("The neroHUB Speed Brake Interlock (SBI) monitors, controls, and maintains the speed of Trackless Mining Machines (TMMs) at all times.")}
                    {t("This reduces the possibility of losing control or a machine running away on a decline.")}
                    {t("The SBI allows for environmental speed limit adjustments based on the terrain on which the Trackless Mining Machine (TMM) will operate.")}
                    {t("Parameters can be configured for custom terrain types, such as the speed limit, throttle inhibit time, slow down timeout, runaway speed, machine angle (which is calibrated upon commissioning), and time elapsed at a specific angle.")}
                    {t("The user can also configure when the Incline and Decline states are initiated.")}
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("How does SBI work")}:
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("Slow down speed is the first configured threshold that causes the unit to trigger the slowdown state.")}
                    {t("This state will induce an audible alert, a visual alarm, a proportional crawl, or neroCRAWL.")}
                    {t("Once a specific speed limit has been exceeded (as per the mine's specifications), a timer will start counting down, indicating to the operator that the machine has to be slowed down, and maintain a speed below the speed threshold for a minimum period (usually about 1 second) before the countdown ends.")}
                    {t("The operator needs to take immediate action to ensure the machine reaches and maintains a speed below the speed indicated as the slow down speed.")}
                    {t("If the required speed has been maintained for this minimum period of time, the machine may proceed as usual. Otherwise, the machine will enter into the 'Overspeed Stop' warning screen, meaning that the speed limit has been exceeded and that the timer from the 'Slow Down Warning' screen has run out, and the vehicle will automatically stop.")}
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("The second configured threshold is over speed, configured to a higher speed than the slowdown speed (usually a few km/h more than the slowdown speed limit).")}
                    {t("The user can configure the speed thresholds related to different pitch states, such as Level, Decline and Incline.")}
                    {t("When the 'Slow down speed' is exceeded, the crawl state of the machine shall be enabled for at least the throttle inhibit time.")}
                    {t("If the machine does not return below the 'Slow down speed' within the 'Slow down time out', the stop state will be enabled.")}
                    {t("If the 'Runaway speed' is ever exceeded, the stop state will be enabled. The machine will stop until the stop state can be cleared, and the machine can continue.")}
                    {t("When the over speed threshold is reached, the machine will automatically lockdown. There are two methods to release the machine: a time out sequence or a user-configurable unlock procedure (where the foreman will have to come and manually clear the latched state on the machine to be unlocked).")}
                    {t("Use Cases (examples of how SBI can be utilised)")}
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("SBI shines exceptionally bright when it comes to preventing operators from exceeding speed limits and is an extra safety net when it comes to slamming the brakes on machines that have reached runaway conditions.")}
                    {t("Using the SBI to inhibit the speed that machines can travel at, the operators will be trained and assisted in maintaining the speed limits as defined by the mine, which will help improve mine safety by preventing injuries and damage to equipment/other mine machines.")}
                    {t("Another instance where the SBI will prove its power is by preventing runaway machines and vehicles from causing havoc in their wake. Any machine with an SBI unit can have a runaway speed configured, and once this threshold is exceeded, the machine will lock its brakes to bring itself to a standstill.")}
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "block", sm: "flex", marginBottom: "12px"}}}>
                    <Typography sx={{fontSize: "20px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("Benefits of SBI")}:
                    </Typography>
                </Box>

                <Box sx={{display: {xs: "block", sm: "flex"}}}>
                    <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("The benefits of having an SBI unit on your machines include, but are not limited to the following")}:
                        <ul>
                            <li>{t("Improve operator driving by controlling the speed they can drive at")} </li>
                            <li>{t("Prevent injuries and damages due to operators speeding")} </li>
                            <li>{t("Prevent runaway vehicles from injuring or damaging equipment and other machines")} </li>
                            <li>{t("Promote safer working conditions and cultivate safety-conscious operators")} </li>
                            <li>{t("By controlling the speed, the operators can drive at, save money on resources such as fuel, and lower maintenance cost and time")} </li>
                        </ul>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default About;
