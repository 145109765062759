import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
// import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { config } from "../config";
import { setUser } from "../store/userSlice";
import { setOrganization } from "../store/organziationSlice";
import { setPermissions } from "../store/permissionsSlice";
import authService from "../utils/authService";
import personService from "../api/person";
import CustomTextField from "../common/CustomTextField";
import { showMessage } from "../store/messageSlice";
import MessageSender from "../common/message/messageSender";

const gridContainer = {
  display: "grid",
  flexFlow: "row",
  gridTemplateRows: "repeat(4, 1fr)",
};
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    width: "160px",
    height: "36px",
    minHeight: "36px",
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "11px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
      backgroundColor: theme.palette.secondary.main,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  })
);
const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);
// const StyledTextField = styled((props) => <TextField {...props} />)(
//   ({ theme }) => ({
//     backgroundColor: theme.palette.custom.text,
//     borderRadius: "11px",
//   })
// );
function LoginPage({ history }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const [requestOrVerify, setRequestOrVerify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [signUpComment, setSignUpComment] = useState(null);
  const [customColor, setCustomColor] = useState(null);
  const [signInLoading, setSignInLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [signInMissing, setSignInMissing] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSubmit = (event) => {
    setSignInLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("password") !== "" && data.get("email") !== "") {
      setSignInMissing(false);
      authService
        .signInWithEmailAndPassword(data.get("email"), data.get("password"))
        .then((res) => {
          dispatch(setUser(res));
          if (res?.organizations?.length) {
            try {
              const currentOrganization = JSON.parse(
                localStorage.getItem("defaultOrganization")
              );
              if (!currentOrganization.uid) {
                throw new Error("no cached organization");
              }
            } catch (e) {
              const nerospecOrganization = res.organizations.find((org) =>
                org?.operation_profile_info?.full_name
                  ?.toLowerCase()
                  .includes("nerospec")
              )?.operation_profile_info;
              if (nerospecOrganization) {
                localStorage.setItem(
                  "defaultOrganization",
                  JSON.stringify(nerospecOrganization)
                );
                const apiKey = nerospecOrganization.api_key_list?.[0]?.key;
                localStorage.setItem("apiKey", apiKey);
              } else {
                localStorage.setItem(
                  "defaultOrganization",
                  JSON.stringify(res.organizations[0]?.operation_profile_info)
                );
                const apiKey = res.organizations[0]?.api_key_list?.[0]?.key;
                localStorage.setItem("apiKey", apiKey);
              }
            }
            dispatch(
              setOrganization(res.organizations[0]?.operation_profile_info)
            );
          }
          personService
            .getUserTypePermissions(res)
            .then((json) => {
              const UserPermissions = [];
              if (
                json &&
                json.data &&
                json.data.app_user_type_permission_link &&
                json.data.app_user_type_permission_link.length > 0
              ) {
                for (let i in json.data.app_user_type_permission_link)
                  UserPermissions.push(
                    json.data.app_user_type_permission_link[i]
                      .permission_secondary.permission_secondary_uid
                  );
                localStorage.setItem(
                  "permissions",
                  JSON.stringify(UserPermissions)
                );
                dispatch(setPermissions(UserPermissions));
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          setSignInLoading(false);
          const from =
            location.state?.from?.pathname + location.state?.from?.search ||
            "/";
          navigate(from, { replace: true });
        })
        .catch((_errors) => {
          dispatch(
            showMessage({
              message: t("Invalid login information provided"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "error", //success error info warning null
            })
          );
          setSignInLoading(false);
          if (process.env.NODE_ENV === "development")
            console.log("error register ", _errors);
        });
    } else {
      setSignInLoading(false);
      setSignInMissing(true);
    }
  };
  const handleForgetPassword = (event) => {
    window.alert("Contact nerospec for assistance");
  };
  const handleSubmitRegister = (event) => {
    setSignUpLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("otp") === null && data.get("password") === null) {
      const body = JSON.stringify({
        auth: {
          value: data.get("email"),
        },
      });
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/person/auth/otp/request`,
          body,
          headers
        )

        .then((json) => {
          if (json.data && json.data.success === true && json.data.count > 0) {
            setCustomColor(theme.palette.success.main);
            setSignUpComment("OTPSent");
            setRequestOrVerify(true);
            // AuthCallbackFunction("OTP Sent");
          } else {
            setCustomColor(null);
            setSignUpComment(json.data.msg);
            // AuthCallbackFunction(json.msg);
          }

          setSignUpLoading(false);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          setSignUpLoading(false);
        });
    } else if (data.get("otp") !== null && data.get("password") === null) {
      const body = JSON.stringify({
        auth: {
          contact: data.get("email"),
          otp: data.get("otp"),
        },
      });
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/person/auth/otp/verify`,
          body,
          headers
        )

        .then((json) => {
          if (json.data && json.data.success === true) {
            setCustomColor(theme.palette.success.main);
            setSignUpComment("OTPVerified");
            setRequestOrVerify(false);
            setShowPassword(true);
            //  AuthCallbackFunction("OTP Verified");
          } else {
            setCustomColor(null);
            setSignUpComment(json.data.msg);
            //  AuthCallbackFunction(json.msg);
          }

          setSignUpLoading(false);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          setSignUpLoading(false);
        });
    } else {
      if (data.get("password").length > 7) {
        personService
          .UserAuthValidate({ email: data.get("email") })
          .then((json) => {
            if (json.data && json.data.success === true) {
              const Person_Profile_ID = String(
                json.data.person_auth_validate_contact[0].person_profile_info
                  .profile_uid
              );
              personService
                .SetUserpassword({
                  id: Person_Profile_ID,
                  password: data.get("password"),
                })
                .then((passwordResult) => {
                  if (passwordResult.data && json.data.success === true) {
                    setCustomColor(theme.palette.success.main);
                    setSignUpComment("PasswordChanged");
                    setSignUpLoading(false);
                  } else {
                    setCustomColor(theme.palette.error.main);
                    setSignUpComment(passwordResult.data.msg);
                    setSignUpLoading(false);
                  }
                })
                .catch((_errors) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("_errors ", _errors);
                });
            } else if (json.data) {
              setCustomColor(null);
              setSignUpComment(json.data.msg);
              setSignUpLoading(false);
            }
          })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("errorr ", errorr);
            setSignUpLoading(false);
          });
      } else {
        setIsError(true);
        setErrorText("Password too short");
        setSignUpLoading(false);
      }
    }
  };
  return (
    <Box
      sx={{
        [theme.breakpoints.down("sm")]: {
          padding: "1rem 0 0 2.25rem ",
        },
        [theme.breakpoints.down("md")]: {
          padding: "1rem 0 0 4.25rem ",
        },
        padding: "2rem 0 0 7rem ",
        ...gridContainer,
      }}
      style={{
        backgroundImage: "url(background_simac.png)",
        backgroundSize: "cover",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div style={{ height: "max-content" }}>
        <img
          width="200px;"
          src={process.env.PUBLIC_URL + "/logo_nerospec_core.png"}
          alt=""
        />
      </div>
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            display: "grid",
            flexFlow: "column",
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              width: "700px",
              display: { xs: "none", sm: "block" },
              margin: "5em auto",
            },
            width: "700px",
            margin: "10em auto",
          }}
        >
          <img
            width="100%"
            src={process.env.PUBLIC_URL + "/header_image_simac.png"}
            alt="logo"
          />
        </Box>
        <Box
          sx={{
            typography: "body1",
            backgroundColor: theme.palette.secondary.main,
            width: "320px",
            margin: "4em auto",
            boxShadow: "0px 1px 8px #2b2f38",
            borderRadius: "11px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.custom.text },
            }}
            value={tabValue}
            onChange={handleTabChange}
            textColor="secondary"
          >
            <StyledTab label={t("Login")} />
            <StyledTab label={t("Register")} />
          </Tabs>
          <div>
            {tabValue === 0 && (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ m: 2, pb: 1, textAlign: "center" }}
              >
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={t("EmailOrNumber")}
                  name="email"
                  id="email"
                  autoComplete="email"
                  autoFocus
                  variant="filled"
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.custom.inputBackgroundColor,
                    color: theme.palette.custom.inputTextColor,
                  }}
                />
                {/* <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("EmailOrNumber")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                /> */}
                <CustomTextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  autoFocus
                  variant="filled"
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.custom.inputBackgroundColor,
                    color: theme.palette.custom.inputTextColor,
                  }}
                />
                {/* <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                /> */}
                {signInMissing && (
                  <Typography
                    sx={{
                      mt: 1,
                      color: customColor
                        ? customColor
                        : theme.palette.custom.light,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    {t("signUpMissing")}
                  </Typography>
                )}
                <StyledLoadingButton
                  loading={signInLoading}
                  loadingIndicator={
                    <CircularProgress color="primary" size={16} />
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                >
                  {t("Login")}
                </StyledLoadingButton>
                <Link
                  underline="none"
                  sx={{ color: theme.palette.custom.light }}
                  href="#"
                  onClick={handleForgetPassword}
                  variant="body2"
                >
                  {t("ForgotPassword")}
                </Link>
              </Box>
            )}
            {tabValue === 1 && (
              <Box
                component="form"
                onSubmit={handleSubmitRegister}
                noValidate
                sx={{ m: 2, pb: 1, textAlign: "center" }}
              >
                <CustomTextField
                  margin="normal"
                  fullWidth
                  label={t("EmailOrNumber")}
                  name="email"
                  id="email"
                  autoComplete="email"
                  autoFocus
                  variant="filled"
                  sx={{
                    width: "100%",
                  }}
                  InputLabelProps={{
                    style: { color: theme.palette.custom.text },
                  }}
                />
                {requestOrVerify && (
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    name="otp"
                    label={t("OTP")}
                    type="number"
                    id="otp"
                    autoFocus
                    variant="filled"
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      style: { color: theme.palette.custom.text },
                    }}
                  />
                  //   <StyledTextField
                  //     margin="normal"
                  //     required
                  //     fullWidth
                  //     name="otp"
                  //     label={t("OTP")}
                  //     type="number"
                  //     id="otp"
                  //   />
                )}
                {showPassword && (
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label={t("Password")}
                    type="password"
                    id="password"
                    autoFocus
                    variant="filled"
                    error={isError}
                    helperText={errorText}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      style: { color: theme.palette.custom.text },
                    }}
                  />
                  //   <StyledTextField
                  //     margin="normal"
                  //     required
                  //     fullWidth
                  //     name="password"
                  //     label={t("Password")}
                  //     type="password"
                  //     id="password"
                  //     autoComplete="current-password"
                  //     error={isError}
                  //     helperText={errorText}
                  //   />
                )}
                <Typography
                  sx={{
                    mt: 1,
                    color: customColor
                      ? customColor
                      : theme.palette.custom.light,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  {t(signUpComment)}
                </Typography>
                <LoadingButton
                  loading={signUpLoading}
                  loadingIndicator={
                    <CircularProgress color="primary" size={16} />
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                >
                  {!requestOrVerify && !showPassword
                    ? t("RequestOtp")
                    : requestOrVerify
                    ? t("VerifyOtp")
                    : t("SignUp")}
                </LoadingButton>
                <Typography
                  sx={{ mt: 1, mb: 1, color: theme.palette.custom.light }}
                  variant="body2"
                  gutterBottom
                >
                  {t("BySigningUp")}
                </Typography>
              </Box>
            )}
          </div>
        </Box>
      </Box>
      <MessageSender />
    </Box>
  );
}

export default LoginPage;
