/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setMachineCommentsData = createAsyncThunk("machineComments/setMachineCommentsData", async (inputData) => {
    let machineComments = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    machineComments = inputData.json
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && machineComments && machineComments.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return machineComments.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return machineComments;
});

export const setMachineCommentsDataDirectly = createAsyncThunk("machineComments/setMachineCommentsDataDirectly", async (inputData) => {
    
    return inputData;
});

const initialState = null;

const machineCommentsSlice = createSlice({
    name: "machineComments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setMachineCommentsData.fulfilled, (state, action) => action.payload);
        builder.addCase(setMachineCommentsDataDirectly.fulfilled, (state, action) => action.payload);
    }
});

export const selectMachineComments = ({machineComments}) => machineComments;

export default machineCommentsSlice.reducer;
