
import { config } from '../config'
import Utils from '../utils/utils'


class EventsService extends Utils.EventEmitter {
    GetDeviceOpsumEvents(inputData) {

        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                "get":{
                    "timeframe":{
                        "from": inputData.historyFromDST,
                        "to": inputData.historyToDST
                    },
                    "device":[{
                        "uid": inputData.deviceID
                    }],
                    "channel":["event"],
                    "method":["list"]
                }
            });
            const headers = {
                "Content-Type": 'application/json',
                "apiKey": inputData.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/device/opsum/query`, {
                method: 'post',
                headers,
                body
            }).then((response) => response.json())
                //axios
                // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

                .then(json => {
                    resolve(json);

                }).catch((err) => {
                    if (process.env.NODE_ENV === 'development') console.log('err ', err)
                    reject(err.response !== undefined ? err.response.data.error_description : null)
                });
        });
    }
}
const instance = new EventsService();

export default instance;