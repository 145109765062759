import { config } from "../config";
import Utils from "../utils/utils";

class DevicesService extends Utils.EventEmitter {
  getDevices(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDevicesCount(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          let Organization_Total_Machines = 0;
          if (json.success === true && json.count > 0)
            Organization_Total_Machines = json.count;
          resolve(Organization_Total_Machines);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetDeviceProfile(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            device_profile_id: inputData.device_profile_id,
            device_uid: inputData.device_uid,
            serial: inputData.serial,
            name: inputData.name,
            oem: inputData.oem,
            device_type: inputData.device_type,
            nerohub: inputData.nerohub,
            device_type_id: inputData.device_type_id,
            device_oem_operation_profile_uid:
              inputData.device_oem_operation_profile_uid,
            machine_type: inputData.machine_type,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceHistoryLatest(inputData) {
    if (inputData.channels.length === 0) {
      inputData.channels.push("battery_low", "value", "is_visible", "din0"); // Default Channels for nerospec devices
      inputData.channels.push(
        "total_out",
        "total_in",
        "temperature",
        "rssi",
        "period_out",
        "period_in",
        "battery_level"
      ); // Channels for nngen2_people_counter1
    }
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device: inputData.devices,
          channel: inputData.channels,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/iot/history/latest/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new DevicesService();

export default instance;
