import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../common/StyledTypography";
import AssignButton from "../common/assignButton";
import OrganizationService from "../api/organization";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showMessage } from "../store/messageSlice";
import AlertDialog from "../common/AlertDialog";

const CustomSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.custom.inputBackgroundColor,
  height: "36px",
  color: theme.palette.custom.text,
  outlineColor: theme.palette.custom.borderColor,
  "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.custom.borderColor,
    color: theme.palette.custom.borderColor,
  },
  "&.Mui-focused": {
    color: theme.palette.custom.text,
  },
  "&:focused": {
    color: theme.palette.custom.borderColor,
  },
  "& .MuiList-root": {
    backgroundColor: theme.palette.custom.inputBackgroundColor,
  },
}));
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.custom.text,
  "&.Mui-focused": {
    color: theme.palette.custom.text,
  },
  left: "7px",
  top: "-7px",
}));

export default function UserAssignTableRow({
  row,
  labelId,
  orgUid,
  handleChangeUserData,
  iKey,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const url = "../FakeUser.png";
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const theme = useTheme();
  let email = "none";
  let mobile = "none";
  for (let i in row.contact) {
    if (
      row.contact[i].contact_type === "email_address" &&
      row.contact[i].primary_contact === true
    ) {
      email = row.contact[i].value;
    } else if (
      row.contact[i].contact_type === "tel_mobile" &&
      row.contact[i].primary_contact === true
    ) {
      mobile = row.contact[i].value;
    }
  }

  function handleAssign(IsLink) {
    setSaveLoading(true);
    let assignToOrg = JSON.parse(localStorage.getItem("assignToOrg"));
    if (row.user_type_uid !== 0) {
      OrganizationService.SetOperationPersonLink(
        {
          operation_id: assignToOrg.uid,
          person_id: row.profile_id,
          user_type_uid: row.user_type_uid,
          auto_assign_ticket: "0",
          status: IsLink ? "link" : "unlink",
        },
        assignToOrg.apiKey
      ).then((json) => {
        if (json) {
          dispatch(
            showMessage({
              message: IsLink
                ? t("User Assigned Successfully")
                : t("User unassigned Successfully"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "success", //success error info warning null
            })
          );

          handleChangeUserData(row, IsLink, row.user_type_uid);
        }
        setSaveLoading(false);
        setOpenDialog(false);
      })
        .catch((_errors) => {
          setSaveLoading(false);
          setOpenDialog(false);
          dispatch(
            showMessage({
              message: t("error occurred"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "error", //success error info warning null
            })
          );
        });
    } else {
      window.alert("Select a user type first.");
      setSaveLoading(false);
      setOpenDialog(false);
    }
  }

  const handleChange = (event) => {
    let isLink =
      row.status === "active" || row.status === "link" ? true : false;
    handleChangeUserData(row, isLink, event.target.value);
  };

  function getButton() {
    const status = row.status;
    const name = row.firstname + " " + row.lastname;
    if (status === "link" || status === "active") {
      return (
        <Button className="viewUser" hidden onClick={() => setOpenDialog(true)}>
          <AssignButton type={"remove"} title={"Unassign " + name} />
        </Button>
      );
    } else {
      return (
        <Button className="viewUser" hidden onClick={() => setOpenDialog(true)}>
          <AssignButton type={"add"} title={"Assign " + name} />
        </Button>
      );
    }
  }
  function handleClose() {
    setOpenDialog(false);
  }

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={iKey}
        sx={{ cursor: "pointer" }}
      >
        <TableCell
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <span
              className="pfpSpan"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(${url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></span>
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.firstname}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.lastname}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{email}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{mobile}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="subtitle2"
            color={(row.status === "link" || row.status === "active") && "green"}
          >
            {row.status === "link" || row.status === "active"
              ? "Assigned"
              : "Unassigned"}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <FormControl fullWidth>
            <CustomInputLabel>User Type</CustomInputLabel>
            <CustomSelect
              labelId="User Type"
              value={row.user_type_uid}
              label="User Type"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.custom.inputBackgroundColor,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: theme.palette.custom.buttonHoverColor,
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: theme.palette.custom.backgroundColor2,
                    },
                  },
                },
              }}
            >
              <MenuItem value={0} key={"999"}>
                Select User Type
              </MenuItem>
              {JSON.parse(localStorage.getItem("userTypes"))?.app_user_type?.map(
                (e) => {
                  return (
                    <MenuItem key={e.user_type_uid} value={e.user_type_uid}>
                      {e.name}
                    </MenuItem>
                  );
                }
              )}
            </CustomSelect>
          </FormControl>
        </TableCell>
        <TableCell align="left">
          {getButton()}
        </TableCell>
      </TableRow>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={() => handleAssign(!(row.status === "link" || row.status === "active"))}
        saveLoading={saveLoading}
        dialogTitle={row.status === "link" || row.status === "active" ? t("Are you sure you want to unassign this user?") : t("Are you sure you want to assign this user?")}
        cancelLabel={t("Cancel")}
        buttonLabel={row.status === "link" || row.status === "active" ? t("Unassign") : t("Assign")}
      />
    </>
  );
}
