/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
  Make0IfNegative,
} from "../utils/systemInfo";

export const setProductivityData = createAsyncThunk('productivity/setProductivityData', async (inputData) => {
  let RecordOPSUM = [];
  if (inputData === null) {
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent,
    inputData.jsonPrevious,
    inputData.machineTypeFilter,
  )
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && RecordOPSUM && RecordOPSUM.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return RecordOPSUM.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return RecordOPSUM;
});
function PopulateDataHomeOPSUM(jsonThisWeek, jsonLastWeek, machineTypeFilter = "") {
  let OpsumArray = [];
  const DevicesArray = jsonThisWeek.opsum.device;
  for (let index in DevicesArray) {
    let opsumDataObj = {
      MachineType: "",
      DeviceName: "",
      DeviceID: "",
      DeviceStatus: "",
      DeviceLatestOPSUM: "",
      DeviceLatestData: "",
      DeviceLatestRaw: "",
      DeviceLastSeen: "",
      NamedLocationOriginal: "",
      GrafanaDashboardUID: "",
      DeviceType: "",
      DeviceTypeIcon: "",
      DeviceTotalWorkingTime: 0,
      DeviceTotalIdleTime: 0,
      DeviceTotalUnscheduledTime: 0,
      DeviceTotalOfflineTime: 0,
      DeviceTotalWorkingTimeLastWeek: 0,
      DeviceTotalIdleTimeLastWeek: 0,
      DeviceTotalUnscheduledTimeLastWeek: 0,
      DeviceTotalOfflineTimeLastWeek: 0,
      DeviceTitleDataName: "Machine",
    };
    opsumDataObj.DeviceName = DevicesArray[index].profile.name;
    opsumDataObj.DeviceID = DevicesArray[index].profile.device_uid;
    const DeviceLatestData = DevicesArray[index].profile.latest_data_dst;
    opsumDataObj.DeviceLatestOPSUM = DevicesArray[index].profile.dst_last_opsum;
    opsumDataObj.DeviceLatestRaw = DevicesArray[index].profile.dst_last_raw;
    opsumDataObj.DeviceLastSeen = DevicesArray[index].profile.dst_last_seen;
    if (
      new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
      opsumDataObj.DeviceLastSeen.length === 0
    ) {
      opsumDataObj.DeviceLastSeen = DeviceLatestData;
      if (
        new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
        opsumDataObj.DeviceLastSeen.length === 0
      )
        opsumDataObj.DeviceLastSeen = " not seen";
    }

    // DeviceType = String(DevicesArray[index].profile.vehicle_type);
    // if (DeviceType == "") DeviceType = "Unknown";

    let MachineType = String(DevicesArray[index].profile.machine_type);
    opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

    let DeviceType = String(DevicesArray[index].profile.vehicle_type);
    if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
    const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
    opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;
    if (DeviceType !== machineTypeFilter && machineTypeFilter !== "") continue;

    let LastLocation = DevicesArray[index]?.location?.named?.lookup?.name;
    if (LastLocation === undefined || LastLocation === "") {
      LastLocation = `Unknown`;
    }
    opsumDataObj.NamedLocationOriginal = LastLocation;
    const ProductionJson = DevicesArray[index].production;
    let DeviceTotalTimespanMinutes =
      ProductionJson.downtime + ProductionJson.idle + ProductionJson.tramming;
    let DeviceTotalWorkingTime = Make0IfNegative(ProductionJson.tramming);
    let DeviceTotalIdleTime = Make0IfNegative(ProductionJson.idle);
    let DeviceTotalUnscheduledTime = Make0IfNegative(
      DeviceTotalTimespanMinutes - jsonThisWeek.scheduled_time.scheduled
    );
    let DeviceTotalOfflineTime = Make0IfNegative(
      ProductionJson.downtime - DeviceTotalUnscheduledTime
    );
    opsumDataObj.DeviceTotalWorkingTime = DeviceTotalWorkingTime;
    opsumDataObj.DeviceTotalIdleTime = DeviceTotalIdleTime;
    opsumDataObj.DeviceTotalUnscheduledTime = DeviceTotalUnscheduledTime;
    opsumDataObj.DeviceTotalOfflineTime = DeviceTotalOfflineTime;

    if (
      jsonLastWeek &&
      jsonLastWeek.opsum &&
      jsonLastWeek.opsum.device &&
      jsonLastWeek.opsum.device.length > 0
    ) {
      const DevicesArrayLastWeek = jsonLastWeek.opsum.device.filter(
        (x) => x.profile.device_uid === opsumDataObj.DeviceID
      );
      for (let index in DevicesArrayLastWeek) {
        const DeviceNameLastWeek = DevicesArrayLastWeek[index].profile.name;
        if (opsumDataObj.DeviceName === DeviceNameLastWeek) {
          // let Table_Row = table.insertRow(-1);
          const ProductionJson = DevicesArrayLastWeek[index].production;
          let DeviceTotalTimespanMinutesLastWeek =
            ProductionJson.downtime +
            ProductionJson.idle +
            ProductionJson.tramming;
          let DeviceTotalWorkingTimeLastWeek = Make0IfNegative(
            ProductionJson.tramming
          );
          let DeviceTotalIdleTimeLastWeek = Make0IfNegative(
            ProductionJson.idle
          );
          let DeviceTotalUnscheduledTimeLastWeek = Make0IfNegative(
            DeviceTotalTimespanMinutesLastWeek -
              jsonLastWeek.scheduled_time.scheduled
          );
          let DeviceTotalOfflineTimeLastWeek = Make0IfNegative(
            ProductionJson.downtime - DeviceTotalUnscheduledTimeLastWeek
          );

          opsumDataObj.DeviceTotalWorkingTimeLastWeek =
            DeviceTotalWorkingTimeLastWeek;
          opsumDataObj.DeviceTotalIdleTimeLastWeek =
            DeviceTotalIdleTimeLastWeek;
          opsumDataObj.DeviceTotalUnscheduledTimeLastWeek =
            DeviceTotalUnscheduledTimeLastWeek;
          opsumDataObj.DeviceTotalOfflineTimeLastWeek =
            DeviceTotalOfflineTimeLastWeek;
        }
      }
    }

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const productivitySlice = createSlice({
  name: "productivity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setProductivityData.fulfilled, (state, action) => action.payload);
  },
});

export const selectProductivity = ({ productivity }) => productivity;

export default productivitySlice.reducer;
