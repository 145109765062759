import { config } from '../config'
import Utils from '../utils/utils'


class AppService extends Utils.EventEmitter {
    SetApiKey(api_key_id = "", operation_uid = "", key = "", expiry_date = "2199-01-01 00:00:00", status = "active", api_key) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                "post": [
                    {
                        "api_key_id": api_key_id,
                        "operation_uid": operation_uid,
                        "key": key,
                        "expiry_date": expiry_date,
                        "super_key": "",
                        "status": status
                    }
                ]
            });
            const headers = {
                "Content-Type": 'application/json',
                "apiKey": api_key,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/app/api-key/post`, {
                method: 'post',
                headers,
                body
            }).then((response) => response.json())
                //axios
                // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

                .then(json => {
                    resolve(json);

                }).catch((err) => {
                    if (process.env.NODE_ENV === 'development') console.log('err ', err)
                    reject(err.response !== undefined ? err.response.data.error_description : null)
                });
        });
    }
    GetAppProfile(uid = "", full_name = "", short_name = "", status = "active", api_key) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                "get": {
                    "uid": uid,
                    "full_name": full_name,
                    "short_name": short_name,
                    "admin_domain": "",
                    "commercial_domain": "",
                    "status": status
                }
            });
            const headers = {
                "Content-Type": 'application/json',
                "apiKey": api_key,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/app/profile/get`, {
                method: 'post',
                headers,
                body
            }).then((response) => response.json())
                //axios
                // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

                .then(json => {
                    resolve(json);

                }).catch((err) => {
                    if (process.env.NODE_ENV === 'development') console.log('err ', err)
                    reject(err.response !== undefined ? err.response.data.error_description : null)
                });
        });
    }
    SetOrganizationAppProfile(app_profile_uid = "", operation_profile_uid = "", status = "active", api_key) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                "post": [
                    {
                        "profile_operation_link_uid": "",
                        "app_profile_uid": app_profile_uid,
                        "operation_profile_uid": operation_profile_uid,
                        "status": status
                    }
                ]
            });
            const headers = {
                "Content-Type": 'application/json',
                "apiKey": api_key,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/app/profile/operation/link/post`, {
                method: 'post',
                headers,
                body
            }).then((response) => response.json())
                //axios
                // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

                .then(json => {
                    resolve(json);

                }).catch((err) => {
                    if (process.env.NODE_ENV === 'development') console.log('err ', err)
                    reject(err.response !== undefined ? err.response.data.error_description : null)
                });
        });
    }
}
const instance = new AppService();

export default instance;