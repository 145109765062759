import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import {
  UpdateURLQueryStringWithoutReload,
  getUrlVars,
} from "../utils/systemInfo";
import SignalsService from "../api/signals";

const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    m: 0,
  })
);

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "300px",
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.text,
  },
}));
function SignalsMenu({ machineType, handleChangeSingals }) {
  const searchInputRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedSignals, setSelectedSignals] = useState([0]);

  const [signalsArray, setSignalsArray] = useState(undefined);
  const [backendSignalsArray, setBackendSignalsArray] = useState(undefined);
  const SensorID = Number(getUrlVars()["SensorID"]);
  useEffect(() => {
    if (organziation && machineType) {
      setSignalsArray(null);
      setBackendSignalsArray(null);
      const apiKey = localStorage.getItem("apiKey");
      SignalsService.GetDeviceSignalDescriptors({
        signal_descriptor_id: "",
        machine_type: machineType,
        apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.device_signal_descriptor &&
            json.device_signal_descriptor.length > 0
          ) {
            let newSelectedArray = [];
            newSelectedArray.push(SensorID);
            setSelectedSignals(newSelectedArray);
            handleChangeSingals(newSelectedArray);
            setSignalsArray(json.device_signal_descriptor);
            setBackendSignalsArray(json.device_signal_descriptor);
          }
          // setLoadingValue(false)
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation, machineType]);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleChooseOrganization = (singalObj) => {
    const newSelectedIndex = selectedSignals.indexOf(singalObj.signal_idx);
    let newSelectedArray = [];
    if (newSelectedIndex !== -1) {
      for (let index = 0; index < selectedSignals.length; index++) {
        if (newSelectedIndex !== index) {
          newSelectedArray.push(selectedSignals[index]);
        }
      }
    } else {
      for (let index = 0; index < selectedSignals.length; index++) {
        newSelectedArray.push(selectedSignals[index]);
      }
      newSelectedArray.push(singalObj.signal_idx);
    }
    setSelectedSignals(newSelectedArray);
    UpdateURLQueryStringWithoutReload("SensorID", newSelectedArray);
    handleChangeSingals(newSelectedArray);
    // const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"])
    // let MachineType = String(deviceObj.machine_type);
    // const GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);
    // let href;
    // switch (pageType) {
    //     case "history":
    //          href = `/deviceHistory?Dashboard=${GrafanaDashboardUID}&MachineName=${deviceObj.name}&device_uid=${deviceObj.device_uid}&DeviceTitleDataName=${DeviceTitleDataName}&timerange`;
    //         navigate(href)
    //         break;
    //     case "signals":
    //          href = `/deviceSignals?Dashboard=MachineMultipleSignal&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&SensorID=0`;
    //         // const newUrl = window.location
    //         navigate(href)
    //         break;

    //     default:
    //         navigate(`/device?device=${deviceObj.device_uid}`)
    //         break;
    // }
  };
  function handleSearch(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && backendSignalsArray) {
      setSignalsArray(
        backendSignalsArray.filter((item) =>
          item.name.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
      return;
    }
  }
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="text"
          onClick={handleOpenUserMenu}
          //  startIcon={<Avatar alt="Remy Sharp" src={handleGetIcon(organziation)} />}
          endIcon={
            Boolean(anchorElUser) ? (
              <KeyboardArrowUpIcon
                sx={{
                  color: theme.palette.custom.text,
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{
                  color: theme.palette.custom.text,
                }}
              />
            )
          }
        >
          <NumberTypography variant={"h5"}>{"Signals"}</NumberTypography>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        multiple
        // value={selectedSignals}
        // renderValue={(selected) => selected.join(', ')}
        TransitionProps={{
          onEntered: () => {
            searchInputRef.current.focus();
          },
        }}
      >
        <TextField
          inputRef={searchInputRef}
          value={searchText}
          onChange={handleSearch}
          margin="normal"
          fullWidth
          id="search"
          label={t("Search")}
          name="search"
          autoFocus
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={{
            ml: "2%",
            width: "200px",
            borderRadius: "11px",
            backgroundColor: theme.palette.custom.light,
          }}
        />
        <Divider
          sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
        />
        {signalsArray &&
          signalsArray.map((setting) => (
            <MenuItem
              key={setting.signal_idx}
              value={setting.signal_idx}
              onClick={() => handleChooseOrganization(setting)}
            >
              {/* <Avatar sx={{ mr: 1 }} alt="Remy Sharp" src={} /> */}
              <Checkbox
                sx={{
                  color: theme.palette.custom.borderColor,
                  "&.Mui-checked": {
                    color: theme.palette.custom.borderColor,
                  },
                }}
                checked={selectedSignals.indexOf(setting.signal_idx) > -1}
              />
              <Typography textAlign="center">
                {setting.name ? setting.name : ""}
              </Typography>
              &nbsp;&nbsp;
              <Typography textAlign="center">
                {setting.eunit ? setting.eunit : ""}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export default SignalsMenu;
