import React, { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import StyledTypography from "../common/StyledTypography";
import { useNavigate } from "react-router-dom";
import handleNavigate from "../common/customFunctions/handleNavigate";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "150px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
export default function TemplatesTableRow({
  row,
  handleClick,
  isItemSelected,
}) {
  const timezoneMins = useSelector(({ timezone }) => timezone);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditButton = () => {
    //  navigate(`/deviceProfile?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`)
  };
  const handleRequirementsButton = (event) => {
    handleNavigate(event, navigate, `/ticketTemplateRequirements?templateId=${row.template_header_uid}`);
    // navigate(

    // );
  };
  return (
    <Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row.ticket_number)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.ticket_number}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.description}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.department_id}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {moment
              .utc(row.created_timestamp)
              .add(timezoneMins, "minutes")
              .format("YYYY-MM-DD HH:mm")}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {moment
              .utc(row.updated_timestamp)
              .add(timezoneMins, "minutes")
              .format("YYYY-MM-DD HH:mm")}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "column",
            }}
          >
            <StyledButton
              variant="contained"
              sx={{ mb: "4px" }}
              onClick={handleEditButton}
              title={"Edit Template"}
            >
              {t("Edit")}
            </StyledButton>
            <StyledButton
              variant="contained"
              onClick={handleRequirementsButton}
              title={"Edit Template Requirements"}
            >
              {t("Requirements")}
            </StyledButton>
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
