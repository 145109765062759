/* eslint-disable no-loop-func */
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useNavigate } from "react-router-dom";
import DashboardsService from "../api/dashboards";
import { setDashboardsBackend } from "../store/dashboardsBackendSlice.js";
import { setDashboardsOrganizationLinkings } from "../store/dashboardsOrganizationLinkingsSlice.js";
import SearchInput from "../common/searchInput.js";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import GrafanaBackendTableRow from "./grafanaBackendTableRow";
import GrafanaOrganizationLinkingTableRow from "./grafanaOrganizationLinkingTableRow.js";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV.js";
import StyledTypography from "../common/StyledTypography.js";
import handleNavigate from "../common/customFunctions/handleNavigate.js";

const StylePageSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  fontSize: "40px",
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    height: "36px",
    minHeight: "36px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonHoverColor,
    },
  })
);

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const grafanaDashboardsheadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sort: true,
  },
  {
    id: "name_backend",
    numeric: false,
    disablePadding: false,
    label: "NameBackend",
    sort: true,
  },
  {
    id: "name_tab",
    numeric: false,
    disablePadding: false,
    label: "NameTab",
    sort: true,
  },
  {
    id: "name_type",
    numeric: false,
    disablePadding: false,
    label: "NameType",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sort: false,
  },
];
const grafanaLinkingheadCells = [
  {
    id: "grafana_dashboard_name",
    numeric: false,
    disablePadding: true,
    label: "grafanaDashboardName",
    sort: true,
  },
  {
    id: "grafana_dashboard_id",
    numeric: false,
    disablePadding: false,
    label: "grafanaDashboardId",
    sort: true,
  },
  {
    id: "operation_name",
    numeric: false,
    disablePadding: false,
    label: "organizationName",
    sort: true,
  },
  {
    id: "operation_profile_id",
    numeric: false,
    disablePadding: false,
    label: "organizationId",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sort: false,
  },
];
let dashboardBackendList;
let dashboardLinkingList;
function GrafanaBackend() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const organziation = useSelector(({ organziation }) => organziation);
  const dashboardsOrganizationLinkings = useSelector(
    ({ dashboardsOrganizationLinkings }) => dashboardsOrganizationLinkings
  );
  const dashboardsBackend = useSelector(
    ({ dashboardsBackend }) => dashboardsBackend
  );
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("DeviceLastSeen");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);
  // const [dashboardsList, setDashboardsList] = useState([]);

  useEffect(() => {
    dashboardBackendList = [];
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      DashboardsService.GetGrafanaDashboard({
        grafana_dashboard_id: "",
        name: "",
        name_backend: "",
        name_tab: "",
        name_type: "",
        status: "",
        apiKey,
      })
        .then((jsonGrafana) => {
          if (jsonGrafana.grafana_dashboard?.length) {
            dashboardBackendList = jsonGrafana.grafana_dashboard;
            dispatch(
              setDashboardsBackend({
                newSearchText: "",
                data: jsonGrafana.grafana_dashboard,
              })
            );
            // setDashboardsList(jsonGrafana.grafana_dashboard);

            setLoadingValue(false);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        if (organziation && !dashboardsBackend) {
          dashboardBackendList = [];
          setLoadingValue(true);
          const apiKey = organziation?.api_key_list?.[0]?.key;
          DashboardsService.GetGrafanaDashboard({
            grafana_dashboard_id: "",
            name: "",
            name_backend: "",
            name_tab: "",
            name_type: "",
            status: "",
            apiKey,
          })
            .then((jsonGrafana) => {
              if (jsonGrafana.grafana_dashboard?.length) {
                dashboardBackendList = jsonGrafana.grafana_dashboard;
                dispatch(
                  setDashboardsBackend({
                    newSearchText: "",
                    data: jsonGrafana.grafana_dashboard,
                  })
                );
                // setDashboardsList(jsonGrafana.grafana_dashboard);

                setLoadingValue(false);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
            });
        }
        break;
      case 1:
        if (organziation && !dashboardsOrganizationLinkings) {
          dashboardLinkingList = [];
          setLoadingValue(true);
          const apiKey = organziation?.api_key_list?.[0]?.key;
          DashboardsService.getGrafanaDashboardOperationLink({
            grafana_dashboard_link_id: "",
            operation_profile_id: "",
            grafana_dashboard_id: "",
            status: "",
            apiKey,
          })
            .then((json) => {
              if (json.grafana_dashboard_operation_link?.length) {
                dashboardLinkingList = json.grafana_dashboard_operation_link;
                dispatch(
                  setDashboardsOrganizationLinkings({
                    newSearchText: "",
                    data: json.grafana_dashboard_operation_link,
                  })
                );
                setLoadingValue(false);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
            });
        }
        break;
      default:
        break;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(
      tabValue === 1 ? row?.grafana_dashboard_link_id : row?.name
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        selected,
        tabValue === 1 ? row?.grafana_dashboard_link_id : row?.name
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // switch (row.DeviceTitleDataName) {
    //     case "Machine":
    //     default:
    //         navigate(`/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`)
    //         break;

    //     case "Device":
    //         navigate(`/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`)

    //         break;
    // }
    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    //let opsumInputObj = opsumInputData;
    //opsumInputObj.machineTypeFilter = "";
    // opsumInputObj.newSearchText = newSearchText;
    switch (tabValue) {
      case 0:
        dispatch(
          setDashboardsBackend({
            newSearchText: newSearchText,
            data: dashboardBackendList,
          })
        );
        break;
      case 1:
        dispatch(
          setDashboardsOrganizationLinkings({
            newSearchText: newSearchText,
            data: dashboardBackendList,
          })
        );
        break;
      default:
        break;
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      switch (tabValue) {
        case 0:
          if (dashboardsBackend && dashboardsBackend.length > 0) {
            return stableSort(
              dashboardsBackend,
              getComparator(order, orderBy)
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
          } else {
            return [];
          }
        case 1:
          if (
            dashboardsOrganizationLinkings &&
            dashboardsOrganizationLinkings.length > 0
          ) {
            return stableSort(
              dashboardsOrganizationLinkings,
              getComparator(order, orderBy)
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
          } else {
            return [];
          }
        default:
          break;
      }
    },
    [
      order,
      orderBy,
      page,
      rowsPerPage,
      dashboardsBackend,
      dashboardsOrganizationLinkings,
      tabValue,
    ]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          {/* <HomePagesMenu /> */}
          <StylePageSpan>{t("grafanaBackend")}</StylePageSpan>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.borderColor,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.custom.buttonHoverColor,
                color: theme.palette.custom.buttonHoverTextColor,
                boxShadow: "none",
              },
              float: "right",
              width: "min-content",
              whiteSpace: "nowrap",
              padding: "1rem",
            }}
            onClick={(event) => {
              handleNavigate(event, navigate, "/grafanaBackendDashboardAddEdit");
              // navigate();
            }}
          >
            {t("Add New Grafana Dashboard")}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.borderColor,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.custom.buttonHoverColor,
                color: theme.palette.custom.buttonHoverTextColor,
                boxShadow: "none",
              },
              float: "right",
              width: "min-content",
              whiteSpace: "nowrap",
              padding: "1rem",
            }}
            onClick={(event) => {
              handleNavigate(event, navigate, "/addEditOrganizationLink");
              // navigate();
            }}
          >
            {t("Add New Organization Link")}
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.custom.borderColor },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        <StyledTab label={t("grafanaDashboards")} />
        <StyledTab label={t("organizationLinking")} />
      </Tabs>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <SearchInput searchText={searchText} handleSearch={handleSearch} />

      {!loadingValue && tabValue === 0 && (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              headCells={grafanaDashboardsheadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {dashboardsBackend &&
              dashboardsBackend.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <GrafanaBackendTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loadingValue && tabValue === 0 && (
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={dashboardBackendList ? dashboardBackendList.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {!loadingValue && tabValue === 1 && (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              headCells={grafanaLinkingheadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {dashboardsOrganizationLinkings &&
              dashboardsOrganizationLinkings.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(
                    row.grafana_dashboard_link_id
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <GrafanaOrganizationLinkingTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loadingValue && tabValue === 1 && (
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={dashboardLinkingList ? dashboardLinkingList.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {loadingValue && (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.borderColor,
          color: "white",
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          tabValue === 0
            ? exportTableToCSV(
                grafanaDashboardsheadCells,
                visibleRows,
                "Grafana Dashboards List"
              )
            : exportTableToCSV(
                grafanaLinkingheadCells,
                visibleRows,
                "Organization Linkings List"
              )
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default GrafanaBackend;
