import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import LastButton from "./lastButton";
import NextButton from "./nextButton";
import ZoomIn from "./zoomIn";
import { useSelector, useDispatch } from "react-redux";
import ZoomOut from "./zoomOut";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DateRangePicker } from "react-date-range";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import moment from "moment";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  // differenceInCalendarDays,
} from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "./commonDateTimePicker.css"; // theme css file
import { config } from "../config";
import { getUrlVars, GetMachineTypeWireframe } from "../utils/systemInfo";

import Loading from "./loading";
import { useLocation } from "react-router-dom";
import ScheduledTimeService from "../api/scheduledTime";
import { setShiftsData } from "../store/shiftsSlice";
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  startOfLastThreeDays: startOfDay(addDays(new Date(), -3)),
  startOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
  startOfLastFourteenDays: startOfDay(addDays(new Date(), -14)),
  startOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
  startOfLastSixtyDays: startOfDay(addDays(new Date(), -60)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastThreeMonths: startOfDay(addMonths(new Date(), -3)),
  startOfLastSixMonths: startOfDay(addMonths(new Date(), -6)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}
export const staticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "Last 3 Days",
    range: () => ({
      startDate: defineds.startOfLastThreeDays,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: defineds.startOfLastSevenDays,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 14 days",
    range: () => ({
      startDate: defineds.startOfLastFourteenDays,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 30 Days",
    range: () => ({
      startDate: defineds.startOfLastThirtyDays,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 60 Days",
    range: () => ({
      startDate: defineds.startOfLastSixtyDays,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 3 Months",
    range: () => ({
      startDate: defineds.startOfLastThreeMonths,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 6 Months",
    range: () => ({
      startDate: defineds.startOfLastSixMonths,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.buttonColor,
    borderRadius: "3px",
    padding: "8px 16px",
    height: "36px",
    minHeight: "36px",
    color: theme.palette.custom.buttonTextColor,
    "&.Mui-selected": {
      color: theme.palette.custom.buttonTextColor,
      backgroundColor: theme.palette.custom.buttonHoverColor,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "250px",
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    // "&:hover": {
    //   backgroundColor: theme.palette.custom.buttonHoverColor,
    //   color: theme.palette.custom.buttonHoverTextColor,
    //   boxShadow: "none",
    // },
  },
}));
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "200px",
  height: "36px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Custom Range</DialogTitle>
    </Dialog>
  );
}

export default function CommonDateTimePicker({
  isSignals,
  device,
  signalsList,
  handleUpdateGrafanaLink,
  machineDeviceName,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(1);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const organziation = useSelector(({ organziation }) => organziation);
  const user = useSelector(({ user }) => user);
  const [loadingValue, setLoadingValue] = useState(true);
  const themeMode = useSelector(({ theme }) => theme);
  const [dateRangelLabel, setDateRangeLabel] = useState(
    `${dayjs().format("DD MMM YYYY")}-${dayjs().format("DD MMM YYYY")}`
  );
  const open = Boolean(anchorEl);

  const [value, setValue] = useState(dayjs(new Date()));
  const [startShift, setShiftStart] = useState("00:00");
  const [nameShift, setNameShift] = useState(null);
  const [endShift, setShiftEnd] = useState("24:00");
  const [showRange, setShowRange] = useState(false);
  const [dashboardSource, setDashboardSource] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const location = useLocation();
  const shifts = useSelector(({ shifts }) => shifts.shifts);
  useEffect(() => {
    const apiKey = organziation?.api_key_list?.[0]?.key;
    const fromDate = startOfDay(addDays(new Date(), -6)).valueOf();
    const toDate = endOfDay(new Date()).valueOf();
    setDateRangeLabel(
      `${dayjs(fromDate).format("DD MMM YYYY")}-${dayjs(toDate).format(
        "DD MMM YYYY"
      )}`
    );
    setSelectionRange({
      startDate: new Date(fromDate),
      endDate: new Date(toDate),
      key: "selection",
    });
    handleChooseDate(fromDate, toDate);
    ScheduledTimeService.GetOrganizationShiftTimes({
      shift_time_config_uid: "",
      operation_uid: organziation.uid,
      name: "",
      day_of_week: "",
      status: "active",
      apiKey: apiKey,
    })
      .then((json) => {
        // let jsonTemp = {};
        // if (json.success === true && json.shift_time_config.length === 0) {
        //   jsonTemp = {
        //     success: true,
        //     count: 2,
        //     shift_time_config: [
        //       {
        //         operation_uid: organziation.uid,
        //         name: "Shift 1",
        //         start_time: "00:00:00",
        //         end_time: "12:00:00",
        //         status: "active",
        //       },
        //       {
        //         operation_uid: organziation.uid,
        //         name: "Shift 2",
        //         start_time: "12:00:00",
        //         end_time: "24:00:00",
        //         status: "active",
        //       },
        //     ],
        //   };
        // } else {
        //   jsonTemp = json;
        // }
        // json = jsonTemp;
        if (
          json &&
          json.shift_time_config &&
          json.shift_time_config.length > 0
        ) {
          console.log("   json.shift_time_config ", json.shift_time_config);
          dispatch(setShiftsData({ json }));
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalsList, themeMode, location.search, machineDeviceName]);
  const handleTabChange = (event, newValue) => {
    let fromDate = dayjs().startOf("d").valueOf();
    let toDate = dayjs().endOf("d").valueOf();
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setShowRange(false);
        const dateValue = dayjs(value);
        const startTime = dayjs(startShift, "HH:mm");
        const endTime = dayjs(
          endShift === "24:00" ? "23:59:59" : endShift,
          "HH:mm"
        );
        // console.log("handlechooseshift", startTime, endTime);
        fromDate = dateValue
          .hour(startTime.hour())
          .minute(startTime.minute())
          .second(startTime.second());
        toDate = dateValue
          .hour(endTime.hour())
          .minute(endTime.minute())
          .second(endTime.second());
        handleChooseDate(fromDate, toDate);
        break;
      case 1:
        fromDate = startOfDay(addDays(new Date(), -6)).valueOf();
        toDate = endOfDay(new Date()).valueOf();
        handleChooseDate(fromDate, toDate);
        break;
      case 2:
        setShowRange(false);
        const fromHour = dayjs(fromValue).get("hour");
        const fromMinutes = dayjs(fromValue).get("minute");
        const newFromValue = dayjs(value)
          .set("hour", fromHour)
          .set("minute", fromMinutes)
          .set("second", 0);
        const toHour = dayjs(toValue).get("hour");
        const toMinutes = dayjs(toValue).get("minute");
        const newToValue = dayjs(value)
          .set("hour", toHour)
          .set("minute", toMinutes)
          .set("second", 59);

        fromDate = dayjs(newFromValue).startOf("d").valueOf();
        toDate = dayjs(newToValue).endOf("d").valueOf();
        handleChooseDate(fromDate, toDate);
        break;
      default:
        break;
    }
  };
  const [fromValue, setFromValue] = useState(dayjs(value).subtract(1, "hour"));
  const [toValue, setToValue] = useState(dayjs(value));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickDateRange = (event) => {
    setShowRange(!showRange);
    // setAnchorDate(event.currentTarget);
  };
  const handleLast = () => {
    let fromDate = dayjs(value).startOf("d").valueOf();
    let toDate = dayjs(value).endOf("d").valueOf();
    let newDate = undefined;
    switch (tabValue) {
      case 0:
        newDate = dayjs(value).subtract(1, "day");
        setValue(dayjs(newDate));
        if (startShift === "00:00" && endShift === "24:00") {
          fromDate = dayjs(newDate).startOf("d");
          toDate = dayjs(newDate).endOf("d");
        } else {
          fromDate = dayjs(newDate)
            .hour(startShift.hour())
            .minute(startShift.minute())
            .second(startShift.second());
          toDate = dayjs(newDate)
            .hour(endShift.hour())
            .minute(endShift.minute())
            .second(endShift.second());
        }

        handleChooseDate(fromDate, toDate);
        break;
      case 2:
        newDate = dayjs(value).subtract(1, "day");
        setValue(dayjs(newDate));
        fromDate = dayjs(newDate).startOf("d").valueOf();
        toDate = dayjs(newDate).endOf("d").valueOf();
        handleChooseDate(fromDate, toDate);
        break;

      default:
        break;
    }
  };
  const handleNext = () => {
    let fromDate = dayjs(value).startOf("d").valueOf();
    let toDate = dayjs(value).endOf("d").valueOf();
    let newDate = undefined;
    switch (tabValue) {
      case 0:
        newDate = dayjs(value).add(1, "day");
        setValue(dayjs(newDate));
        if (startShift === "00:00" && endShift === "24:00") {
          fromDate = dayjs(newDate).startOf("d");
          toDate = dayjs(newDate).endOf("d");
        } else {
          fromDate = dayjs(newDate)
            .hour(startShift.hour())
            .minute(startShift.minute())
            .second(startShift.second());
          toDate = dayjs(newDate)
            .hour(endShift.hour())
            .minute(endShift.minute())
            .second(endShift.second());
        }
        handleChooseDate(fromDate, toDate);
        break;
      case 2:
        newDate = dayjs(value).add(1, "day");
        setValue(dayjs(newDate));
        fromDate = dayjs(newDate).startOf("d").valueOf();
        toDate = dayjs(newDate).endOf("d").valueOf();
        handleChooseDate(fromDate, toDate);
        break;

      default:
        break;
    }
  };
  const handleRangeLast = () => {
    const dateDifference = getDateDifference(
      selectionRange.startDate,
      selectionRange.endDate
    );
    const ranges = {
      selection: {
        startDate: getDateShift(selectionRange.startDate, -dateDifference),
        endDate: getDateShift(selectionRange.endDate, -dateDifference),
      },
    };
    handleSelect(ranges);
  };
  const handleRangeNext = () => {
    const dateDifference = getDateDifference(
      selectionRange.startDate,
      selectionRange.endDate
    );
    const startDate = getDateShift(selectionRange.startDate, dateDifference);
    const endDate = getDateShift(selectionRange.endDate, dateDifference);
    const ranges = {
      selection: {
        startDate: startDate < new Date() ? startDate : new Date(),
        endDate: endDate < new Date() ? endDate : new Date(),
      },
    };
    handleSelect(ranges);
  };
  function getDateDifference(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    const differenceInMilliseconds = Math.abs(date2 - date1);
    return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
  }
  function getDateShift(dateString, days) {
    let date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date;
  }
  function handleChooseShift(shiftName, shiftStart, shiftEnd) {
    const dateValue = dayjs(value);
    const startTime = dayjs(shiftStart, "HH:mm");
    const endTime = dayjs(
      shiftEnd === "24:00" ? "23:59:59" : shiftEnd,
      "HH:mm"
    );
    let startDateTime = dateValue
      .hour(startTime.hour())
      .minute(startTime.minute())
      .second(startTime.second());
    let endDateTime = dateValue
      .hour(endTime.hour())
      .minute(endTime.minute())
      .second(endTime.second());

    if (startTime.hour() > endTime.hour()) {
      const datebeforeValue = dayjs(value).subtract(1, "day");
      startDateTime = datebeforeValue
        .hour(startTime.hour())
        .minute(startTime.minute())
        .second(startTime.second());
      endDateTime = dateValue
        .hour(endTime.hour())
        .minute(endTime.minute())
        .second(endTime.second());
    }

    setShiftStart(shiftStart);
    setShiftEnd(shiftEnd);
    setNameShift(shiftName);
    handleChooseDate(startDateTime, endDateTime);
    setAnchorEl(null);
  }
  const handleCloseDialog = (value) => {
    setOpenDialog(false);
  };
  const handleSelect = (ranges) => {
    const startDate = dayjs(ranges.selection.startDate).startOf("d").valueOf();
    const endDate = dayjs(ranges.selection.endDate).startOf("d").valueOf();
    if (startDate !== endDate) {
      setSelectionRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      });
      setDateRangeLabel(
        `${dayjs(ranges.selection.startDate).format("DD MMM YYYY")}-${dayjs(
          ranges.selection.endDate
        ).format("DD MMM YYYY")}`
      );
      // const fromDate = dayjs.unix(ranges.selection.startDate);
      // const toDate = dayjs.unix(ranges.selection.endDate);
      const fromDate = dayjs(ranges.selection.startDate).startOf("d").valueOf();
      const toDate = dayjs(ranges.selection.endDate).endOf("d").valueOf();
      handleChooseDate(fromDate, toDate);
      setShowRange(false);
      //setShowRange(false);
      // {
      //   selection: {
      //     startDate: [native Date Object],
      //     endDate: [native Date Object],
      //   }
      // }
    } else {
      setSelectionRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      });
      setDateRangeLabel(
        `${dayjs(ranges.selection.startDate).format("DD MMM YYYY")}-${dayjs(
          ranges.selection.endDate
        ).format("DD MMM YYYY")}`
      );
    }
  };
  // const selectionRange = {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  // }
  // function handleblur() {
  //   setShowRange(false);
  // }
  const handleZoomFunction = (zoomType) => {
    let _startDateChanged = selectionRange.startDate;
    let _endDateChanged = selectionRange.endDate;

    const minutes = (_endDateChanged - _startDateChanged) / 60000;
    const minutesZoomOut = minutes / 2;
    const minutesZoomIn = minutes / 4;

    if (zoomType === "-") {
      _startDateChanged = dayjs(_startDateChanged).subtract(
        minutesZoomOut,
        "minutes"
      );
      _endDateChanged = dayjs(_endDateChanged).add(minutesZoomOut, "minutes");

      if (_endDateChanged > dayjs()) {
        _endDateChanged = dayjs();
        setZoomOutDisabled(true);
      }
      handleChooseDate(_startDateChanged, _endDateChanged);
    } else if (zoomType === "+") {
      _startDateChanged = dayjs(_startDateChanged).add(
        minutesZoomIn,
        "minutes"
      );
      _endDateChanged = dayjs(_endDateChanged).subtract(
        minutesZoomIn,
        "minutes"
      );
      setZoomOutDisabled(false);

      if (_startDateChanged < _endDateChanged) {
        handleChooseDate(_startDateChanged, _endDateChanged);
      }
    } else {
      _startDateChanged = selectionRange.startDate;
      _endDateChanged = selectionRange.endDate;
      handleChooseDate(_startDateChanged, _endDateChanged);
    }
  };
  const handleChooseDate = (from, to) => {
    let iframeUrl = undefined;
    if (document.getElementById("grafana") !== null)
      iframeUrl = document.getElementById("grafana").src;
    const url =
      iframeUrl !== undefined ? iframeUrl : config.url.grafana_url + "d/123/";
    let OldPanelID = url.substring(url.indexOf("/d") + 3);
    let NewSrc = "";
    const Dashboard = getUrlVars()["Dashboard"];
    if (typeof Dashboard !== "undefined") {
      NewSrc = url.replace(
        OldPanelID,
        `${Dashboard}_${i18n.language.toString()}`
      );
    }

    let srcToReplace = NewSrc;
    let FromIndex = srcToReplace.indexOf("&from");
    let ToIndex = srcToReplace.indexOf("&kiosk=true");
    let res = srcToReplace.substring(FromIndex, ToIndex);
    res = "&from=" + from + "&to=" + to;
    //res = '&from=' + start + '&to=' + end;from=now-7d&to=now
    NewSrc = srcToReplace.replace(srcToReplace, res);
    // NewSrc = srcToReplace + "?   orgId=1" + NewSrc + "&kiosk=true&theme=" + getCookie("Theme").toLowerCase();
    const themeColor =
      localStorage.getItem("color_theme") === "dark" ? "dark" : "light";
    NewSrc =
      srcToReplace + "?orgId=1" + NewSrc + "&kiosk=true&theme=" + themeColor;
    const MachineName = getUrlVars()["MachineName"];
    if (typeof MachineName !== "undefined")
      NewSrc += "&var-MachineName=" + MachineName;
    const device_uid = getUrlVars()["device_uid"];
    if (typeof device_uid !== "undefined")
      NewSrc += "&var-device_uid=" + device_uid;
    if (typeof Dashboard !== "undefined")
      NewSrc += "&var-Organization_Profile_ID=" + organziation.uid;
    const varMachineType = getUrlVars()["MachineType"];
    if (typeof varMachineType !== "undefined") {
      NewSrc += "&var-Vehicle_Type=" + varMachineType;
    } else if (
      machineDeviceName !== undefined &&
      machineDeviceName !== "Fleet"
    ) {
      NewSrc += "&var-Vehicle_Type=" + machineDeviceName;
    }
    const varSiteTarget = getUrlVars()["Site_Target"];
    if (typeof varSiteTarget !== "undefined")
      NewSrc += "&var-targets=" + varSiteTarget;
    const varSensorID = getUrlVars()["SensorID"];
    if (signalsList !== undefined) {
      let singalsString = "";
      for (let index = 0; index < signalsList.length; index++) {
        singalsString += signalsList[index];
        if (index < signalsList.length - 1) {
          singalsString += ",";
        }
      }
      NewSrc += "&var-SensorID=" + singalsString;
    } else if (typeof varSensorID !== "undefined")
      NewSrc += "&var-SensorID=" + varSensorID;

    const varTankHeight = getUrlVars()["TankHeight"];
    if (typeof varTankHeight !== "undefined")
      NewSrc += "&var-TankHeight=" + varTankHeight;
    if (typeof Dashboard !== "undefined")
      NewSrc += "&var-user_profile_id=" + user.profileId;
    setLoadingValue(false);
    setDashboardSource(NewSrc);
    if (handleUpdateGrafanaLink) {
      handleUpdateGrafanaLink(NewSrc);
    }
  };
  const handleChangeDatePicker = (newValue) => {
    const fromHour = dayjs(fromValue).get("hour");
    const fromMinutes = dayjs(fromValue).get("minute");
    const newFromValue = dayjs(newValue)
      .set("hour", fromHour)
      .set("minute", fromMinutes)
      .set("second", 0);
    const toHour = dayjs(toValue).get("hour");
    const toMinutes = dayjs(toValue).get("minute");
    const newToValue = dayjs(newValue)
      .set("hour", toHour)
      .set("minute", toMinutes)
      .set("second", 59);

    let fromDate = dayjs(newValue).startOf("d").valueOf();
    let toDate = dayjs(newValue).endOf("d").valueOf();
    switch (tabValue) {
      case 0:
        if (startShift === "00:00" && endShift === "24:00") {
          fromDate = dayjs(newValue).startOf("d");
          toDate = dayjs(newValue).endOf("d");
        } else {
          const startTime = dayjs(startShift, "HH:mm");
          fromDate = dayjs(newValue)
            .hour(startTime.hour())
            .minute(startTime.minute())
            .second(startTime.second());
          const endTime = dayjs(endShift, "HH:mm");
          toDate = dayjs(newValue)
            .hour(endTime.hour())
            .minute(endTime.minute())
            .second(endTime.second());
        }
        setValue(newValue);
        handleChooseDate(fromDate, toDate);
        break;
      case 2:
        fromDate = dayjs(newFromValue).valueOf();
        toDate = dayjs(newToValue).valueOf();
        setFromValue(newFromValue);
        setToValue(newToValue);
        setValue(newValue);
        handleChooseDate(fromDate, toDate);
        break;

      default:
        break;
    }
  };
  const handleFromdateChange = (newValue) => {
    setFromValue(newValue);
    // let fromDate = dayjs(newValue).valueOf();
    // let toDate = dayjs(toValue).valueOf();
    // handleChooseDate(fromDate, toDate);
  };
  const handleFromdateClose = () => {
    const newValue = fromValue;
    let fromDate = dayjs(newValue).valueOf();
    let toDate = dayjs(toValue).valueOf();
    handleChooseDate(fromDate, toDate);
  };
  const handleTodateChange = (newValue) => {
    setToValue(newValue);
    // let fromDate = dayjs(fromValue).valueOf();
    // let toDate = dayjs(newValue).valueOf();
    // handleChooseDate(fromDate, toDate);
  };
  const handleTodateClose = () => {
    const newValue = toValue;
    //setToValue(newValue);
    let fromDate = dayjs(fromValue).valueOf();
    let toDate = dayjs(newValue).valueOf();
    handleChooseDate(fromDate, toDate);
  };
  function getTomorrow() {
    var date = new Date();

    // add a day
    date.setDate(date.getDate() + 1);
    return date;
  }
  function getMinDate() {
    var date = new Date();

    // add a day
    date.setFullYear(date.getFullYear() - 10);
    return date;
  }
  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.custom.text,
            },
          }}
          style={{ height: "36px", minHeight: "36px" }}
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
        >
          <StyledTab label={t("DailyShifts")} />
          <StyledTab label={t("DateRange")} sx={{ margin: "0px 8px" }} />
          <StyledTab label={t("TimeRange")} />
        </Tabs>
        {(tabValue === 0 || tabValue === 2) && (
          <Box
            sx={{
              display: "flex",
              margin: { xs: "12px 12px 12px 12px", sm: "0px" },
            }}
          >
            <LastButton onClick={handleLast} />
            <DatePicker
              //  label={t("Date")}
              slotProps={{
                textField: { size: "small" },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.buttonColor,
                      borderColor: theme.palette.custom.borderColor,
                      borderRadius: "4px",
                    },
                  },
                },
                layout: {
                  sx: {
                    ".MuiDateCalendar-root": {
                      color: theme.palette.custom.borderColor,
                      borderWidth: 1,
                      borderColor: theme.palette.custom.borderColor,
                      border: "2px solid",
                      backgroundColor: theme.palette.custom.backgroundColor,
                    },
                    ".MuiPickersDay-root": {
                      color: theme.palette.custom.text,
                      borderColor: theme.palette.custom.borderColor,
                      borderRadius: "4px",
                    },
                    ".Mui-selected": {
                      borderColor: theme.palette.custom.borderColor,
                      border: "2px solid",
                    },
                    ".MuiPickersCalendarHeader-root": {
                      color: theme.palette.custom.text,
                    },
                    ".MuiPickersCalendarHeader-switchViewButton": {
                      color: theme.palette.custom.text,
                    },
                    ".MuiDayCalendar-weekDayLabel": {
                      color: theme.palette.custom.text,
                    },
                  },
                },
              }}
              sx={{
                height: "36px",
                backgroundColor: theme.palette.custom.buttonColor,
                color: theme.palette.custom.buttonTextColor,
                margin: "0px 8px",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                },
                "& .MuiButtonBase-root": {
                  height: "30px",
                  color: theme.palette.custom.buttonTextColor,
                  // backgroundColor: theme.palette.custom.buttonColor,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: "4px",
                  },
                  height: "36px",
                  color: theme.palette.custom.buttonTextColor,
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.custom.text,
                },
              }}
              value={value}
              onChange={(newValue) => handleChangeDatePicker(newValue)}
              TextFieldComponent={(params) => (
                <TextField
                  sx={{
                    height: "20px",
                    backgroundColor: theme.palette.custom.text,
                    borderColor: theme.palette.custom.borderColor,
                    color: theme.palette.custom.text,
                  }}
                  fullWidth
                  defaultValue={{ undefined }}
                  {...params}
                />
              )}
            />
            <NextButton onClick={handleNext} />
          </Box>
        )}
        {tabValue === 0 && (
          <>
            <Tooltip title="Open settings">
              <StyledButton
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ width: "250px" }}
              >
                {startShift === "00:00" && endShift === "24:00"
                  ? t("AllDay")
                  : `${nameShift}: ${moment(startShift, "HH:mm:ss").format(
                      "HH:mm"
                    )} - ${moment(endShift, "HH:mm:ss").format("HH:mm")} `}
              </StyledButton>
            </Tooltip>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                key={0}
                onClick={() => handleChooseShift(null, "00:00", "24:00")}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.custom.borderColor,
                    color: theme.palette.custom.buttonColor,
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  color={theme.palette.custom.buttonTextColor}
                  textAlign="center"
                >
                  {t("AllDay")}
                </Typography>
              </MenuItem>
              {shifts &&
                shifts.map((item) => (
                  <MenuItem
                    key={Math.random()}
                    onClick={() =>
                      handleChooseShift(
                        item.name,
                        item.start_time,
                        item.end_time
                      )
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.custom.borderColor,
                        color: theme.palette.custom.buttonColor,
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{ color: theme.palette.custom.buttonTextColor }}
                      textAlign="center"
                    >
                      {item.name}:{item.start_time}-{item.end_time}
                    </Typography>
                  </MenuItem>
                ))}
              {/* {handleGetOptions(shifts)} */}
            </StyledMenu>
            <SimpleDialog
              // selectedValue={selectedValue}
              open={openDialog}
              onClose={handleCloseDialog}
            />
          </>
        )}
        {tabValue === 1 && (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <LastButton onClick={handleRangeLast} />
            <Tooltip title="Open settings">
              <StyledButton
                id="demo-customized-button"
                aria-haspopup="true"
                variant="contained"
                disableElevation
                onClick={handleClickDateRange}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  width: "fit-content",
                  margin: { xs: "12px 12px 12px 12px", sm: "0px" },
                }}
              >
                {dateRangelLabel}
              </StyledButton>
            </Tooltip>
            <NextButton onClick={handleRangeNext} />
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ display: "flex" }}>
            <ZoomOut
              disabled={zoomOutDisabled}
              onClick={() => handleZoomFunction("-")}
            />
            <StyledButton
              id="demo-zoom-button"
              variant="contained"
              disableElevation
              onClick={() => handleZoomFunction("-")}
              sx={{ margin: "0px 8px" }}
            >
              {t("ResetZoom")}
            </StyledButton>
            <ZoomIn onClick={() => handleZoomFunction("+")} />
          </Box>
        )}
        {tabValue === 2 && (
          <Box sx={{ height: "36px" }}>
            <TimePicker
              slotProps={{
                popper: {
                  sx: {
                    "& .MuiPaper-root": {
                      backgroundColor: theme.palette.custom.backgroundColor,
                      borderWidth: 4,
                      borderColor: theme.palette.custom.borderColor,
                      color: theme.palette.custom.borderColor,
                      border: "2px solid",
                    },
                    "& .MuiMenuItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                        color: theme.palette.custom.text,
                      },
                      color: theme.palette.custom.text,
                    },
                  },
                },
                layout: {
                  sx: {
                    ".MuiButtonBase-root": {
                      color: theme.palette.custom.text,
                    },
                  },
                },
              }}
              sx={{
                backgroundColor: theme.palette.custom.buttonColor,
                borderRadius: "4px",
                color: theme.palette.custom.text,
                margin: "0px",
                width: "150px",
                height: "36px",
                padding: "0px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                },
                "& .MuiButtonBase-root": {
                  color: theme.palette.custom.buttonTextColor,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: "8px",
                  },
                  height: "36px",
                  color: theme.palette.custom.buttonTextColor,
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.custom.text,
                },
                ".MuiDialogActions-root": {
                  color: theme.palette.custom.borderColor,
                  backgroundColor: theme.palette.custom.backgroundColor,
                },
                ".MuiPickersLayout-actionBar": {
                  color: theme.palette.custom.backgroundColor,
                },
              }}
              // label="From"
              value={fromValue}
              onChange={(newValue) => handleFromdateChange(newValue)}
              onClose={handleFromdateClose}
            />
            <TimePicker
              slotProps={{
                popper: {
                  sx: {
                    "& .MuiPaper-root": {
                      backgroundColor: theme.palette.custom.backgroundColor,
                      borderWidth: 4,
                      borderColor: theme.palette.custom.borderColor,
                      color: theme.palette.custom.borderColor,
                      border: "2px solid",
                    },
                    "& .MuiMenuItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                        color: theme.palette.custom.text,
                      },
                      color: theme.palette.custom.text,
                    },
                  },
                },
                layout: {
                  sx: {
                    ".MuiButtonBase-root": {
                      color: theme.palette.custom.text,
                    },
                  },
                },
              }}
              sx={{
                backgroundColor: theme.palette.custom.buttonColor,
                borderRadius: "4px",
                color: theme.palette.custom.text,
                margin: "0px 0px 0px 8px",
                width: "150px",
                height: "36px",
                padding: "0px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                },
                "& .MuiButtonBase-root": {
                  color: theme.palette.custom.buttonTextColor,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: "8px",
                  },
                  height: "36px",
                  color: theme.palette.custom.buttonTextColor,
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.custom.text,
                },
                ".MuiDialogActions-root": {
                  color: theme.palette.custom.borderColor,
                  backgroundColor: theme.palette.custom.backgroundColor,
                },
                ".MuiPickersLayout-actionBar": {
                  color: theme.palette.custom.backgroundColor,
                },
              }}
              //label="To"
              value={toValue}
              onChange={(newValue) => handleTodateChange(newValue)}
              onClose={handleTodateClose}
            />
          </Box>
        )}
      </Box>
      {showRange && (
        <Box sx={{ position: "absolute", left: "30%" }}>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            // onBlur={handleblur}
            staticRanges={staticRanges}
            inputRanges={[]}
            maxDate={getTomorrow()}
            minDate={getMinDate()}
          />
        </Box>
      )}
      {dashboardSource && isSignals ? (
        <Box
          sx={{
            display: "flex",
            flexFlow: { xs: "column", sm: "row" },
            width: "100%",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "23%" } }}>
            {device.DeviceTitleDataName !== "Device" ? (
              !loadingValue && device ? (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={4} sm={8} md={12} key={0}>
                      <div
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: GetMachineTypeWireframe(
                            `MachineType_Side_${device.DeviceType.replaceAll(
                              " ",
                              ""
                            )}.svg`
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={8} md={12} key={1}>
                      <div
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: GetMachineTypeWireframe(
                            `MachineType_Top_${device.DeviceType.replaceAll(
                              " ",
                              ""
                            )}.svg`
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={8} md={12} key={2}>
                      <div
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: GetMachineTypeWireframe(
                            `MachineType_Perspective_${device.DeviceType.replaceAll(
                              " ",
                              ""
                            )}.svg`
                          ).replace(
                            "theme.palette.custom.borderColor",
                            theme.palette.custom.borderColor
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ height: "30vh", pt: "10%" }}>
                  <Loading />
                </Box>
              )
            ) : null}
          </Box>
          <Divider
            sx={{
              display: { xs: "flex", sm: "none" },
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          <Divider
            orientation="vertical"
            sx={{
              display: { xs: "none", sm: "flex" },
              height: "500px",
              mx: "2%",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
          <Box sx={{ width: { xs: "100%", sm: "76%" } }}>
            <iframe
              className="GrafanaIFrame"
              name="grafana"
              id="grafana"
              // onload="iframeLoaded()"
              style={{ border: "none", height: "70vh" }}
              src={
                dashboardSource
                  ? dashboardSource
                  : `${config.url.grafana_url}d/MachineMultipleSignal?orgId=1&from=now-7d&to=now&kiosk=true&theme=Dark&var-Organization_Profile_ID=${organziation.uid}`
              }
              title="Dashboard"
              width="100%"
              height="500px"
            ></iframe>
          </Box>
        </Box>
      ) : dashboardSource ? (
        <iframe
          className="GrafanaIFrame"
          name="grafana"
          id="grafana"
          style={{ border: "none", height: "70vh" }}
          src={
            dashboardSource
              ? dashboardSource
              : `${config.url.grafana_url}d/MachineMultipleSignal?orgId=1&from=now-7d&to=now&kiosk=true&theme=Dark&var-Organization_Profile_ID=${organziation.uid}`
          }
          title="Dashboard"
          width="100%"
          height="500px"
        ></iframe>
      ) : null}
    </Box>
  );
}
