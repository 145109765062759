import axios from "axios";
import { config } from "../config";
import Utils from "../utils/utils";

class ProfileService extends Utils.EventEmitter {
    SetUserPassword(login_uid, person_profile_id, password) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                post: [
                    {
                        login_uid: login_uid,
                        person_profile_id: person_profile_id,
                        password: password,
                        status: "active"
                    }
                ]
            });
            const headers = {
                "Content-Type": "application/json"
            };
            axios
                .post(`${config.url.api_ip}/ns-core/person/profile/login/post`, body, headers)

                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);
                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    GetUserPassword(profileInfo) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get:
                {
                    login_uid: "",
                    person_profile_id: profileInfo.person_profile_id,
                    password: "",
                    status: "active"
                }

            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": profileInfo.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/person/profile/login/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    GetUserProfile(profileInfo) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get: {
                    profile_uid: "",
                    profile_id: profileInfo.userProfileID,
                    app_profile_uid: "",
                    firstname: "",
                    lastname: "",
                    tel_primary: "",
                    email_primary: "",
                    operation_profile_uid: "" || profileInfo.operation_profile_uid,
                    status: profileInfo.status
                }
            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": profileInfo.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/person/profile/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    SetUserProfile(person_profile_id, person_firstname, person_lastname, status = "", userType) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                post: [
                    {
                        profile_id: person_profile_id,
                        firstname: person_firstname,
                        lastname: person_lastname,
                        status: status,
                        user_type_uid: userType,
                        auto_assign_ticket: "false"
                    }
                ]
            });
            const headers = {
                "Content-Type": "application/json"
            };
            axios
                .post(`${config.url.api_ip}/ns-core/person/profile/post`, body, headers)

                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);
                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    GetUserProfilePicture(profileInfo) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get:
                {
                    person_profile_id: profileInfo.userProfileID,
                    image_type: "profile_image",
                    status: "active"
                }

            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": profileInfo.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/person/profile/image/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    SetUserProfilePicture(person_profile_id, form_id, apiKey) {
        let form = document.getElementById(form_id);
        let data = new FormData(form);
        // If you want to add an extra field for the FormData
        // data.append("CustomField", "This is some extra data, testing");
        data.append("person_profile_id", person_profile_id);
        data.append("status", "active");
        data.append("image_type", "profile_image");
        if (data.has("file")) {
            return new Promise((resolve, reject) => {
                const method = "POST";
                const body = data
                const headers = {
                    // "Content-Type": "application/json",
                    "apiKey": apiKey,
                    "apiSession": localStorage.getItem("session_id")
                };
                fetch(`${config.url.api_ip}/ns-core/person/profile/image/post`, {
                    method,
                    headers,
                    body
                })
                    .then((response) => response.json())
                    .then((json) => {
                        resolve(json);
                    })
                    .catch((err) => {
                        if (process.env.NODE_ENV === "development") console.log("err ", err);
                        reject(err.response !== undefined ? err.response.data.error_description : null);
                    });

            });
        }
    }
    SetUserContact({contact_uid, person_profile_uid, contact_type, value}) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                post: [
                    {
                        contact_uid,
                        person_profile_uid,
                        contact_type,
                        value,
                        primary_contact: "true",
                        status: "active"
                    }
                ]
            });
            const headers = {
                "Content-Type": "application/json"
            };
            axios
                .post(`${config.url.api_ip}/ns-core/person/profile/contact/post`, body, headers)

                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);
                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    GetUserContact() {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get: {}
            });
            const headers = {
                "Content-Type": "application/json"
            };
            axios
                .post(`${config.url.api_ip}/ns-core/person/profile/contact/post`, body, headers)

                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);
                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }

    GetUserType(profileInfo) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get: {
                    "user_type_uid": profileInfo.user_type_id,
                    "name": "",
                    "status": "active"
                }
            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": profileInfo.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/app/user/type/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }
    GetUserProfileAttributes({ userProfileID, apiKey }) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                get: {
                    operation_profile_id: "",
                    person_profile_id: userProfileID,
                    channel: "",
                    status: "",
                }
            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/person/profile/attribute/get`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }

    SetUserProfileAttributes({ attributes, person_profile_id, operation_profile_id, apiKey }) {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                post: attributes.map(a => ({ ...a, person_profile_id, operation_profile_id })),
                // [
                //     {
                //         "person_profile_id": "3",
                //         "operation_profile_id": "1",
                //         "channel": "measure_default",
                //         "value": "metric",
                //         "status": "active"
                //     }
                // ]

            });
            const headers = {
                "Content-Type": "application/json",
                "apiKey": apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/person/profile/attribute/post`, {
                method: "post",
                headers,
                body
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((err) => {
                    if (process.env.NODE_ENV === "development") console.log("err ", err);

                    reject(err.response !== undefined ? err.response.data.error_description : null);
                });
        });
    }


}

const instance = new ProfileService();

export default instance;
