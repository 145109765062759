/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
} from "../utils/systemInfo";

export const setMonitoringData = createAsyncThunk('monitoring/setMonitoringData', async (inputData) => {
  let RecordOPSUM = [];
  if (inputData === null) {
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent,
    inputData.jsonPrevious,
    inputData.machineTypeFilter,
  )
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && RecordOPSUM && RecordOPSUM.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return RecordOPSUM.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return RecordOPSUM;
});
function PopulateDataHomeOPSUM(jsonThisWeek, jsonLastWeek, machineTypeFilter = "") {
  let OpsumArray = [];
  const DevicesArray = jsonThisWeek.opsum.device;
  for (let index in DevicesArray) {
    let opsumDataObj = {
      MachineType: "",
      DeviceName: "",
      DeviceID: "",
      DeviceStatus: "",
      DeviceLatestOPSUM: "",
      DeviceLatestData: "",
      DeviceLatestRaw: "",
      DeviceLastSeen: "",
      NamedLocationOriginal: "",
      GrafanaDashboardUID: "",
      DeviceType: "",
      DeviceTypeIcon: "",
      DeviceTitleDataName: "Machine",
    };
    opsumDataObj.DeviceName = DevicesArray[index].profile.name;
    opsumDataObj.DeviceID = DevicesArray[index].profile.device_uid;
    const DeviceLatestData = DevicesArray[index].profile.latest_data_dst;
    opsumDataObj.DeviceLatestOPSUM = DevicesArray[index].profile.dst_last_opsum;
    opsumDataObj.DeviceLatestRaw = DevicesArray[index].profile.dst_last_raw;
    opsumDataObj.DeviceLastSeen = DevicesArray[index].profile.dst_last_seen;
    if (
      new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
      opsumDataObj.DeviceLastSeen.length === 0
    ) {
      opsumDataObj.DeviceLastSeen = DeviceLatestData;
      if (
        new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
        opsumDataObj.DeviceLastSeen.length === 0
      )
        opsumDataObj.DeviceLastSeen = " not seen";
    }

    // DeviceType = String(DevicesArray[index].profile.vehicle_type);
    // if (DeviceType == "") DeviceType = "Unknown";

    let MachineType = String(DevicesArray[index].profile.machine_type);
    opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

    let DeviceType = String(DevicesArray[index].profile.vehicle_type);
    if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
    const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
    opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;
    if (DeviceType !== machineTypeFilter && machineTypeFilter !== "") continue;

    opsumDataObj.DeviceStatus = "Inactive";
    if (DevicesArray[index].monitoring.active)
      opsumDataObj.DeviceStatus = "Active";
    // let NamedLocationOriginal = DevicesArray[index]?.location?.named?.current;
    // opsumDataObj.NamedLocationOriginal = NamedLocationOriginal;
    // if ((NamedLocationOriginal === undefined) || (NamedLocationOriginal === "")) {
    //   opsumDataObj.NamedLocationOriginal = `Unknown`;
    // }
    let LastLocation = DevicesArray[index]?.location?.named?.lookup?.name;
    if (LastLocation === undefined || LastLocation === "") {
      LastLocation = `Unknown`;
    }
    opsumDataObj.NamedLocationOriginal = LastLocation;

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMonitoringData.fulfilled, (state, action) => action.payload);
  },
});

export const selectMonitoring = ({ monitoring }) => monitoring;

export default monitoringSlice.reducer;
