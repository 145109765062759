import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import personService from "../../api/person";
import { config } from "../../config";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
  },
}));
function OrganizationsMenu(props) {
  const searchInputRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const user = useSelector(({ user }) => user);
  const [orgName, setOrgName] = useState("Select Organization");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [organizationsList, setOrganizationsList] = useState(null);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (organziation && user && user.organizations) {
      if (props.userProfileId) {
        const apiKey = organziation?.api_key_list?.[0]?.key;
        personService.GetUserOrganizations({
          operation_uid: "",
          person_id: props.userProfileId,
          apiKey,
        })
          .then((json) => {
            setOrganizationsList(json?.person_operation?.[0]?.operation?.map(org => ({ operation_profile_info: org })))
          })
          .catch((errorr) => {
            if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
          });
      }
      else {
        setOrganizationsList(
          user.organizations.filter(
            (x) => x.app_profile_info.full_name === config.url.api_app_name
          )
        );
      }
      setOrgName(organziation.full_name);
      let url = handleGetIcon(organziation, "true");
      document.getElementById("orgImg").firstElementChild.src = url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organziation]);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleGetIcon = (orgObj, selected) => {
    let imageurl = "logo-icon.png";
    if (selected === "true") {
      if (
        orgObj &&
        orgObj.operation_image_list &&
        orgObj.operation_image_list.length > 0
      ) {
        for (
          let index = 0;
          index < orgObj.operation_image_list.length;
          index++
        ) {
          if (
            orgObj.operation_image_list[index].image_type === "small_logo" &&
            orgObj.operation_image_list[index].public_url.includes(".png")
          )
            imageurl = orgObj.operation_image_list[index].public_url;
        }
      }
    }
    return imageurl;
  };
  const handleChooseOrganization = (userOrgObj) => {
    const operationProfileInfo = userOrgObj.operation_profile_info;
    setOrgName(operationProfileInfo.full_name);
    let url = handleGetIcon(operationProfileInfo, "true");
    document.getElementById("orgImg").firstElementChild.src = url;
    localStorage.setItem("selectedOrg", JSON.stringify(operationProfileInfo));
    setAnchorElUser(null);
  };
  function handleSearch(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && user && user.organizations) {
      setOrganizationsList(
        user.organizations.filter(
          (item) =>
            item.app_profile_info.full_name === config.url.api_app_name &&
            item.operation_profile_info.full_name
              .toLowerCase()
              .includes(newSearchText.toLowerCase())
        )
      );
      return;
    } else if (user && user.organizations) {
      setOrganizationsList(
        user.organizations.filter(
          (x) => x.app_profile_info.full_name === config.url.api_app_name
        )
      );
    }
  }
  return (
    <>
      <Tooltip title="Select Organization">
        <Button
          sx={{
            width: { xs: "100%", sm: "100%%" },
            borderColor: theme.palette.custom.borderColor,
            borderRadius: "11px",
            backgroundColor: theme.palette.custom.light,
            '&:hover': {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: 'none',
            }
          }}
          variant="text"
          onClick={handleOpenUserMenu}
          startIcon={
            <Avatar
              alt="Remy Sharp"
              id="orgImg"
              src={handleGetIcon(organziation, "false")}
            />
          }
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              mb: 0,
              color: theme.palette.custom.text,
            }}
            variant="h4"
            gutterBottom
          >
            {orgName}
          </Typography>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionProps={{
          onEntered: () => {
            searchInputRef.current.focus();
          },
        }}
      >
        {/* <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        value={searchText}
                        onChange={handleSearch}
                        inputRef={searchInputRef}
                        id="outlined-uncontrolled"
                       
                        defaultValue="foo"
                        sx={{ ml: '2%', width: '95%', borderRadius: '11px', backgroundColor: theme.palette.custom.light }}

                    />
                </Box> */}
        {/* <Input
                    //  inputRef={searchInputRef}
                    //value={searchText}
                    // onChange={handleSearch}
                    placeholder="Search for an app or page"
                    inputProps={{
                        'aria-label': 'Search',
                    }}
                    sx={{ ml: '2%', width: '95%', borderRadius: '11px', backgroundColor: theme.palette.custom.light }}
                    disableUnderline
                /> */}
        <TextField
          inputRef={searchInputRef}
          value={searchText}
          onChange={handleSearch}
          margin="normal"
          fullWidth
          id="search"
          label={t("Search")}
          name="search"
          autoFocus
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={{
            ml: "2%",
            width: "95%",
            borderRadius: "11px",
            backgroundColor: theme.palette.custom.light,
          }}
        />
        <Divider
          sx={{ my: "10px", backgroundColor: theme.palette.custom.light }}
        />
        {organizationsList &&
          organizationsList.map((setting) => (
            <MenuItem
              key={setting.operation_profile_info.uid}
              disabled={props.readOnly}
              onClick={() => handleChooseOrganization(setting)}
            >
              <Avatar
                sx={{ mr: 1 }}
                alt="Remy Sharp"
                src={handleGetIcon(setting.operation_profile_info, "true")}
              />
              <Typography textAlign="center">
                {setting.operation_profile_info
                  ? setting.operation_profile_info.full_name
                  : ""}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export default OrganizationsMenu;
