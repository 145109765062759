import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import CustomTextField from "./CustomTextField";

function SearchInput({searchText, handleSearch}) {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <CustomTextField
            value={searchText}
            onChange={handleSearch}
            margin="normal"
            fullWidth
            label={t("Search")}
            name="search"
            variant="filled"
            sx={{
                width: {xs: "100%", sm: "20%"},    
            }}
            InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
        />
    );
}

export default SearchInput;
