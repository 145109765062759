/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setTicketTemplatesData = createAsyncThunk("ticketTemplates/setTicketTemplatesData", async (inputData) => {
    let templates = [];
    if (inputData === null) {
        return null;
    }
    templates = PopulateTemplates(inputData.json);
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && templates && templates.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return templates.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return templates;
});
function PopulateTemplates(json) {
    let template = [];
    const TemplatesArray = json.ticket_template_header;
    for (let index in TemplatesArray) {
        let templateDataObj = {
            name: "",
            description: "",
            template_header_uid: ""
        };

        templateDataObj.name = TemplatesArray[index].name;
        templateDataObj.description = TemplatesArray[index].description;
        templateDataObj.template_header_uid = TemplatesArray[index].template_header_uid;
        template.push(templateDataObj);
    }
    return template;
}

const initialState = null;

const templatesSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTicketTemplatesData.fulfilled, (state, action) => action.payload);
    }
});

export const selectTemplate = ({templates}) => templates;

export default templatesSlice.reducer;
