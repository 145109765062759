import PersonAdd from "@mui/icons-material/PersonAdd";
import PersonRemove from "@mui/icons-material/PersonRemove";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";


const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  display:"flex",
  color: normalizeHexCode(theme.palette.custom.buttonTextColor),
  backgroundColor: theme.palette.custom.buttonColor,
  borderRadius: "4px",
  border: `1px solid ${theme.palette.custom.borderColor}`,
  // display: "flex",
  // justifyContent: "center",
  minWidth: "46px",
  height: "36px",
  padding: "5px 10px",
  '&:hover': {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: 'none',
  },
}));
function normalizeHexCode(hex) {
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }
  while (hex.length < 6) {
    hex = '0' + hex;
  }
  return '#' + hex;
}
function AssignButton({ type, title, text }) {
  return (
    <StyledBox title={title}>
      {type === "add" ? (
        <PersonAdd sx={{ fontSize: 24 }} />
      ) : (
        <PersonRemove sx={{ fontSize: 24 }} />
      )}
      &nbsp;
      <Typography sx={{whiteSpace:"nowrap"}}>{text}</Typography>
    </StyledBox>
  );
}

export default AssignButton;
