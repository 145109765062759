import { useRef, useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { setOrganization } from "../../store/organziationSlice";
import { setPermissions } from "../../store/permissionsSlice";
import personService from "../../api/person";
import { ContainsFileExtension } from "../../utils/systemInfo";

const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.custom.backgroundColor,
    color: theme.palette.custom.text,
  },
}));
function OrganizationsMenu(props, ref) {
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const user = useSelector(({ user }) => user);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [organizationsList, setOrganizationsList] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (user && user.organizations) {
      setOrganizationsList(
        user.organizations.filter(
          (x) => x.app_profile_info.full_name === config.url.api_app_name
        )
      );
    }
  }, [user]);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleGetIcon = (orgObj) => {
    let imageurl = "logo-icon.png";
    if (
      orgObj &&
      orgObj.operation_image_list &&
      orgObj.operation_image_list.length > 0
    ) {
      const imagesArray = orgObj.operation_image_list;
      for (let i in imagesArray) {
        if (
          imagesArray[i].image_type === "small_logo" &&
          imagesArray[i].status === "active"
        ) {
          if (ContainsFileExtension(imagesArray[i].public_url)) {
            imageurl = imagesArray[i].public_url;
            break;
          }
        }
      }
      //   for (let index = 0; index < orgObj.operation_image_list.length; index++) {
      //     if (
      //       orgObj.operation_image_list[index].image_type === "small_logo" &&
      //       orgObj.operation_image_list[index].public_url.includes(".png")
      //     )
      //       imageurl = orgObj.operation_image_list[index].public_url;
      //   }
    }
    return imageurl;
  };
  const handleChooseOrganization = (userOrgObj) => {
    const operationProfileInfo = userOrgObj.operation_profile_info;
    localStorage.setItem(
      "defaultOrganization",
      JSON.stringify(operationProfileInfo)
    );
    localStorage.setItem(
      "apiKey",
      operationProfileInfo?.api_key_list?.[0]?.key
    );
    dispatch(setOrganization(operationProfileInfo));
    window.location.reload();
    const operationPersonProfileInfo =
      userOrgObj.operation_person_profile_link_info;
    const apiKey = operationProfileInfo.api_key_list[0].key;
    const userType = operationPersonProfileInfo.user_type_uid;
    personService
      .getUserTypePermissions({ apiKey, userType })
      .then((json) => {
        const UserPermissions = [];
        if (
          json &&
          json.data &&
          json.data.app_user_type_permission_link &&
          json.data.app_user_type_permission_link.length > 0
        ) {
          for (let i in json.data.app_user_type_permission_link)
            UserPermissions.push(
              json.data.app_user_type_permission_link[i].permission_secondary
                .permission_secondary_uid
            );
          localStorage.setItem("permissions", JSON.stringify(UserPermissions));
          dispatch(setPermissions(UserPermissions));
        }
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
  };
  function handleSearch(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && user && user.organizations) {
      setOrganizationsList(
        user.organizations.filter(
          (item) =>
            item.app_profile_info.full_name === config.url.api_app_name &&
            item.operation_profile_info.full_name
              .toLowerCase()
              .includes(newSearchText.toLowerCase())
        )
      );
      return;
    } else if (user && user.organizations) {
      setOrganizationsList(
        user.organizations.filter(
          (x) => x.app_profile_info.full_name === config.url.api_app_name
        )
      );
    }
  }
  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.operation_profile_info.full_name.toUpperCase();
    const bandB = b.operation_profile_info.full_name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="text"
          onClick={handleOpenUserMenu}
          startIcon={
            <Avatar alt="Remy Sharp" src={handleGetIcon(organziation)} />
          }
        >
          <StyleSpan>
            {organziation && organziation.short_name
              ? organziation.short_name.toUpperCase()
              : ""}
          </StyleSpan>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionProps={{
          onEntered: () => {
            searchInputRef.current.focus();
          },
        }}
      >
        {/* <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        value={searchText}
                        onChange={handleSearch}
                        inputRef={searchInputRef}
                        id="outlined-uncontrolled"
                       
                        defaultValue="foo"
                        sx={{ ml: '2%', width: '95%', borderRadius: '11px', backgroundColor: theme.palette.custom.light }}

                    />
                </Box> */}
        {/* <Input
                    //  inputRef={searchInputRef}
                    //value={searchText}
                    // onChange={handleSearch}
                    placeholder="Search for an app or page"
                    inputProps={{
                        'aria-label': 'Search',
                    }}
                    sx={{ ml: '2%', width: '95%', borderRadius: '11px', backgroundColor: theme.palette.custom.light }}
                    disableUnderline
                /> */}
        <TextField
          inputRef={searchInputRef}
          value={searchText}
          onChange={handleSearch}
          margin="normal"
          fullWidth
          id="search"
          label={t("Search")}
          name="search"
          autoFocus
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={[
            { ml: "2%" },
            { width: "95%" },
            { borderRadius: "11px" },
            { backgroundColor: theme.palette.custom.inputBackgroundColor },
            {
              "&:active": {
                borderColor: theme.palette.custom.borderColor,
                color: theme.palette.custom.text,
              },
            },
          ]}
        />
        <Divider
          sx={{ my: "10px", backgroundColor: theme.palette.custom.light }}
        />
        {organizationsList &&
          organizationsList.sort(compare).map((setting) => (
            <MenuItem
              key={setting.operation_profile_info.uid}
              onClick={() => handleChooseOrganization(setting)}
              sx={{
                backgroundColor:
                  organziation?.uid === setting.operation_profile_info.uid &&
                  theme.palette.custom.borderColor,
              }}
            >
              <Avatar
                sx={{ mr: 1 }}
                alt="Remy Sharp"
                src={handleGetIcon(setting.operation_profile_info)}
              />
              <Typography
                sx={{ color: theme.palette.custom.text }}
                textAlign="center"
              >
                {setting.operation_profile_info
                  ? setting.operation_profile_info.full_name
                  : ""}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export default forwardRef(OrganizationsMenu);
