
import { config } from '../config'
import Utils from '../utils/utils'


class Notificationservice extends Utils.EventEmitter {
    GetOperationNotifications(inputData) {

        return new Promise((resolve, reject) => {
            const body = JSON.stringify({
                "get":{
                    "operation_uid": inputData.operation_profile_uid
                }
            });
            const headers = {
                "Content-Type": 'application/json',
                "apiKey": inputData.apiKey,
                "apiSession": localStorage.getItem("auth_token")
            };
            fetch(`${config.url.api_ip}/ns-core/operation/notifications/get`, {
                method: 'post',
                headers,
                body
            }).then((response) => response.json())
                //axios
                // .post(`${config.url.api_ip}/ns-core/device/opsum/query`, body, headers)

                .then(json => {
                    resolve(json);

                }).catch((err) => {
                    if (process.env.NODE_ENV === 'development') console.log('err ', err)
                    reject(err.response !== undefined ? err.response.data.error_description : null)
                });
        });
    }
}
const instance = new Notificationservice();

export default instance;