import React from 'react'
import { Box } from '@mui/material';
import Machine from './machine';

function MachineCompare() {
    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '1rem' }}>
                <Machine compare/>
                <Machine compare device2 />
        </Box>
    )
}
export default MachineCompare;
