

import { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import StyledTypography from '../common/StyledTypography';

export default function DeviceEventsTableRow({ row, handleClick, isItemSelected }) {

    return (
        <Fragment>
            <TableRow
                hover
                onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.name}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
            >
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.dst}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.battery_healthy_b}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.battery_level}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.battery_low_b}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.battery_voltage}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.device_healthy_b}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.external_temperature}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.internal_temperature}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.lat}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.lon}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.level_reading_at_present}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.payload.level_reading_at_present_1t}</StyledTypography>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}