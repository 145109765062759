import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { capitalize } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuItem, ListItemIcon } from "@mui/material/";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  GetDateTimeStringToday,
  GetDateTimeStringYesterday,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  GetDateTimeString30DaysAgo,
  GetDateTimeString90DaysAgo,
  GetDateTimeString2DaysAgo,
  GetDateTimeString60DaysAgo,
  GetDateTimeString180DaysAgo,
  GetDateTimeString28DaysAgo,
  GetMachineTypeIcon,
} from "../utils/systemInfo";
import moment from "moment";
import { setOpsumData } from "../store/opsumSlice";
import { setProductivityData } from "../store/productivitySlice";
import OpsumService from "../api/opsum";
import DevicesService from "../api/devices";
import HomePagesMenu from "../common/HomePagesMenu";
import Loading from "../common/loading";
import NumberTypography from "../common/NumberTypography";
import EnhancedTableHead from "../common/EnhancedTableHead";
import ProductivityTableRow from "./productivityTableRow";
import StyledTypography from "../common/StyledTypography";
import Breadcrumbs from "../common/breadcrumbs";

import SearchInput from "../common/searchInput.js";
import handleNavigate from "../common/customFunctions/handleNavigate.js";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Productivity", href: "productivity" },
];

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
  },
}));
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
}));
const StyleMachineSpan = styled((props) => <span {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    fontSize: "40px",
  })
);
const GraphicContainer = styled((props) => <div {...props} />)(({ theme }) => ({
  color: "white",
  display: "grid",
  gridTemplateColumns: "repeat(8, 1fr)",
  gridGap: "5px",
  gridAutoRows: "5rem",
  fontSize: "16px",
  fontWeight: "bold",
}));
const GridRows = styled((props) => <span {...props} />)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  paddingLeft: "20px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
const GridCols = styled((props) => <span {...props} />)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: "10px",
  width: "100%",
  textAlign: "center",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const timespanList = [
  {
    id: 0,
    label: "Day on Day",
    secondLabel: "Last 24 hours",
    timespan: "24 hours",
  },
  {
    id: 1,
    label: "Week on Week",
    secondLabel: "Last 7 days",
    timespan: "168 hours",
  },
  {
    id: 2,
    label: "Fortnight on Fortnight",
    secondLabel: "Last 14 days",
    timespan: "336 hours",
  },
  {
    id: 3,
    label: "Month on Month",
    secondLabel: "Last 30 days",
    timespan: "720 hours",
  },
  {
    id: 4,
    label: "Quarter on Quarter",
    secondLabel: "Last 90 hours",
    timespan: "2160 hours",
  },
];
const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceTotalWorkingTime",
    numeric: false,
    disablePadding: false,
    label: "WorkingTime",
    sort: false,
  },
  {
    id: "DeviceTotalIdleTime",
    numeric: false,
    disablePadding: false,
    label: "Idle Time",
    sort: false,
  },
  {
    id: "DeviceTotalOfflineTime",
    numeric: false,
    disablePadding: false,
    label: "Offline Time",
    sort: false,
  },
  {
    id: "DeviceTotalUnscheduledTime",
    numeric: false,
    disablePadding: false,
    label: "UnscheduledTime",
    sort: false,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function Productivity() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const opsum = useSelector(({ opsum }) => opsum);
  const productivity = useSelector(({ productivity }) => productivity);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorMachine, setAnchorMachine] = useState(null);
  const [machineDeviceName, setMachineDeviceName] = useState("Fleet");
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [machineDeviceArray, setMachineDeviceArray] = useState(["Fleet"]);
  const [dateTimeStringFrom, setDateTimeStringFrom] = useState(
    GetDateTimeString7DaysAgo()
  );
  const [dateTimeStringFromBefore, setDateTimeStringFromBefore] = useState(
    GetDateTimeString14DaysAgo()
  );
  const [dateTimeStringTo, setDateTimeStringTo] = useState(
    GetDateTimeStringToday()
  );
  const [dateRangeString, setDateRangeString] = useState("Last 7 days");
  const [timespanString, setTimespanString] = useState("168 hours");
  const [timeSpanMenuLabel, setTimespanMenuLabel] = useState("Week on Week");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);

  useEffect(() => {
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    if (apiKey) {
      //  const apiKey = organziation?.api_key_list?.[0]?.key;
      setOpsumInputData(null);

      DevicesService.getDevices({
        DeviceID: "",
        DeviceType: ["nerohub", "nanohub"],
        Source: "",
        apiKey,
        HistoryFromDST: "",
        HistoryToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            let machineTypesArray = ["Fleet"];
            for (let index = 0; index < json.device_profile.length; index++) {
              if (
                machineTypesArray.indexOf(
                  json.device_profile[index].vehicle_type
                ) === -1
              ) {
                machineTypesArray.push(json.device_profile[index].vehicle_type);
              }
            }

            setMachineDeviceArray(machineTypesArray);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
      OpsumService.getDeviceOpsumQuery({
        deviceID: "",
        deviceType: "nerohub",
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((jsonCurrent) => {
          OpsumService.getDeviceOpsumQuery({
            deviceID: "",
            deviceType: "nerohub",
            apiKey,
            historyFromDST: GetDateTimeString14DaysAgo(),
            historyToDST: GetDateTimeString7DaysAgo(),
          })
            .then((jsonPrevious) => {
              setOpsumInputData({
                jsonCurrent,
                jsonPrevious,
                machineTypeFilter: "",
                datePreviousFrom: GetDateTimeString14DaysAgo(),
                dateFrom: GetDateTimeString7DaysAgo(),
                dateto: GetDateTimeString7DaysAgo(),
              });
              dispatch(
                setOpsumData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter: "",
                })
              );
              dispatch(
                setProductivityData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter: "",
                  datePreviousFrom: GetDateTimeString14DaysAgo(),
                  dateFrom: GetDateTimeString7DaysAgo(),
                  dateto: GetDateTimeString7DaysAgo(),
                  newSearchText: undefined,
                })
              );
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });

      // dispatch(setOpsumData({
      //     deviceID: "",
      //     deviceType: "nerohub",
      //     apiKey,
      //     historyFromDST: GetDateTimeString7DaysAgo(),
      //     historyToDST: GetDateTimeStringToday()
      // }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenMachineMenu = (event) => {
    setAnchorMachine(event.currentTarget);
  };
  const handleCloseMachineMenu = () => {
    setAnchorMachine(null);
  };
  const handleChooseTimespan = (item) => {
    setLoadingValue(true);
    setSearchText(null);
    setTimespanMenuLabel(item.label);
    setTimespanString(item.timespan);
    setDateRangeString(item.secondLabel);
    let DataFrom;
    let DataFromBefore;
    const DataTo = GetDateTimeStringToday();
    switch (item.id) {
      case 0:
        DataFrom = GetDateTimeStringYesterday();
        DataFromBefore = GetDateTimeString2DaysAgo();
        break;
      case 1:
      default:
        DataFrom = GetDateTimeString7DaysAgo();
        DataFromBefore = GetDateTimeString28DaysAgo();
        break;
      case 2:
        DataFrom = GetDateTimeString14DaysAgo();
        DataFromBefore = GetDateTimeStringYesterday();
        break;
      case 3:
        DataFrom = GetDateTimeString30DaysAgo();
        DataFromBefore = GetDateTimeString60DaysAgo();
        break;
      case 4:
        DataFrom = GetDateTimeString90DaysAgo();
        DataFromBefore = GetDateTimeString180DaysAgo();
        break;
    }
    setDateTimeStringFromBefore(DataFromBefore);
    setDateTimeStringFrom(DataFrom);
    setDateTimeStringTo(DataTo);
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      OpsumService.getDeviceOpsumQuery({
        deviceID: "",
        deviceType: "nerohub",
        apiKey,
        historyFromDST: DataFrom,
        historyToDST: DataTo,
      })
        .then((jsonCurrent) => {
          OpsumService.getDeviceOpsumQuery({
            deviceID: "",
            deviceType: "nerohub",
            apiKey,
            historyFromDST: DataFromBefore,
            historyToDST: DataFrom,
          })
            .then((jsonPrevious) => {
              setOpsumInputData({
                jsonCurrent,
                jsonPrevious,
                machineTypeFilter:
                  machineDeviceName === "Fleet" ? "" : machineDeviceName,
                datePreviousFrom: DataFromBefore,
                dateFrom: DataFrom,
                dateto: DataTo,
              });
              dispatch(
                setOpsumData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter:
                    machineDeviceName === "Fleet" ? "" : machineDeviceName,
                })
              );
              dispatch(
                setProductivityData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter:
                    machineDeviceName === "Fleet" ? "" : machineDeviceName,
                  datePreviousFrom: DataFromBefore,
                  dateFrom: DataFrom,
                  dateto: DataTo,
                  newSearchText: undefined,
                })
              );
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setAnchorElUser(false);
  };
  const handleChooseMachine = (item) => {
    setLoadingValue(true);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = item;
    opsumInputObj.newSearchText = undefined;
    dispatch(setOpsumData(opsumInputObj));
    dispatch(setProductivityData(opsumInputObj));
    setMachineDeviceName(item);
    setAnchorMachine(false);
    setLoadingValue(false);
  };
  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter =
      machineDeviceName === "Fleet" ? "" : machineDeviceName;
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setProductivityData(opsumInputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (productivity && productivity.length > 0) {
        return stableSort(productivity, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, productivity]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  function handleView(event, row) {
    switch (row.DeviceTitleDataName) {
      case "Device":
        handleNavigate(event, navigate, `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`);
        // if (event.ctrlKey || event.button === 1) {
        //   window.open(
        //     `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`,
        //     "_blank"
        //   );
        // } else if (event.type === "click") {
        //   navigate(

        //   );
        // }
        break;
      case "Machine":
      default:
        handleNavigate(event, navigate, `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`);
        // if (event.ctrlKey || event.button === 1) {
        //   window.open(
        //     `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`,
        //     "_blank"
        //   );
        // } else if (event.type === "click") {
        //   navigate(

        //   );
        // }
        break;
    }
  }
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "block", sm: "center" },
            height: "64px",
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "flex" }, height: "inherit" }}>
            <HomePagesMenu pageName={"Productivity"} />
          </Box>
          <>
            <Tooltip title="Open settings">
              <Button
                variant="text"
                onClick={handleOpenMachineMenu}
                endIcon={
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "45px", color: theme.palette.custom.text }}
                  />
                }
              >
                <StyleMachineSpan>{machineDeviceName}</StyleMachineSpan>
              </Button>
            </Tooltip>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorMachine}
              open={Boolean(anchorMachine)}
              onClose={handleCloseMachineMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {machineDeviceArray &&
                machineDeviceArray.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleChooseMachine(item)}
                  >
                    <ListItemIcon>
                      {item !== "Fleet" && (
                        <div
                          style={{ width: "30px" }}
                          dangerouslySetInnerHTML={{
                            __html: GetMachineTypeIcon(item).replace(
                              "theme.palette.custom.text",
                              theme.palette.custom.text
                            ),
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <Typography textAlign="center">
                      {item ? item : ""}
                    </Typography>
                  </MenuItem>
                ))}
            </StyledMenu>
          </>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("DataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t(dateRangeString)}
            {` (${moment(dateTimeStringFrom).format("YYYY/MM/DD")} - ${moment(
              dateTimeStringTo
            ).format("YYYY/MM/DD")})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:{timespanString}
          </Typography>
        </Box>
        <>
          <Tooltip title="Open settings">
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: theme.palette.custom.text }}
                />
              }
            >
              <StyleSpan>{timeSpanMenuLabel}</StyleSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {timespanList &&
              timespanList.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleChooseTimespan(item)}
                >
                  <Typography textAlign="center">
                    {item.label ? item.label : ""}
                  </Typography>
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      </Box>
      <Box
        sx={{
          paddingTop: "12px",
          display: { xs: "block", sm: "flex" },
          justifyContent: "space-between",
          minHeight: "150px",
        }}
      >
        <Box
          sx={{
            height: "140px",
            width: { xs: "100%", sm: "23%" },
            marginBottom: { xs: 2, sm: 0 },
            p: "12px",
            textAlign: "center",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="h6"
            gutterBottom
          >
            {t("TotalWorkingTime")}
          </Typography>
          {loadingValue ? (
            <Box sx={{ pt: "20px" }}>
              <Loading />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              <StyledTypography>
                <Typography
                  sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                  variant="h4"
                  gutterBottom
                >
                  {opsum.TotalWorkingTime
                    ? Number(opsum.TotalWorkingTime / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;
                </Typography>{" "}
                <Typography variant="h4">{t("hours")}</Typography>
              </StyledTypography>
              <span
                title={`Previous: ${Number(
                  opsum.TotalWorkingTimeLastWeek / 60
                ).toFixed(2)}`}
              >
                {handleArrowIcon(
                  opsum.TotalWorkingTime,
                  opsum.TotalWorkingTimeLastWeek,
                  false,
                  36
                )}
              </span>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: "140px",
            width: { xs: "100%", sm: "23%" },
            marginBottom: { xs: 2, sm: 0 },
            p: "12px",
            textAlign: "center",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="h6"
            gutterBottom
          >
            {t("TotalIdleTime")}
          </Typography>
          {loadingValue ? (
            <Box sx={{ pt: "20px" }}>
              <Loading />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              <StyledTypography>
                <Typography
                  sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                  variant="h4"
                  gutterBottom
                >
                  {opsum.TotalIdleTime
                    ? Number(opsum.TotalIdleTime / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;
                </Typography>{" "}
                <Typography variant="h4">{t("hours")}</Typography>
              </StyledTypography>
              <span
                title={`Previous: ${Number(
                  opsum.TotalIdleTimeLastWeek / 60
                ).toFixed(2)}`}
              >
                {handleArrowIcon(
                  opsum.TotalIdleTime,
                  opsum.TotalIdleTimeLastWeek,
                  true,
                  36
                )}
              </span>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: "140px",
            width: { xs: "100%", sm: "23%" },
            marginBottom: { xs: 2, sm: 0 },
            p: "12px",
            textAlign: "center",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="h6"
            gutterBottom
          >
            {t("TotalOfflineTime")}
          </Typography>
          {loadingValue ? (
            <Box sx={{ pt: "20px" }}>
              <Loading />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              <StyledTypography>
                <Typography
                  sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                  variant="h4"
                  gutterBottom
                >
                  {opsum.TotalOfflineTime
                    ? Number(opsum.TotalOfflineTime / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;
                </Typography>{" "}
                <Typography variant="h4">{t("hours")}</Typography>
              </StyledTypography>
              <span
                title={`Previous: ${Number(
                  opsum.TotalOfflineTimeLastWeek / 60
                ).toFixed(2)}`}
              >
                {handleArrowIcon(
                  opsum.TotalOfflineTime,
                  opsum.TotalOfflineTimeLastWeek,
                  true,
                  36
                )}
              </span>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: "140px",
            width: { xs: "100%", sm: "23%" },
            marginBottom: { xs: 2, sm: 0 },
            p: "12px",
            textAlign: "center",
            borderRadius: "16px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="h6"
            gutterBottom
          >
            {t("TotalUnscheduledTime")}
          </Typography>
          {loadingValue ? (
            <Box sx={{ pt: "20px" }}>
              <Loading />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              <StyledTypography>
                <Typography
                  sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                  variant="h4"
                  gutterBottom
                >
                  {opsum.TotalUnscheduledTime
                    ? Number(opsum.TotalUnscheduledTime / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;
                </Typography>{" "}
                <Typography variant="h4">{t("hours")}</Typography>
              </StyledTypography>
              <span
                title={`Previous: ${Number(
                  opsum.TotalUnscheduledTimeLastWeek / 60
                ).toFixed(2)}`}
              >
                {handleArrowIcon(
                  opsum.TotalUnscheduledTime,
                  opsum.TotalUnscheduledTimeLastWeek,
                  false,
                  36
                )}
              </span>
            </Box>
          )}
        </Box>
      </Box>
      <SearchInput searchText={searchText} handleSearch={handleSearch} />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {productivity && productivity.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.DeviceName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ProductivityTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      handleArrowIcon={handleArrowIcon}
                      dateFromBefore={dateTimeStringFromBefore}
                      dateFrom={dateTimeStringFrom}
                      dateto={dateTimeStringTo}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleView={handleView}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={productivity ? productivity.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <GraphicContainer>
        <div
          style={{
            gridColumn: "1/9",
            backgroundColor: "#75B1E2",
          }}
          title="Calendar Time (CT)"
        >
          <GridRows>{t("CalendarTime")}</GridRows>
        </div>
        <div
          style={{
            gridColumn: "1/8",
            backgroundColor: "#2C90CA",
          }}
        >
          <GridRows title="Scheduled Time (ST)">{t("ScheduledTime")}</GridRows>
        </div>
        <div
          style={{
            gridColumn: " 8/9",
            gridRow: "2/7",
            backgroundColor: "#BCBDC0",
          }}
        >
          <GridCols title="Unscheduled (UT)">{t("Unscheduled")}</GridCols>
        </div>
        <div
          style={{
            gridColumn: "1/7",
            backgroundColor: "#3E83B8",
          }}
        >
          <GridRows title="Available Time (AT)">{t("AvailableTime")}</GridRows>
        </div>
        <div
          style={{
            gridColumn: "7/8",
            gridRow: "3/7",
            backgroundColor: "#FF7A2C",
          }}
        >
          <GridCols title="Downtime (DT)">{t("Downtime")}</GridCols>
        </div>
        <div
          style={{
            gridColumn: "1/6",
            backgroundColor: "#236A9A",
          }}
        >
          <GridRows title="Operating Time (OT)">{t("OperatingTime")}</GridRows>
        </div>
        <div
          style={{
            gridColumn: "6/7",
            gridRow: "4/7",
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "0.25fr 1fr",
            gridGap: "5px",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFB41D",
            }}
          >
            <GridCols title="Standby (SB)">{t("Standby")}</GridCols>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gridGap: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFB41D",
              }}
            >
              <GridCols id="SBO" title="Operating Standby (SBO)">
                {t("OperatingStandby")}
              </GridCols>
            </div>
            <div
              style={{
                backgroundColor: "#FFB41D",
              }}
            >
              <GridCols id="SBE" title="External Standby (SBE)">
                {t("ExternalStandby")}
              </GridCols>
            </div>
          </div>
        </div>
        <div
          style={{
            gridColumn: "1/5",
            backgroundColor: "#084B7A",
          }}
        >
          <GridRows title="Working Time (WT)">{t("WorkingTime")}</GridRows>
        </div>
        <div
          style={{
            gridColumn: "5/6",
            gridRow: "5/7",
            backgroundColor: "#FF7A2C",
          }}
        >
          <GridCols title="Operating Delay (OD)">
            {t("OperatingDelay")}
          </GridCols>
        </div>
        <div
          style={{
            gridColumn: "1/4",
            backgroundColor: "#002D65",
          }}
        >
          <GridRows title="Productive Time (PT)">
            {t("ProductiveTime")}
          </GridRows>
        </div>
        <div
          style={{
            backgroundColor: "#98B2B2",
          }}
        >
          <GridCols title="Non-Productive (NP)">{t("NonProductive")}</GridCols>
        </div>
      </GraphicContainer>
    </Box>
  );
}

export default Productivity;
