import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import { useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import StyledTableCell from "./StyledTableCell";

import utils from "../utils/utils";
import { UserPermissionConstants } from "../utils/permissions";

export default function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    hideCheck,
    isDevice,
    vertical,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const permissions = useSelector(({ permissions }) => permissions);
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {!hideCheck && <StyledTableCell padding="checkbox"></StyledTableCell>}

        {headCells.map((headCell) =>
          headCell.id === "user_type_uid" &&
            utils.hasPermission(
              UserPermissionConstants.VIEW_DEVELOPER_PORTAL,
              permissions
            ) ? (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{
                    writingMode: vertical ? "vertical-lr" : "",
                    textOrientation: "mixed",
                  }}
                >
                  {t(headCell.label)}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                t(headCell.label)
              )}
            </StyledTableCell>
          ) : headCell.id !== "user_type_uid" ? (
            isDevice && headCell.id === "NamedLocationOriginal" ? null : (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.sort ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                    style={{
                      writingMode: vertical ? "vertical-lr" : "",
                      textOrientation: "mixed",
                    }}
                  >
                    {t(headCell.label)}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  t(headCell.label)
                )}
              </StyledTableCell>
            )
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}
