import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import ProjectService from "../api/project";
import { setMachineCommentsData } from "../store/machineStatusCommentSlice";
import MachineCommentTableRow from "./machineStatusCommentTableRow";
import { stableSort, getComparator, getUrlVars } from "../utils/systemInfo";
import SearchInput from "../common/searchInput";
import Breadcrumbs from "../common/breadcrumbs";
import StyledTypography from "../common/StyledTypography";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({ theme }) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));

const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    { id: 1, name: "Machine Status", href: "machineStatus" },
    { id: 1, name: "Machine Comments", href: "machineComments" }
];
const commentHead = [
    {
        id: "createdBy",
        numeric: false,
        disablePadding: true,
        label: "Created By",
        sort: true
    },
    {
        id: "createdTime",
        numeric: false,
        disablePadding: false,
        label: "Created Timestamp",
        sort: true
    },
    {
        id: "comment",
        numeric: false,
        disablePadding: false,
        label: "Comment",
        sort: true
    }
];

export default function MachineStatusCommentsPage() {
    const organziation = useSelector(({ organziation }) => organziation);
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const machineComments = useSelector(({ machineComments }) => machineComments);
    const [commentInputData, setCommentInputData] = useState(undefined);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("firstname");
    const [pageComment, setPageComment] = useState(0);
    const [rowsPerPageComment, setRowsPerPageComment] = useState(Number(localStorage.getItem("rowsPerPageComment")) || 5);
    const [searchTextComment, setSearchTextComment] = useState("");
    const [loadingValueComment, setLoadingValueComment] = useState(true);
    const machineName = getUrlVars()["name"];
    const visibleRowsComment = useMemo(
        () => {
            if (machineComments?.length) {
                return stableSort(machineComments, getComparator(order, orderBy)).slice(pageComment * rowsPerPageComment, pageComment * rowsPerPageComment + rowsPerPageComment);
            } else {
                return [];
            }
        },
        [order, orderBy, pageComment, rowsPerPageComment, machineComments]
        //[page, rowsPerPage, safety],
    );

    function handleSearchComment(ev) {
        setLoadingValueComment(true);
        const newSearchText = ev.target.value;
        setSearchTextComment(newSearchText);
        let commentInputObj = commentInputData;
        commentInputObj.newSearchText = newSearchText;
        dispatch(setMachineCommentsData(commentInputObj));
        setLoadingValueComment(false);
    }
    const handleChangeRowsPerPageComment = (event) => {
        setRowsPerPageComment(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPageComment", event.target.value);
        setPageComment(0);
    };
    const handleChangePageComment = (event, newPage) => {
        setPageComment(newPage);
    };
    const handleRequestSortComment = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    useEffect(() => {
        setCommentInputData(null);
        const apiKey = organziation?.api_key_list?.[0]?.key;
        const groupID = getUrlVars()["groupId"];
        ProjectService.GetGroupComment(groupID, "", "", "active", apiKey)
            .then((json) => {
                if (json && json.comment_task_group && json.comment_task_group.length > 0) {
                    setCommentInputData({ json: json.comment_task_group });
                    dispatch(setMachineCommentsData({ json: json.comment_task_group }));
                }
                setLoadingValueComment(false);
            })
            .catch((error) => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{ height: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography
                    sx={{
                        fontSize: "45px",
                        mb: 0,
                        color: theme.palette.custom.borderColor
                    }}
                    gutterBottom>
                    {t("Comments")} {t("for")} {machineName}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <SearchInput searchText={searchTextComment} handleSearch={handleSearchComment} />
            {loadingValueComment ? (
                <Box sx={{ height: "30vh", pt: "10%" }}>
                    <Loading />
                </Box>
            ) : (
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    //  size={'medium'}
                    >
                        <EnhancedTableHead headCells={commentHead} order={order} orderBy={orderBy} onRequestSort={handleRequestSortComment} hideCheck />

                        <TableBody>
                            {machineComments &&
                                machineComments.length > 0 &&
                                visibleRowsComment.length ? visibleRowsComment.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <MachineCommentTableRow labelId={labelId} key={index} row={row}></MachineCommentTableRow>;
                                })
                                : <StyledTypography>{t("No entries found")}</StyledTypography>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <StyledTablePagination
                SelectProps={{
                    inputProps: {
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.secondary.dark,
                                    "color": theme.palette.custom.text,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.custom.borderColor
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "blue"
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: "blue"
                                    }
                                }
                            }
                        }
                    }
                }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={machineComments ? machineComments.length : 0}
                rowsPerPage={rowsPerPageComment}
                page={pageComment}
                onPageChange={handleChangePageComment}
                onRowsPerPageChange={handleChangeRowsPerPageComment}
            />
        </Box>
    );
}
