import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../common/breadcrumbs";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "Change Log", href: "changelog"}
];
function Changelog() {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                minHeight: "90vh",
                height: "fit-content"
            }}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: {xs: "block", sm: "center"}, height: "64px"}}>
                <Box sx={{display: {xs: "block", sm: "flex"}}}>
                    <Box sx={{width: "100%"}}>
                        {
                            //<HomePagesMenu pageName={'Maintenance'} />
                        }
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Typography sx={{fontSize: "32px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                            {
                                // {capitalize(t('Devices'))}
                            }
                            {t("Change Log")}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />

            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.6.0 (2024-03-04)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Improved Site Layout (Map)")}</li>
                    <li>{t("Improved Theming System")}</li>
                    <li>{t("Improved Notifications")}</li>
                    <li>{t("Improved Dashboards Filtering and Sorting")}</li>
                    <li>{t("Improved Helpdesk")}</li>
                    <li>{t("New Breadcrumb Navigation")}</li>
                    <li>{t("New dynamic and better defined Page Titles")}</li>
                    <li>{t("New 'Quarter on Quarter' timerange selection")}</li>
                    <li>
                        <span>{t("Multi-language support")}</span>
                        <ul>
                            <li>{t("Improved German translations")}</li>
                            <li>{t("Improved Afrikaans translations")}</li>
                            <li>{t("Added Support for French Language")}</li>
                            <li>{t("Added Support for Italian Language")}</li>
                            <li>{t("Added Support for Mongolian Language")}</li>
                            <li>{t("Added Support for Russian Language")}</li>
                            <li>{t("Added Support for Spanish Language")}</li>
                        </ul>
                    </li>
                    <li>
                        <span>{t("New Admin Portal")}</span>
                        <ul>
                            <li>{t("Assign Users")}</li>
                            <li>{t("Assign Devices")}</li>
                            <li>{t("Assign User Devices")}</li>
                        </ul>
                    </li>
                    <li>
                        <span>{t("Developer Portal")}</span>
                        <ul>
                            <li>{t("New 'Signal File Upload'")}</li>
                            <li>{t("New 'Machine Roll Out'")}</li>
                            <li>{t("Improved Dashboard Editing")}</li>
                        </ul>
                    </li>
                    <li>{t("General Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.5.0 (2022-11-16)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Improved Site Layout (Map)")}</li>
                    <li>{t("Helpdesk Ticketing System")}</li>
                    <li>{t("Improved Theming System")}</li>
                    <li>{t("Improved multi-language support")}</li>
                    <li>{t("Improved Notifications")}</li>
                    <li>{t("Bug Fixes")}</li>
                    <li>
                        <span>{t("Developer Portal")}</span>
                        <ul>
                            <li>{t("Assign Users")}</li>
                            <li>{t("Assign Devices")}</li>
                            <li>{t("File Upload")}</li>
                            <li>{t("Grafana Backend")}</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.4.0 (2022-08-15)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Improved Theming System")}</li>
                    <li>{t("Improved multi-language support")}</li>
                    <li>{t("Machine Last Seen Location")}</li>
                    <li>{t("Improved Site Layout (Map)")}</li>
                    <li>{t("Enhanced Machine Maintenance and Scheduling")}</li>
                    <li>{t("Multi-Signal select for Machine Sensors Data")}</li>
                    <li>{t("Individual Machine/Device notifications from detailed page")}</li>
                    <li>{t("Improved User Interface")}</li>
                    <li>{t("Bug Fixes")}</li>
                    <li>
                        <span>{t("Developer Portal")}</span>
                        <ul>
                            <li>{t("Device Maintenance Types")}</li>
                            <li>{t("Improvements to User SignIn Logger")}</li>
                            <li>{t("User Audit Trails")}</li>
                            <li>{t("Named Location Config")}</li>
                            <li>{t("Grafana Backend")}</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.3.0 (2022-03-31)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Theming System")}</li>
                    <li>{t("Improved multi-language support")}</li>
                    <li>{t("Improved Organization Switching")}</li>
                    <li>{t("Improved permissions system")}</li>
                    <li>{t("New Dashboards Dropdown")}</li>
                    <li>{t("Bug Fixes")}</li>
                    <li>
                        <span>{t("Developer Portal")}</span>
                        <ul>
                            <li>{t("Gitea")}</li>
                            <li>{t("Server Health")}</li>
                            <li>{t("Language API")}</li>
                            <li>{t("Themes API")}</li>
                            <li>{t("API Keys")}</li>
                            <li>{t("API Endpoints")}</li>
                            <li>{t("User Types")}</li>
                            <li>{t("Device Error Codes")}</li>
                            <li>{t("User SignIn Logger")}</li>
                            <li>{t("Sitemap")}</li>
                            <li>{t("SIMAC History")}</li>
                            <li>{t("HUB Server Config")}</li>
                            <li>{t("Embarcadero AppWave")}</li>
                            <li>{t("Xneelo cPanel")}</li>
                            <li>{t("WordPress Websites")}</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.2.2 (2022-02-01)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("New Dashboards Page")}</li>
                    <li>{t("Improved multi-language support")}</li>
                    <li>{t("Raw Data Table View for IoT Devives")}</li>
                    <li>{t("Loading Animation Circle for more pages")}</li>
                    <li>{t("Performance Improvements")}</li>
                    <li>{t("Machine 3D View")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.2.1 (2021-12-10)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("User Interface Improvements")}</li>
                    <li>{t("Performance Improvements")}</li>
                    <li>{t("IoT Devices Integration")}</li>
                    <li>{t("Pinger Sites/Sims Integration")}</li>
                    <li>{t("Loading Animation Circle for pages")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.2.0 (2021-09-27)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("New Home Page")}</li>
                    <li>{t("New Mobile UI")}</li>
                    <li>{t("Improved loading time on Machine Page")}</li>
                    <li>{t("Improved Time/Date range picker")}</li>
                    <li>{t("Improved Organization/User Management")}</li>
                    <li>{t("Machine Events now visible on Machine Page")}</li>
                    <li>{t("Machine Signals now visible on Machine Page")}</li>
                    <li>{t("New Site Layout (Map) Pins")}</li>
                    <li>{t("UI is 10% smaller")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.1.0 (2021-07-14)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Machine Alerts/Notifications")}</li>
                    <li>{t("New Machine Page")}</li>
                    <li>{t("Machine Comparison Page")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                2.0.0 (2021-07-01)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Rewrite of User Interface")}</li>
                    <li>{t("New Organizations Page")}</li>
                    <li>{t("Multilanguage Support (English, German, Afrikaans)")}</li>
                    <li>{t("Improved Site Layout (Map)")}</li>
                    <li>{t("Expand/Collapse functionality for Users List")}</li>
                    <li>{t("Performance Improvements")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.4.1 (2021-05-20)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Performance Improvements")}</li>
                    <li>{t("Bug Fixes")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.4.0 (2021-05-18)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("New Users Page")}</li>
                    <li>{t("Ability to edit profile")}</li>
                    <li>{t("Ability to view other users' profile")}</li>
                    <li>{t("New Change Log Page")}</li>
                    <li>{t("Organization Selection")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.3.0 (2021-04-15)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("New Login Page")}</li>
                    <li>{t("New User Profile Page")}</li>
                    <li>{t("New Contact Us Page")}</li>
                    <li>{t("New Documentation Page")}</li>
                    <li>{t("Export Machine List to CSV")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.2.0 (2021-03-01)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Rewrite of User Interface")}</li>
                    <li>{t("New Home Page with Machine List")}</li>
                    <li>{t("New Dashboards Page")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.1.0 (2020-09-30)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Implemented Themes (Dark Mode)")}</li>
                </ul>
            </Typography>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Typography sx={{fontSize: "28px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                1.0.0 (2020-08-06)
            </Typography>
            <Typography sx={{fontSize: "14.4px", mb: 0, color: theme.palette.custom.text}} variant="h3" gutterBottom>
                <ul>
                    <li>{t("Initial Release")}</li>
                </ul>
            </Typography>
        </Box>
    );
}
export default Changelog;
