import { UploadFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StyledTypography from "./StyledTypography";

function ImportButton({ onClick }) {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: theme.palette.custom.buttonColor,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        display: "flex",
        // justifyContent: "center",
        marginTop: "5px",
        height: "40px",
        padding: "5px 10px",
        alignItems: "center",
        textAlign: "center",
        color: theme.palette.custom.buttonTextColor,
        "&:hover": {
          backgroundColor: theme.palette.custom.buttonHoverColor,
          color: theme.palette.custom.buttonHoverTextColor,
          boxShadow: "none",
        },
      }}
    >
      <UploadFile
        onClick={onClick}
        sx={{
          color: theme.palette.custom.buttonTextColor,
          fontSize: 24,
          "&:hover": {
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
        }}
      />
      <StyledTypography
        sx={{
          textAlign: "center",
          paddingTop: "3px",
          ml: "2px",
          "&:hover": {
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
        }}
      >
        Import CSV
      </StyledTypography>
    </Button>
  );
}

export default ImportButton;
