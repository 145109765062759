import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from '../common/breadcrumbs';
import { useNavigate } from "react-router-dom";
import { setDashboard } from "../store/dashboardSlice";
import DashboardsService from "../api/dashboards";
import CustomTextField from "../common/CustomTextField";
import { setDashboardsOrganizationLinkings } from "../store/dashboardsOrganizationLinkingsSlice";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};




const initDashboard = {
    grafana_dashboard_id: "",
    grafana_dashboard_link_id: "",
    operation_profile_id: "",
    order_by: "",
    status: "active"
}

async function handleSave({ operation_profile_id, grafana_dashboard_id, status, grafana_dashboard_link_id, order_by, api_key_current }) {
    let saved = false;
    await DashboardsService.SetGrafanaDashboardOperationLink(grafana_dashboard_link_id, operation_profile_id, grafana_dashboard_id, status, order_by, api_key_current).then(async (json) => {
        if (json.success === true && json.count > 0) {
            saved = true;
        }
    });
    return saved;
}

const breadcrumbs = [
    { id: 0, name: 'Home', href: './' },
    { id: 1, name: 'Dashboards', href: 'dashboards' },
    { id: 2, name: 'Add Organization Link', href: 'addEditOrganizationLink' },
];
function AddOrganizationLink(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboardsOrganizationLinking = useSelector(({ dashboardsOrganizationLinking }) => dashboardsOrganizationLinking);
    const { t } = useTranslation();
    const theme = useTheme();
    const [operationProfileId, setOperationProfileId] = useState("");
    const [status, setStatus] = useState("");
    const [grafanaDashboardId, setGrafanaDashboardId] = useState("");
    const organziation = useSelector(({ organziation }) => organziation);


    useEffect(() => {
        setOperationProfileId(dashboardsOrganizationLinking.operation_profile_id);
        setGrafanaDashboardId(dashboardsOrganizationLinking.grafana_dashboard_id);
        setStatus(dashboardsOrganizationLinking.status);
        return () => {
            dispatch(setDashboard(initDashboard));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardsOrganizationLinking]);
    async function onSaveClick() {
        const dashboardToSave = {
            grafana_dashboard_id: grafanaDashboardId,
            operation_profile_id: operationProfileId,
            status,
            grafana_dashboard_link_id: dashboardsOrganizationLinking.grafana_dashboard_link_id,
            order_by: "",
            api_key_current: organziation?.api_key_list?.[0].key
        }
        const saved = await handleSave(dashboardToSave);
        if (saved) {
            DashboardsService.getGrafanaDashboardOperationLink({
                grafana_dashboard_link_id: "",
                operation_profile_id: "",
                grafana_dashboard_id: "",
                status: "",
                apiKey: organziation?.api_key_list?.[0]?.key,
            }).then((json) => {
                if (json.grafana_dashboard_operation_link?.length) {
                    dispatch(
                        setDashboardsOrganizationLinkings({
                            newSearchText: "",
                            data: json.grafana_dashboard_operation_link,
                        })
                    );
                }
            }).catch((errorr) => {
                if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
            });
            navigate('/grafanaBackend');
        }
    }
    return (
        <Box
            sx={{ p: "12px", borderRadius: "16px", border: `1px solid ${theme.palette.custom.borderColor}` }}
            style={{ height: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.borderColor }} gutterBottom>
                    {t("Add/Edit Grafana Dashboard Organization Linking")}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%", flexDirection: "row", color: "#FFFFFF" }}>
                <Box sx={{ display: "flex", justifyContent: "stretch", width: "100%", gap: "3rem" }}>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "100%" }}>
                        <CustomTextField
                            value={operationProfileId}
                            onChange={(e) => {
                                setOperationProfileId(e.target.value);
                            }}
                            id="txtOperationProfileId"
                            label={t("organizationId")}
                            variant="filled"
                            margin="normal"
                        />
                        <CustomTextField
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            id="txtStatus"
                            label={t("Status")}
                            variant="filled"
                            margin="normal"
                        />
                    </Box>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "100%" }}>
                        <CustomTextField
                            value={grafanaDashboardId}
                            onChange={(e) => {
                                setGrafanaDashboardId(e.target.value);
                            }}
                            id="txtGrafanaDashboardId"
                            label={t("grafanaDashboardId")}
                            variant="filled"
                            margin="normal"
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                <Button
                    variant="contained"
                    sx={{
                        "backgroundColor": theme.palette.custom.borderColor,
                        "color": "white",
                        '&:hover': {
                            backgroundColor: theme.palette.custom.buttonHoverColor,
                            color: theme.palette.custom.buttonHoverTextColor,
                            boxShadow: 'none',
                        }
                    }}
                    onClick={onSaveClick}>
                    Save
                </Button>
            </Box>
        </Box>
    );
}

export default AddOrganizationLink;
