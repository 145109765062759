import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import TableGreenTypography from "../common/TableGreenTypography";
import StyledTypography from "../common/StyledTypography";
import AssignButton from "../common/assignButton";
import OrganizationService from "../api/organization";
import AlertDialog from "../common/AlertDialog";
import { LatestDataColoring } from "../utils/systemInfo";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../store/messageSlice";

const CustomSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.custom.inputBackgroundColor,
  height: "36px",
  color: theme.palette.custom.text,
  outlineColor: theme.palette.custom.borderColor,
  "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.custom.borderColor,
    color: theme.palette.custom.borderColor,
  },
  "&.Mui-focused": {
    color: theme.palette.custom.text,
  },
  "&:focused": {
    color: theme.palette.custom.borderColor,
  },
  "& .MuiList-root": {
    backgroundColor: theme.palette.custom.inputBackgroundColor,
  },
}));
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.custom.text,
  "&.Mui-focused": {
    color: theme.palette.custom.text,
  },
  left: "7px",
  top: "-7px",
}));

const typesList = [
  {
    id: 0,
    label: "Owner",
  },
  {
    id: 1,
    label: "Developer",
  },
  {
    id: 2,
    label: "Viewer",
  },
  {
    id: 3,
    label: "Installer",
  },
];
export default function DevToUserDeviceTableRow({
  row,
  labelId,
  orgUid,
  handleChangeData,
  iKey,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  function handleAssign(isLink) {
    setSaveLoading(true);
    let org = JSON.parse(localStorage.getItem("assignToOrg"));
    if (row.permission !== 0) {
      OrganizationService.SetPersonDeviceLink({
        operation_uid: org.uid,
        device_id: row.DevicePID,
        person_id: localStorage.getItem("assignToUser"),
        status: isLink ? "link" : "unlink",
        apiKey: org.apiKey,
      }).then((json) => {
        if (json) {
          if (isLink) {
            OrganizationService.SetPersonDevicePermission({
              operation_uid: org.uid,
              device_id: row.DevicePID,
              person_id: localStorage.getItem("assignToUser"),
              permission: row.permission,
              apiKey: org.apiKey,
            }).then((json) => {
              if (json) {
                dispatch(
                  showMessage({
                    message: t("Device Assigned Successfully"), //text or html
                    autoHideDuration: 10000, //ms
                    anchorOrigin: {
                      vertical: "top", //top bottom
                      horizontal: "center", //left center right
                    },
                    variant: "success", //success error info warning null
                  })
                );
                handleChangeData(row, isLink, row.permission);
              }
              setSaveLoading(false);
              setOpenDialog(false);
            });
          } else {
            dispatch(
              showMessage({
                message: t("Device Unassigned Successfully"), //text or html
                autoHideDuration: 10000, //ms
                anchorOrigin: {
                  vertical: "top", //top bottom
                  horizontal: "center", //left center right
                },
                variant: "success", //success error info warning null
              })
            );
            handleChangeData(row, isLink);
            setSaveLoading(false);
            setOpenDialog(false);
          }

          //  handleChangeData(row, isLink);
        }
        // setSaveLoading(false);
        // setOpenDialog(false);
      });
    } else {
      window.alert("Select a user type first.");
      setSaveLoading(false);
      setOpenDialog(false);
    }
  }

  function handleChangeUserType(event) {
    let isLink =
      row.status === "active" || row.status === "link" ? true : false;
    handleChangeData(row, isLink, event.target.value);
  }
  function getButton() {
    const status = row.status;
    if (status === "Assigned") {
      return (
        <Button
          className="viewUser"
          hidden
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <AssignButton type={"remove"} title={"Unassign"} />
        </Button>
      );
    } else {
      return (
        <Button
          className="viewUser"
          hidden
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <AssignButton type={"add"} title={"Assign"} />
        </Button>
      );
    }
  }
  function handleClose() {
    setOpenDialog(false);
  }
  return (
    <>
      <TableRow hover tabIndex={-1} key={iKey} sx={{ cursor: "pointer" }}>
        <TableCell
          sx={{
            height: "100%",
            color: theme.palette.custom.text,
            padding: "16px",
          }}
          component="th"
          id={labelId}
          scope="row"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "left",
            }}
          >
            <StyledTypography variant="subtitle2">
              {row.DeviceName}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.DeviceTitleDataName === "Device" ||
            isNaN(row.DeviceSerialHex) ||
            !row.DeviceSerial
              ? row.DeviceSerial
              : `${row.DeviceSerialHex} (${row.DeviceSerial})`}
          </StyledTypography>
        </TableCell>
        <TableCell
          sx={{
            height: "100%",
            color: theme.palette.custom.text,
            padding: "16px",
          }}
          component="th"
          id={labelId}
          scope="row"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <div
              style={{ width: "40px" }}
              dangerouslySetInnerHTML={{
                __html: row.DeviceTypeIcon.replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />{" "}
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.DeviceType}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="subtitle2"
            color={row.status === "Assigned" && "green"}
          >
            {row.status}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <TableGreenTypography
            variant="subtitle2"
            color={LatestDataColoring(row.DeviceLastSeen, true)}
          >
            {row.DeviceLastSeen !== "Not set" &&
            row.DeviceLastSeen !== " not seen"
              ? moment
                  .utc(row.DeviceLastSeen)
                  .add(timezoneMins, "minutes")
                  .format("YYYY-MM-DD HH:mm")
              : row.DeviceLastSeen}
          </TableGreenTypography>
        </TableCell>
        <TableCell align="left">
          <FormControl fullWidth>
            <CustomInputLabel>{t("User Type")}</CustomInputLabel>
            <CustomSelect
              labelId="User Type"
              value={row.permission}
              label="User Type"
              onChange={handleChangeUserType}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.custom.inputBackgroundColor,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: theme.palette.custom.buttonHoverColor,
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: theme.palette.custom.backgroundColor2,
                    },
                  },
                },
              }}
            >
              <MenuItem value={0} key={"999"}>
                {t("Select User Type")}
              </MenuItem>
              {typesList.map((e) => {
                return (
                  <MenuItem key={e.id} value={e.label.toLowerCase()}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </CustomSelect>
          </FormControl>
        </TableCell>
        <TableCell align="left">{getButton(row.status)}</TableCell>
      </TableRow>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={() => handleAssign(!(row.status === "Assigned"))}
        saveLoading={saveLoading}
        dialogTitle={
          row.status === "Assigned"
            ? t("Are you sure you want to unassign this machine/device?")
            : t("Are you sure you want to assign this machine/device?")
        }
        cancelLabel={t("Cancel")}
        buttonLabel={row.status === "Assigned" ? t("Unassign") : t("Assign")}
      />
    </>
  );
}
