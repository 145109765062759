/* eslint-disable no-loop-func */
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "@mui/material/Avatar";
import DashboardsService from "../api/dashboards";
import { setDashboards } from "../store/dashboardsSlice";
import { useNavigate } from "react-router-dom";
import handleNavigate from "./customFunctions/handleNavigate";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.text,
  },
}));
const StylePageSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  fontSize: "40px",
  '&:hover': { color: theme.palette.custom.textDarkColor }
}));
function HomePagesMenu({ pageName }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const dashboards = useSelector(({ dashboards }) => dashboards);
  const [machineDeviceName, setPageDeviceName] = useState(
    pageName ? pageName : ""
  );
  const [anchorPage, setAnchorPage] = useState(null);
  const [dashboardsList, setDashboardsList] = useState([]);

  useEffect(() => {
    if (dashboards) {
      setDashboardsList(dashboards);
    } else if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;

      let NewDashboardItemsDynamic = [];
      DashboardsService.getGrafanaDashboardOperationLink({
        grafana_dashboard_link_id: "",
        operation_profile_id: organziation.uid.toString(),
        grafana_dashboard_id: "",
        status: "active",
        apiKey,
      })
        .then((json) => {
          if (
            json.success === true &&
            json.grafana_dashboard_operation_link.length > 0
          ) {
            const jsonArray = json.grafana_dashboard_operation_link;
            let LoopCount = 0;
            for (let i in jsonArray) {
              DashboardsService.GetGrafanaDashboard({
                grafana_dashboard_id: jsonArray[i].grafana_dashboard_id,
                name: "",
                name_backend: "",
                name_tab: "",
                name_type: "",
                status: "active",
                apiKey,
              })
                .then((jsonGrafana) => {
                  LoopCount++;
                  const jsonGrafanaDashboard = jsonGrafana.grafana_dashboard[0];
                  if (jsonGrafanaDashboard !== undefined) {
                    NewDashboardItemsDynamic.push({
                      name: jsonGrafanaDashboard.name,
                      image: `${process.env.PUBLIC_URL}/images/dashboards/${jsonGrafanaDashboard.name_backend}_Dark.png`,
                      href: `/dashboard?Dashboard=${jsonGrafanaDashboard.name_backend}&DeviceTitleDataName=${jsonGrafanaDashboard.name_type}&timerange`,
                      onclick: "",
                      tab: [`${jsonGrafanaDashboard.name_tab}`],
                    });
                  }
                  if (LoopCount === jsonArray.length) {
                    NewDashboardItemsDynamic.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    );
                    setDashboardsList(NewDashboardItemsDynamic);
                    dispatch(setDashboards(NewDashboardItemsDynamic));
                    //DashboardItemsDynamic = NewDashboardItemsDynamic;
                  }
                })
                .catch((errorr) => {
                  if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
                });
            }
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOpenPageMenu = (event) => {
    setAnchorPage(event.currentTarget);
  };
  const handleClosePageMenu = () => {
    setAnchorPage(null);
  };
  const handleChoosePage = (event, item) => {
    setPageDeviceName(item.name);
    // const newUrl =
    //   window.location.origin + window.location.pathname + item.href;
    handleNavigate(event, navigate, `/dashboard${item.href}`);
    // navigate(`/dashboard${item.href}`)
    // document.location.href = newUrl;
    //navigate(item.href)
    //window.location.reload();
    setAnchorPage(null);
  };
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="text"
          onClick={handleOpenPageMenu}
          sx={{ '&:hover': { backgroundColor: 'inherit' } }}
          endIcon={
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "45px",
                color: theme.palette.custom.text,
                '&:hover': {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                  boxShadow: 'none',
                }
              }}
            />
          }
        >
          <StylePageSpan>{t(machineDeviceName)}</StylePageSpan>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorPage}
        open={Boolean(anchorPage)}
        onClose={handleClosePageMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {dashboardsList &&
          dashboardsList.map((item) => (
            <MenuItem key={item} onClick={(e) => handleChoosePage(e, item)}>
              <Avatar
                sx={{ mr: 1 }}
                alt="Remy Sharp"
                src={item.image}
                variant="square"
              />
              <Typography textAlign="center">
                {t(item.name)}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export default HomePagesMenu;
